import { EmbeddedViewRef, Injectable } from '@angular/core';
import { CommonConstants, Control, ControlConfig, ControlResourceKeys, EmailAddressFormat, Typography } from '../constants/common-constants';
import { ToastrService } from 'ngx-toastr';
import { ControlHtmlService } from './control-html.service';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';
import { Guid } from "typescript-guid";
import { SessionStorageService } from './session-storage.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class ControlPropertyService {
    commonConstants = CommonConstants;
    arrControlIdNumber: any = [];
    controlFieldDependenceControlArray: any = [];
    hideFixedwidthCheckbox: any;
    isCheckboxDisabled: boolean;
    asteriskOriginalText: string = ''; // related to hide text with asterisks.
    hiddenText: string = '';
    dateFormat: any = [];
    dateformatValue: any = [];

    constructor(private toastrService: ToastrService,
        private controlHtmlService: ControlHtmlService,
        private commonService: CommonService,
        private authService: AuthService,
        private sessionStorageService: SessionStorageService
    ) {
        this.dateFormat = this.sessionStorageService.getDateformat();
        this.dateformatValue = this.sessionStorageService.getDateformatValue();
    }

    applyCheckboxRadioGroupName(textAreaVal: any, type: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let resizableCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));

        if (resizableCurrentSelectedControl) {
            if (textAreaVal == '' || textAreaVal == null) {
                let attr_id: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                if (attr_id.toLowerCase() == Control.Radio.toLowerCase()) {
                    let chkRadioRequiredSelected: any = (<HTMLInputElement>document.getElementById("chkRadioRequiredSelected"));
                    if (chkRadioRequiredSelected.checked) {
                        if (type == "1") {
                            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
                            //this.toastrService.warning('Please enter valid radio group value.', 'Warning');
                            this.showWarning('Please enter valid radio group value.', 'Warning')
                            return false;
                        }
                        else if (type == "2") {
                            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                            if (spnCurrentSelectedControl) {
                                spnCurrentSelectedControl?.setAttribute('name', '');
                            }
                            this.toastrService.warning('Radio group name cannot be blank.', 'Warning');
                            return false;
                        }
                    }
                }
                else if (attr_id.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    let checkboxgroup: any = (<HTMLInputElement>document.getElementById("checkboxgroup"));
                    if (checkboxgroup.checked) {
                        if (type == "1") {
                            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
                            this.toastrService.warning('Please enter valid checkbox group value.', 'Warning');
                            return false;
                        }
                        else if (type == "2") {
                            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                            if (spnCurrentSelectedControl) {
                                spnCurrentSelectedControl?.setAttribute('name', '');
                            }
                            this.toastrService.warning('Checkbox group name cannot be blank.', 'Warning');
                            return false;
                        }
                    }
                }
            }
            else {
                if (type == "1") {
                    let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
                    if (spnCurrentSelectedControl) {
                        spnCurrentSelectedControl?.setAttribute('data-groupname', textAreaVal);
                    }

                    let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));
                    if (inputGroupNameCtrl && inputGroupNameCtrl.value != '') {
                        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                        if (spnCurrentSelectedControl) {
                            spnCurrentSelectedControl?.setAttribute('name', inputGroupNameCtrl.value);
                        }
                    }
                }
                else if (type == "2") {
                    let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                    if (spnCurrentSelectedControl) {
                        spnCurrentSelectedControl?.setAttribute('name', textAreaVal);
                    }

                    let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
                    if (inputGroupCtrl && inputGroupCtrl.value != '') {
                        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
                        if (spnCurrentSelectedControl) {
                            spnCurrentSelectedControl?.setAttribute('data-groupname', inputGroupCtrl.value);
                        }
                    }
                }
            }
        }
        return true;
    }

    checkTooltipIsApplicable(cName: any, cId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + cId));
        if (controlEle) {
            let tooltip: any = controlEle.getAttribute('data-customtooltip');
            if (tooltip === null) {
                let controlTooltipEle: any = document.getElementById('controlInputTooltip');
                if (controlTooltipEle) {
                    controlTooltipEle.value = '';
                }
            }

            let guid: any = controlEle.getAttribute('data-guid');
            this.removeControlAttributes(controlEle, guid);
        }
    }

    getParentWidthAndHeight(currentEle: any) {
        let response: any;
        let imgId: any = currentEle.parentNode.parentNode.id;
        let imgControlElement: any = document.getElementById(imgId);
        let imgWidth: any = null;
        let imgHeight: any = null;
        // Access the first child element (assuming it is the img tag)
        var imgElement = imgControlElement.children[0];

        // Check if the child element is an img tag
        if (imgElement.tagName.toLowerCase() === 'img') {
            // Set the CSS style width property
            imgWidth = imgElement.offsetWidth;
            imgHeight = imgElement.offsetHeight;
        }
        response = { 'Width': imgWidth, 'Height': imgHeight };
        return response;
    }

    getElePositions(ctrlId: any) {
        let response: any = { 'Left': 0, 'Top': 0 };
        // if (ctrlId && ctrlId.style) {
        //     var transformString = ctrlId.style.transform;
        //     // Regular expression to match the numbers within the translate3d function
        //     var regex = /translate3d\(([-\d.]+)px,\s+([-\d.]+)px,\s+([-\d.]+)px\)/;

        //     // Match the values using the regex
        //     var match = transformString.match(regex);

        //     // Check if there is a match
        //     if (match) {
        //         // Extract the left, top, and third value (z-axis, not used in this case)
        //         var leftValue = parseFloat(match[1]);
        //         var topValue = parseFloat(match[2]);
        //         response = { 'Left': leftValue, 'Top': topValue };
        //     }
        //     return response;
        // }
        if (ctrlId && ctrlId.style) {
            response = { 'Left': parseFloat(ctrlId.style.left.replace('px', '')), 'Top': parseFloat(ctrlId.style.top.replace('px', '')) };
        }
        return response;
    }

    getDuplicateHeightWidth(fontFamily: any, fontSize: any) {
        var fakeEl = document.createElement('pre');
        fakeEl.id = 'hdnTextArea';
        fakeEl.style.display = 'inline-block';
        fakeEl.style.visibility = 'hidden';
        document.body.appendChild(fakeEl);

        fakeEl.textContent = 'abcdefghijklmnopqrstuvwxyz0123456789';
        fakeEl.style.fontFamily = fontFamily;
        fakeEl.style.fontSize = fontSize + 'px';

        var response = { 'Width': (fakeEl.offsetWidth / fakeEl.textContent.length), 'Height': fakeEl.offsetHeight };

        fakeEl.remove();

        if (response.Height === 260 && fontFamily === "Courier") {
            response.Height = 130;
        }

        // Now you can use 'response' object as needed.
        return response;
    }

    getDimensionBasedOnFontSizeAndStyle(fontFamily: any, fontSize: any) {
        var fakeEl = document.createElement('pre');
        fakeEl.id = 'hdnTextArea';
        fakeEl.style.display = 'inline-block';
        fakeEl.style.visibility = 'hidden';
        document.body.appendChild(fakeEl);

        fakeEl.textContent = 'ABCDEFGHIJKLMNOPQRSTUVWWWWWWWWWWWWWWWWWWWWWWWXYZ';
        fakeEl.style.fontFamily = fontFamily;
        fakeEl.style.fontSize = fontSize + 'px';

        var width = (fontSize === 60) ? 53 : (fakeEl.offsetWidth / fakeEl.textContent.length);
        var height = fakeEl.offsetHeight;

        var response = { 'Width': width, 'Height': height };

        fakeEl.remove();
        return response;
    }

    applyClickedProperty(checked: any, type: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        let parentCtrl: any = document.getElementById(currentSelectedControl);
        let ctrlName: any = '';
        if (parentCtrl) {
            ctrlName = parentCtrl.getAttribute('data-controlname');
        }
        if (spnCurrentSelectedControl) {
            if (type == "1") {
                if (checked)
                    spnCurrentSelectedControl?.setAttribute('data-selected', 'true');
                else
                    spnCurrentSelectedControl?.setAttribute('data-selected', 'false');
            }
            else if (type == "2") {
                if (checked)
                    spnCurrentSelectedControl?.setAttribute('data-selected1', 'true');
                else
                    spnCurrentSelectedControl?.setAttribute('data-selected1', 'false');
            }
            else if (type === '3') {
                if (checked) {
                    spnCurrentSelectedControl?.setAttribute('data-isfixedwidth', 'true');
                    let maxCharLimit = '';
                    switch (ctrlName.toLowerCase()) {
                        case 'name':
                            maxCharLimit = '100';
                            break;
                        case 'title':
                            maxCharLimit = '100';
                            break;
                        case 'text':
                            maxCharLimit = spnCurrentSelectedControl.getAttribute('data-validation-type') && spnCurrentSelectedControl.getAttribute('data-validation-type').toLowerCase() === 'email' ? '100' : '4000';
                            break;
                    }

                    let charInputLimitId: string;
                    let inputLengthId: string;

                    if (ctrlName.toLowerCase() === 'text') {
                        charInputLimitId = 'textCharInputLimit';
                        inputLengthId = 'textInputLength';
                    } else {
                        charInputLimitId = ctrlName.toLowerCase() + 'CharInputLimit';
                        inputLengthId = ctrlName.toLowerCase() + 'InputLength';
                    }

                    spnCurrentSelectedControl.setAttribute('data-maxcharlimit', maxCharLimit);

                    let charInputLimit = document.getElementById(charInputLimitId);
                    if (charInputLimit) {
                        this.hideCtrlCharLimitInput(charInputLimit);
                    }

                    let inputLength: any = document.getElementById(inputLengthId);
                    if (inputLength) {
                        this.showCtrlLengthInput(inputLength);
                        inputLength.value = 20;
                        spnCurrentSelectedControl.style.width = '167px';

                        if (ctrlName.toLowerCase() === 'title') {
                            let maxlengthallowedValue: any = spnCurrentSelectedControl.getAttribute('maxlengthallowed');
                            if (maxlengthallowedValue) {
                                inputLength.value = maxlengthallowedValue;
                            } else {
                                inputLength.value = '20';
                                spnCurrentSelectedControl.style.width = '167px';
                            }
                        }
                        if (ctrlName.toLowerCase() === 'text') {
                            spnCurrentSelectedControl.style.height = '20px';
                        }
                    }

                    if (ctrlName.toLowerCase() === 'text') {
                        let dataValidationType: any = spnCurrentSelectedControl.getAttribute('data-validation-type');
                        if (dataValidationType && dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date' ||
                            dataValidationType.toLowerCase() === 'email') {
                            setTimeout(() => {
                                inputLength.setAttribute('disabled', true);
                            }, 0);
                        }
                    }
                }
                else {
                    spnCurrentSelectedControl?.setAttribute('data-isfixedwidth', 'false');
                    let dataValidationType: any = '';
                    if (ctrlName.toLowerCase() === 'text') {
                        dataValidationType = spnCurrentSelectedControl.getAttribute('data-validation-type');
                    }
                    let controlTypeMap: any = {
                        'name': { inputLengthId: 'nameInputLength', charInputLimitId: 'nameCharInputLimit', maxCharLimit: 100 },
                        'title': { inputLengthId: 'titleInputLength', charInputLimitId: 'titleCharInputLimit', maxCharLimit: 100 },
                        'text': { inputLengthId: 'textInputLength', charInputLimitId: 'textCharInputLimit', maxCharLimit: (dataValidationType.toLowerCase() === 'email') ? 100 : 4000 }
                    };

                    let controlType: any = ctrlName.toLowerCase();
                    const controlInfo = controlTypeMap[controlType];

                    if (controlInfo) {
                        let inputLength: any = document.getElementById(controlInfo.inputLengthId);
                        if (inputLength) {
                            this.hideCtrlLengthInput(inputLength);
                        }

                        let charInputLimit: any = document.getElementById(controlInfo.charInputLimitId);
                        if (charInputLimit) {
                            this.showCtrlCharLimitInput(charInputLimit);
                            const maxCharLimit = controlInfo.maxCharLimit;
                            charInputLimit.value = controlInfo.maxCharLimit;
                            spnCurrentSelectedControl.style.width = '167px';
                            if (controlType === 'text') {
                                spnCurrentSelectedControl.style.height = '20px';
                            }

                            if (charInputLimit.value && charInputLimit.value <= maxCharLimit) {
                                spnCurrentSelectedControl.setAttribute('data-maxcharlimit', charInputLimit.value);
                                charInputLimit.value = charInputLimit.value;
                            } else {
                                charInputLimit.value = maxCharLimit.toString();
                                spnCurrentSelectedControl.setAttribute('data-maxcharlimit', charInputLimit.value);
                            }
                        }
                    }
                }

            }

            //remove dependency if checkbox is required control
            let guid: any = spnCurrentSelectedControl.getAttribute('data-guid');
            if (guid.toLowerCase() == Control.Checkbox.toLowerCase() && type == "1") {
                let isRequired: any = spnCurrentSelectedControl?.getAttribute('data-selected');
                if (isRequired && isRequired.toLowerCase() === 'true') {
                    let spnControl = (<HTMLInputElement>document.getElementById(currentSelectedControl));
                    let docContentId: any = spnControl.getAttribute('data-document-content-id');
                    this.removeColorForItem(currentSelectedControl);
                    document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');

                    let childControlsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
                    if (childControlsArray && childControlsArray.length > 0) {
                        Array.from(childControlsArray).forEach((item: any, v: any) => {
                            let attr_id: any = item?.getAttribute('id');
                            let childDocContentId: any = item.getAttribute('data-document-content-id');
                            this.removeColorForItem(attr_id);
                            this.removeAttributes(childDocContentId);
                        });
                    }
                }
            }
        }
    }

    removeAttributes(docContentId: any) {
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-id', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('is-parent-present');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-rule-id', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-support-text-content', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('alreadyDependentField');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('addedAsChild');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-parent-present', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.removeAttribute('group-code');
    }

    setAttributes(dependentItem: any, selectedControlFieldType: any, currentPanelContrlID: any) {
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-condition-id', dependentItem.ID);
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-id', currentPanelContrlID);
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('is-parent-present', 'true');
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-rule-id', dependentItem.ConditionID);
        if (selectedControlFieldType && selectedControlFieldType.toLowerCase() == Control.Text.toLowerCase()) {
            document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-support-text-content', dependentItem.SupportText);
        }
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.classList.add('alreadyDependentField');
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.classList.add('addedAsChild');
    }

    updateDependecyForDependentControls(currentControlId: any, ctrlContentId: any) {
        // let ControlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        let ControlDependencyFieldsString: any = this.sessionStorageService.getControlDependencyFieldsArray();
        if (ControlDependencyFieldsString) {
            let controlDependencyFieldsArray = JSON.parse(ControlDependencyFieldsString);
            controlDependencyFieldsArray = controlDependencyFieldsArray.filter((obj: any) => { return obj.ControlId.toLowerCase() != currentControlId.toLowerCase() });
            // localStorage.setItem('ControlDependencyFieldsArray', JSON.stringify(controlDependencyFieldsArray));
            this.sessionStorageService.setControlDependencyFieldsArray(JSON.stringify(controlDependencyFieldsArray));
        }

        // let allImageControlsString: any = localStorage.getItem('AllImageControls');
        let allImageControlsString: any = this.sessionStorageService.getAllImageControls();
        if (allImageControlsString) {
            let allImageControlsArray = JSON.parse(allImageControlsString);
            allImageControlsArray = allImageControlsArray.filter((obj: any) => { return obj.ControlId.toLowerCase() != currentControlId.toLowerCase() });
            // localStorage.setItem('AllImageControls', JSON.stringify(allImageControlsArray));
            this.sessionStorageService.setAllImageControls(JSON.stringify(allImageControlsArray));
        }

        //todo update dependencies fot this control if it is parent to othercontrol  data-parent-id
        let conditionalControlsDetailsArray: any = document.querySelectorAll('[data-parent-id="' + ctrlContentId + '"]');
        if (conditionalControlsDetailsArray && conditionalControlsDetailsArray.length > 0) {
            Array.from(conditionalControlsDetailsArray).forEach((existingParentIdCtrl: any, v: any) => {
                let attr_docContentId: any = existingParentIdCtrl.getAttribute('data-document-content-id');
                this.removeAttributes(attr_docContentId);
                this.removeElementColor(attr_docContentId);
            });
        }
    }

    showHideDependenciesButton() {
        let displayDependenciesButton: boolean = false;
        let btnGlblAddRules: any = document.getElementById("btnGlblAddRules");
        let divGlblAddRules: any = document.getElementById("divGlblAddRules");
        let controllingFieldClassArray: any = document.getElementsByClassName("controllingField");

        let count: any = 0;
        for (let i = 0; i < controllingFieldClassArray.length; i++) {
            if (controllingFieldClassArray[i]) {
                let attr_id: any = controllingFieldClassArray[i].getAttribute('id');
                let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + attr_id));
                if (controlEle && controlEle.style.display !== "none") {
                    count = parseInt(count) + 1;
                }
            }
        }
        let className: any = 'disabledCtrl';
        if (count > 0) {
            displayDependenciesButton = true;
            if (btnGlblAddRules) {
                btnGlblAddRules.classList.remove("disabledCtrl");
                btnGlblAddRules.setAttribute('title', "Add Dependencies");
                if (divGlblAddRules) {
                    divGlblAddRules.setAttribute('title', "Add Dependencies");
                    divGlblAddRules.classList.add("enabledCtrl");
                    divGlblAddRules.classList.remove("disabledPrepareCtrl");
                }
                className = 'enabledCtrl';
            }
        }
        else {
            displayDependenciesButton = false;
            btnGlblAddRules.classList.remove("enabledCtrl");
            btnGlblAddRules.setAttribute('title', "Add controlling field to the document and add dependencies");
            if (divGlblAddRules) {
                divGlblAddRules.setAttribute('title', "Add controlling field to the document and add dependencies");
                divGlblAddRules.classList.add("disabledPrepareCtrl");
                divGlblAddRules.classList.remove("enabledCtrl");
            }

            className = 'disabledCtrl';
        }
        return { className: className, displayDependenciesButton: displayDependenciesButton };
    }

    updateColorCode(groupColorCode: any) {
        let controllingFieldArray: any = document.querySelectorAll('.controllingField');
        if (controllingFieldArray && controllingFieldArray.length > 0) {
            Array.from(controllingFieldArray).forEach((item: any, v: any) => {
                let controlID: any = item.getAttribute('data-document-content-id');
                let attribute_id: any = item.getAttribute('id');
                var isParentPresent = item.getAttribute('is-parent-present');
                var isChildrenPresent = item.getAttribute('is-child-present');

                if (controlID != "") {
                    if (isParentPresent == "false" && isChildrenPresent == "true") {
                        var groupCode = item.getAttribute('group-code');
                        var attr_id = item.getAttribute('id');
                        if (typeof groupCode != 'undefined') {
                            var colorForElement = this.commonConstants.DependencyColorCodes[parseInt(groupCode) % 10].Color;
                            this.applyColorCodeForParent(attr_id, colorForElement, isChildrenPresent);
                            this.getChildsForParent(controlID, colorForElement);
                            groupColorCode = parseInt(groupCode) > groupColorCode ? parseInt(groupCode) : groupColorCode;
                        }
                    }
                    this.removeParentColor(controlID, attribute_id);
                }
            });
        }
        groupColorCode = groupColorCode + 1;
        return groupColorCode;
    }

    getChildsForParent(id: any, elementColor: any) {
        let getChilds = document.querySelectorAll('[data-parent-id="' + id + '"]');
        Array.from(getChilds).forEach((item: any, v: any) => {
            var attr_id = item.getAttribute('id');
            this.applyColorCodeForChild(attr_id, elementColor);
            let isChildPresentForCurrent = item.getAttribute('is-child-present');
            if (isChildPresentForCurrent == "true") {
                let docContentId = item.getAttribute('data-document-content-id');
                this.getChildsForParent(docContentId, elementColor);
                this.removeParentColor(docContentId, attr_id);
            }
        })
    }

    applyColorCodeForParent(attr_id: any, colorCode: any, isChildExists: any) {
        if (isChildExists == "false" || isChildExists == undefined) {
            this.applyColorCodeForItem(attr_id, colorCode);
        }
    }

    applyColorCodeForChild(attr_id: any, colorCode: any) {
        this.applyColorCodeForItem(attr_id, colorCode);
    }

    applyColorCodeForItem(attr_id: any, colorCode: any) {
        let resizableItem: any = document.getElementById("resizable_" + attr_id);
        if (resizableItem) {
            let guid: any = resizableItem.getAttribute('data-guid');
            if (guid && guid.toLowerCase() != Control.Checkbox.toLowerCase() && guid.toLowerCase() != Control.Radio.toLowerCase()) {
                resizableItem.classList.remove('control-active-bg-color');
                resizableItem.style["background-color"] = '';
                resizableItem.style["background"] = colorCode;
                resizableItem.style["background-color"] = colorCode;
                resizableItem.style["border-width"] = '';
            }
        }
        let divItem: any = document.getElementById("div" + attr_id);
        if (divItem) { divItem.style["border-color"] = "transparent transparent transparent " + colorCode; }
        let spnItem: any = document.getElementById("spn" + attr_id);
        if (spnItem) {
            spnItem.style["background-color"] = colorCode;
        }
    }

    removeParentColor(docContentId: any, attr_id: any) {
        let parentElement: any = document.querySelector('[data-document-content-id="' + docContentId + '"]');
        if (docContentId != "" && parentElement) {
            var isChildrenPresent = parentElement?.getAttribute('is-child-present');
            var isParPresent = parentElement?.getAttribute('is-parent-present');
            var attr_parent_id = parentElement?.getAttribute('id');
            let data_guid: any = '';
            if ((isChildrenPresent == 'false' || typeof isChildrenPresent == 'undefined') && (isParPresent == 'false' || typeof isParPresent == 'undefined')) {
                this.removeColorForItem(attr_parent_id);
            }
        }
    }

    removeElementColor(docContentId: any) {
        let childElement: any = document.querySelector('[data-document-content-id="' + docContentId + '"]');
        if (childElement != "" && childElement) {
            var attr_id = childElement?.getAttribute('id');
            this.removeColorForItem(attr_id);
        }
    }

    removeColorForItem(attr_id: any) {
        let data_guid: any = '';
        let resizableItem: any = document.getElementById("resizable_" + attr_id);
        if (resizableItem) {
            data_guid = resizableItem.getAttribute('data-guid');
            if (data_guid && data_guid.toLowerCase() != Control.Checkbox.toLowerCase() && data_guid.toLowerCase() != Control.Radio.toLowerCase()) {
                resizableItem.style["background-color"] = 'rgb(255, 255, 255)';
            }
        }

        let divItem: any = document.getElementById("div" + attr_id);
        if (divItem) {
            // let divItem: any = document.getElementById("div" + attr_id);
            if (data_guid) {
                if (data_guid.toLowerCase() == Control.DropDown.toLowerCase())
                    divItem.style["border-color"] = "transparent transparent transparent #fff";
                else divItem.style["border-color"] = "transparent transparent transparent #eee";
            }
        }
        let spnItem: any = document.getElementById("spn" + attr_id);
        if (data_guid && data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || data_guid.toLowerCase() != Control.Radio.toLowerCase()) {
            if (resizableItem && resizableItem.getAttribute('data-selected1') == "true") {
                let mainParentEle: any = document.getElementById(attr_id);
                if (mainParentEle) {
                    spnItem.classList.add('checked-visible');
                    spnItem.setAttribute('checked', 'true');
                    let isChildrenPresent: any = mainParentEle.getAttribute('is-child-present');
                    let isParentPresent: any = mainParentEle.getAttribute('is-parent-present');
                    if (isParentPresent == "true" || isChildrenPresent == "true") {
                        let controlGroupCode: any = mainParentEle.getAttribute('group-code');
                        if (controlGroupCode != null && controlGroupCode != "null" && controlGroupCode != undefined && controlGroupCode != "undefined" && controlGroupCode != "") {
                            if (controlGroupCode != "0" && controlGroupCode != 0) {
                                let colorCode: any = this.commonConstants.DependencyColorCodes[parseInt(controlGroupCode) % 10].Color;
                                this.applyColorCodeForParent(attr_id, colorCode, "false");
                            }
                        }
                    }
                    else {
                        spnItem.style["background-color"] = 'green';
                    }
                }
            }
            else {
                if (spnItem) { spnItem.style["background-color"] = ''; }
            }
        }
        else {
            if (spnItem) { spnItem.style["background-color"] = ''; }
        }
    }

    updateTextAreaText(textAreaVal: any, currentSelectedControl: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
        if (spnCurrentSelectedControl) {
            let textInput: any = document.getElementById('addTextTextarea');
            let checkbox: any = document.getElementById('checkboxHideText');
            let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
            if (ctrlEle && !textAreaVal.includes('*')) {
                //ctrlEle.setAttribute('data-isHideTextWithAsterisk', (checkbox != null && (checkbox.checked == 'true' || checkbox.checked == true)) ? 'true' : 'false');
                ctrlEle.setAttribute('data-isHideTextWithAsterisk', ctrlEle.getAttribute('data-isHideTextWithAsterisk'));
                let oldValue: any = ctrlEle.getAttribute('data-asteriskOriginalText');
                ctrlEle.setAttribute('data-asteriskOriginalText', textAreaVal.includes('*') ? oldValue !== "null" ? oldValue : '' : textAreaVal);
            }
            // Call toggleHideTextWithAsterisks if the checkbox is checked
            let isHideTextWithAsterisk: any = ctrlEle.getAttribute('data-isHideTextWithAsterisk');
            if (isHideTextWithAsterisk == 'true' || isHideTextWithAsterisk == true) {
                this.toggleHideTextWithAsterisks(true, currentSelectedControl);
            }
            // Save the text only if not empty (but we handle asterisks separately)
            let integrationType: any = ctrlEle.getAttribute('data-IntegrationType');
            if (integrationType === 'Bullhorn' || integrationType === 'Vincere') {
                let entityValue: any = ctrlEle?.getAttribute('data-EntityFieldValue');
                let entityField: any = ctrlEle?.getAttribute('data-entityfield');
                let validValue: any = entityValue && entityValue !== 'null' && entityValue !== 'undefined' ? entityValue : entityField && entityField !== 'null' && entityField !== 'undefined' ? entityField : textAreaVal;
                ctrlEle.setAttribute('data-addtextareaval', validValue && validValue === "*".repeat(validValue.length) ? ctrlEle?.getAttribute('data-addtextareaval') : validValue);
                ctrlEle.setAttribute('data-asteriskoriginaltext', validValue && validValue === "*".repeat(validValue.length) ? ctrlEle?.getAttribute('data-addtextareaval') : validValue);
                spnCurrentSelectedControl.innerHTML = validValue.replace(/\n/g, ' ');
                if (isHideTextWithAsterisk === true || isHideTextWithAsterisk === 'true') {
                    spnCurrentSelectedControl.innerHTML = '*'.repeat(spnCurrentSelectedControl.innerHTML.length);
                }
            }
            else {
                spnCurrentSelectedControl.innerHTML = textAreaVal.replace(/\n/g, ' ');
            }
            spnCurrentSelectedControl.classList.add('add-text-ellipsis');
            if (textInput) {
                spnCurrentSelectedControl.setAttribute('data-addtextareaval', textInput.value);
                ctrlEle.setAttribute('data-addtextareaval', textInput.value);
            }
            this.applyTextPrefillData(currentSelectedControl);
        }
    }

    applyIdAndHtmlId(ctrlObj: any) {
        let parentEle: any = document.getElementById(ctrlObj.id);
        if (parentEle) {
            let ctrlId: any = parentEle.getAttribute('data-int-control-id');
            let dataDocumentContentVal: any = parentEle.getAttribute('data-document-content-id');
            let dataDocumentContentId: any = document.getElementById('dataDocumentContent');
            let addCtrlHtmlId: any = document.getElementById('ctrlHtmlId');
            if (dataDocumentContentId) {
                dataDocumentContentId.value = dataDocumentContentVal;
            }
            if (addCtrlHtmlId) {
                addCtrlHtmlId.value = ctrlId;
            }
        }
    }

    //byDefault required field checked, unchecked
    addDefaultRequiredField(obj: any, isRequiredChecked: any) {
        let ctrlEle: any = document.getElementById('resizable_' + obj.id);
        if (ctrlEle) {
            ctrlEle.setAttribute('data-selected', isRequiredChecked);
        }

        let chkRequiredSelectedEle: any = document.getElementById('chkRequiredSelected');
        if (obj.className.toLowerCase() === 'radio') {
            chkRequiredSelectedEle = document.getElementById('chkRadioRequiredSelected');
        }
        if (chkRequiredSelectedEle) {
            chkRequiredSelectedEle.checked = isRequiredChecked;
        }
    }

    isControlingField(currentControlId: any) {
        let isControlingField = false;
        if (currentControlId != '' && currentControlId != null && currentControlId != undefined) {
            let item: any = document.getElementById('resizable_' + currentControlId);
            if (item) {
                let data_guid: any = item.getAttribute('data-guid');
                if ((data_guid != null && data_guid != '' && data_guid != undefined) &&
                    (data_guid.toLowerCase() == Control.Text.toLowerCase() || data_guid.toLowerCase() == Control.DropDown.toLowerCase() ||
                        data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || data_guid.toLowerCase() == Control.Radio.toLowerCase())) {
                    isControlingField = true;
                }
            }
        }
        return isControlingField;
    }

    applyControlProperties(currentSelectedControl: any, resizableCurrentSelectedControl: any, attr_id: any) {
        setTimeout(() => {
            let chRequiredSelected: any = (<HTMLInputElement>document.getElementById("chRequiredSelected"));
            if (chRequiredSelected) { //required
                if (resizableCurrentSelectedControl?.getAttribute('data-selected') == "true") chRequiredSelected.checked = true; else chRequiredSelected.checked = false;
            }

            let chkPreSelected: any = (<HTMLInputElement>document.getElementById("chkPreSelected"));
            if (chkPreSelected) { //preselected
                if (resizableCurrentSelectedControl?.getAttribute('data-selected1') == "true") chkPreSelected.checked = true; else chkPreSelected.checked = false;
            }

            let chkRadioRequiredSelected: any = (<HTMLInputElement>document.getElementById("chkRadioRequiredSelected"));
            if (chkRadioRequiredSelected) { //radio required
                if (resizableCurrentSelectedControl?.getAttribute('data-selected') == "true") chkRadioRequiredSelected.checked = true; else chkRadioRequiredSelected.checked = false;
            }

            let checkboxgroup: any = (<HTMLInputElement>document.getElementById("checkboxgroup"));
            let dataIsGroupChecked: any = resizableCurrentSelectedControl.getAttribute('data-isgroupchecked');
            if (checkboxgroup) { if (dataIsGroupChecked == "true") checkboxgroup.checked = true; else checkboxgroup.checked = false; }

            let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
            let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));
            if (inputGroupCtrl) {
                let attr_data_groupname: any = resizableCurrentSelectedControl.getAttribute('data-groupname');
                if (attr_data_groupname != '' && attr_data_groupname != null) {
                    inputGroupCtrl.value = attr_data_groupname;
                }
                else inputGroupCtrl.value = '';
            }

            if (inputGroupNameCtrl) {
                let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
                if (spnCurrentSelectedControl) {
                    let attr_name: any = spnCurrentSelectedControl.getAttribute('name');
                    if (attr_name != '' && attr_name != null) {
                        inputGroupNameCtrl.value = attr_name;
                    }
                    else if (attr_id.toLowerCase() == Control.Radio.toLowerCase()) {
                        inputGroupNameCtrl.value = 'Radio';
                    }
                    else if (attr_id.toLowerCase() == Control.Checkbox.toLowerCase()) {
                        inputGroupNameCtrl.value = 'Checkbox';
                    }
                }
            }

            if (attr_id && attr_id.toLowerCase() == Control.Date.toLowerCase() && resizableCurrentSelectedControl) {
                let prefillDateCheckbox: any = document.getElementById("prefillDateCheckbox");
                if (prefillDateCheckbox) {
                    if (resizableCurrentSelectedControl.getAttribute('data-defaultdate') == "true") prefillDateCheckbox.checked = true;
                    else prefillDateCheckbox.checked = false;
                }
            }

            if (attr_id && attr_id.toLowerCase() == Control.DropDown.toLowerCase() && resizableCurrentSelectedControl) {
                let drpFirstLineBlank: any = document.getElementById("drpFirstLineBlank");
                if (drpFirstLineBlank) {
                    if (resizableCurrentSelectedControl.getAttribute('data-firstlineblank') == "true") drpFirstLineBlank.checked = true;
                    else drpFirstLineBlank.checked = false;
                }
            }

            if (attr_id && attr_id.toLowerCase() == Control.Text.toLowerCase() && resizableCurrentSelectedControl) {
                let radioPrefill: any = document.getElementById("radioPrefill");
                let radioDisplayLabel: any = document.getElementById("radioDisplayLabel");
                let checkboxHideText: any = document.getElementById("checkboxHideText");
                if (checkboxHideText) checkboxHideText.checked = resizableCurrentSelectedControl.getAttribute('data-isHideTextWithAsterisk') == "true" ? true : false;
                checkboxHideText?.style.setProperty('background', '');
                if (resizableCurrentSelectedControl.getAttribute('data-prefill') == "true") {
                    if (radioPrefill) radioPrefill.checked = true;
                    if (radioDisplayLabel) radioDisplayLabel.checked = false;
                }
                else {
                    resizableCurrentSelectedControl.setAttribute('data-prefill', 'false');
                    resizableCurrentSelectedControl.setAttribute('data-displayLabel', 'true');
                    checkboxHideText.checked = (checkboxHideText.checked === true || checkboxHideText.checked === 'true') ? true : false;
                    if (radioPrefill) radioPrefill.checked = false;
                    if (radioDisplayLabel) radioDisplayLabel.checked = true;
                }
            }

            this.removeControlAttributes(resizableCurrentSelectedControl, resizableCurrentSelectedControl?.getAttribute('data-guid'));
        }, 15);
    }

    //byDefault is fixed width field checked
    addDefaultFixedWidth(obj: any, hideFixedwidthCheckbox: any, isFixedWidthChecked: any) {
        let pCtrlEle: any = document.getElementById(obj.id);
        let ctrlName: any = '';
        if (pCtrlEle) {
            ctrlName = pCtrlEle.getAttribute('data-controlname');
        }
        if (hideFixedwidthCheckbox === false) {
            let ctrlEle: any = document.getElementById('resizable_' + obj.id);
            if (ctrlEle) {
                ctrlEle.setAttribute('data-isfixedwidth', isFixedWidthChecked);
                if (ctrlName.toLowerCase() === 'text') {
                    ctrlEle.setAttribute('data-maxcharlimit', '4000');
                    ctrlEle.setAttribute('maxcharallowed', '4000');
                    ctrlEle.setAttribute('maxemailcharallowed', '4000');
                } else {
                    ctrlEle.setAttribute('data-maxcharlimit', '100');
                }
                obj.isFixedWidth = isFixedWidthChecked;
            }

            if (ctrlName.toLowerCase() === 'name') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'nameInputLength', 'nameCharInputLimit', 'isFixedWidthNameContainer', isFixedWidthChecked);
            }
            else if (ctrlName.toLowerCase() === 'title') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'titleInputLength', 'titleCharInputLimit', 'isFixedWidthTitleContainer', isFixedWidthChecked);
            }
            else if (ctrlName.toLowerCase() === 'text') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer', isFixedWidthChecked);
            }
        } else {
            setTimeout(() => {
                if (ctrlName.toLowerCase() === 'name') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'nameInputLength', 'nameCharInputLimit', 'isFixedWidthNameContainer');
                }
                else if (ctrlName.toLowerCase() === 'title') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'titleInputLength', 'titleCharInputLimit', 'isFixedWidthTitleContainer');
                }
                else if (ctrlName.toLowerCase() === 'text') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer');
                }
            }, 200);
        }
    }

    applyPrefillDefaultDateProperty(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId))
        if (event.target.checked) controlEle.setAttribute('data-defaultdate', "true");
        else controlEle.setAttribute('data-defaultdate', "false");
    }

    applyRadioGroup(event: any, currentControlId: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (spnCurrentSelectedControl) {
            if (event.target.checked)
                spnCurrentSelectedControl?.setAttribute('data-selected', 'true');
            else
                spnCurrentSelectedControl?.setAttribute('data-selected', 'false');
        }
    }

    applyFirstLineBlank(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId))
        if (controlEle) {
            if (event.target.checked) controlEle.setAttribute('data-firstlineblank', "true");
            else controlEle.setAttribute('data-firstlineblank', "false");
        }
    }

    applyLabelName(event: any, currentControlId: any, type: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId))
        if (controlEle) {
            if (event.target.value) {
                controlEle.innerHTML = event.target.value;
                controlEle.setAttribute('isupdated', "true");
                controlEle.setAttribute('data-label-text', event.target.value);
            }
            else {
                controlEle.innerHTML = ''; controlEle.setAttribute('isupdated', "false");
                if (type === 'label') { this.toastrService.warning('Please enter a label for the label control.', 'Warning'); return false; }
                //else {this.toastrService.warning('Please enter a label for the hyperlink control.', 'Warning'); return false;}
            }
        }
        return true;
    }

    applyHyperLinkURL(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById(currentControlId))
        if (controlEle) {
            if (event.target.value) {
                let spnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
                if (/^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(event.target.value)) {
                    controlEle.setAttribute('data-hyperlink', event.target.value);
                    // let spnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
                    if (spnControlEle) {
                        if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                            spnControlEle.innerHTML = event.target.value;
                            spnControlEle.setAttribute('isupdated', "true");
                        }
                    }
                }
                else {
                    event.target.value = '';
                    spnControlEle.setAttribute('isupdated', "false");
                    controlEle.setAttribute('data-hyperlink', '');
                    this.toastrService.warning(this.commonConstants.hyperlinkWarningMessage, 'Warning'); return false;
                }
            }
            else {
                controlEle.setAttribute('data-hyperlink', '');
                this.toastrService.warning(this.commonConstants.hyperlinkWarningMessage, 'Warning'); return false;
            }
        }
        return true;
    }

    applyNewContentId(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById(currentControlId))
        if (controlEle) {
            if (event.target.value) controlEle.setAttribute('data-int-control-id', event.target.value);
            else {
                this.toastrService.warning('Field cannot be blank.', 'Warning'); return false;
            }
        }
        return true;
    }

    applyRadioAndCheckBoxSizes(defaultSize: any, controlEle: any, spnControlEle: any) {
        controlEle.style["padding-top"] = "0px";
        if (defaultSize == "1") {
            if (controlEle) {
                controlEle.style.width = "20px";
                controlEle.style.height = "20px";
            }
            if (spnControlEle) {
                spnControlEle.style.width = "16px";
                spnControlEle.style.height = "16px";
            }
        }
        else if (defaultSize == "2") {
            if (controlEle) {
                controlEle.style.width = "22px";
                controlEle.style.height = "22px";
            }
            if (spnControlEle) {
                spnControlEle.style.width = "18px";
                spnControlEle.style.height = "18px";
            }
        }
        else if (defaultSize == "3") {
            if (controlEle) {
                controlEle.style.width = "24px";
                controlEle.style.height = "24px";
            }
            if (spnControlEle) {
                spnControlEle.style.width = "20px";
                spnControlEle.style.height = "20px";
            }
        }
        else if (defaultSize == "4") {
            if (controlEle) {
                controlEle.style.width = "26px";
                controlEle.style.height = "26px";
            }
            if (spnControlEle) {
                spnControlEle.style.width = "22px";
                spnControlEle.style.height = "22px";
            }
        }
        else {
            if (controlEle) {
                controlEle.style.width = "22px";
                controlEle.style.height = "22px";
            }
            if (spnControlEle) {
                spnControlEle.style.width = "18px";
                spnControlEle.style.height = "18px";
            }
        }
    }

    applyRadioCheckboxSizes(defaultSize: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-size', this.getRadioCheckBoxSize(defaultSize));
            let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId));
            this.applyRadioAndCheckBoxSizes(defaultSize, controlEle, spnControlEle);
        }
        return true;
    }

    getRadioCheckBoxSize(defaultSize: any) {
        if (defaultSize == "1") { return "Small"; }
        else if (defaultSize == "2") { return "Standard"; }
        else if (defaultSize == "3") { return "Large"; }
        else if (defaultSize == "4") { return "Extra Large"; }
        else return "Standard";
    }

    getRadioCheckBoxSizeVal(defaultSize: any) {
        if (defaultSize == "Small") { return "1"; }
        else if (defaultSize == "Standard") { return "2"; }
        else if (defaultSize == "Large") { return "3"; }
        else if (defaultSize == "Extra Large") { return "4"; }
        else return "2";
    }

    applyDefaultDateFormat(defaultDateFormat: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-defaultFormat', this.getDateFormat(defaultDateFormat));
        }
        let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId))
        if (spnControlEle) {
            spnControlEle.innerHTML = this.getDateFormatInnerHtmlVal(defaultDateFormat);
        }
        return true;
    }

    getDateFormat(defaultDateFormat: any) {
        this.dateFormat = this.sessionStorageService.getDateformat();
        let dateformatArray: any = this.dateFormat;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.value == defaultDateFormat);
        if (dateArrayObj && dateArrayObj.length > 0)
            return dateArrayObj[0].viewValue;
        else return this.dateFormat[0].viewValue;
    }

    getDateFormatInnerHtmlVal(defaultDateFormat: any) {
        this.dateFormat = this.sessionStorageService.getDateformat();
        this.dateformatValue = this.sessionStorageService.getDateformatValue();
        let dateformatArray: any = this.dateformatValue;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.value == defaultDateFormat);
        if (dateArrayObj && dateArrayObj.length > 0) {
            return dateArrayObj[0].viewValue;
        }
        else {
            return this.dateFormat[0].viewValue;
        }
    }

    getDateFormatVal(defaultDateFormatVal: any) {
        this.dateFormat = this.sessionStorageService.getDateformat();
        let dateformatArray: any = this.dateFormat;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.viewValue == defaultDateFormatVal);
        if (dateArrayObj && dateArrayObj.length > 0) {
            return dateArrayObj[0].value;
        }
        else {
            return this.dateFormat[0].value;
        }
    }

    getDefaultDateOption(dateFormatID: any) {
        this.dateFormat = this.sessionStorageService.getDateformat();
        let dateformatArray: any = this.sessionStorageService.getDateformat();
        if (dateformatArray && dateformatArray.length) {
            let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.dateFormatId.toLowerCase() == dateFormatID.toLowerCase());
            if (dateArrayObj && dateArrayObj.length > 0) {
                return dateArrayObj[0].value;
            }
            else {
                return '1';
            }
        } else {
            return '1';
        }
    }

    getTextTypeValue(textType: any) {
        let returnTypeVal: any = '';
        switch (textType.toLowerCase()) {
            case 'text':
                returnTypeVal = '26C0ACEA-3CC8-43D6-A255-A870A8524A77';
                break;
            case 'numeric':
                returnTypeVal = 'B0443A47-89C3-4826-BECC-378D81738D03';
                break;
            case 'alphabet':
                returnTypeVal = '88A0B11E-5810-4ABF-A8B6-856C436E7C49';
                break;
            case 'ssn':
                returnTypeVal = 'DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D';
                break;
            case 'zip':
                returnTypeVal = '5121246A-D9AB-49F4-8717-4EF4CAAB927B';
                break;
            case 'date':
                returnTypeVal = '8348E5CD-59EA-4A77-8436-298553D286BD';
                break;
            case 'email':
                returnTypeVal = '1AD2D4EC-4593-435E-AFDD-F8A90426DE96';
                break;
        }
        return returnTypeVal;
    }

    getFontFamilyId(fontFamily: any) {
        let returnId: any = 'D4A45ECD-3865-448A-92FA-929C2295EA34'; //default courier
        switch (fontFamily.toLowerCase()) {
            case 'arial':
                returnId = '1AB25FA7-A294-405E-A04A-3B731AD795AC';
                break;
            case 'cambria':
                returnId = '1875C58D-52BD-498A-BE6D-433A8858357E';
                break;
            case 'courier':
                returnId = 'D4A45ECD-3865-448A-92FA-929C2295EA34';
                break;
            case 'times new roman':
                returnId = '956D8FD3-BB0F-4E30-8E55-D860DEABB346';
                break;
        }
        return returnId;
    }

    applyFontSizePxOrPt(currentControlId: any, selectedValue: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (spnCurrentSelectedControl) {
            let fontSizeSelect: any = (<HTMLInputElement>document.getElementById('fontSizeSelect'));
            if (selectedValue && fontSizeSelect && fontSizeSelect.value) {
                spnCurrentSelectedControl.style.fontSize = fontSizeSelect.value + '' + selectedValue;
            }
        }
    }

    setTypographyConversionValue(fontSize: any) {
        let fontSizeVal: any = Typography.SetTypography[fontSize];
        return fontSizeVal;
    }

    getTypographyConversionValue(fontSize: any) {
        let fontSizeVal: any = Typography.GetTypography[fontSize];
        return fontSizeVal;
    }

    applyFontStyles(currentControlId: any, styleObj: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (spnCurrentSelectedControl) {
            spnCurrentSelectedControl.style.fontWeight = styleObj.isBold ? 'bold' : 'normal';
            spnCurrentSelectedControl.style.fontStyle = styleObj.isItalic ? 'italic' : 'normal';
            spnCurrentSelectedControl.style.textDecoration = styleObj.isUnderlined ? 'underline' : 'none';
        }
    }

    applyTextAlign(currentControlId: any, type: any) {
        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (resizableCurrentSelectedControl) {
            resizableCurrentSelectedControl.style.textAlign = type;
            resizableCurrentSelectedControl.setAttribute('data-textAlign', type);

            let hyperCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("hyper_" + currentControlId));
            if (hyperCurrentSelectedControl) {
                hyperCurrentSelectedControl.style.textAlign = type;
            }
        }
    }

    applyColorPicker(currentControlId: any, color: any) {
        setTimeout(() => {
            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
            if (spnCurrentSelectedControl) {
                spnCurrentSelectedControl.style.color = color;
            }
        }, 300);
    }

    //Updating Font Family Id based on selection
    updateDefaultFontFamilyId(id: any, fontFamily: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById('spn' + id));
        if (spnCurrentSelectedControl) {
            spnCurrentSelectedControl.style.fontFamily = fontFamily;
            spnCurrentSelectedControl.setAttribute('font-family-id', this.getFontFamilyId(fontFamily));
        }
    }

    //Numeric --> Number or Decimal
    applyTextTypeDecimal(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            const valueToFind = selectedVal;
            const additionalValidationItem = this.commonConstants.AdditionalValidation.find((item: { value: any; }) => item.value === valueToFind);
            if (additionalValidationItem) {
                controlEle.removeAttribute('data-additional-validation-option');
                controlEle.setAttribute('data-additional-validation-name', additionalValidationItem.viewname);
                if (selectedVal === '2' || selectedVal === 2) {
                    controlEle.setAttribute('data-additional-validation-option', '1');
                }
            }
        }
    }

    //Numeric --> Decimal options are Both,Comma and Period
    applyTextTypeDecimalOption(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
        }
    }

    applyTextTypeDate(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
            controlEle.setAttribute('data-texttype', this.getTextTypeValue('date'));
        }
    }

    applyTextTypeZip(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
            controlEle.setAttribute('data-texttype', this.getTextTypeValue('zip'));
        }
    }

    checkContolAttribuesData(type: any, typeEnvelopeOrTemplate: any) {
        const regex = /^[0-9a-zA-Z\ _]+$/;
        let isValid: any = true;
        let controlArray: any = [];
        let resultObj: any = {};
        let docGuid: any;
        let attr_data_texttype_Guid: any;
        let documentImagesControlsArray: any = document.querySelectorAll('.signControl');
        if (documentImagesControlsArray && documentImagesControlsArray.length > 0) {
            let totalControlCount = documentImagesControlsArray.length;
            Array.from(documentImagesControlsArray).forEach((ele: any) => {
                if (ele && isValid == true) {
                    let attr_id: any = ele?.getAttribute('id');
                    //resizable
                    let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + attr_id));
                    if (controlEle && controlEle.style.display !== "none") {
                        //Main parent div
                        let attr_int_control_id: any = ele?.getAttribute('data-int-control-id');
                        let attr_contentId: any = ele?.getAttribute('data-document-content-id');
                        let attr_pageNo: any = ele?.getAttribute('data-pageno');
                        let attr_doc_pageNo: any = ele?.getAttribute('docpageno');
                        let docImgAttr: any = document.getElementById("selectionBox" + attr_pageNo);
                        let imgDocumentId: any;
                        if (docImgAttr && docImgAttr != null) {
                            imgDocumentId = docImgAttr?.getAttribute('img-document-id');
                            docGuid = Guid.parse(imgDocumentId);
                            docGuid = docGuid.value;
                            attr_doc_pageNo = docImgAttr?.getAttribute('docpageno');
                        }
                        let attr_ctrlNum: any = ele?.getAttribute('data-ctrlnum');
                        let attr_ctrlName: any = ele?.getAttribute('data-controlname');
                        let attr_group_code: any = ele?.getAttribute('group-code');
                        let attr_data_condition_id: any = ele?.getAttribute('data-condition-id');
                        let attr_data_parent_id: any = ele?.getAttribute('data-parent-id');
                        let attr_is_parent_present: any = ele?.getAttribute('is-parent-present');
                        let attr_data_parent_rule_id: any = ele?.getAttribute('data-parent-rule-id');
                        let attr_is_child_present: any = ele?.getAttribute('is-child-present');
                        let attr_supportText: any = ele?.getAttribute('data-parent-support-text-content');

                        //Resizable div

                        let attr_data_guid: any = controlEle?.getAttribute('data-guid');
                        let attr_title: any = controlEle?.getAttribute('title');
                        let attr_data_rcptid: any = controlEle?.getAttribute('data-rcptid');
                        let attr_data_original_rcptid: any = controlEle?.getAttribute('data-original-rcptid');
                        let attr_controlValue: any = controlEle?.getAttribute('data-control-val');
                        // let attr_data_recp_color: any = controlEle?.getAttribute('data-recp-color');
                        // let attr_pageno: any = controlEle?.getAttribute('pageno');
                        let attr_data_typography: any = controlEle?.getAttribute('data-typography');
                        let attr_data_selected: any = controlEle?.getAttribute('data-selected');
                        let attr_data_selected1: any = controlEle?.getAttribute('data-selected1');
                        let attr_data_customtooltip: any = controlEle?.getAttribute('data-customtooltip');
                        let attr_data_original_title: any = controlEle?.getAttribute('data-original-title');
                        let attr_data_additional_validation_name: any = controlEle?.getAttribute('data-additional-validation-name');
                        let attr_data_additional_validation_option: any = controlEle?.getAttribute('data-additional-validation-option');
                        let attr_data_isfixedwidth: any = controlEle?.getAttribute('data-isfixedwidth');
                        let attr_data_prefill: any = controlEle?.getAttribute('data-prefill');
                        let attr_data_displayLabel: any = controlEle?.getAttribute('data-displayLabel');

                        let attr_maxlengthallowed: any = controlEle?.getAttribute('maxlengthallowed');
                        let attr_maxcharallowed: any = "";
                        let attr_maxemailcharallowed: any = "";
                        let attr_data_isHideTextWithAsterisk: any = controlEle?.getAttribute('data-isHideTextWithAsterisk');
                        let attr_data_asteriskOriginalText: any = controlEle?.getAttribute('data-asteriskOriginalText');

                        if (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") {
                            attr_maxcharallowed = controlEle?.getAttribute('data-maxcharlimit');
                            if (attr_maxcharallowed == null) {
                                attr_maxcharallowed = controlEle?.getAttribute('maxcharallowed');
                            }

                            attr_maxemailcharallowed = controlEle?.getAttribute('data-maxcharlimit');
                            if (attr_maxemailcharallowed == null) {
                                attr_maxemailcharallowed = controlEle?.getAttribute('maxemailcharallowed');
                            }
                        }
                        else {
                            attr_maxcharallowed = controlEle?.getAttribute('maxlengthallowed');
                            attr_maxemailcharallowed = controlEle?.getAttribute('maxlengthallowed');
                        }

                        if (attr_data_additional_validation_name != '' && attr_data_additional_validation_name != null) {
                            if (attr_data_additional_validation_name == 'Zip' || attr_data_additional_validation_name == "Zip Format") {
                                if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                    if (attr_data_additional_validation_option == '1')
                                        attr_data_additional_validation_option = 'Zip';
                                    else if (attr_data_additional_validation_option == '2')
                                        attr_data_additional_validation_option = 'Zip+4';
                                }
                                else attr_data_additional_validation_option = 'Zip';
                            }
                            else if (attr_data_additional_validation_name == 'Decimal') {
                                if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                    if (attr_data_additional_validation_option == '1')
                                        attr_data_additional_validation_option = 'Both';
                                    else if (attr_data_additional_validation_option == '2')
                                        attr_data_additional_validation_option = 'Comma';
                                    else if (attr_data_additional_validation_option == '3')
                                        attr_data_additional_validation_option = 'Period';
                                }
                                else attr_data_additional_validation_option = 'Both';
                            }
                            else if (attr_data_additional_validation_name == 'Date Format') {
                                if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                    if (attr_data_additional_validation_option == '1')
                                        attr_data_additional_validation_option = 'mm/dd/yyyy';
                                    else if (attr_data_additional_validation_option == '2')
                                        attr_data_additional_validation_option = 'dd/mm/yyyy';
                                    else if (attr_data_additional_validation_option == '3')
                                        attr_data_additional_validation_option = 'yyyy/mm/dd';
                                    else if (attr_data_additional_validation_option == '4')
                                        attr_data_additional_validation_option = 'dd-mmm-yyyy';
                                }
                                else attr_data_additional_validation_option = 'mm/dd/yyyy';
                            }
                        }

                        let attr_style: any = controlEle?.getAttribute('style');
                        //Spn div
                        let spnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + attr_id));
                        let attr_font_family_id: any = spnControlEle?.getAttribute('font-family-id');
                        if (attr_font_family_id == null || attr_font_family_id == "null" || attr_font_family_id == "" || attr_font_family_id == undefined) attr_font_family_id = 'd4a45ecd-3865-448a-92fa-929c2295ea34';
                        let attr_spn_style: any = spnControlEle?.getAttribute('style');
                        let textAlign: any = spnControlEle?.getAttribute('data-textAlign');
                        if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) { textAlign = 'left'; }

                        let innerText: any = attr_ctrlName, name: any = '', color: any = '', senderControlValue: any = '', labelValue: any = '', groupname: any = null, localControlStyle: any = null;
                        let selectControl: any = [], dependencies: any = [];
                        let attr_data_texttype: any = '', attr_data_size: any = '', attr_data_defaultdate: any = false;

                        let style: any = {};
                        var styelAttr = attr_spn_style;
                        if (typeof styelAttr !== 'undefined' && styelAttr !== false) {
                            var styles = styelAttr.split(';');
                            for (var st in styles) {
                                var aux = (styles[st]).trim().split(':');
                                if (aux[1] && aux[1] != '' && aux[1].trim() != 'undefined')
                                    style[(aux[0]).trim()] = (aux[1].trim());
                            }
                        }

                        if (!(attr_title == "Checkbox" || attr_title == "Radio" || attr_title == "Dropdown")) {
                            color = style['color'] || "#000000";
                            if (color.indexOf('rgb') > -1) {
                                var regExp = /\(([^)]+)\)/;
                                var matches: any = regExp.exec(color);
                                let result: any, rgb: any;
                                if (matches[1]) {
                                    result = matches[1].replace(/\s/g, '').split(',');
                                }
                                rgb = result.map(function (x: any) { return parseInt(x); });
                                color = "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
                            }

                            localControlStyle = {
                                FontColor: color || "#000000",
                                FontID: attr_font_family_id != null && attr_font_family_id != "null" ? attr_font_family_id.toLowerCase() : '',
                                FontFamily: style['font-family'] != null && style['font-family'] != 'null' && style['font-family'] != undefined ? style['font-family'] : 'Courier',
                                FontSize: (style['font-size']) ? style['font-size'].replace('px', '') : 14,
                                IsBold: (style['font-weight']) ? (style['font-weight'] === 'bold' || style['font-weight'] === '600') ? true : false : false,
                                IsItalic: (style['font-style']) ? style['font-style'] === 'italic' ? true : false : false,
                                IsUnderline: (style['text-decoration']) ? style['text-decoration'] === 'underline' ? true : false : false,
                                AdditionalValidationName: attr_data_additional_validation_name != null ? attr_data_additional_validation_name : '',
                                AdditionalValidationOption: attr_data_additional_validation_option != null ? attr_data_additional_validation_option : '',
                                ControlHeight: (style['height']) ? style['height'].replace('px', '') : 18,
                                ControlWidth: (style['width']) ? style['width'].replace('px', '') : 18,
                                TextAlign: textAlign != null && textAlign != "null" && textAlign != '' && textAlign != undefined ? textAlign : 'left'
                            };
                        }

                        var currentStyle: any = {};
                        var styelAttr = attr_style;
                        if (typeof styelAttr !== 'undefined' && styelAttr !== false) {
                            var styles = styelAttr.split(';');
                            for (var st in styles) {
                                var aux = styles[st].trim().split(':');
                                if (aux[1] && aux[1] != 'undefined')
                                    currentStyle[aux[0].trim()] = aux[1].trim();
                            }
                        }

                        var controlHeight = controlEle.offsetHeight;
                        var controlWidth = controlEle.offsetWidth;
                        if ((attr_title == "Checkbox" || attr_title == "Radio") && currentStyle) {
                            controlHeight = currentStyle.height ? parseFloat(currentStyle.height.replace('px', '')) : controlHeight;
                            controlWidth = currentStyle.width ? parseFloat(currentStyle.width.replace('px', '')) : controlWidth;
                        }

                        let selControl: any = document.getElementById(attr_id);
                        let parentHeight: any = selControl.parentNode.parentNode.offsetHeight; //'imgControl_' + attr_pageNo
                        let controlPositions: any = this.getElePositions(controlEle);
                        let controlLeftPosition: any = parseFloat(controlPositions.Left);
                        let controlTopPosition: any = parseFloat(controlPositions.Top);
                        // let outerHeight: any = controlEle.style.height.split('px')[0];
                        let controlBottom: any = parseInt(parentHeight) - (controlTopPosition + parseInt(controlHeight));

                        let imgEle: any = document.getElementById(attr_pageNo);
                        var imageWidth = imgEle.width;
                        var imageHeight = imgEle.height;
                        let originalWidth: any = imgEle.naturalWidth;
                        let originalHeight: any = imgEle.naturalHeight;
                        let ratioWidth: any = 1 / (imageWidth / originalWidth);
                        let ratioHeight: any = 1 / (imageHeight / originalHeight);

                        let ZCoordinate: any; //need to check once again
                        if ((controlBottom * ratioHeight) <= 0) ZCoordinate = 1;
                        else ZCoordinate = (controlBottom * ratioHeight);

                        senderControlValue = (attr_data_selected1 == null || attr_data_selected1 == "false" || attr_data_selected1 == false) ? "" : attr_data_selected1;
                        let controlHtml: any = '', attr_data_hyperlink: any = '', selectOptionString: any = '';
                        if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Signature.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getSignatureHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Initials.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getInitialHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getDateTimeStampHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Title.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getTitleHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Company.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getCompanyHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Email.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getEmailHtml();
                            let attr_data_confirm_submit: any = controlEle.getAttribute('data-confirm-submit');
                            senderControlValue = attr_data_confirm_submit;
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Name.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getNameHtml();
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getDropdownHtml();
                            // let attr_data_defaultdate: any = controlEle?.getAttribute('data-firstlineblank');
                            let selectDropdownControlField: any = (document.getElementById('spn' + attr_id)) as HTMLSelectElement;
                            if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                                for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                                    var text = selectDropdownControlField.options[index].text;
                                    var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                                    var value = selectDropdownControlField.options[index]?.getAttribute('value');
                                    selectControl.push({ ID: ruleId, OptionText: text, Order: value });

                                    var option = '';
                                    if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                                    else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                                    selectOptionString = selectOptionString + option;
                                }

                                if (selectOptionString == '') {
                                    if (type == "send") { isValid = false; this.toastrService.warning('Please add list items for the dropdown control.', 'Warning'); return isValid; }
                                }
                            }
                            else {
                                if (type == "send") { isValid = false; this.toastrService.warning('Please add list items for the dropdown control.', 'Warning'); return isValid; }
                            }
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getCheckboxHtml();
                            let attr_data_isGroupChecked: any = controlEle?.getAttribute('data-isGroupChecked');
                            let attr_data_groupname: any = controlEle?.getAttribute('data-groupname');
                            groupname = attr_data_groupname;
                            if (spnControlEle) name = spnControlEle.getAttribute('name');
                            if (attr_data_isGroupChecked != null && attr_data_isGroupChecked == 'true') {
                                if (attr_data_groupname == null || attr_data_groupname == '') {
                                    if (type == "send") {
                                        isValid = false; this.toastrService.warning('Please enter checkbox control group name field value.', 'Warning'); return isValid;
                                    }
                                }
                                else if (attr_data_groupname != "" && attr_data_groupname.length > 50) {
                                    if (type == "send") {
                                        isValid = false; this.toastrService.warning("Group Name should not be more than 50 characters.", 'Warning'); return isValid;
                                    }
                                }
                                else if (attr_data_groupname != "" && !attr_data_groupname.match(regex)) {
                                    if (type == "send") {
                                        isValid = false; this.toastrService.warning("Group Name should be only characters and numbers.", 'Warning'); return isValid;
                                    }
                                }
                                else if (name == null || name == '') {
                                    if (type == "send") {
                                        isValid = false; this.toastrService.warning('Please enter checkbox control name field value.', 'Warning'); return isValid;
                                    }
                                }
                            }
                            attr_data_size = controlEle?.getAttribute('data-size');
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getRadioHtml();
                            attr_data_size = controlEle?.getAttribute('data-size');
                            let attr_data_groupname: any = controlEle?.getAttribute('data-groupname');
                            groupname = attr_data_groupname;
                            if (spnControlEle) name = spnControlEle.getAttribute('name');
                            if (attr_data_groupname == null || attr_data_groupname == '') {
                                if (type == "send") { isValid = false; this.toastrService.warning('Please enter radio control group name field value.', 'Warning'); return isValid; }
                            }
                            else if (attr_data_groupname != "" && attr_data_groupname.length > 50) {
                                if (type == "send") { isValid = false; this.toastrService.warning("Group Name should not be more than 50 characters.", 'Warning'); return isValid; }
                            }
                            else if (attr_data_groupname != "" && !attr_data_groupname.match(regex)) {
                                if (type == "send") { isValid = false; this.toastrService.warning("Group Name should be only characters and numbers.", 'Warning'); return isValid; }
                            }
                            else if (name == null || name == '') {
                                if (type == "send") { isValid = false; this.toastrService.warning('Please enter radio control name field value.', 'Warning'); return isValid; }
                            }
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getDateHtml();
                            attr_data_defaultdate = controlEle?.getAttribute('data-defaultdate');
                            // let attr_data_defaultformat: any = controlEle?.getAttribute('data-defaultformat');
                            if (spnControlEle) innerText = spnControlEle.innerHTML;
                            senderControlValue = (attr_data_selected1 == null || attr_data_selected1 == "false" || attr_data_selected1 == false) ? "" : attr_data_selected1;
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getTextHtml();
                            if (spnControlEle) innerText = spnControlEle.innerText; // data-prefill
                            if (innerText == null || innerText == '') {
                                let isPrefill: any = controlEle?.getAttribute('data-prefill');
                                if (type == "send" && (isPrefill === true || isPrefill === 'true')) { isValid = false; this.toastrService.warning('Please enter a value for the text control.', 'Warning'); return isValid; }
                            }

                            attr_data_texttype = (controlEle?.getAttribute('data-texttype') != null && controlEle?.getAttribute('data-texttype') != '') ? Guid.parse(controlEle?.getAttribute('data-texttype')) : null;
                            if (attr_data_texttype) attr_data_texttype_Guid = attr_data_texttype.value;
                            else attr_data_texttype_Guid = null;

                            // let attr_data_validation_type: any = controlEle?.getAttribute('data-validation-type');
                            // let attr_data_additional_validation_name: any = controlEle?.getAttribute('data-additional-validation-name');
                            // let attr_data_additional_validation_option: any = controlEle?.getAttribute('data-additional-validation-option');
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Label.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getLabelHtml();
                            if (spnControlEle) innerText = spnControlEle.innerText;
                            if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                                innerText = '';
                            }
                            if (innerText == null || innerText == '') {
                                if (type == "send") { isValid = false; this.toastrService.warning("Please enter a label in the label control.", 'Warning'); return isValid; }
                            }
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
                            controlHtml = this.controlHtmlService.getHyperlinkHtml();
                            attr_data_hyperlink = ele?.getAttribute('data-hyperlink');
                            senderControlValue = attr_data_hyperlink;
                            if (spnControlEle) innerText = spnControlEle.innerText;
                            if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                                innerText = '';
                            }
                            if (innerText == null || innerText == '') {
                                if (type == "send") { isValid = false; this.toastrService.warning('Please enter a label for the hyperlink control.', 'Warning'); return isValid; }
                            }
                            else if (attr_data_hyperlink == null || attr_data_hyperlink == '') {
                                if (type == "send") { isValid = false; this.toastrService.warning(this.commonConstants.hyperlinkWarningMessage, 'Warning'); return isValid; }
                            }
                        }

                        let rawDependentControlsOnDocument: any = document.querySelectorAll('[data-parent-id="' + attr_contentId + '"]');
                        if (rawDependentControlsOnDocument != undefined && rawDependentControlsOnDocument != null && rawDependentControlsOnDocument.length > 0) {
                            Array.from(rawDependentControlsOnDocument).forEach((ritem: any, index: any) => {
                                dependencies.push({
                                    ControlID: ritem.getAttribute('data-document-content-id').toLowerCase(),
                                    ControlParentID: attr_contentId.toLowerCase(),
                                    ConditionID: ritem.getAttribute('data-parent-rule-id').toLowerCase(),
                                    ConditionText: ritem.getAttribute('data-parent-support-text-content') == undefined ? null : ritem.getAttribute('data-parent-support-text-content'),
                                    GroupCode: attr_group_code
                                });
                            });
                        }

                        labelValue = innerText || attr_data_original_title;
                        //todo integration type
                        let entityValue: any = '';
                        let integrationType: any = controlEle?.getAttribute('data-IntegrationType');
                        if (typeEnvelopeOrTemplate === "envelope" && integrationType != "undefined" && integrationType != "null" && integrationType != undefined && integrationType != null && integrationType != '' &&
                            (integrationType === 'Bullhorn' || integrationType === 'Vincere') && controlEle?.getAttribute('data-Entity') != 'Select' && controlEle?.getAttribute('data-EntityFieldValue') != 'Select') {
                            let entityType: any = controlEle?.getAttribute('data-EntityField');
                            entityValue = controlEle?.getAttribute('data-EntityFieldValue');
                            if (controlEle.id.includes('resizable_textControl')) {
                                let entityTypeText: any = entityType.toLowerCase();
                                if (entityTypeText && (entityTypeText.includes('date') || entityTypeText.toLowerCase() == 'availability start')) {
                                    let ctrl: any = document.getElementById(controlEle.id);
                                    let validationOption: any;
                                    let defaultDateFormatValue = "mm/dd/yyyy";
                                    if (this.sessionStorageService.getCulture() === "es-es") defaultDateFormatValue = "mm/dd/aaaa";
                                    validationOption = (attr_data_additional_validation_option && attr_data_additional_validation_option != "null" && attr_data_additional_validation_option != "undefined") ? attr_data_additional_validation_option : defaultDateFormatValue;

                                    if (ctrl) {
                                        this.updateDateFormatNameAndOptions(ctrl, validationOption);
                                    }
                                    entityValue = controlEle?.getAttribute('data-entityfielddateformatedvalue');
                                    controlEle.setAttribute('data-asteriskoriginaltext', entityValue);
                                }
                            }
                            senderControlValue = '';
                        }
                        else {
                            entityValue = controlEle?.getAttribute('data-EntityFieldValue');
                        }

                        senderControlValue = this.checkNull(entityValue, senderControlValue);
                        if(!entityValue && attr_data_prefill && attr_data_prefill == "true")
                        {
                            if(attr_data_isHideTextWithAsterisk === true || attr_data_isHideTextWithAsterisk === 'true'){
                                senderControlValue = attr_data_asteriskOriginalText;
                            }else{
                                senderControlValue = labelValue;
                            }
                        }
                        if (isValid == false) return false;
                        else {
                            controlHtml = controlHtml.replace("#id", attr_id);
                            controlHtml = controlHtml.replace("#ctrlNumber", attr_ctrlNum);
                            controlHtml = controlHtml.replace("#left", controlLeftPosition + "px");
                            controlHtml = controlHtml.replace("#top", controlTopPosition + "px");

                            if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase()) {
                                controlHtml = controlHtml.replace("#width", controlWidth + "px");
                                controlHtml = controlHtml.replace("#height", controlHeight + "px");
                            }

                            controlHtml = controlHtml.replace("#contentId", attr_contentId);
                            controlHtml = controlHtml.replace("#controlId", attr_int_control_id);
                            controlHtml = controlHtml.replace("#groupCode", attr_group_code == null ? '' : attr_group_code);
                            controlHtml = controlHtml.replace("#conditionId", attr_data_condition_id == null ? '' : attr_data_condition_id.toLowerCase());
                            controlHtml = controlHtml.replace("#borderColor", currentStyle.outline == null ? '' : currentStyle.outline);

                            if (ele?.classList.contains('addedAsChild') == true || (attr_is_child_present != null && attr_is_child_present != "null" && attr_is_child_present != "") ||
                                (attr_is_parent_present != null && attr_is_parent_present != "null" && attr_is_parent_present != "")) {
                                if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase())
                                    controlHtml = controlHtml.replace("#backGroundColor", (style && style['background-color'] != null) ? style['background-color'] : currentStyle.background);
                                else
                                    controlHtml = controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);
                            }
                            else {
                                controlHtml = controlHtml.replace("#backGroundColor", "rgba(220, 220, 220, 1)");
                            }

                            // controlHtml = controlHtml.replace("#backGroundColor", "rgba(220, 220, 220, 1)");
                            // controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);
                            controlHtml = controlHtml.replace("#originalTitle", attr_data_original_title == null || attr_data_original_title == 'null' ? '' : attr_data_original_title);
                            controlHtml = controlHtml.replace("#title", attr_title == null ? '' : attr_title);
                            controlHtml = controlHtml.replace("#parentId", attr_data_parent_id == null || attr_data_parent_id == 'null' ? '' : attr_data_parent_id.toLowerCase());
                            controlHtml = controlHtml.replace("#parentRuleId", attr_data_parent_rule_id == null || attr_data_parent_rule_id == 'null' ? '' : attr_data_parent_rule_id.toLowerCase());
                            controlHtml = controlHtml.replace("#supportText", attr_supportText == null || attr_supportText == 'null' ? '' : attr_supportText);
                            controlHtml = controlHtml.replace("#color", color == null ? "#000000" : color);
                            controlHtml = controlHtml.replace("#guid", attr_data_guid == null ? '' : attr_data_guid.toLowerCase());
                            controlHtml = controlHtml.replace("#recipientId", attr_data_rcptid == null || attr_data_rcptid == 'null' ? '' : attr_data_rcptid.toLowerCase());
                            controlHtml = controlHtml.replace("#required", (attr_data_selected == undefined || attr_data_selected == "false" || attr_data_selected == null) ? "false" : "true");

                            controlHtml = controlHtml.replace("#customTooltip", attr_data_customtooltip == null ? '' : attr_data_customtooltip);
                            controlHtml = controlHtml.replace("#originalRecipientId", attr_data_original_rcptid == null ? '' : attr_data_original_rcptid.toLowerCase());

                            if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase() && attr_data_guid.toLowerCase() != Control.DropDown.toLowerCase()) {
                                controlHtml = controlHtml.replace("#fontFamily", localControlStyle.FontFamily);
                                controlHtml = controlHtml.replace("#fontWeight", localControlStyle.IsBold);
                                controlHtml = controlHtml.replace("#textDecoration", localControlStyle.IsUnderline);
                                controlHtml = controlHtml.replace("#fontstyle", localControlStyle.IsItalic);
                                controlHtml = controlHtml.replace("#fontsize", localControlStyle.FontSize + "px");
                                controlHtml = controlHtml.replace("#textAlign", localControlStyle.TextAlign);
                                controlHtml = controlHtml.replace("#lineHeight", (style['line-height']) ? style['line-height'] : localControlStyle.FontSize + "px");
                                controlHtml = controlHtml.replace("#fontFamilyId", attr_font_family_id != null ? attr_font_family_id.toLowerCase() : '');
                                controlHtml = controlHtml.replace("#placeHolder", spnControlEle == null ? attr_title : spnControlEle.innerHTML);
                            }

                            if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase()) {
                                controlHtml = controlHtml.replace("#width", controlWidth + "px");
                                controlHtml = controlHtml.replace("#height", controlHeight + "px");
                            }

                            controlHtml = controlHtml.replace("#borderColor", currentStyle.outline == null ? '' : currentStyle.outline);
                            controlHtml = controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);

                            if (attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) controlHtml = controlHtml.replace("#preselected", (attr_data_defaultdate == undefined || attr_data_defaultdate == "false" || attr_data_defaultdate == null) ? "false" : "true");
                            else controlHtml = controlHtml.replace("#preselected", (attr_data_selected1 == undefined || attr_data_selected1 == "false" || attr_data_selected1 == null) ? "false" : "true");

                            if (attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Text.toLowerCase() || attr_data_guid.toLowerCase() == Control.Title.toLowerCase()) {
                                controlHtml = controlHtml.replace("#isfixedwidth", (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") ? false : true);
                                if ((attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false")) {
                                    // controlHtml = controlHtml.replace("#maxlengthallowed", '20');
                                    controlHtml = controlHtml.replace("#maxcharallowed", attr_maxcharallowed != null && attr_maxcharallowed != 'null' ? attr_maxcharallowed : '');
                                    controlHtml = controlHtml.replace("#maxemailcharallowed", attr_maxemailcharallowed != null && attr_maxemailcharallowed != 'null' ? attr_maxemailcharallowed : '');
                                    controlHtml = controlHtml.replace("#maxlengthallowed", attr_maxlengthallowed != null && attr_maxlengthallowed != 'null' ? attr_maxlengthallowed : '20');
                                }
                                else {
                                    if (attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Title.toLowerCase()) {
                                        controlHtml = controlHtml.replace("#maxcharallowed", '100');
                                        controlHtml = controlHtml.replace("#maxemailcharallowed", '100');
                                    }
                                    else {
                                        controlHtml = controlHtml.replace("#maxcharallowed", '4000');
                                        controlHtml = controlHtml.replace("#maxemailcharallowed", '100');
                                    }
                                    controlHtml = controlHtml.replace("#maxlengthallowed", attr_maxlengthallowed != null && attr_maxlengthallowed != 'null' ? attr_maxlengthallowed : '4000');
                                }
                                controlHtml = controlHtml.replace("#dataIsHideTextWithAsterisk", (attr_data_isHideTextWithAsterisk == false || attr_data_isHideTextWithAsterisk == undefined || attr_data_isHideTextWithAsterisk == null || attr_data_isHideTextWithAsterisk == "false") ? false : true);

                            }
                            else {
                                controlHtml = controlHtml.replace("#isfixedwidth", true);
                                controlHtml = controlHtml.replace("#maxlengthallowed", '20');
                                controlHtml = controlHtml.replace("#maxcharallowed", '20');
                                controlHtml = controlHtml.replace("#maxemailcharallowed", '20');
                            }

                            if (attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) controlHtml = controlHtml.replace("#attr_data_hyperlink", attr_data_hyperlink);
                            if (attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                                controlHtml = controlHtml.replace("#textType", attr_data_texttype == null ? '' : attr_data_texttype);
                                let old_attr_data_addTextAreaText: any = spnControlEle?.getAttribute('data-addtextareaval');
                                if (old_attr_data_addTextAreaText != undefined && old_attr_data_addTextAreaText != null && old_attr_data_addTextAreaText != "null" && old_attr_data_addTextAreaText != "undefined") controlHtml = controlHtml.replace("#dataAddTextAreaVal", old_attr_data_addTextAreaText);
                                else controlHtml = controlHtml.replace("#dataAddTextAreaVal", '');

                                //Setting AddTextArea attribute dataAddTextAreaVal
                                // let addTextTextareaEle: any = document.getElementById('addTextTextarea');
                                // if (addTextTextareaEle) {
                                //     controlHtml = controlHtml.replace("#dataAddTextAreaVal", old_attr_data_addTextAreaText == null ? '' : old_attr_data_addTextAreaText);
                                // }
                            }

                            if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                                if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild", 'parentBorder addedAsChild');
                                else controlHtml = controlHtml.replace("#addedAsChild", '');

                                controlHtml = controlHtml.replace("#isChildPresent", attr_is_child_present == null ? "false" : attr_is_child_present);
                                controlHtml = controlHtml.replace("#isParentPresent", attr_is_parent_present == null ? "false" : attr_is_parent_present);
                            }

                            if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                                controlHtml = controlHtml.replace("#groupName", (groupname == null || groupname == '') ? "" : groupname);
                                controlHtml = controlHtml.replace("#size", attr_data_size == null ? '' : attr_data_size);
                                controlHtml = controlHtml.replace("#name", name == null ? '' : name);
                                controlHtml = controlHtml.replace("#width", style['width']);
                                controlHtml = controlHtml.replace("#height", style['height']);
                                if (attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                                    if (attr_data_selected == "true") controlHtml = controlHtml.replace("#disabled", 'disabled');
                                    else controlHtml = controlHtml.replace("#disabled", '');
                                    labelValue = (name == null || name == '') ? 'Checkbox' : name;
                                    name = (name == null || name == '') ? 'Checkbox' : name;
                                }
                            }

                            if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                                controlHtml = controlHtml.replace("#isChildPresent", attr_is_child_present == null ? "false" : attr_is_child_present);
                                controlHtml = controlHtml.replace("#isParentPresent", attr_is_parent_present == null ? "false" : attr_is_parent_present);

                                if (controlEle?.classList.contains('expanded') == true) controlHtml = controlHtml.replace("#expanded", 'expanded');
                                else controlHtml = controlHtml.replace("#expanded", '');

                                if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild1", 'parentBorder addedAsChild');
                                else controlHtml = controlHtml.replace("#addedAsChild1", '');

                                controlHtml = controlHtml.replace("#fontFamily", style['font-family']);
                                controlHtml = controlHtml.replace("#fontFamily1", style['font-family']);
                                controlHtml = controlHtml.replace("#backGroundColor1", currentStyle.background == null ? '' : currentStyle.background);
                                controlHtml = controlHtml.replace("#selectOptions", selectOptionString);
                            }

                            if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Initials.toLowerCase()) {
                                labelValue = "Initials";
                            }
                            else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
                                labelValue = "Date & Time Stamp";
                            }

                            if (attr_data_guid && (attr_data_guid.toLowerCase() == Control.Title.toLowerCase()
                                || attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase() || attr_data_guid.toLowerCase() == Control.Label.toLowerCase() ||
                                attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Signature.toLowerCase() ||
                                attr_data_guid.toLowerCase() == Control.Initials.toLowerCase() || attr_data_guid.toLowerCase() == Control.Company.toLowerCase()
                            )) {
                                senderControlValue = "";
                            }

                            let ctrlType: any = '00000000-0000-0000-0000-000000000000';
                            if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                                ctrlType = (attr_data_texttype == undefined || attr_data_texttype == null) ? '00000000-0000-0000-0000-000000000000' : attr_data_texttype_Guid;
                            }

                            if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild", 'alreadyDependentField addedAsChild');
                            else controlHtml = controlHtml.replace("#addedAsChild", '');

                            controlHtml = controlHtml.replace("#data-typography", (attr_data_typography == null || attr_data_typography == undefined || attr_data_typography == '') ? 'px' : attr_data_typography);

                            if (attr_data_prefill && attr_data_prefill == "true") {
                                controlHtml = controlHtml.replace("#dataPrefill", "true");
                                controlHtml = controlHtml.replace("#dataDisplayLabel", "false");
                            }
                            else {
                                controlHtml = controlHtml.replace("#dataPrefill", "false");
                                controlHtml = controlHtml.replace("#dataDisplayLabel", "true");
                            }

                            //console.log("attr_id:" + controlHtml);
                            let tempControlValue: any = "";
                            if (attr_controlValue != undefined && attr_controlValue != null && attr_controlValue != '') {
                                tempControlValue = attr_controlValue;
                            }
                            else if (((attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) && senderControlValue != undefined && senderControlValue != '')) {
                                tempControlValue = senderControlValue;
                            }
                            else if (integrationType != "undefined" && integrationType != "null" && integrationType != undefined && integrationType != null && integrationType != ''
                                && senderControlValue != "undefined" && senderControlValue != "null" && senderControlValue != undefined && senderControlValue != null && senderControlValue != '') {
                                tempControlValue = senderControlValue;
                            }
                            else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase() && attr_data_prefill && attr_data_prefill == "true") {
                                if (spnControlEle) tempControlValue = spnControlEle.innerHTML;
                                else tempControlValue = '';
                            }

                            if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                                if ((ctrlType != undefined && ctrlType != null)) {
                                    if (attr_data_prefill && attr_data_prefill == "true") {
                                        labelValue = this.getTextControlType(ctrlType);
                                        tempControlValue = (attr_data_isHideTextWithAsterisk == false || attr_data_isHideTextWithAsterisk == "false" || attr_data_isHideTextWithAsterisk == null || attr_data_isHideTextWithAsterisk == undefined) ? tempControlValue : attr_data_asteriskOriginalText;
                                    } else {
                                        labelValue = (attr_data_isHideTextWithAsterisk == false || attr_data_isHideTextWithAsterisk == "false" || attr_data_isHideTextWithAsterisk == null || attr_data_isHideTextWithAsterisk == undefined) ? labelValue : (attr_data_asteriskOriginalText === '' || attr_data_asteriskOriginalText == null || attr_data_asteriskOriginalText == 'null') ? '' : attr_data_asteriskOriginalText;
                                    }
                                }

                                if (integrationType && integrationType != "undefined" && integrationType != "null" && (integrationType === 'Bullhorn' || integrationType === 'Vincere') &&
                                    controlEle?.getAttribute('data-Entity') != 'Select' &&
                                    controlEle?.getAttribute('data-EntityFieldValue') != 'Select') {
                                    let entityFieldValue: any = controlEle?.getAttribute('data-EntityField');
                                    if (entityFieldValue && entityFieldValue != "null" && entityFieldValue != "undefined") {
                                        labelValue = entityFieldValue;
                                    }
                                }
                            }

                            controlArray.push({
                                ID: attr_contentId.toLowerCase(),
                                ControlID: attr_data_guid.toLowerCase(),
                                DocumentID: docGuid,
                                RecipientID: (attr_data_guid.toLowerCase() == Control.Label.toLowerCase() || attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) ? '00000000-0000-0000-0000-000000000000' : attr_data_rcptid.toLowerCase(),
                                ControlHtmlID: attr_id,
                                IntControlId: attr_int_control_id,
                                Required: (attr_data_selected == undefined || attr_data_selected == "true") ? true : false,
                                DocumentPageNumber: attr_doc_pageNo,
                                PageNumber: attr_pageNo,
                                XCoordinate: parseInt((controlLeftPosition * ratioWidth).toString()),
                                YCoordinate: parseInt((controlTopPosition * ratioHeight).toString()),
                                ZCoordinate: parseInt((ZCoordinate).toString()), //need to check once again
                                ControlValue: tempControlValue,
                                //Label: attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() ? name : attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() ? name : attr_data_guid.toLowerCase() == Control.Text.toLowerCase() ? attr_data_asteriskOriginalText : labelValue,
                                Label: attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() ? name : attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() ? name : labelValue,
                                Height: controlHeight,
                                Width: controlWidth,
                                GroupName: attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase() ? (groupname == undefined ? null : groupname) : (groupname != undefined && groupname != null && groupname != "" ? groupname : null),
                                ControlHtmlData: controlHtml,
                                RecipientName: (attr_data_guid.toLowerCase() == Control.Label.toLowerCase() || attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) ? '' : this.getRecipientName(attr_data_rcptid),
                                IsControlDeleted: false,
                                ControlType: ctrlType,
                                Left: parseFloat(controlLeftPosition),
                                Top: parseFloat(controlTopPosition),
                                SenderControlValue: senderControlValue,
                                ControlStyle: localControlStyle,
                                Options: selectControl,
                                Dependencies: dependencies,
                                ParentHeight: parentHeight,
                                ImageOriginalHeight: originalHeight,
                                ImageHeight: imageHeight,
                                ImageOriginalWidth: originalWidth,
                                ImageWidth: imageWidth,
                                OrignalPageFormat: imageWidth > imageHeight ? "landscape" : "portrait",
                                Size: attr_data_size == null ? null : attr_data_size,
                                IsDefaultRequired: (attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) ? attr_data_defaultdate == "true" ? true : false : false,
                                CustomToolTip: attr_data_customtooltip == null || attr_data_customtooltip == 0 || attr_data_customtooltip == "0" ? '' : attr_data_customtooltip,
                                FontTypeMeasurement: (attr_data_typography == null || attr_data_typography == undefined || attr_data_typography == '') ? 'px' : attr_data_typography,
                                IsFixedWidth: (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") ? false : true,
                                IsHideTextWithAsterisk: (attr_data_isHideTextWithAsterisk == false || attr_data_isHideTextWithAsterisk == null || attr_data_isHideTextWithAsterisk == "false") ? false : true
                            });
                        };
                    }
                }
            });
        }

        resultObj = {
            isValid: isValid,
            controlArray: controlArray
        };
        //console.log("resultObj:" + resultObj);
        return resultObj;
    }

    getRecipientName(recipientId: any) {
        let name: any = '';
        if (recipientId) {
            let recpId: any = recipientId;
            let receipentLists: any = this.authService.getRecipientDetails();
            if (receipentLists) {
                let receipentListsDetails: any = JSON.parse(receipentLists);
                if (receipentListsDetails && receipentListsDetails.length > 0) {
                    let recObj: any = receipentListsDetails.filter((rec: any) => rec.id.toLowerCase() == recpId.toLowerCase());
                    if (recObj && recObj.length > 0) {
                        name = recObj[0].name;
                    }
                }
            }
        }
        return name;
    }

    getControlOriginalTitle(recipientId: any, currentSelectedControl: any) {
        let name: any = '', placeHolderText: any = '';
        let resizableCurrentControl: any = document.getElementById(currentSelectedControl);
        if (resizableCurrentControl) {
            let docControl: any = document.getElementById("resizable_" + currentSelectedControl)
            let data_guid: any = docControl?.getAttribute('data-guid');
            let data_selected: any = docControl?.getAttribute('data-selected');
            if (data_guid && (data_guid.toLowerCase() == Control.Label.toLowerCase() || data_guid.toLowerCase() == Control.Hyperlink.toLowerCase())) {
                resizableCurrentControl?.removeAttribute('data-original-title');
                resizableCurrentControl?.setAttribute('title', '');
                return '';
            }
            else {
                if (recipientId) {
                    let recpId: any = recipientId;
                    let receipentLists: any = this.authService.getRecipientDetails();
                    if (receipentLists) {
                        let receipentListsDetails: any = JSON.parse(receipentLists);
                        if (receipentListsDetails && receipentListsDetails.length > 0) {
                            let recObj: any = receipentListsDetails.filter((rec: any) => rec.id.toLowerCase() == recpId.toLowerCase());
                            if (recObj && recObj.length > 0) {
                                name = recObj[0].name;
                            }
                        }
                    }
                    if (resizableCurrentControl) {
                        placeHolderText = resizableCurrentControl.getAttribute('data-controlname');
                        placeHolderText = this.commonService.getHoverControlText(placeHolderText);
                    }
                }
                if (data_guid && (data_guid.toLowerCase() == Control.Checkbox.toLowerCase())) {
                    let depenencyCurrentControl: any = document.getElementById("dependency_" + currentSelectedControl);
                    if (depenencyCurrentControl && data_selected && data_selected == "true") {
                        depenencyCurrentControl?.setAttribute('title', "Required checkbox controls can not add dependencies.");
                    }
                }
                return placeHolderText + " control assigned to " + name;
            }
        }
        else return '';
    }

    setdefaultTextValidation() {
        let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
        if (textValidationsoptions) {
            textValidationsoptions.value = '1';
        }

        let textTypeDecimal: any = (<HTMLInputElement>document.getElementById('textTypeDecimal'));
        if (textTypeDecimal) {
            textTypeDecimal.value = '1';
        }

        let textTypeDecimalOptions: any = (<HTMLInputElement>document.getElementById('textTypeDecimalOptions'));
        if (textTypeDecimalOptions) {
            textTypeDecimalOptions.value = '1';
        }

        let textZipOptions: any = (<HTMLInputElement>document.getElementById('textZipOptions'));
        if (textZipOptions) {
            textZipOptions.value = '1';
        }

        let textDateOptions: any = (<HTMLInputElement>document.getElementById('textDateOptions'));
        if (textDateOptions) {
            textDateOptions.value = '1';
        }

        let textInputTypeEle: any = document.getElementById('textInputLength');
        if (textInputTypeEle) {
            textInputTypeEle.removeAttribute('disabled');
        }
    }

    checkNull(val: any, defaultVal: any) {
        if (val && val.trim() != '' && val != "undefined" && val != "null" && val != null && val != undefined) { return val; }
        else { return defaultVal; }
    }

    applyRecipientDetailsOnMouseOverChange(recipient: any, color: any, currentSelectedControl: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            resizableCurrentControl.setAttribute('data-rcptid', recipient.id);
            resizableCurrentControl.setAttribute('data-original-rcptid', recipient.id);
            resizableCurrentControl.setAttribute('data-recp-color', color);
            resizableCurrentControl.style.outline = 'none';
            resizableCurrentControl.style.outline = color + ' solid 3px';
            let titleText: any = this.getControlOriginalTitle(recipient.id, currentSelectedControl);
            resizableCurrentControl.setAttribute('data-original-title', titleText);
            resizableCurrentControl.setAttribute('title', titleText);
            this.updateRecipientsInStorage(currentSelectedControl, recipient.id);
        }
    }

    getSameControlNumberCount(ctrlGuid: any, pageNum: any, id: any) {
        let arryCtrlPageNum: any = [];
        // let controlPageGuidNumArray: any = localStorage.getItem('ControlPageGuidNumArray');
        let controlPageGuidNumArray: any = this.sessionStorageService.getControlPageGuidNumArray();
        if (controlPageGuidNumArray) {
            arryCtrlPageNum = JSON.parse(controlPageGuidNumArray);
        }

        if (arryCtrlPageNum && arryCtrlPageNum.length > 0) {
            let sameCtrlArray: any = arryCtrlPageNum.filter(function (obj: any) { return obj.dataGuid.toLowerCase() == ctrlGuid.toLowerCase() && obj.pageNo == pageNum });
            if (sameCtrlArray && sameCtrlArray.length > 0)
                return sameCtrlArray.length + 1;
            else return 1;
        }
        else return 1;
    }

    createControlOnDocument(newcomponentRef: any, dynamicComponentRefs: any, appRef: any, ctrlObj: any, type: any, isInitialCtrl: any) {
        let arryCtrlPageNum: any = [];
        let imageCtrl: any = document.getElementById('imgControl_' + ctrlObj.pageNo);
        if (imageCtrl) {
            // Set input properties as needed
            let controlClassName: any = ctrlObj.controlName.toLowerCase();
            let controlId: any = controlClassName;
            let controlName: any = ctrlObj.controlName;
            let placeHolderText: any = controlName;
            let pageNum: any = ctrlObj.pageNo;

            let defaultWidth: any = 100;
            let defaultHeight: any = 20;
            let minWidth: any = 100;
            let minHeight: any = 10;
            let ctrlBgColor: any = '#fff';
            let controlDateFormat: any = '';
            let controlResourceKeys: any = '';
            let defaultDate: boolean = false;
            let originalTitle: any = '';
            let data_guid: any;
            let attr_data_rcptid: any;
            let attr_data_recp_color: any;
            let controlTopPosition: any;
            let controlLeftPosition: any;
            let controlHeight: any = '';
            let controlWidth: any = ''

            let resizableControl: any = document.getElementById('resizable_' + ctrlObj.id);
            if (resizableControl) {
                data_guid = resizableControl?.getAttribute('data-guid');
                attr_data_rcptid = resizableControl?.getAttribute('data-rcptid');
                attr_data_recp_color = resizableControl?.getAttribute('data-recp-color');
                let controlPositions: any = this.getElePositions(resizableControl);
                controlHeight = resizableControl?.offsetHeight;
                controlWidth = resizableControl?.offsetWidth;
                if (controlWidth === 0) {
                    controlWidth = ctrlObj.controlWidth
                }
                if (controlHeight === 0) {
                    controlHeight = ctrlObj.controlHeight;
                }
                // controlLeftPosition = parseInt(controlPositions.Left) + 15;
                // controlTopPosition = parseInt(controlPositions.Top) + controlHeight + 7;

                if (isInitialCtrl === 'true') {
                    controlLeftPosition = parseInt(controlPositions.Left);
                    controlTopPosition = parseInt(controlPositions.Top);
                } else {
                    controlLeftPosition = parseInt(controlPositions.Left) + 15;
                    controlTopPosition = parseInt(controlPositions.Top) + controlHeight + 7;
                }
            }
            else {
                data_guid = ctrlObj.data_guid;
                attr_data_rcptid = ctrlObj.attr_data_rcptid;
                attr_data_recp_color = ctrlObj.attr_data_recp_color;
                controlTopPosition = ctrlObj.controlTopPosition;
                controlLeftPosition = ctrlObj.controlLeftPosition;
                controlHeight = ctrlObj.controlHeight;
                controlWidth = ctrlObj.controlWidth;
            }

            //Checking is the control go beyond the page boundaries
            let imageWidth: any = imageCtrl.offsetWidth;
            let imageHeight: any = imageCtrl.offsetHeight;
            // console.log("Height:" + imageHeight + " : " + (controlTopPosition + controlHeight))
            // console.log("Width:" + imageWidth + " : " + (controlLeftPosition + controlWidth))

            if (type != ControlConfig.Existing && imageHeight < (controlTopPosition + controlHeight)) {
                this.toastrService.warning('The control may extend beyond the page boundary.', 'Warning'); return false;
            }
            else if (type != ControlConfig.Existing && imageWidth < (controlLeftPosition + controlWidth)) {
                this.toastrService.warning('The control may extend beyond the page boundary.', 'Warning'); return false;
            }

            if (data_guid.toLowerCase() === Control.Signature.toLowerCase()) {
                controlClassName = 'sign';
                controlName = 'Signature';
                controlId = 'sign';
                defaultWidth = 215;
                defaultHeight = 35;
                minWidth = 100;
                minHeight = 35;
                placeHolderText = 'Sign';
                controlResourceKeys = ControlResourceKeys.Signature;
                originalTitle = 'Signature';
            }
            else if (data_guid.toLowerCase() === Control.Initials.toLowerCase()) {
                controlClassName = 'initials';
                controlId = 'newInitials';
                defaultWidth = 47;
                defaultHeight = 22;
                minWidth = 47;
                controlName = 'NewInitials';
                placeHolderText = 'Initials';
                controlResourceKeys = ControlResourceKeys.Initials; //translations text
                originalTitle = 'Initials';
            }
            else if (data_guid.toLowerCase() === Control.DateTimeStamp.toLowerCase()) {
                controlClassName = 'datetimestamp';
                controlName = 'DateTimeStamp';
                controlId = 'dateTimeStamp';
                defaultWidth = 150;
                ctrlBgColor = 'yellow';
                placeHolderText = 'Date & Time Stamp';
                controlResourceKeys = ControlResourceKeys.DateTimeStamp;
                originalTitle = 'Date & Time Stamp';
            }
            else if (data_guid.toLowerCase() === Control.Name.toLowerCase()) {
                defaultWidth = 167;
                defaultHeight = 20;
                minWidth = 0;
                controlClassName = 'name';
                controlResourceKeys = ControlResourceKeys.Name;
                originalTitle = 'Name';
            }
            else if (data_guid.toLowerCase() === Control.Text.toLowerCase()) {
                defaultWidth = 167;
                defaultHeight = 20;
                minWidth = 0;
                controlClassName = 'text';
                controlResourceKeys = ControlResourceKeys.Text;
                originalTitle = 'Text';
            }
            else if (data_guid.toLowerCase() === Control.DropDown.toLowerCase()) {
                controlClassName = 'dropdown';
                controlId = 'dropDown';
                defaultWidth = controlWidth;
                minWidth = controlWidth;
                controlResourceKeys = ControlResourceKeys.DropDown;
                originalTitle = 'DropDown';
            }
            else if (data_guid.toLowerCase() === Control.Date.toLowerCase()) {
                controlDateFormat = this.getDateFormatInnerHtmlVal("2"); //'mm/dd/yyyy';
                controlName = 'Date';
                controlClassName = 'date';
                defaultDate = false;
                originalTitle = 'Date';
                defaultHeight = 16;
            }
            else if (data_guid.toLowerCase() === Control.Label.toLowerCase()) {
                ctrlBgColor = 'yellow';
                controlClassName = 'label';
                controlResourceKeys = ControlResourceKeys.Label;
                originalTitle = 'Label';
            }
            else if (data_guid.toLowerCase() === Control.Radio.toLowerCase()) {
                defaultWidth = 22;
                defaultHeight = 22;
                minWidth = 18;
                minHeight = 18;
                ctrlBgColor = 'white';
                controlClassName = 'radio';
                controlResourceKeys = ControlResourceKeys.Radio;
                originalTitle = 'Radio';
            }
            else if (data_guid.toLowerCase() === Control.Checkbox.toLowerCase()) {
                defaultWidth = 22;
                defaultHeight = 22;
                minWidth = 18;
                minHeight = 18;
                ctrlBgColor = 'white';
                controlClassName = 'checkbox';
                controlResourceKeys = ControlResourceKeys.Checkbox;
                originalTitle = 'Checkbox';
            }
            else if (data_guid.toLowerCase() === Control.Company.toLowerCase()) {
                controlClassName = 'company';
                controlResourceKeys = ControlResourceKeys.Company;
                originalTitle = 'Company';
                defaultWidth = 100;
                defaultHeight = 20;
                minWidth = 100;
            }
            else if (data_guid.toLowerCase() === Control.Title.toLowerCase()) {
                controlClassName = 'title';
                controlResourceKeys = ControlResourceKeys.Title;
                originalTitle = 'Title';
                defaultWidth = 167;
                defaultHeight = 20;
                minWidth = 100;
            }
            else if (data_guid.toLowerCase() === Control.Hyperlink.toLowerCase()) {
                controlClassName = 'hyperlink';
                controlResourceKeys = ControlResourceKeys.Hyperlink;
                originalTitle = 'Hyperlink';
                ctrlBgColor = 'yellow';
            }
            else if (data_guid.toLowerCase() === Control.Email.toLowerCase()) {
                controlClassName = 'email';
                controlResourceKeys = ControlResourceKeys.Email;
                originalTitle = 'Email';
                defaultWidth = 100;
                defaultHeight = 20;
                minWidth = 100;
            }

            // let controlPageGuidNumArray: any = localStorage.getItem('ControlPageGuidNumArray');
            let controlPageGuidNumArray: any = this.sessionStorageService.getControlPageGuidNumArray();
            if (controlPageGuidNumArray) {
                arryCtrlPageNum = JSON.parse(controlPageGuidNumArray);
            }
            arryCtrlPageNum.push({ dataGuid: data_guid, pageNo: pageNum, id: ctrlObj.id });
            // localStorage.setItem('ControlPageGuidNumArray', JSON.stringify(arryCtrlPageNum));
            this.sessionStorageService.setControlPageGuidNumArray(JSON.stringify(arryCtrlPageNum));

            placeHolderText = this.commonService.getTranslatedText(placeHolderText, controlResourceKeys);
            newcomponentRef.instance.ctrlObj = {
                top: controlTopPosition,
                left: controlLeftPosition,
                width: controlWidth,
                height: controlHeight,
                controlName: controlName,
                className: controlClassName,
                id: type == ControlConfig.Duplicate ? controlId + 'Control' + this.commonService.getId() : ctrlObj.id,
                dataDocumentContentId: type == ControlConfig.Duplicate ? this.commonService.generateUUID() : ctrlObj.contentId,
                dataGuid: data_guid,
                title: placeHolderText,
                pageNo: pageNum,
                minWidth: minWidth,
                minHeight: minHeight,
                bgColor: (controlClassName != "label" && controlClassName != "hyperlink") ? ctrlBgColor : ctrlBgColor,
                outlineColor: (controlClassName != "label" && controlClassName != "hyperlink") ? '2px solid ' + attr_data_recp_color : "2px solid #999",
                recipientId: (controlClassName != "label" && controlClassName != "hyperlink") ? attr_data_rcptid : "",
                recpColor: (controlClassName != "label" && controlClassName != "hyperlink") ? attr_data_recp_color : "1px solid #999",
                ctrlNumber: this.getSameControlNumberCount(data_guid, pageNum, ctrlObj.id),
                controlDateFormat: controlDateFormat,
                typography: 'px',
                placeHolderText: placeHolderText,
                recipientsObj: { selectedRecipentName: this.getRecipientName(attr_data_rcptid), selectedRecipentColor: attr_data_recp_color },
                originalTitle: originalTitle.toLowerCase(),
                defaultDate: defaultDate,
                isRequired: ctrlObj.isRequired,
                textFormatting: ctrlObj.textFormatting,
                isEnvelopeEditable: ctrlObj.isEnvelopeEditable
            };

            dynamicComponentRefs.set(newcomponentRef.instance.ctrlObj.id, newcomponentRef);
            // Attach the component to the DOM
            appRef.attachView(newcomponentRef.hostView);
            const domElem = (newcomponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            imageCtrl?.appendChild(domElem);
            return newcomponentRef.instance.ctrlObj;
        }
        else {
            console.log("Image is not there for page number:" + ctrlObj.pageNo);
        }
    }

    updateJsonArrays(controlName: any, controlClassName: any, ctrlObj: any, data_guid: any) {
        let ctrlName = this.commonService.getControlName(controlName, controlClassName);
        // let allImageControlsString: any = localStorage.getItem('AllImageControls');
        let allImageControlsString: any = this.sessionStorageService.getAllImageControls();
        let arrControlIdNumbers: any = [];
        if (allImageControlsString) {
            arrControlIdNumbers = JSON.parse(allImageControlsString);
        }

        let ctrlIdEle: any = document.getElementById(ctrlObj.id);
        let ctrlNum: any = ctrlObj.ctrlNumber;
        if (ctrlIdEle) {
            ctrlNum = ctrlIdEle.getAttribute("data-ctrlnum");
        }

        arrControlIdNumbers.push({
            Control: ctrlName,
            ControlId: ctrlObj.id,
            DataDocumentContentId: ctrlObj.dataDocumentContentId,
            ConditionID: '00000000-0000-0000-0000-000000000000',
            PageNo: ctrlObj.pageNo,
            DataGuid: ctrlObj.dataGuid,
            ControlName: ctrlName,
            CtrlNumber: ctrlNum,
            RecipientId: ctrlObj.recipientId,
        });
        // localStorage.setItem('AllImageControls', JSON.stringify(arrControlIdNumbers));
        this.sessionStorageService.setAllImageControls(JSON.stringify(arrControlIdNumbers));


        // let controlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        let controlDependencyFieldsString: any = this.sessionStorageService.getControlDependencyFieldsArray();
        let arrControlFieldDependenceControls: any = [];
        if (controlDependencyFieldsString) {
            arrControlFieldDependenceControls = JSON.parse(controlDependencyFieldsString);
        }

        if (data_guid.toLowerCase() === Control.Radio.toLowerCase() || data_guid.toLowerCase() === Control.Checkbox.toLowerCase()
            || data_guid.toLowerCase() === Control.DropDown.toLowerCase() || data_guid.toLowerCase() === Control.Text.toLowerCase()) {
            arrControlFieldDependenceControls.push({
                Control: ctrlName,
                ControlId: ctrlObj.id,
                DataDocumentContentId: ctrlObj.dataDocumentContentId,
                ConditionID: '00000000-0000-0000-0000-000000000000',
                PageNo: ctrlObj.pageNo,
                DataGuid: ctrlObj.dataGuid,
                ControlName: ctrlName,
                CtrlNumber: ctrlNum,
                RecipientId: ctrlObj.recipientId,
            });
            // localStorage.setItem('ControlDependencyFieldsArray', JSON.stringify(arrControlFieldDependenceControls));
            this.sessionStorageService.setControlDependencyFieldsArray(JSON.stringify(arrControlFieldDependenceControls));
        }
    }

    updateDuplicateControlProperties(newControlId: any, oldControlId: any, data_guid: any) {
        let oldControlEle: any = (<HTMLInputElement>document.getElementById(oldControlId));
        if (!oldControlEle) return;
        //Resizable div
        let oldResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + oldControlId));
        if (!oldResizableControlEle) return;

        let newResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + newControlId));
        if (!newResizableControlEle) return;

        let old_attr_title: any = oldResizableControlEle?.getAttribute('title');
        if (old_attr_title) newResizableControlEle?.setAttribute('title', old_attr_title);

        let old_attr_controlValue: any = oldResizableControlEle?.getAttribute('data-control-val');
        if (old_attr_controlValue) newResizableControlEle?.setAttribute('data-control-val', old_attr_controlValue);

        let old_attr_data_typography: any = oldResizableControlEle?.getAttribute('data-typography');
        if (old_attr_data_typography) newResizableControlEle?.setAttribute('data-typography', old_attr_data_typography);

        let old_attr_data_selected: any = oldResizableControlEle?.getAttribute('data-selected');
        let old_attr_data_selected1: any = oldResizableControlEle?.getAttribute('data-selected1');
        if (old_attr_data_selected1) newResizableControlEle?.setAttribute('data-selected1', old_attr_data_selected1);

        let old_attr_data_customtooltip: any = oldResizableControlEle?.getAttribute('data-customtooltip');
        if (old_attr_data_customtooltip) newResizableControlEle?.setAttribute('data-customtooltip', old_attr_data_customtooltip);

        let old_attr_data_isfixedwidth: any = oldResizableControlEle?.getAttribute('data-isfixedwidth');
        let old_attr_maxlengthallowed: any = oldResizableControlEle?.getAttribute('maxlengthallowed');
        let old_attr_maxcharallowed: any = oldResizableControlEle?.getAttribute('maxcharallowed');
        let old_attr_maxemailcharallowed: any = oldResizableControlEle?.getAttribute('maxemailcharallowed');
        let old_attr_maxcharlimit: any = oldResizableControlEle?.getAttribute('data-maxcharlimit');

        //Spn div
        let oldSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + oldControlId));
        if (!oldSpnControlEle) return;

        let newSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + newControlId));
        if (!newSpnControlEle) return;

        if (data_guid && (data_guid.toLowerCase() == Control.Name.toLowerCase() || data_guid.toLowerCase() == Control.Text.toLowerCase() || data_guid.toLowerCase() == Control.Title.toLowerCase())) {
            newResizableControlEle?.setAttribute('maxlengthallowed', old_attr_maxlengthallowed == null || old_attr_maxlengthallowed == 'null' ? '' : old_attr_maxlengthallowed);
            newResizableControlEle?.setAttribute('maxcharallowed', old_attr_maxcharallowed == null || old_attr_maxcharallowed == 'null' ? '' : old_attr_maxcharallowed);
            if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                newResizableControlEle?.setAttribute('maxemailcharallowed', old_attr_maxemailcharallowed == null ? '' : old_attr_maxemailcharallowed);
            }
            else {
                newResizableControlEle?.setAttribute('data-maxcharlimit', old_attr_maxcharlimit == null ? '20' : old_attr_maxcharlimit);
            }
            newResizableControlEle?.setAttribute('data-isfixedwidth', (old_attr_data_isfixedwidth == null || old_attr_data_isfixedwidth == true) ? 'true' : old_attr_data_isfixedwidth);
        }
        else {
            if (old_attr_maxlengthallowed) newResizableControlEle?.setAttribute('maxlengthallowed', old_attr_maxlengthallowed == null ? '' : old_attr_maxlengthallowed);
            if (old_attr_maxcharallowed) newResizableControlEle?.setAttribute('maxcharallowed', old_attr_maxcharallowed == null ? '' : old_attr_maxcharallowed);
            if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                if (old_attr_maxemailcharallowed) newResizableControlEle?.setAttribute('maxemailcharallowed', old_attr_maxemailcharallowed == null ? '' : old_attr_maxemailcharallowed);
            }
            if (old_attr_data_isfixedwidth) newResizableControlEle?.setAttribute('data-isfixedwidth', (old_attr_data_isfixedwidth == null || old_attr_data_isfixedwidth == true) ? 'true' : old_attr_data_isfixedwidth);
        }

        if (data_guid && data_guid.toLowerCase() == Control.Date.toLowerCase()) {
            let old_attr_data_defaultformat: any = oldResizableControlEle?.getAttribute('data-defaultformat');
            if (old_attr_data_defaultformat) newResizableControlEle?.setAttribute('data-defaultformat', old_attr_data_defaultformat);

            let old_attr_data_defaultdate = oldResizableControlEle?.getAttribute('data-defaultdate');
            if (old_attr_data_defaultdate) newResizableControlEle?.setAttribute('data-defaultdate', old_attr_data_defaultdate);
        }

        let old_attr_data_confirm_submit: any = oldResizableControlEle.getAttribute('data-confirm-submit');
        if (old_attr_data_confirm_submit) newResizableControlEle?.setAttribute('data-confirm-submit', old_attr_data_confirm_submit);

        if (data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase() || data_guid && data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            let old_attr_data_isGroupChecked: any = oldResizableControlEle?.getAttribute('data-isGroupChecked');
            if (old_attr_data_isGroupChecked) newResizableControlEle?.setAttribute('data-isGroupChecked', old_attr_data_isGroupChecked);

            let old_attr_data_groupname: any = oldResizableControlEle?.getAttribute('data-groupname');
            if (old_attr_data_groupname) newResizableControlEle?.setAttribute('data-groupname', old_attr_data_groupname);

            let old_attr_data_size = oldResizableControlEle?.getAttribute('data-size');
            if (old_attr_data_size) newResizableControlEle?.setAttribute('data-size', old_attr_data_size);
        }

        if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) {
            let old_attr_data_texttype = oldResizableControlEle?.getAttribute('data-texttype');
            if (old_attr_data_texttype) newResizableControlEle?.setAttribute('data-texttype', old_attr_data_texttype);

            let old_attr_data_validation_type: any = oldResizableControlEle?.getAttribute('data-validation-type');
            if (old_attr_data_validation_type) newResizableControlEle?.setAttribute('data-validation-type', old_attr_data_validation_type);

            let old_attr_data_additional_validation_name: any = oldResizableControlEle?.getAttribute('data-additional-validation-name');
            if (old_attr_data_additional_validation_name) newResizableControlEle?.setAttribute('data-additional-validation-name', old_attr_data_additional_validation_name);

            let old_attr_data_hidetextwithasterisk: any = oldResizableControlEle?.getAttribute('data-ishidetextwithasterisk');
            if (old_attr_data_hidetextwithasterisk) newResizableControlEle?.setAttribute('data-ishidetextwithasterisk', old_attr_data_hidetextwithasterisk);

            let old_attr_data_data_asteriskOriginalText: any = oldResizableControlEle?.getAttribute('data-asteriskOriginalText');
            if (old_attr_data_data_asteriskOriginalText) newResizableControlEle?.setAttribute('data-asteriskOriginalText', old_attr_data_data_asteriskOriginalText);

            let old_attr_data_additional_validation_option: any = oldResizableControlEle?.getAttribute('data-additional-validation-option');
            if (old_attr_data_additional_validation_option) newResizableControlEle?.setAttribute('data-additional-validation-option', old_attr_data_additional_validation_option);

            let old_attr_data_prefill: any = oldResizableControlEle?.getAttribute('data-prefill');
            let old_attr_data_displayLabel: any = oldResizableControlEle?.getAttribute('data-displayLabel');
            if (old_attr_data_prefill && old_attr_data_prefill == "true") {
                newResizableControlEle?.setAttribute('data-prefill', "true");
                newResizableControlEle?.setAttribute('data-displayLabel', "false");
            }
            else {
                newResizableControlEle?.setAttribute('data-displayLabel', "true");
                newResizableControlEle?.setAttribute('data-prefill', "false");
            }
            newSpnControlEle.innerText = oldSpnControlEle.innerText;
            newResizableControlEle?.setAttribute('data-addtextareaval', oldSpnControlEle.innerText);
            newSpnControlEle?.setAttribute('data-addtextareaval', oldSpnControlEle.innerText);
        }

        let old_attr_data_hyperlink = oldResizableControlEle?.getAttribute('data-hyperlink');
        if (old_attr_data_hyperlink) newResizableControlEle?.setAttribute('data-hyperlink', old_attr_data_hyperlink);

        let old_entity: any = oldResizableControlEle?.getAttribute("data-Entity");
        let old_entityType: any = oldResizableControlEle?.getAttribute("data-EntityField");
        let old_entityValue = oldResizableControlEle?.getAttribute("data-EntityFieldValue");

        if (data_guid && data_guid.toLowerCase() == Control.Initials.toLowerCase()) {
            let old_attr_fromParentInitialCtrl: any = oldResizableControlEle?.getAttribute('fromParentInitialCtrl');
            if (old_attr_fromParentInitialCtrl) newResizableControlEle?.setAttribute('fromParentInitialCtrl', old_attr_fromParentInitialCtrl);
        }

        let old_attr_font_family_id: any = oldSpnControlEle?.getAttribute('font-family-id');
        if (old_attr_font_family_id) newSpnControlEle?.setAttribute('font-family-id', old_attr_font_family_id);

        let old_name = oldSpnControlEle?.getAttribute('name');
        if (old_name) newSpnControlEle?.setAttribute('name', old_name);

        let old_innerText = oldSpnControlEle?.innerHTML;
        if (old_innerText) newSpnControlEle.innerHTML = old_innerText;

        let old_attr_spn_style: any = oldSpnControlEle?.getAttribute('style');
        newSpnControlEle?.setAttribute('style', '');
        //let new_attr_spn_style: any = newSpnControlEle?.getAttribute('style');
        if (typeof old_attr_spn_style !== 'undefined' && old_attr_spn_style !== false) {
            var styles = old_attr_spn_style.split(';');
            for (var st in styles) {
                var aux = (styles[st]).trim().split(':');
                if (aux[1] && aux[0] && (aux[0]).trim() != "background-color")
                    newSpnControlEle.style[(aux[0]).trim()] = (aux[1].trim());
            }
        }

        if (data_guid && data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
            let hyperlinkEle: any = document.getElementById(oldControlId);
            let controlEle: any = (<HTMLInputElement>document.getElementById(newControlId));
            if (controlEle) {
                let link: any = '';
                if (hyperlinkEle.getAttribute('data-hyperlink') !== 'null' && hyperlinkEle.getAttribute('data-hyperlink') !== null) {
                    link = hyperlinkEle.getAttribute('data-hyperlink');
                }
                controlEle.setAttribute('data-hyperlink', link);
            }

            newResizableControlEle.style.background = 'yellow';
            newResizableControlEle.style.outline = 'none';
        }

        if (data_guid && data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
            let old_attr_data_firstlineblank: any = oldResizableControlEle?.getAttribute('data-firstlineblank');
            if (old_attr_data_firstlineblank) newResizableControlEle?.setAttribute('data-firstlineblank', old_attr_data_firstlineblank);

            let oldSelectDropdownControlField: any = (document.getElementById('spn' + oldControlId)) as HTMLSelectElement;
            let newSelectDropdownControlField: any = (document.getElementById('spn' + newControlId)) as HTMLSelectElement;
            newSelectDropdownControlField.options.length = 0;

            if (oldSelectDropdownControlField && oldSelectDropdownControlField.options && oldSelectDropdownControlField.options.length > 0) {
                for (let index = 0; index < oldSelectDropdownControlField.options.length; index++) {
                    var text = oldSelectDropdownControlField.options[index].text;
                    var ruleId = this.commonService.generateUUID(); // oldSelectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                    var value = oldSelectDropdownControlField.options[index]?.getAttribute('value');

                    var option = '';
                    if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                    else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                    newSelectDropdownControlField.insertAdjacentHTML('beforeend', option);
                }
            }
        }

        if (data_guid && (data_guid.toLowerCase() == Control.Label.toLowerCase() || data_guid.toLowerCase() == Control.Hyperlink.toLowerCase())) {
            let old_attr_data_isupdated: any = oldSpnControlEle?.getAttribute('isupdated');
            if (old_attr_data_isupdated && old_attr_data_isupdated != null) {
                newSpnControlEle?.setAttribute('isupdated', old_attr_data_isupdated);
            }
        }

        if (data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase() || data_guid && data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            if (old_attr_data_selected1 != null && old_attr_data_selected1 == "true") {
                newSpnControlEle.classList.add('checked-visible');
                newSpnControlEle.setAttribute('checked', 'true');
                newSpnControlEle.style["background-color"] = 'green';

                if (data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase()) {
                    oldResizableControlEle.style["background-color"] = '';
                    oldResizableControlEle.style["background-color"] = 'white';
                    oldSpnControlEle.classList.remove('checked-visible');
                    oldSpnControlEle.removeAttribute("checked");
                    oldResizableControlEle?.setAttribute('data-selected1', "false");

                    let radioDependencieArray: any = document.querySelectorAll('[data-groupname ="' + newResizableControlEle.getAttribute('data-groupname') + '"]'); // document.querySelectorAll('.radio-dependencie');
                    if (radioDependencieArray && radioDependencieArray.length > 0) {
                        Array.from(radioDependencieArray).forEach((radioItem: any, ind: any) => {
                            let id: any = radioItem.getAttribute('id').replace('resizable_', '')
                            radioItem = document.getElementById("spn" + id);
                            if (radioItem.getAttribute('id') != newSpnControlEle.getAttribute('id') && radioItem.classList.contains('radio-dependencie')) {
                                radioItem.style["background-color"] = '';
                                radioItem.style["background-color"] = 'white';
                                radioItem.classList.remove('checked-visible');
                                radioItem.removeAttribute("checked");
                                radioItem?.setAttribute('data-selected1', "false");
                            }
                        });
                    }
                }
                else if (data_guid && data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    oldSpnControlEle.classList.add('checked-visible');
                    oldSpnControlEle.setAttribute('checked', 'true');
                    oldSpnControlEle.style["background-color"] = 'green';
                }
            }
            else {
                newSpnControlEle.classList.remove('checked-visible');
                newSpnControlEle.removeAttribute("checked");
                if (data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase())
                    newSpnControlEle.style["background-color"] = 'white';
            }
        }

        if (old_attr_data_selected) {
            newResizableControlEle?.setAttribute('data-selected', old_attr_data_selected);
            this.showRequiredIcon(newControlId);
        }
        this.removeControlAttributes(newResizableControlEle, data_guid);
    }

    updateExistingControlProperties(newControlObj: any, oldControlObj: any, integrationResp: any, iscopyEnvelopeId: any, EnvelopeType: any) {
        let newControlId: any = newControlObj.id;
        let newControlEle: any = (<HTMLInputElement>document.getElementById(newControlId));
        if (!newControlEle) return;
        let newResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + newControlId));
        if (!newResizableControlEle) return;
        let newSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + newControlId));
        if (!newSpnControlEle) return;

        var e = document.createElement('div');
        e.setAttribute('style', 'display: none;');
        e.innerHTML = oldControlObj.controlHtmlData.trim();
        document.body.appendChild(e);
        var htmlConvertedIntoDom: any = e.childNodes;

        let controlStyleAttr: any = '', originaltitleAttr: any = '', controlstitleAttr: any = '', groupCodeAttr: any = '', defaultdateAttr: any = '', sizeAttr: any = '',
            maxlengthallowedAttr: any = '', maxcharallowedAttr: any = '', maxemailcharallowedAttr: any = '', parentidAttr: any = '', parentruleidAttr: any = '', supporttextcontentAttr: any = '', groupnameAttr: any = '', expandedAttr: any = '',
            ischildpresentAttr: any = '', contenttitleAttr: any = '', isparentpresentAttr: any = '', isdisabledClassExists: any = '', grpNameAttr: any = '', textAlignAttr: any = 'left',
            contentStyleAttr: any = '', fontfamilyidAttr: any = '', innerHtmlAttr: any = '', controInfoAttr: any = '', guidAttr: any = '', rcptidAttr: any = '', prefillAttr: any = '', displayLabelAttr: any = '',
            texttypeAttr: any = '', selectedAttr: any = '', selected1Attr: any = '', customtooltipAttr: any = '', hyperlinkAttr: any = '', fontfamilyAttr: any = '', addedAsChildAttr: any = '',
            typographyAttr: any = '', confirmsubmitAttr: any = '', originalrcptidAttr: any = '', isfixedwidthAttr: any = '', controlvalAttr: any = '', mainSpnCtrlStyle: any = {}, spnControlStyle: any = {}, spnStyle: any = {}, hideTextWithAsterisk: any, asteriskOriginalText: any;

        if (htmlConvertedIntoDom && htmlConvertedIntoDom[0]) {
            let id: any = htmlConvertedIntoDom[0]?.getAttribute('id');
            let styleAttr: any = htmlConvertedIntoDom[0].getAttribute('style');
            let controlnameAttr: any = htmlConvertedIntoDom[0].getAttribute('data-controlname');
            let ctrlnumAttr: any = htmlConvertedIntoDom[0].getAttribute('data-ctrlnum');
            let contentidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-document-content-id');
            let controlidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-int-control-id');
            let conditionidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-condition-id');
            groupCodeAttr = htmlConvertedIntoDom[0].getAttribute('group-code');
            hyperlinkAttr = htmlConvertedIntoDom[0].getAttribute('data-hyperlink');
            groupnameAttr = htmlConvertedIntoDom[0].getAttribute('data-groupname');

            if (htmlConvertedIntoDom[0].querySelector('.controls')) {
                controlStyleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('style');
                originaltitleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-original-title');
                controlstitleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('title');
                maxlengthallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxlengthallowed');
                maxcharallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxcharallowed');
                maxemailcharallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxemailcharallowed');
                parentidAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-id');
                parentruleidAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-rule-id');
                supporttextcontentAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-support-text-content');
                expandedAttr = htmlConvertedIntoDom[0].classList.contains('expanded');
                addedAsChildAttr = htmlConvertedIntoDom[0].classList.contains('addedAsChild');
            }

            if (htmlConvertedIntoDom[0].querySelector('.content')) {
                contentStyleAttr = htmlConvertedIntoDom[0].querySelector('.content').getAttribute('style');
                fontfamilyidAttr = htmlConvertedIntoDom[0].querySelector('.content').getAttribute('font-family-id');
                innerHtmlAttr = htmlConvertedIntoDom[0].querySelector('.content').innerHTML;
            }

            if (htmlConvertedIntoDom[0].querySelector('.controlDrpDown')) {
                fontfamilyAttr = htmlConvertedIntoDom[0].querySelector('.controlDrpDown').getAttribute('font-family');
            }
            if (htmlConvertedIntoDom[0].querySelector('.ctnRadio')) {
                grpNameAttr = htmlConvertedIntoDom[0].querySelector('.ctnRadio').getAttribute('name');
            }
            if (htmlConvertedIntoDom[0].querySelector('.ctnCheckbox')) {
                grpNameAttr = htmlConvertedIntoDom[0].querySelector('.ctnCheckbox').getAttribute('name');
            }

            if (htmlConvertedIntoDom[0].querySelector('.conditionControl')) {
                ischildpresentAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('is-child-present');
                contenttitleAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('title');
                isparentpresentAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('is-parent-present');
                isdisabledClassExists = htmlConvertedIntoDom[0].querySelector('.conditionControl ').classList.contains('disabled');
            }

            if (htmlConvertedIntoDom[0].querySelector('.control-info')) {
                controInfoAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('title');
                guidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-guid');
                rcptidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-rcptid');
                texttypeAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-texttype');
                selectedAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-selected');
                selected1Attr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-selected1');
                defaultdateAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-defaultdate');
                customtooltipAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-customtooltip');
                typographyAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-typography');
                confirmsubmitAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-confirm-submit');
                originalrcptidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-original-rcptid');
                isfixedwidthAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-isfixedwidth');
                controlvalAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-control-val');
                hyperlinkAttr = htmlConvertedIntoDom[0].getAttribute('data-hyperlink');
                sizeAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-size');
                textAlignAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-textAlign');
                prefillAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-prefill');
                displayLabelAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-displayLabel');
                hideTextWithAsterisk = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-isHideTextWithAsterisk');
                asteriskOriginalText = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-asteriskOriginalText');
            }
            newControlEle?.setAttribute('data-isHideTextWithAsterisk', hideTextWithAsterisk != null ? hideTextWithAsterisk : '');
            newControlEle?.setAttribute('data-asteriskOriginalText', asteriskOriginalText != null ? asteriskOriginalText : '');
            newControlEle?.setAttribute('data-int-control-id', controlidAttr != null ? controlidAttr : '');
            newControlEle?.setAttribute('group-code', groupCodeAttr != null ? groupCodeAttr : '');
            newControlEle?.setAttribute('data-condition-id', conditionidAttr != null ? conditionidAttr : '');

            newControlEle?.setAttribute('data-parent-id', oldControlObj.conditionalControlsDetails.controllingFieldID);
            newControlEle?.setAttribute('data-parent-rule-id', oldControlObj.conditionalControlsDetails.controllingConditionID);
            newControlEle?.setAttribute('data-parent-support-text-content', oldControlObj.conditionalControlsDetails.controllingSupportText);

            newControlEle?.setAttribute('is-parent-present', isparentpresentAttr != null ? isparentpresentAttr : 'false');
            newControlEle?.setAttribute('is-child-present', ischildpresentAttr != null ? ischildpresentAttr : 'false');
            newControlEle?.setAttribute('data-parent-support-text-content', supporttextcontentAttr != null ? supporttextcontentAttr : '');
            newControlEle?.setAttribute('data-ctrlnum', (ctrlnumAttr != null && ctrlnumAttr != null && ctrlnumAttr != "null" && ctrlnumAttr != "") ? ctrlnumAttr : newControlEle?.getAttribute('data-ctrlnum'));

            newResizableControlEle?.setAttribute('data-selected', selectedAttr != null ? selectedAttr : 'false');
            if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-selected1', selected1Attr != null ? selected1Attr : 'false');
                if (selected1Attr != null && selected1Attr == "true") {
                    newSpnControlEle.classList.add('checked-visible');
                    newSpnControlEle.setAttribute('checked', 'true');
                    newSpnControlEle.style["background-color"] = "green";
                }
                else {
                    newSpnControlEle.classList.remove('checked-visible');
                    newSpnControlEle.removeAttribute("checked");
                    newSpnControlEle.style["background-color"] = 'white';
                }
            }
            newResizableControlEle?.setAttribute('data-customtooltip', (customtooltipAttr != null && customtooltipAttr != '') ? customtooltipAttr : '');
            newResizableControlEle?.setAttribute('data-control-val', controlvalAttr != null ? controlvalAttr : '');
            newResizableControlEle?.setAttribute('data-typography', typographyAttr != null ? typographyAttr : '');
            newResizableControlEle?.setAttribute('data-placeholder', controInfoAttr != null ? controInfoAttr : '');
            newResizableControlEle?.setAttribute('data-original-title', originaltitleAttr != null ? originaltitleAttr : '');
            //newResizableControlEle?.setAttribute('title', originaltitleAttr != null ? originaltitleAttr : '');
            newResizableControlEle?.setAttribute('data-confirm-submit', confirmsubmitAttr != null ? confirmsubmitAttr : '');

            if (typeof styleAttr !== 'undefined' && styleAttr !== false && styleAttr != null) {
                var stylesArray = styleAttr.split(';');
                for (var st in stylesArray) {
                    var aux = (stylesArray[st]).trim().split(':');
                    if (aux[1])
                        mainSpnCtrlStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (mainSpnCtrlStyle) {
                let left: any = mainSpnCtrlStyle['left'].replace('px', '');
                let top: any = mainSpnCtrlStyle['top'].replace('px', '');
                // newResizableControlEle.style.transform = `translate3d(${left}px, ${top}px, ${0}px)`;
                newResizableControlEle.style.left = left + 'px';
                newResizableControlEle.style.top = top + 'px';
            }

            if (typeof controlStyleAttr !== 'undefined' && controlStyleAttr !== false && controlStyleAttr != null) {
                var styles = controlStyleAttr.split(';');
                for (var st in styles) {
                    var aux = (styles[st]).trim().split(':');
                    if (aux[1])
                        spnControlStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (spnControlStyle) {
                // If this is applied then old UI control back ground color grey color applied
                if (spnControlStyle['background-color'] == "rgba(220, 220, 220, 1)" || spnControlStyle['background-color'] == "rgba(220, 220, 220)") {
                    if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                        if (selected1Attr == null || selected1Attr == "false" || selected1Attr == undefined) {
                            this.applyColorCodeForItem(newControlId, "rgb(255, 255, 255)");
                        }
                    }
                    else this.applyColorCodeForItem(newControlId, "rgb(255, 255, 255)");

                    let spnItem: any = document.getElementById("spn" + newControlId);
                    if (spnItem) {
                        if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                            if (selected1Attr == null || selected1Attr == "false" || selected1Attr == undefined) {
                                newSpnControlEle.classList.remove('checked-visible');
                                newSpnControlEle.removeAttribute("checked");
                                newSpnControlEle.style["background-color"] = 'white';
                            }
                        }
                        else {
                            spnItem.style["background-color"] = '';
                            spnItem.style["background"] = '';
                        }
                    }
                }
                else {
                    if (newControlObj.dataGuid.toLowerCase() != Control.Label.toLowerCase() && newControlObj.dataGuid.toLowerCase() != Control.DateTimeStamp.toLowerCase()
                        && newControlObj.dataGuid.toLowerCase() != Control.Hyperlink.toLowerCase()) {
                        this.applyColorCodeForItem(newControlId, "rgb(255, 255, 255)");
                        let colorcode: any = spnControlStyle['background-color'];
                        if (spnControlStyle['background-color'] == undefined) {
                            colorcode = "rgb(255, 255, 255)";
                        }

                        this.applyColorCodeForItem(newControlId, colorcode);
                        if (colorcode == "rgb(255, 255, 255)") {
                            let spnItem: any = document.getElementById("spn" + newControlId);
                            if (spnItem) {
                                spnItem.style["background-color"] = '';
                                spnItem.style["background"] = '';
                            }
                        }
                    }

                }
            }

            if (typeof contentStyleAttr !== 'undefined' && contentStyleAttr !== false && contentStyleAttr != null) {
                var styles = contentStyleAttr.split(';');

                for (var st in styles) {
                    var aux = (styles[st]).trim().split(':');
                    if (aux[1])
                        spnStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (newControlObj.dataGuid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) { textAlignAttr = 'left'; }
            if (spnStyle) {
                newSpnControlEle.style['color'] = spnStyle['color'];
                newSpnControlEle.style['font-family'] = spnStyle['font-family'] && spnStyle['font-family'] != 'null' && spnStyle['font-family'] != 'undefind' ? spnStyle['font-family'] : 'courier';
                newSpnControlEle.style['font-weight'] = spnStyle['font-weight'];
                newSpnControlEle.style['text-decoration'] = spnStyle['text-decoration']; //underline
                newSpnControlEle.style['font-style'] = spnStyle['font-style']; //italic
                newSpnControlEle.style['font-size'] = spnStyle['font-size'];

                if (newControlObj.dataGuid.toLowerCase() == Control.Date.toLowerCase()) { newSpnControlEle.style['line-height'] = spnStyle['font-size']; }

                newSpnControlEle.style['text-align'] = textAlignAttr && textAlignAttr != '' && textAlignAttr != 'null' && textAlignAttr != 'undefind' ? textAlignAttr : 'left';
                newSpnControlEle?.setAttribute('data-textAlign', newSpnControlEle.style['text-align']);
                if (newControlObj.dataGuid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
                    let hyperControl: any = document.getElementById("hyper_" + newControlObj.id);
                    if (hyperControl) {
                        hyperControl.style['text-align'] = newSpnControlEle.style['text-align'];
                    }
                }
                if (newControlObj.dataGuid.toLowerCase() != Control.Date.toLowerCase()) {
                    newSpnControlEle.style['line-height'] = spnStyle['font-size'];
                }
            }

            if (newControlObj.dataGuid.toLowerCase() == Control.DropDown.toLowerCase()) {
                newSpnControlEle?.setAttribute('font-family', 'courier');
                newSpnControlEle?.setAttribute('font-family-id', 'D4A45ECD-3865-448A-92FA-929C2295EA34')
            }
            else {
                newSpnControlEle?.setAttribute('font-family-id', fontfamilyidAttr != null && fontfamilyidAttr != 'null' ? fontfamilyidAttr : 'D4A45ECD-3865-448A-92FA-929C2295EA34');
            }
        }
        document.body.removeChild(e);

        if (newControlObj.dataGuid.toLowerCase() == Control.Name.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Title.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', maxlengthallowedAttr);
            newResizableControlEle?.setAttribute('maxcharallowed', maxcharallowedAttr);
            newResizableControlEle?.setAttribute('data-isfixedwidth', isfixedwidthAttr);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Text.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', maxlengthallowedAttr);
            newResizableControlEle?.setAttribute('maxcharallowed', maxcharallowedAttr);
            newResizableControlEle?.setAttribute('maxemailcharallowed', maxemailcharallowedAttr);
            newResizableControlEle?.setAttribute('data-isfixedwidth', isfixedwidthAttr);
            newResizableControlEle?.setAttribute('data-texttype', texttypeAttr);
            newResizableControlEle?.setAttribute('data-isHideTextWithAsterisk', oldControlObj.isHideTextWithAsterisk);
            newResizableControlEle?.setAttribute('data-asteriskOriginalText', oldControlObj.controlValue !== '' || oldControlObj.controlValue === null || oldControlObj.controlValue === undefined ? oldControlObj.controlValue : oldControlObj.label);

            const parser = new DOMParser();
            const doc = parser.parseFromString(oldControlObj.controlHtmlData, 'text/html');
            const copyAddTextAreaVal = doc?.querySelector('span[data-addtextareaval]');
            let addTextAreaVal: any = copyAddTextAreaVal?.getAttribute('data-addtextareaval');
            newResizableControlEle?.setAttribute('data-addtextareaval', addTextAreaVal);
            newSpnControlEle?.setAttribute('data-addtextareaval', addTextAreaVal);

            this.setTextAttributes(newResizableControlEle, maxcharallowedAttr, maxemailcharallowedAttr, isfixedwidthAttr, texttypeAttr);

            let validationType: any = '';
            let validationsTextArray: any = this.commonConstants.ValidationsText;
            let arrayObj: any = validationsTextArray.filter((obj: any) => obj.value.toLowerCase() == texttypeAttr.toLowerCase());
            if (arrayObj && arrayObj.length > 0)
                validationType = arrayObj[0].label;
            newResizableControlEle?.setAttribute('data-validation-type', validationType);

            newResizableControlEle?.setAttribute('data-additional-validation-name', oldControlObj.controlStyle.additionalValidationName);
            let additionalValidationOptionName: any = oldControlObj.controlStyle.additionalValidationOption;
            if (oldControlObj.controlStyle.additionalValidationName != '' && oldControlObj.controlStyle.additionalValidationName != null) {
                if (oldControlObj.controlStyle.additionalValidationName == 'Zip' || oldControlObj.controlStyle.additionalValidationName == "Zip Format") {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'Zip')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Zip+4')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                    }
                }
                else if (oldControlObj.controlStyle.additionalValidationName == 'Decimal') {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'Both')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Comma')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Period')
                            oldControlObj.controlStyle.additionalValidationOption = '3';
                    }
                }
                else if (oldControlObj.controlStyle.additionalValidationName == 'Date Format') {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'mm/dd/yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'dd/mm/yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'yyyy/mm/dd')
                            oldControlObj.controlStyle.additionalValidationOption = '3';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'dd-mmm-yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '4';
                    }
                }
            }

            newResizableControlEle?.setAttribute('data-additional-validation-option', oldControlObj.controlStyle.additionalValidationOption);
            if (oldControlObj.isHideTextWithAsterisk) {
                let isPrefillText: any;

                if (oldControlObj.controlHtmlData) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(oldControlObj.controlHtmlData, 'text/html');
                    const prefillAttr = doc?.querySelector('span[data-prefill]');
                    isPrefillText = prefillAttr?.getAttribute('data-prefill');
                }
                let controlValue = oldControlObj.controlValue;
                let label = oldControlObj.label;
                let integrationType: any = '';
                if (integrationResp && integrationResp.integrationType) {
                    integrationType = integrationResp.integrationType;
                }

                if (((integrationType && integrationType.toLowerCase()) === 'bullhorn' || (integrationType && integrationType.toLowerCase()) === 'vincere')) {
                    if (EnvelopeType === 'envelope') {
                        newSpnControlEle.innerHTML = controlValue !== null && controlValue !== '' ? ('*'.repeat(controlValue.length)) : label !== null && label !== '' ? ('*'.repeat(label.length)) :  label;
                    } else if (EnvelopeType === 'template') {
                        newSpnControlEle.innerHTML = controlValue !== null && controlValue !== '' ? ('*'.repeat(controlValue.length)) : label !== null && label !== '' ? ('*'.repeat(label.length)) :  label;
                    }
                }
                else if (iscopyEnvelopeId === true && EnvelopeType === 'envelope') {
                    newSpnControlEle.innerHTML = (isPrefillText === true || isPrefillText === 'true') ? (controlValue || label) : (controlValue === 'null' || controlValue === '' ? label : controlValue);
                } else if (EnvelopeType === 'envelope' && (isPrefillText === false || isPrefillText === 'false') && integrationType === '') {
                    newSpnControlEle.innerHTML = controlValue === 'null' || controlValue === '' ? (label ? '*'.repeat(label.length) : label) : controlValue;
                }
                else if (EnvelopeType === 'template') {
                    newSpnControlEle.innerHTML = (isPrefillText === true || isPrefillText === 'true') ? (controlValue ? '*'.repeat(controlValue.length) : label) : (controlValue === 'null' || controlValue === '' ? (label ? '*'.repeat(label.length) : label) : controlValue);
                }
                else {
                    newSpnControlEle.innerHTML = controlValue ? '*'.repeat(controlValue.length) : label;
                }

                const isAsteriskOriginalText = (isPrefillText === true || isPrefillText === 'true')
                    ? controlValue || newSpnControlEle.innerHTML
                    : label || newSpnControlEle.innerHTML;

                newResizableControlEle?.setAttribute(
                    'data-ishidetextwithasterisk',
                    iscopyEnvelopeId === true && EnvelopeType === 'envelope' ? 'false' : 'true'
                );
                newResizableControlEle?.setAttribute('data-asteriskOriginalText', isAsteriskOriginalText);
            } else {
                newSpnControlEle.innerHTML = innerHtmlAttr;
            }
            let externalIntegrationType: any = integrationResp.integrationType; //Vincere, Bullhorn
            newResizableControlEle.setAttribute('data-IntegrationType', externalIntegrationType);
            if (externalIntegrationType && (externalIntegrationType.toLowerCase() === 'vincere')) {
                let entityFieldValue: any = this.getKeyByValue(oldControlObj.customToolTip, integrationResp.entityFieldsList);
                if (integrationResp.envelopeStage && (integrationResp.envelopeStage == "PrepareEditTemplate" || integrationResp.envelopeStage == "PrepareTemplate")) {
                    let ctrlLabel: any = newResizableControlEle.innerText;
                    newResizableControlEle?.setAttribute("data-Entity", integrationResp.entityList[1].key);
                    newResizableControlEle?.setAttribute("EntityField", ctrlLabel);
                    newResizableControlEle?.setAttribute("data-EntityField", ctrlLabel);
                    if (ctrlLabel === '*'.repeat(newResizableControlEle.innerText.length) || ctrlLabel === '') {
                        ctrlLabel = oldControlObj.controlValue;
                    }
                    entityFieldValue = this.getKeyByValue(ctrlLabel, integrationResp.entityFieldsList);
                    newResizableControlEle?.setAttribute("data-EntityFieldValue", entityFieldValue);
                    newResizableControlEle?.setAttribute('data-asteriskoriginaltext', entityFieldValue);
                    prefillAttr = "false";
                }
                else {
                    newResizableControlEle?.setAttribute("data-Entity", integrationResp.entityList[1].value);
                    newResizableControlEle?.setAttribute("data-EntityField", oldControlObj.customToolTip);
                    newResizableControlEle?.setAttribute("data-EntityFieldValue", (entityFieldValue != null && entityFieldValue != 'null') ? entityFieldValue : oldControlObj.customToolTip);
                    newResizableControlEle?.setAttribute('data-asteriskoriginaltext', (entityFieldValue != null && entityFieldValue != 'null') ? entityFieldValue : oldControlObj.customToolTip);
                    if (entityFieldValue !== '' && entityFieldValue !== undefined && entityFieldValue !== null) {
                        newSpnControlEle.innerHTML = entityFieldValue;
                        prefillAttr = "true";
                    }
                    else {
                        prefillAttr = "false";
                    }
                }

                let defaultDateFormatValue = "mm/dd/yyyy";
                if (this.sessionStorageService.getCulture() === "es-es") defaultDateFormatValue = "mm/dd/aaaa";
                if (newResizableControlEle?.getAttribute('data-Entity') != 'Select' && newResizableControlEle?.getAttribute('data-EntityFieldValue') != 'Select') {
                    let entityType: any = newResizableControlEle?.getAttribute('data-EntityField');
                    entityFieldValue = newResizableControlEle?.getAttribute('data-EntityFieldValue');
                    if (newResizableControlEle.id.includes('resizable_textControl')) {
                        if ((integrationResp.envelopeStage != "PrepareEditTemplate" && integrationResp.envelopeStage != "PrepareTemplate") && entityType && (entityType.toLowerCase().includes('date') || entityType.toLowerCase() === 'availability start')) {
                            let attr_data_additional_validation_option: any = additionalValidationOptionName;
                            let validationOption: any = (attr_data_additional_validation_option && attr_data_additional_validation_option != "null" && attr_data_additional_validation_option != "undefined") ? attr_data_additional_validation_option : defaultDateFormatValue;
                            if (newResizableControlEle) {
                                this.updateDateFormatNameAndOptions(newResizableControlEle, validationOption);
                                entityFieldValue = newResizableControlEle?.getAttribute('data-entityfielddateformatedvalue');
                                newResizableControlEle?.setAttribute("data-EntityField", entityType);
                                newResizableControlEle?.setAttribute("data-EntityFieldValue", entityFieldValue);
                                newResizableControlEle?.setAttribute('data-asteriskoriginaltext', entityFieldValue);
                                newSpnControlEle.innerHTML = entityFieldValue;
                            }
                        }
                    }
                }
                this.updateTextAreaText(innerHtmlAttr, newControlId);
            } else {
                newResizableControlEle?.setAttribute("data-Entity", '');
                newResizableControlEle?.setAttribute("data-EntityField", '');
                newResizableControlEle?.setAttribute("data-EntityFieldValue", '');
            }

            if (prefillAttr == "true") {
                newResizableControlEle?.setAttribute('data-prefill', 'true');
                newResizableControlEle?.setAttribute('data-displayLabel', 'false');
            }
            else {
                newResizableControlEle?.setAttribute('data-prefill', 'false');
                newResizableControlEle?.setAttribute('data-displayLabel', 'true');
            }
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            newResizableControlEle?.setAttribute('data-size', sizeAttr);
            if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-groupname', groupnameAttr);
                newSpnControlEle?.setAttribute('name', grpNameAttr);
            }

            if (newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-isGroupChecked', '');
                if (groupnameAttr != null && groupnameAttr != '' && grpNameAttr != null && grpNameAttr != '') {
                    newResizableControlEle?.setAttribute('data-groupname', groupnameAttr);
                    newSpnControlEle?.setAttribute('name', grpNameAttr);
                    newResizableControlEle?.setAttribute('data-isGroupChecked', 'true');
                }
                else newResizableControlEle?.setAttribute('data-isGroupChecked', 'false');
            }
            newSpnControlEle?.setAttribute('font-family-id', 'D4A45ECD-3865-448A-92FA-929C2295EA34');
            let defaultSize: any = this.getRadioCheckBoxSizeVal(sizeAttr);
            this.applyRadioAndCheckBoxSizes(defaultSize, newResizableControlEle, newSpnControlEle);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.DropDown.toLowerCase()) {
            newResizableControlEle?.setAttribute('data-firstlineblank', 'false');
            let newSelectDropdownControlField: any = (document.getElementById('spn' + newControlId)) as HTMLSelectElement;
            if (oldControlObj && oldControlObj.selectControlOptions && oldControlObj.selectControlOptions.length > 0) {
                oldControlObj.selectControlOptions = oldControlObj.selectControlOptions.sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                for (let index = 0; index < oldControlObj.selectControlOptions.length; index++) {
                    var text = oldControlObj.selectControlOptions[index].optionText;
                    var ruleId = oldControlObj.selectControlOptions[index]?.id;
                    var value = oldControlObj.selectControlOptions[index]?.order;

                    if (value == "0" && text == '') {
                        newResizableControlEle?.setAttribute('data-firstlineblank', 'true');
                    }

                    var option = '';
                    if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                    else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                    newSelectDropdownControlField.insertAdjacentHTML('beforeend', option);
                }
            }
            if (expandedAttr == true || expandedAttr == "true")
                newResizableControlEle.classList.add('expanded');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Label.toLowerCase()) {
            newSpnControlEle.innerHTML = innerHtmlAttr;
            newSpnControlEle?.setAttribute('isupdated', 'true');
            newSpnControlEle?.setAttribute('data-label-text', innerHtmlAttr);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
            newControlEle?.setAttribute('data-hyperlink', hyperlinkAttr);
            newSpnControlEle.innerHTML = innerHtmlAttr;
            newSpnControlEle?.setAttribute('isupdated', 'true');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Date.toLowerCase()) {
            this.dateFormat = this.sessionStorageService.getDateformat();
            this.dateformatValue = this.sessionStorageService.getDateformatValue();
            newResizableControlEle?.setAttribute('data-defaultdate', defaultdateAttr);
            newSpnControlEle.innerHTML = innerHtmlAttr;
            let dateFormatAttr: any = '';
            let dateformatArray: any = this.dateformatValue;
            let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.viewValue == innerHtmlAttr);
            if (dateArrayObj && dateArrayObj.length > 0)
                dateFormatAttr = dateArrayObj[0].value;
            else dateFormatAttr = this.dateFormat[0].value;

            dateformatArray = this.dateFormat;
            dateArrayObj = dateformatArray.filter((obj: any) => obj.value == dateFormatAttr);
            if (dateArrayObj && dateArrayObj.length > 0)
                dateFormatAttr = dateArrayObj[0].viewValue;
            else dateFormatAttr = this.dateFormat[0].viewValue;

            newResizableControlEle?.setAttribute('data-defaultformat', dateFormatAttr);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Company.toLowerCase() ||
            newControlObj.dataGuid.toLowerCase() == Control.Email.toLowerCase() ||
            newControlObj.dataGuid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', '');
            newResizableControlEle?.setAttribute('maxcharallowed', '');
            newResizableControlEle?.setAttribute('maxemailcharallowed', '');
            newResizableControlEle?.setAttribute('data-isfixedwidth', 'true');
        }

        if (newControlObj.dataGuid.toLowerCase() == Control.Initials.toLowerCase()) {
            let newResizableControlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + newControlObj.id));
            newResizableControlEle?.setAttribute('fromParentInitialCtrl', 'true');
        }

        this.showRequiredIcon(newControlId);
        this.removeControlAttributes(newResizableControlEle, newControlObj.dataGuid);
    }

    updateExistingControlDependencyFields(documentDetails: any) {
        if (documentDetails && documentDetails.length > 0) {
            for (let index = 0; index < documentDetails.length; index++) {
                const documentElement = documentDetails[index];
                let documentContentDetails: any = documentElement.documentContentDetails;
                if (documentContentDetails && documentContentDetails.length > 0) {
                    for (let index = 0; index < documentContentDetails.length; index++) {
                        const docContentItem: any = documentContentDetails[index];
                        if (docContentItem.conditionalControlsDetails && docContentItem.conditionalControlsDetails.dependentFields && docContentItem.conditionalControlsDetails.dependentFields.length > 0) {
                            let dependenciesItemsArray: any = docContentItem.conditionalControlsDetails.dependentFields;
                            Array.from(dependenciesItemsArray).forEach((depenItem: any, v: any) => {
                                let childDependentItem: any = document.querySelector('[data-document-content-id="' + depenItem.controlID + '"]');
                                if (childDependentItem) {
                                    childDependentItem.setAttribute('data-parent-id', docContentItem.id);
                                    childDependentItem.setAttribute('data-parent-rule-id', depenItem.conditionID);
                                    childDependentItem.setAttribute('is-parent-present', "true");

                                    let parentItem: any = document.querySelector('[data-document-content-id="' + docContentItem.id + '"]');
                                    let parent_groupCode: any = parentItem.getAttribute('group-code');
                                    let child_groupCode: any = childDependentItem.getAttribute('group-code');
                                    if (child_groupCode != '' || parent_groupCode != '') {
                                        //parent control
                                        if (parent_groupCode == null || parent_groupCode == '') {
                                            if (child_groupCode != null && child_groupCode != "" && child_groupCode != "null" && child_groupCode != undefined)
                                                parentItem.setAttribute('group-code', child_groupCode);
                                        }
                                        else if (child_groupCode == '' || child_groupCode == null || child_groupCode == 'null' || child_groupCode == undefined) {
                                            //child control
                                            if (parent_groupCode != null && parent_groupCode != "" && parent_groupCode != "null" && parent_groupCode != undefined) {
                                                childDependentItem.setAttribute('group-code', parent_groupCode);
                                                child_groupCode = parent_groupCode;
                                            }
                                        }
                                        //child control
                                        if (child_groupCode != null && child_groupCode != "" && child_groupCode != "null" && child_groupCode != undefined) {
                                            if (child_groupCode != 0 && child_groupCode != "0") {
                                                let colorCode: any = this.commonConstants.DependencyColorCodes[parseInt(child_groupCode) % 10].Color;
                                                this.applyColorCodeForItem(childDependentItem.getAttribute('id'), colorCode);
                                                childDependentItem?.classList.add('alreadyDependentField');
                                                childDependentItem?.classList.add('addedAsChild');
                                            }
                                        }
                                    }
                                }
                            });
                        }
                        else {
                            let resizableControlItem: any = document.getElementById("resizable_" + docContentItem.controlHtmlID);
                            if (resizableControlItem) {
                                let mainControlItem: any = document.getElementById(docContentItem.controlHtmlID);
                                let attr_isParentPresent: any = mainControlItem.getAttribute('is-parent-present');
                                if (attr_isParentPresent != "true") {
                                    let attr_dataGuid: any = resizableControlItem.getAttribute('data-guid');
                                    if (attr_dataGuid && (attr_dataGuid.toLowerCase() == Control.Text.toLocaleLowerCase() || attr_dataGuid.toLowerCase() == Control.Radio.toLocaleLowerCase() ||
                                        attr_dataGuid.toLowerCase() == Control.Checkbox.toLocaleLowerCase() || attr_dataGuid.toLowerCase() == Control.DropDown.toLocaleLowerCase())) {

                                        if (attr_dataGuid.toLowerCase() == Control.Radio.toLocaleLowerCase() || attr_dataGuid.toLowerCase() == Control.Checkbox.toLocaleLowerCase()) {
                                            let attr_dataSelected: any = resizableControlItem.getAttribute('data-selected1');
                                            if (attr_dataSelected != "true")
                                                this.removeColorForItem(docContentItem.controlHtmlID);
                                        } else {
                                            this.removeColorForItem(docContentItem.controlHtmlID);
                                        }

                                        let controlItem: any = document.getElementById(docContentItem.controlHtmlID);
                                        this.removeAttributes(controlItem.getAttribute('data-document-content-id'));
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    disableUndoButtons(isEnvelopeEditable: any) {
        let className: any = 'disabledCtrl';
        if (isEnvelopeEditable == false) return className;
        let undoBtn: any = document.getElementById("undoBtn");
        // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
        let arrCopyPasteCtrl: any = this.sessionStorageService.getArrCopyPasteCtrl();
        if (arrCopyPasteCtrl) {
            let arrCopyPasteCtrlArray: any = JSON.parse(arrCopyPasteCtrl);
            // let arrUndoRedoCtrlArrTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
            let arrUndoRedoCtrlArrTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
            if (arrUndoRedoCtrlArrTemp !== '') {
                let arrUndoRedoCtrlArrayTemp: any = JSON.parse(arrUndoRedoCtrlArrTemp);
                if ((arrCopyPasteCtrlArray && arrCopyPasteCtrlArray.length > 0) &&
                    (arrUndoRedoCtrlArrayTemp && arrUndoRedoCtrlArrayTemp.length > 0)) {
                    undoBtn.classList.remove("disabledCtrl");
                    className = 'enabledCtrl';
                }
            }
        }
        return className;
    }

    disableRedoButtons(isEnvelopeEditable: any) {
        let className: any = 'disabledCtrl';
        if (isEnvelopeEditable == false) return className;
        let redoBtn: any = document.getElementById("redoBtn");
        // let arrUndoCtrlsCount: any = localStorage.getItem('arrUndoCtrlsCount');
        let arrUndoCtrlsCount: any = this.sessionStorageService.getArrUndoCtrlsCount();
        if (arrUndoCtrlsCount) {
            let arrUndoCtrlsCountArray: any = JSON.parse(arrUndoCtrlsCount);
            if (arrUndoCtrlsCountArray && arrUndoCtrlsCountArray.length > 0) {
                redoBtn.classList.remove("disabledCtrl");
                className = 'enabledCtrl';
            }
        }
        return className;
    }

    undo() {
        let ctrlIdArr: any[] = [];
        // let arrUndoRedoCtrl: any = localStorage.getItem('ArrUndoRedoCtrl');
        let arrUndoRedoCtrl: any = this.sessionStorageService.getArrUndoRedoCtrl();

        // let arrUndoRedoCtrlArrTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
        let arrUndoRedoCtrlArrTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
        if (arrUndoRedoCtrlArrTemp) {
            let arrUndoRedoCtrlArrayTemp: any = JSON.parse(arrUndoRedoCtrlArrTemp);
            // console.log('arrUndoRedoCtrlArrayTemp', arrUndoRedoCtrlArrayTemp);
            if (arrUndoRedoCtrl) {
                let arrUndoRedoCtrlArray: any = JSON.parse(arrUndoRedoCtrl);
                if (arrUndoRedoCtrlArrayTemp && arrUndoRedoCtrlArrayTemp.length > 0) {
                    if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
                        arrUndoRedoCtrlArrayTemp.forEach((ele: any, index: any) => {
                            let undoCtrl: any = arrUndoRedoCtrlArrayTemp[index];
                            ctrlIdArr.push(undoCtrl.Control.id);
                            arrUndoRedoCtrlArray = arrUndoRedoCtrlArray.filter(function (item: any) { return item.Control.id !== ele.Control.id; });
                            // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(arrUndoRedoCtrlArray));
                            this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(arrUndoRedoCtrlArray));
                        })

                        arrUndoRedoCtrlArrayTemp = [];
                        let temp: any = arrUndoRedoCtrlArray.reverse();
                        // let selecteditemsLength: any = localStorage.getItem('selecteditemsLength');
                        let selecteditemsLength: any = this.sessionStorageService.getSelecteditemsLength();
                        if (temp && temp.length) {
                            temp.forEach((ele: any, index: any) => {
                                if (index <= parseInt(selecteditemsLength) - 1) {
                                    arrUndoRedoCtrlArrayTemp.push(ele);
                                }
                            })
                        }
                        // localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify(arrUndoRedoCtrlArrayTemp));
                        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(arrUndoRedoCtrlArrayTemp));
                    }
                }
            }
        }

        return ctrlIdArr;
    }

    //when delete the control while doing undo redo
    updateUndoRedoCopyPasteControlArray(ctrlId: any) {
        // let arrUndoRedoCtrl: any = localStorage.getItem('ArrUndoRedoCtrl');
        // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');

        let arrUndoRedoCtrl: any = this.sessionStorageService.getArrUndoRedoCtrl();
        let arrCopyPasteCtrl: any = this.sessionStorageService.getArrCopyPasteCtrl();

        let arrUndoRedoCtrlArray: any = JSON.parse(arrUndoRedoCtrl);
        if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
            arrUndoRedoCtrlArray = arrUndoRedoCtrlArray.filter(function (item: any) { return item.Control.id !== ctrlId; });
            // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(arrUndoRedoCtrlArray));
            this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(arrUndoRedoCtrlArray));
            if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
                let copyPasteItem: any = arrUndoRedoCtrlArray[arrUndoRedoCtrlArray.length - 1];
                arrCopyPasteCtrl = [];
                arrCopyPasteCtrl.push({ ParentControl: copyPasteItem.ParentControl, Control: copyPasteItem.Control, Action: 'P' });
                // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
                this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(arrCopyPasteCtrl));
            }
        }
        if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length == 0) {
            // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
            let arrCopyPasteCtrl: any = this.sessionStorageService.getArrCopyPasteCtrl();
            arrCopyPasteCtrl = [];
            // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
            this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(arrCopyPasteCtrl));
        }
    }

    removeControlAttributes(newResizableControlEle: any, data_guid: any) {
        if (data_guid && data_guid.toLowerCase() == Control.Signature.toLowerCase() || data_guid.toLowerCase() == Control.Initials.toLowerCase() ||
            data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase() || data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-defaultdate');
            newResizableControlEle?.removeAttribute('data-placeholder');
            newResizableControlEle?.removeAttribute('data-typography');
            newResizableControlEle?.removeAttribute('data-confirm-submit');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
        }
        else if (data_guid && data_guid.toLowerCase() == Control.Date.toLowerCase()) {
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
        }
        else if (data_guid && data_guid.toLowerCase() != Control.Email.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-confirm-submit');
        }
        else if (data_guid.toLowerCase() != Control.DropDown.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-firstlineblank');
        }
        else if (data_guid && (data_guid.toLowerCase() == Control.Label.toLowerCase() || data_guid.toLowerCase() == Control.Hyperlink.toLowerCase())) {
            newResizableControlEle?.removeAttribute('data-defaultdate');
            newResizableControlEle?.removeAttribute('data-confirm-submit');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
            newResizableControlEle?.removeAttribute('data-customtooltip');
            newResizableControlEle?.removeAttribute('data-original-title');
            newResizableControlEle?.setAttribute('title', '');
        }
        else if (data_guid && (
            data_guid.toLowerCase() != Control.Text.toLowerCase() &&
            data_guid.toLowerCase() != Control.Name.toLowerCase() &&
            data_guid.toLowerCase() != Control.Title.toLowerCase())) {
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
        }
    }

    enableShowControlId(val: any) {
        if (val && val != null && (val == "True" || val == "true")) return "block";
        else return "none";
    }

    setHideFixedwidthCheckbox(val: any) {
        this.hideFixedwidthCheckbox = val;
    }

    getHideFixedwidthCheckbox() {
        return this.hideFixedwidthCheckbox;
    }

    hideCtrlCharLimitInput(charInputLimit: any) {
        if (charInputLimit) {
            charInputLimit.style.display = 'none';
        }
    }

    showCtrlCharLimitInput(charInputLimit: any) {
        if (charInputLimit) {
            charInputLimit.style.display = 'block';
        }
    }

    hideCtrlLengthInput(inputLenth: any) {
        if (inputLenth) {
            inputLenth.style.display = 'none';
        }
    }

    showCtrlLengthInput(inputLenth: any) {
        if (inputLenth) {
            inputLenth.style.display = 'block';
        }
    }

    checkFixedWidthFalseAndMaxCharLimit(ctrlName: any, ctrlInputLength: string, ctrlCharInputLimit: string, isFixedWidthContainer: string, isFixedWidthChecked: any) {
        let inputLength: any = document.getElementById(ctrlInputLength);
        if (inputLength) {
            this.hideCtrlLengthInput(inputLength);
        }
        let charInputLimit: any = document.getElementById(ctrlCharInputLimit);
        if (charInputLimit) {
            this.showCtrlCharLimitInput(charInputLimit);
            charInputLimit.value = (ctrlName.toLowerCase() === 'text') ? '4000' : '100';
        }
        let isFixedWidthNameContainer: any = document.getElementById(isFixedWidthContainer);
        if (isFixedWidthNameContainer && isFixedWidthNameContainer.children && isFixedWidthNameContainer.children[0]) {
            let isFixedWidthEle: any = document.getElementById(isFixedWidthNameContainer.children[0].id)
            if (isFixedWidthEle) {
                isFixedWidthEle.checked = isFixedWidthChecked;
                let fixedWidthDisplayOrNot: any = this.getHideFixedwidthCheckbox();
                if (fixedWidthDisplayOrNot !== true) {
                    isFixedWidthNameContainer.style.display = 'block';
                }
                else {
                    isFixedWidthNameContainer.style.display = 'none';
                }
            }
        }
    }

    checkFixedWidthTrueAndMaxCharLimit(ctrlName: any, ctrlInputLength: string, ctrlCharInputLimit: string, isFixedWidthContainer: string) {
        let inputLength: any = document.getElementById(ctrlInputLength);
        if (inputLength) {
            this.showCtrlLengthInput(inputLength);
            inputLength.value = '20';
        }
        let charInputLimit: any = document.getElementById(ctrlCharInputLimit);
        if (charInputLimit) {
            this.hideCtrlCharLimitInput(charInputLimit);
        }

        let isFixedWidthCont: any = document.getElementById(isFixedWidthContainer);
        if (this.hideFixedwidthCheckbox === true) {
            if (isFixedWidthCont) {
                isFixedWidthCont.style.display = 'none';
            }
        } else {
            if (isFixedWidthCont) {
                isFixedWidthCont.style.display = 'block';
            }
        }
    }

    updateRecipientsInStorage(currentSelectedControl: any, selectedRecipentId: any) {
        // let allImageControlsString: any = localStorage.getItem('AllImageControls');
        let allImageControlsString: any = this.sessionStorageService.getAllImageControls();
        let arrControlIdNumbers: any = [];
        if (allImageControlsString) {
            arrControlIdNumbers = JSON.parse(allImageControlsString);
            for (let index = 0; index < arrControlIdNumbers.length; index++) {
                const element = arrControlIdNumbers[index];
                if (element.ControlId.toLowerCase() === currentSelectedControl.toLowerCase()) {
                    element.RecipientId = selectedRecipentId;
                }
            }
        }

        // localStorage.setItem('AllImageControls', JSON.stringify(arrControlIdNumbers));
        this.sessionStorageService.setAllImageControls(JSON.stringify(arrControlIdNumbers));

        // let controlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        let controlDependencyFieldsString: any = this.sessionStorageService.getControlDependencyFieldsArray();
        let arrControlFieldDependenceControls: any = [];
        if (controlDependencyFieldsString) {
            arrControlFieldDependenceControls = JSON.parse(controlDependencyFieldsString);
            for (let index = 0; index < arrControlFieldDependenceControls.length; index++) {
                const element = arrControlFieldDependenceControls[index];
                if (element.ControlId.toLowerCase() === currentSelectedControl.toLowerCase()) {
                    element.RecipientId = selectedRecipentId;
                }
            }
        }

        // localStorage.setItem('ControlDependencyFieldsArray', JSON.stringify(arrControlFieldDependenceControls));
        this.sessionStorageService.setControlDependencyFieldsArray(JSON.stringify(arrControlFieldDependenceControls));

        setTimeout(() => {
            let controlEleItem: any = document.getElementById(currentSelectedControl);
            let ctrlParentId: any = controlEleItem.getAttribute("data-parent-id");
            if (ctrlParentId && ctrlParentId != null && ctrlParentId != "null") {
                let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + ctrlParentId + '"]');
                if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length == 1) {
                    var parentElement: any = document.querySelector('[data-document-content-id="' + ctrlParentId + '"]');
                    var isParentPresent = parentElement?.getAttribute('is-parent-present');
                    if ((isParentPresent == "false" || isParentPresent == "" || isParentPresent == "null" || isParentPresent == null || typeof isParentPresent == "undefined")) {
                        this.removeColorForItem(parentElement?.getAttribute('id'));
                    }
                }
            }

            let childDependentList: any = controlEleItem.getAttribute("is-child-present");
            if (childDependentList == "true") {
                this.removeColorForDependentFields(controlEleItem.getAttribute("data-document-content-id"));
            }

            let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
            let data_guid = resizableCurrentControl.getAttribute("data-guid");
            if (data_guid.toLowerCase() != Control.DateTimeStamp.toLowerCase() && data_guid.toLowerCase() != Control.Label.toLowerCase() && data_guid.toLowerCase() != Control.Hyperlink.toLowerCase()) {
                this.removeColorForItem(currentSelectedControl);
            }

            let ctrlParentDocumentContentId: any = controlEleItem.getAttribute("data-document-content-id");
            this.removeAttributes(ctrlParentDocumentContentId);
        }, 1);
    }

    removeColorForDependentFields(docContentId: any) {
        let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
        if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
            Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                let docContentid: any = childDependentItem.getAttribute('data-document-content-id');
                this.removeAttributes(docContentid);
                document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');
                this.removeColorForItem(childDependentItem.getAttribute('id'));
                this.removeColorForDependentFields(childDependentItem);
            });
        }
    }

    resetTextToTextArea(id: any) {
        // let resetAddTextArea: any = document.getElementById('addTextTextarea');
        // if (resetAddTextArea) {
        //     resetAddTextArea.value = 'Text';
        // }
        let ctrlSpnEle: any = document.getElementById('spn' + id);
        if (ctrlSpnEle) {
            ctrlSpnEle.innerText = 'Text';
        }
    }

    resetTextAreaInputs() {
        let currentControlId: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let ctrlEle: any = document.getElementById('resizable_' + currentControlId);
        if (ctrlEle) {
            let attr_guid: any = ctrlEle.getAttribute('data-guid');
            let attr_texttype: any = ctrlEle.getAttribute('data-texttype');
            let disableInputField: any = document.getElementById('textInputLength');
            if (attr_guid && attr_guid.toLowerCase() == Control.Text.toLowerCase()) {
                if (attr_texttype && (attr_texttype != "5121246A-D9AB-49F4-8717-4EF4CAAB927B" && attr_texttype != "DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D" &&
                    attr_texttype != "8348E5CD-59EA-4A77-8436-298553D286BD")) {
                    if (disableInputField) {
                        disableInputField.classList.remove('disabled');
                    }
                }
                else {
                    if (disableInputField) {
                        disableInputField.classList.add('disabled');
                    }
                }
            }

            let attr_integrationType: any = ctrlEle.getAttribute('data-integrationtype');
            if (attr_integrationType && attr_integrationType != "undefined" && attr_integrationType != "null" && attr_integrationType.toLowerCase() !== 'vincere') {
                let textValidationsoptions: any = document.getElementById('textValidationsoptions');
                if (textValidationsoptions) {
                    textValidationsoptions.classList.remove('disabled');
                }
            }
            let resetEntityFields: any = document.getElementById('entityFields');
            if (resetEntityFields) {
                resetEntityFields.value = 'Select';
            }
        }
    }

    getValueByKey(value: any, list: any) {
        if (value) {
            for (let i = 0; i < list.length; i++) {
                let item: any = list[i] && (list[i].value ? list[i].value : list[i].Value);
                if (item && (item.toLowerCase() === value.toLowerCase() || item.toLowerCase() === value.toLowerCase())) {
                    return (list[i].Value || list[i].value);
                }
            }
        }
        return null; // If the value is not found, return null
    }

    //passing value here and getting key value based on value
    getKeyByValue(value: any, list: any) {
        if (value) {
            for (let i = 0; i < list.length; i++) {
                let item: any = list[i] && (list[i].value ? list[i].value : list[i].Value);
                if (item && (item.toLowerCase() === value.toLowerCase() || item.toLowerCase() === value.toLowerCase())) {
                    return (list[i].Key || list[i].key);
                }
            }
        }
        return null; // If the value is not found, return null
    }

    showWarning(msg: any, type: any) {
        this.toastrService.warning(msg, type);
    }

    updateDateFormatNameAndOptions(ctrl: any, validationOption: any) {
        let entityFieldsValues: any = ctrl.getAttribute('data-entityfieldvalue');
        let entityField: any = ctrl.getAttribute('data-entityfield');
        ctrl.setAttribute('data-entityfielddateformatedvalue', '');
        if((entityField && (entityField.toLowerCase().includes('date') || entityField.toLowerCase() === 'availability start'))){
            ctrl?.setAttribute('data-validation-type', 'Date');
            ctrl?.setAttribute('data-texttype', this.getTextTypeValue('date'));
        }
        if (entityFieldsValues) { // Avoid short/invalid values
            // Extract only the date part (ignore time if present)
            let dateOnly = entityFieldsValues.split(' ')[0]; // Remove time like "00:00:00"

            // Detect and parse the date
            let parsedDate = this.detectAndParseDate(dateOnly);
            ctrl?.setAttribute('data-validation-type', 'Date');

            if (parsedDate) {
                let year = parsedDate.getFullYear();
                let month = ('0' + (parsedDate.getMonth() + 1)).slice(-2); // Ensure 2-digit month
                let day = ('0' + parsedDate.getDate()).slice(-2); // Ensure 2-digit day
                let dmonth = parseInt(month, 10);
                let df: any = validationOption;

                // Step 3: Convert to desired format
                let dateRetVal: any = this.commonService.getDateFormat(day, month, year, df, dmonth);

                // Step 4: Set attributes
                ctrl.setAttribute('data-entityfielddateformatedvalue', dateRetVal);
                ctrl.setAttribute('data-asteriskoriginaltext', dateRetVal);
            }
        } else {
            ctrl.setAttribute('data-entityfielddateformatedvalue', entityFieldsValues ?? entityField);
            ctrl.setAttribute('data-asteriskoriginaltext', entityFieldsValues ?? entityField);
        }
    }
    // Function to dynamically detect and parse a date based on known formats
    detectAndParseDate(dateStr: string): Date | null {
        if (!dateStr) return null;

        // Match dates like 10-01-2025, 01/10/2025, 2025-01-10
        let dateMatch = dateStr.match(/(\d{1,4})[-\/.](\d{1,2})[-\/.](\d{1,4})/);
        if (dateMatch) {
            let first = parseInt(dateMatch[1], 10);
            let second = parseInt(dateMatch[2], 10);
            let third = parseInt(dateMatch[3], 10);
            // In this case, 10 means decimal (base-10), which is the standard numbering system.If radix was not specified, older JavaScript versions may assume octal (base-8) for numbers starting with 0.Might return NaN in some browsers (treated as octal).
            let year, month, day;

            if (first > 31) { // YYYY-MM-DD or YYYY-DD-MM
                year = first;
                if (second > 12) {
                    day = second; month = third; // YYYY-DD-MM
                } else {
                    month = second; day = third; // YYYY-MM-DD
                }
            } else if (third > 31) { // DD-MM-YYYY or MM-DD-YYYY
                year = third;
                if (second > 12) {
                    day = first; month = second; // DD-MM-YYYY
                } else {
                    month = first; day = second; // MM-DD-YYYY
                }
            } else {
                day = first; month = second; year = third; // Default to DD-MM-YYYY
            }

            return new Date(`${year}-${month}-${day}`);
        }

        // Try JavaScript's Date parser for natural formats
        let parsed = new Date(dateStr);
        if (!isNaN(parsed.getTime())) return parsed;

        return null; // if no valid date detected
    }

    moveSelectedItems(selectedItems: any, deltaX: number, deltaY: number) {
        let containerWidth: any = 0;
        let containerHeight: any = 0;

        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach((ctrl: any) => {
                let ctrlEle: any = document.getElementById(ctrl);
                let checkBGColor: any = ctrlEle.style.background;
                if (ctrlEle && checkBGColor === 'bisque') {
                    let pageNo: any = ctrlEle.getAttribute('pageno');
                    let imageControlEle: any = document.getElementById('imgControl_' + parseInt(pageNo));
                    if (imageControlEle) {
                        containerWidth = (imageControlEle.offsetWidth);
                        containerHeight = (imageControlEle.offsetHeight);
                    }

                    let positions: any = this.getElePositions(ctrlEle);
                    let width: any = ctrlEle.style.width.split('px')[0];
                    let height: any = ctrlEle.style.height.split('px')[0];
                    let newTransformValue: any = 0;

                    if (deltaX === 1 && (parseFloat(positions.Left) + parseFloat(width) + 10) <= containerWidth) {
                        //newTransformValue = `translate3d(${positions.Left + deltaX}px, ${positions.Top + deltaY}px, 0px)`;
                        ctrlEle.style.left = (parseFloat(positions.Left) + deltaX) + "px";
                        ctrlEle.style.top = (parseFloat(positions.Top) + deltaY) + "px";
                    }
                    else if (deltaX === -1 && (parseFloat(positions.Left) > 10)) {
                        //newTransformValue = `translate3d(${positions.Left + deltaX}px, ${positions.Top + deltaY}px, 0px)`;
                        ctrlEle.style.left = (parseFloat(positions.Left) + deltaX) + "px";
                        ctrlEle.style.top = (parseFloat(positions.Top) + deltaY) + "px";
                    }
                    else if (deltaY === 1 && (parseFloat(positions.Top) + parseFloat(height) + 10) <= containerHeight) {
                        //newTransformValue = `translate3d(${positions.Left + deltaX}px, ${positions.Top + deltaY}px, 0px)`;
                        ctrlEle.style.left = (parseFloat(positions.Left) + deltaX) + "px";
                        ctrlEle.style.top = (parseFloat(positions.Top) + deltaY) + "px";
                    }
                    else if (deltaY === -1 && (parseFloat(positions.Top) > 8)) {
                        //newTransformValue = `translate3d(${positions.Left + deltaX}px, ${positions.Top + deltaY}px, 0px)`;
                        ctrlEle.style.left = (parseFloat(positions.Left) + deltaX) + "px";
                        ctrlEle.style.top = (parseFloat(positions.Top) + deltaY) + "px";
                    }
                }
            });
        }
    }

    checkeInitOnEachPageProperties(ctrlId: any) {
        let resizableEle: any = document.getElementById('resizable_' + ctrlId);
        if (resizableEle) {
            let fromParentInitialCtrl: any = resizableEle.getAttribute('fromParentInitialCtrl');
            if (fromParentInitialCtrl === 'true') {
                let chInitRequiredContainer: any = document.getElementById('chInitRequiredContainer');
                if (chInitRequiredContainer) {
                    chInitRequiredContainer.classList.add('place-on-initials-btn');
                }
            } else {
                let chInitRequiredContainer: any = document.getElementById('chInitRequiredContainer');
                if (chInitRequiredContainer) {
                    chInitRequiredContainer.classList.remove('place-on-initials-btn');
                }
            }
        }
    }


    updateBgColorDateTimeStampAndLabel(ele: any, selectedCtrlEle: any) {
        let ctrlEle: any = document.getElementById(ele.replace('resizable_', ''));
        let controlName: any = '', isParentPresent: any = '', isChildPresent: any = '', controlGroupCode: any = '', controlParentId: any = '';
        if (ctrlEle) {
            controlName = ctrlEle.getAttribute('data-controlname');
            controlGroupCode = ctrlEle.getAttribute('group-code');
            controlParentId = ctrlEle.getAttribute('data-parent-id');
            isParentPresent = ctrlEle.getAttribute('is-parent-present');
            isChildPresent = ctrlEle.getAttribute('is-child-present');

            if (controlGroupCode == null && controlParentId != "null" && controlParentId != "" && controlParentId != null) {
                let parentGrpCode: any = document.querySelector('[data-document-content-id="' + controlParentId + '"]')?.getAttribute('group-code');
                if (parentGrpCode != null && parentGrpCode != "") {
                    controlGroupCode = parentGrpCode;
                }
            }

            if (controlName === 'DateTimeStamp' || controlName === 'Label' || controlName === 'Hyperlink') {
                selectedCtrlEle.style.background = '#ffff00';
            }
            else {
                if ((controlParentId == null || controlParentId == "null" || controlParentId == undefined || controlParentId == "undefined" || controlParentId == "") &&
                    (isChildPresent == "false" || isChildPresent == undefined || isChildPresent == "undefined" || isChildPresent == null || isChildPresent == "null" || isChildPresent == "")) {

                    if (selectedCtrlEle.style.background == "bisque") {
                        selectedCtrlEle.style.background = '#ffffff';
                    }

                    let attr_guid: any = document.getElementById(ele)!.getAttribute('data-guid');
                    if (attr_guid && attr_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                        let attr_dataSelected: any = document.getElementById(ele)!.getAttribute('data-selected1');
                        let spnCtrlEle: any = document.getElementById(ele.replace('resizable_', 'spn'));
                        if (spnCtrlEle && attr_dataSelected && attr_dataSelected == "true") {
                            spnCtrlEle.style["background-color"] = 'green';
                        }
                        //spnCtrlEle.style["background-color"] = '#ffffff';
                    }
                    else {
                        let divItem: any = document.getElementById(ele.replace('resizable_', 'div'));
                        if (divItem) {
                            if (attr_guid && attr_guid.toLowerCase() == Control.DropDown.toLowerCase())
                                divItem.style["border-color"] = "transparent transparent transparent #fff";
                            else divItem.style["border-color"] = "transparent transparent transparent #eee";
                        }
                    }
                }
                else {
                    if (controlGroupCode != null && controlGroupCode != "null" && controlGroupCode != undefined && controlGroupCode != "undefined" && controlGroupCode != "") {
                        if (controlGroupCode != "0" && controlGroupCode != 0) {
                            let colorCode: any = this.commonConstants.DependencyColorCodes[parseInt(controlGroupCode) % 10].Color;
                            this.applyColorCodeForParent(ele.replace('resizable_', ''), colorCode, "false");

                        }
                    }
                    else {
                        selectedCtrlEle.style.background = '#ffffff';
                    }
                }
            }
        }
    }

    removePreviousControlSelection(ctrlAndClickedControlsArr: any) {
        // if (this.dynamicComponentRefs) {
        // 	this.dynamicComponentRefs.forEach((value, key) => {
        // 		let ctrlKeyVal: any = `${key}`;
        // 		let selectedCtrlEle: any = document.getElementById('resizable_' + ctrlKeyVal);
        // 		if (selectedCtrlEle) {
        // 			this.controlPropertyService.updateBgColorDateTimeStampAndLabel(ele, selectedCtrlEle);
        // 			let attr_data_guid: any = selectedCtrlEle.getAttribute('data-guid');
        // 			if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
        // 				let spnSelectEle: any = document.getElementById('spn' + ctrlKeyVal);
        // 				spnSelectEle.classList.remove('mouse-selected');
        // 				//spnSelectEle.style.background = '#ffffff';
        // 				spnSelectEle.style.background = selectedCtrlEle.style.background;
        // 			}
        // 		}
        // 	});
        // }

        //console.log('ctrlAndClickedControlsArr', this.ctrlAndClickedControlsArr);

        if (ctrlAndClickedControlsArr && ctrlAndClickedControlsArr.length > 0) {
            ctrlAndClickedControlsArr.forEach((ele: any) => {
                let selectedCtrlEle: any = document.getElementById(ele);
                if (selectedCtrlEle) {
                    this.updateBgColorDateTimeStampAndLabel(ele, selectedCtrlEle);
                    let attr_data_guid: any = selectedCtrlEle.getAttribute('data-guid');
                    if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                        let spnSelectEle: any = document.getElementById('spn' + ele.replace('resizable_', ''));
                        spnSelectEle.classList.remove('mouse-selected');
                        spnSelectEle.style.background = selectedCtrlEle.style.background;
                    }
                }
            });
        }
    }

    restrictMouseEvents(event: KeyboardEvent) {
        let eventTargetId: any = event.target;
        let idsArr: any = ['controlInputTooltip', 'nameInputLength', 'nameCharInputLimit', 'ctrlHtmlId', 'dropdownList', 'dropDownItemList', 'inputLabel', 'inputHyperlinkText', 'inputHyperLinkURL', 'addTextTextarea', 'textCharInputLimit', 'textInputLength', 'inputGroupCtrl', 'inputGroupNameCtrl', 'inpTextColor', 'searchtext', 'paginationInput', 'titleInputLength','titleCharInputLimit'];
        let classArr: any = ['dropdownEditOption', 'dropdownCancel', 'dropdownDelete', 'txtDepSpecificText', 'show'];
        let isClassExists: boolean = false;
        classArr.forEach((element: any) => {
            if (eventTargetId?.classList.contains(element)) {
                isClassExists = true;
            }
        });

        let returnRestrcition: boolean = false;
        if (event && eventTargetId && !idsArr.includes(eventTargetId.id) && !isClassExists) {
            returnRestrcition = true;
        }
        return returnRestrcition;
    }

    // Subject to emit keyboard events
    private keyboardEventSubject = new Subject<KeyboardEvent>();
    keyboardEvent$ = this.keyboardEventSubject.asObservable();
    handleKeyboardEvent(event: KeyboardEvent, selectedItems: any[]) {
        if (event && event.target) {
            if (this.restrictMouseEvents(event)) {
                switch (event.key) {
                    case 'ArrowUp':
                        this.moveSelectedItems(selectedItems, 0, -1);
                        break;
                    case 'ArrowDown':
                        this.moveSelectedItems(selectedItems, 0, 1);
                        break;
                    case 'ArrowLeft':
                        this.moveSelectedItems(selectedItems, -1, 0);
                        break;
                    case 'ArrowRight':
                        this.moveSelectedItems(selectedItems, 1, 0);
                        break;
                }

                // Prevent the default action for arrow keys
                if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                    event.preventDefault();
                }
            }
        }
    }

    removeCtrlSelectionBgColor(selectedItems: any) {
        selectedItems.forEach((ele: any) => {
            let selectedCtrlEle: any = document.getElementById(ele);
            if (selectedCtrlEle) {
                this.updateBgColorDateTimeStampAndLabel(ele, selectedCtrlEle);
                let attr_data_guid: any = selectedCtrlEle.getAttribute('data-guid');
                if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                    let spnSelectEle: any = document.getElementById('spn' + ele.replace('resizable_', ''));
                    spnSelectEle.classList.remove('mouse-selected');

                    let isParentPresent: any = '', isChildPresent: any = '';
                    isParentPresent = selectedCtrlEle.getAttribute('is-parent-present');
                    isChildPresent = selectedCtrlEle.getAttribute('is-child-present');
                    if (isParentPresent == "false" || isChildPresent == "false") {
                        selectedCtrlEle.style.background = '#ffffff';
                    }
                }
                if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    let spnresizable: any = document.getElementById(ele);
                    if (spnresizable.getAttribute('data-selected1') != "true") {
                        spnresizable.style.background = "white";
                    }
                }

                this.showRequiredIcon(ele.replace('resizable_', ''));
            }
        })
    }

    removeControlActiveClass(type: any) {
        let controlActiveCtrls: any = document.querySelectorAll('.control-active');
        if (controlActiveCtrls && controlActiveCtrls.length > 0) {
            for (let index = 0; index < controlActiveCtrls.length; index++) {
                controlActiveCtrls[index].classList.remove('control-active');
                controlActiveCtrls[index].style.outline = 'none';
                controlActiveCtrls[index].style.outline = controlActiveCtrls[index].getAttribute('data-recp-color') + ' solid 2px';

                let attr_guid: any = controlActiveCtrls[index].getAttribute('data-guid');
                let attr_id: any = controlActiveCtrls[index].getAttribute('id');
                if (attr_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    if (controlActiveCtrls[index].getAttribute('data-selected1') == "true") {
                        controlActiveCtrls[index].classList.remove('control-active-bg-color');
                        let spnEle: any = document.getElementById(attr_id.replace('resizable_', 'spn'));
                        if (spnEle) {
                            let mainParentEle: any = document.getElementById(attr_id.replace('resizable_', ''));
                            if (mainParentEle) {
                                let isChildrenPresent: any = mainParentEle.getAttribute('is-child-present');
                                let isParentPresent: any = mainParentEle.getAttribute('is-parent-present');
                                let tempGroupCode: any = mainParentEle.getAttribute('temp-group-code');
                                if (isParentPresent == "true" || isChildrenPresent == "true") {
                                    controlActiveCtrls[index].style.background = 'white';
                                    let bcolor: any = spnEle.style["background-color"];
                                    if (bcolor == null || bcolor == '') {
                                        let groupCode: any = mainParentEle.getAttribute('group-code');
                                        if (groupCode == null || groupCode == '' || groupCode == "null" || groupCode == undefined) {
                                            if (tempGroupCode && tempGroupCode != "" && tempGroupCode != null && tempGroupCode != undefined) {
                                                groupCode = tempGroupCode;
                                            }
                                        }
                                        var colorForElement = this.commonConstants.DependencyColorCodes[parseInt(groupCode) % 10].Color;
                                        this.applyColorCodeForItem(attr_id.replace('resizable_', ''), colorForElement);
                                    }
                                }
                                else if (tempGroupCode && tempGroupCode != "" && tempGroupCode != null && tempGroupCode != undefined) {

                                }
                                else {
                                    let resizableEle: any = document.getElementById(attr_id);
                                    if (resizableEle) {
                                        if (resizableEle.getAttribute('data-selected1') == "true") {
                                            spnEle.style["background-color"] = "green";
                                        }
                                        else spnEle.style["background-color"] = "white";
                                    }
                                }
                            }
                        }
                    }
                    else controlActiveCtrls[index].classList.remove('control-active-bg-color');
                }
                else {
                    let mainParentEle: any = document.getElementById(attr_id.replace('resizable_', ''));
                    if (mainParentEle) {
                        let isChildrenPresent: any = mainParentEle.getAttribute('is-child-present');
                        let isParentPresent: any = mainParentEle.getAttribute('is-parent-present');
                        let groupCode: any = mainParentEle.getAttribute('group-code');
                        let tempGroupCode: any = mainParentEle.getAttribute('temp-group-code');
                        if (groupCode == null || groupCode == '' || groupCode == "null" || groupCode == undefined) {
                            if (tempGroupCode && tempGroupCode != "" && tempGroupCode != null && tempGroupCode != undefined) {
                                groupCode = tempGroupCode;
                            }
                        }
                        if (groupCode == null || groupCode == '' || groupCode == "null" || groupCode == undefined) {
                            controlActiveCtrls[index].classList.remove('control-active-bg-color');
                        }
                        else {
                            controlActiveCtrls[index].classList.remove('control-active-bg-color');
                            if (isParentPresent == "true" || isChildrenPresent == "true") {
                                if (type != "clicked") {
                                    var colorForElement = this.commonConstants.DependencyColorCodes[parseInt(groupCode) % 10].Color;
                                    this.applyColorCodeForItem(attr_id.replace('resizable_', ''), colorForElement);
                                }
                            }
                        }
                    }
                }

                this.showRequiredIcon(attr_id.replace('resizable_', ''));
            }
        }
    }

    setRadioCheckBoxGroupChecked(currentSelectedControl: any, textFormatting: any) {
        let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + currentSelectedControl);
        let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
        setTimeout(() => {
            if (resizableCurrentSelectedControl) {
                let textFormattingCheckboxGroup: any = textFormatting ? textFormatting.checkboxGroup : true ;
                let attr_data_groupname: any = resizableCurrentSelectedControl.getAttribute('data-groupname');
                let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
                let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));
                let attr_data_guid: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) {
                    if (inputGroupNameCtrl) {
                        inputGroupNameCtrl.value = 'Radio';
                        if (textFormatting != null && textFormatting.checkboxName != "") {
                            inputGroupNameCtrl.value = textFormatting.checkboxName;
                        }
                        spnCurrentSelectedControl.setAttribute('name', inputGroupNameCtrl.value);
                    }
                }
                else if (attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    let checkboxgroup: any = (<HTMLInputElement>document.getElementById("checkboxgroup"));
                    if (textFormattingCheckboxGroup == false) {
                        resizableCurrentSelectedControl.setAttribute('data-isGroupChecked', 'false');
                        if (checkboxgroup) checkboxgroup.checked = false;
                    }
                    else {
                        resizableCurrentSelectedControl.setAttribute('data-isGroupChecked', 'true');
                        if (checkboxgroup) checkboxgroup.checked = true;
                    }

                    if (inputGroupNameCtrl) {
                        inputGroupNameCtrl.value = 'Checkbox';
                        if (textFormatting != null && textFormatting.checkboxName != "") {
                            inputGroupNameCtrl.value = textFormatting.checkboxName;
                        }
                        spnCurrentSelectedControl.setAttribute('name', inputGroupNameCtrl.value);
                    }
                }

                if (attr_data_groupname != '' && attr_data_groupname != null && inputGroupCtrl) {
                    inputGroupCtrl.value = attr_data_groupname;
                }
                else if (inputGroupCtrl) {
                    if (textFormatting != null && textFormatting.checkboxGroupName != "") {
                        inputGroupCtrl.value = textFormatting.checkboxGroupName;
                    }
                    else {
                        if(attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()){
                            if(textFormattingCheckboxGroup == true) inputGroupCtrl.value = this.generateRandomString();
                            else inputGroupCtrl.value = '';
                        }
                        else inputGroupCtrl.value = this.generateRandomString();
                    }
                    resizableCurrentSelectedControl.setAttribute('data-groupname', inputGroupCtrl.value);
                }
                if (spnCurrentSelectedControl && inputGroupNameCtrl) {
                    let attr_name: any = spnCurrentSelectedControl.getAttribute('name');
                    if (attr_name != '' && attr_name != null) {
                        inputGroupNameCtrl.value = attr_name;
                    }
                }

                setTimeout(() => {
                    if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) {
                        this.removeSameRadioNamePreselected(currentSelectedControl);
                    }
                }, 10);
            }
        }, 50);
    }

    generateRandomString() {
        return "Group" + (Math.random() + 1).toString(36).substring(8) + "" + (Math.random() + 1).toString(36).substring(8);
    };

    expandAllProp() {
        let button: any = document.getElementById("collapseAll");
        let accordionHeaders: any = document.querySelectorAll(".custAccordion-head button");
        let accordionCollapses: any = document.querySelectorAll(".custAccord-collap");
        if (button && accordionHeaders && accordionCollapses) {
            button.innerHTML = "keyboard_double_arrow_down";
            button.setAttribute('title', 'Expand all');
            accordionHeaders.forEach((header: any) => {
                header.classList.remove("collapsed");
            });
            accordionCollapses.forEach((collapse: any) => {
                collapse.classList.add("show");
            });
        }
        else {
            setTimeout(() => {
                this.expandAllProp();
                console.log("Expanding from 2nd time");
            }, 20);
        }
    }

    getTextControlType(textType: any) {
        let returnTypeVal: any = 'Text';
        switch (textType) {
            case '26C0ACEA-3CC8-43D6-A255-A870A8524A77':
                returnTypeVal = 'Text';
                break;
            case 'B0443A47-89C3-4826-BECC-378D81738D03':
                returnTypeVal = 'Numeric';
                break;
            case '88A0B11E-5810-4ABF-A8B6-856C436E7C49':
                returnTypeVal = 'Alphabet';
                break;
            case 'DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D':
                returnTypeVal = 'SSN';
                break;
            case '5121246A-D9AB-49F4-8717-4EF4CAAB927B':
                returnTypeVal = 'Zip';
                break;
            case '8348E5CD-59EA-4A77-8436-298553D286BD':
                returnTypeVal = 'Date';
                break;
            case '1AD2D4EC-4593-435E-AFDD-F8A90426DE96':
                returnTypeVal = 'Email';
                break;
        }
        return returnTypeVal;
    }

    applyPrefillLabelTextProp(currentSelectedControl: any, type: any) {
        let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + currentSelectedControl);
        let checkboxHideText: any = document.getElementById("checkboxHideText");
        let checkbox_HideText: any = document.getElementById('checkbox-HideText');
        if (resizableCurrentSelectedControl) {
            let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
            if (type == 1) {
                resizableCurrentSelectedControl.setAttribute('data-prefill', 'false');
                resizableCurrentSelectedControl.setAttribute('data-displayLabel', 'true');

                let addTextTextarea: any = document.getElementById('addTextTextarea');
                if (addTextTextarea) {
                    if (addTextTextarea.value && addTextTextarea.value.trim().length > 400) {
                        addTextTextarea.value = addTextTextarea.value.substring(0, 400);
                    }
                    addTextTextarea.setAttribute('maxlength', '400');
                }

                this.isCheckboxDisabled = false;
                let isCheckBoxChecked: any = resizableCurrentSelectedControl.getAttribute('data-ishidetextwithasterisk');
                if (checkboxHideText && checkboxHideText !== undefined && checkboxHideText !== null && checkboxHideText !== 'null') {
                    checkboxHideText.style.setProperty('background', '');
                    checkboxHideText.checked = isCheckBoxChecked === "true" ? true : false;
                }
                let originalText: any = resizableCurrentSelectedControl.getAttribute('data-asteriskOriginalText');
                if (spnCurrentSelectedControl) {
                    spnCurrentSelectedControl.textContent = (checkboxHideText && checkboxHideText.checked) ? addTextTextarea.value : (originalText && originalText !== "null") ? originalText : '';
                }
            }
            else if (type == 2) {
                resizableCurrentSelectedControl.setAttribute('data-prefill', 'true');
                resizableCurrentSelectedControl.setAttribute('data-displayLabel', 'false');
                let addTextTextarea: any = document.getElementById('addTextTextarea');
                if (addTextTextarea) addTextTextarea.setAttribute('maxlength', '4000');
                this.applyTextPrefillData(currentSelectedControl);
                resizableCurrentSelectedControl.setAttribute('data-prefill', 'true');
                resizableCurrentSelectedControl.setAttribute('data-displayLabel', 'false');
                let isCheckBoxChecked: any = resizableCurrentSelectedControl.getAttribute('data-ishidetextwithasterisk');
                if (checkboxHideText && checkboxHideText !== undefined && checkboxHideText !== null && checkboxHideText !== 'null') {
                    checkboxHideText.style.setProperty('background', '');
                    checkboxHideText.checked = isCheckBoxChecked === "true" ? true : false;
                }
                checkbox_HideText?.style.setProperty('cursor', 'pointer');
                this.isCheckboxDisabled = false;
                checkboxHideText?.removeAttribute("disabled");
            }
        }
    }

    toggleAllAccordions() {
        let button: any = document.getElementById("collapseAll");
        let accordionHeaders: any = document.querySelectorAll(".custAccordion-head button");
        let accordionCollapses: any = document.querySelectorAll(".custAccord-collap");

        let isCollapsed = Array.from(accordionHeaders).some((header: any) => {
            return !header.classList.contains("collapsed");
        });

        if (isCollapsed) {
            button.innerHTML = "keyboard_double_arrow_up";
            button.setAttribute('title', 'Collapse all');
            accordionHeaders.forEach((header: any) => {
                header.classList.add("collapsed");
            });
            accordionCollapses.forEach((collapse: any) => {
                collapse.classList.remove("show");
            });
        }
        else {
            button.innerHTML = "keyboard_double_arrow_down";
            button.setAttribute('title', 'Expand all');
            accordionHeaders.forEach((header: any) => {
                header.classList.remove("collapsed");
            });
            accordionCollapses.forEach((collapse: any) => {
                collapse.classList.add("show");
            });
        }
    }

    accordaionExapand() {
        let acrdionbutton: any = document.getElementById("depencieclose");
        if (acrdionbutton && acrdionbutton.classList.contains("collapsed")) {
            let accordionCollapses: any = document.getElementById("collapseDependence");
            acrdionbutton.classList.remove("collapsed");
            accordionCollapses.classList.add("show");
        }
    }

    removeSameRadioNamePreselected(controlId: any) {
        let newSpnControlEle: any = document.getElementById("spn" + controlId);
        let newresizableControlEle: any = document.getElementById("resizable_" + controlId);
        if (newresizableControlEle) {
            let radioDependencieArray: any = document.querySelectorAll('[data-groupname ="' + newresizableControlEle.getAttribute('data-groupname') + '"]');
            if (radioDependencieArray && radioDependencieArray.length > 0) {
                Array.from(radioDependencieArray).forEach((radioItem: any, ind: any) => {
                    let id: any = radioItem.getAttribute('id').replace('resizable_', '')
                    radioItem = document.getElementById("spn" + id);
                    if (radioItem.getAttribute('id') != newSpnControlEle.getAttribute('id') && radioItem.classList.contains('radio-dependencie')) {
                        radioItem.style["background-color"] = '';
                        let mainParentEle: any = document.getElementById(radioItem.getAttribute('id').replace('spn', ''));
                        let resizableControlEle: any = document.getElementById('resizable_' + radioItem.getAttribute('id').replace('spn', ''));
                        if (mainParentEle) {
                            let isChildrenPresent: any = mainParentEle.getAttribute('is-child-present');
                            let isParentPresent: any = mainParentEle.getAttribute('is-parent-present');
                            let tempGroupCode: any = mainParentEle.getAttribute('temp-group-code');
                            if (isParentPresent == "true" || isChildrenPresent == "true") {
                                resizableControlEle.classList.remove('control-active-bg-color');
                                let spnEle: any = document.getElementById(radioItem.getAttribute('id'));
                                let bcolor: any = spnEle.style["background-color"];
                                //if (bcolor == null || bcolor == '') {
                                let groupCode: any = mainParentEle.getAttribute('group-code');
                                if (groupCode == null || groupCode == '' || groupCode == "null" || groupCode == undefined) {
                                    if (tempGroupCode && tempGroupCode != "" && tempGroupCode != null && tempGroupCode != undefined) {
                                        groupCode = tempGroupCode;
                                    }
                                }
                                var colorForElement = this.commonConstants.DependencyColorCodes[parseInt(groupCode) % 10].Color;
                                this.applyColorCodeForItem(radioItem.getAttribute('id').replace('spn', ''), colorForElement);
                                //}
                            }
                            else {
                                radioItem.style["background-color"] = 'white';
                            }
                        }
                        radioItem.classList.remove('checked-visible');
                        radioItem.removeAttribute("checked");
                        radioItem?.setAttribute('data-selected1', "false");

                        if (resizableControlEle) {
                            resizableControlEle.setAttribute('data-selected1', "false");
                            //radioItem.style["background-color"] = 'white';
                        }
                    }
                });
            }
        }
    }

    disableOrEnableClearAllIcon(ctrlsTotalCount: any) {
        let clearAllDiv: any = document.getElementById('clearAllDiv');
        let clearAllImage: any = document.getElementById('clearAllImage');
        if (ctrlsTotalCount === 0) {
            if (clearAllDiv) {
                clearAllDiv.classList.add('wrapper-cursor');
            }
            if (clearAllImage) {
                clearAllImage.classList.add('pointer-events-none');
            }
        } else {
            if (clearAllDiv) {
                clearAllDiv.classList.remove('wrapper-cursor');
            }
            if (clearAllImage) {
                clearAllImage.classList.remove('pointer-events-none');
            }
        }
    }

    //Show requird * when control is required control
    showRequiredIcon(id: any) {
        let resizableEle: any = document.getElementById('resizable_' + id);
        if (resizableEle) {
            let dataGuid: any = resizableEle.getAttribute('data-guid');
            if (dataGuid && (dataGuid.toLowerCase() !== Control.DateTimeStamp.toLowerCase() && dataGuid.toLowerCase() !== Control.Label.toLowerCase() && dataGuid.toLowerCase() != Control.Hyperlink.toLowerCase())) {
                //required code
                let requiredCurrentSelectedControl: any = document.getElementById('req' + id);
                if (resizableEle) {
                    let isRequired: any = resizableEle.getAttribute('data-selected');
                    let recipentColor: any = resizableEle.getAttribute('data-recp-color');
                    if (requiredCurrentSelectedControl && isRequired == "true") {
                        requiredCurrentSelectedControl.style.display = "block";
                        if (recipentColor) {
                            resizableEle.style.outline = 'none';
                            resizableEle.style.outline = recipentColor + ' solid 3px';
                        }
                    }
                    else if (requiredCurrentSelectedControl) {
                        requiredCurrentSelectedControl.style.display = "none";
                        if (recipentColor) {
                            resizableEle.style.outline = 'none';
                            resizableEle.style.outline = recipentColor + ' solid 2px';
                        }
                    }
                }
                //required code
            }
        }
    }

    //Prefill text property validations start here
    //check the given value is number or not
    isNumber(str: string): boolean {
        if (typeof str !== 'string') return false;
        if (str.trim() === '') return false;
        return !Number.isNaN(Number(str));
    }
    ////check the given email is valid email or not
    validateEmail(email: any) {
        var re = EmailAddressFormat.ValidEmail;
        return re.test(email);
    }
    ////check the given date is valid format or not
    isValidDateCustom(dateValue: any, dateFormat: any) {
        var dateFormat = dateFormat.toUpperCase();
        var isValid1 = moment(dateValue, dateFormat, true).isValid();
        var isValid2 = moment(dateValue, dateFormat.replace("YY", "YYYY"), true).isValid();
        return isValid1 || isValid2;
    }

    ////check for prefill text validations
    applyTextPrefillData(currentSelectedControl: any) { //will do in next sprint
        let type: any = 1;
        let radioPrefill: any = document.getElementById('radioPrefill');
        if (radioPrefill && radioPrefill.checked == true) {
            type = 2;
        }
        // this.applyTextPrefillValidations(currentSelectedControl, type);
    }

    //check prefill text validations and show toaster if they are invalid
    applyTextPrefillValidations(currentSelectedControl: any, type: any) {
        let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentSelectedControl) {
            let data_textType: any = resizableCurrentSelectedControl.getAttribute('data-texttype');
            let textType: any = this.getTextControlType(data_textType);
            //console.log(textType + " type: " + type);

            let addTextTextarea: any = document.getElementById('addTextTextarea');
            if (addTextTextarea && addTextTextarea.value && type == 2) {
                let additionalValidationName: any = resizableCurrentSelectedControl.getAttribute('data-additional-validation-name');
                let additionalValidationOption: any = resizableCurrentSelectedControl.getAttribute('data-additional-validation-option');

                let inputLength: any = '', myMask: any = '', ctrlType: any = '', txtName: any = '';
                let isFixedWidth: any = "false";
                let isNewSignersUIEnabled: any = this.getHideFixedwidthCheckbox();
                if (isNewSignersUIEnabled != "true") {
                    isFixedWidth = resizableCurrentSelectedControl.getAttribute('data-isfixedwidth');
                }

                if (isFixedWidth == "true") {
                    let textInputLength: any = document.getElementById('textInputLength');
                    if (textInputLength)
                        inputLength = textInputLength.value;
                }
                else {
                    let textCharInputLimit: any = document.getElementById('textCharInputLimit');
                    if (textCharInputLimit)
                        inputLength = textCharInputLimit.value;
                }

                if (textType == "Text") {

                }
                else if (textType == "Alphabet") {
                    let value: any = addTextTextarea.value.trim();
                    var regExp = /^[a-zA-Z ]+$/;
                    if (!regExp.test(value)) {
                        this.toastrService.warning('*Only Characters allowed.', 'Warning');
                        addTextTextarea.value = '';
                    }
                }
                else if (textType == "Numeric") { // Number
                    var isNumericValue: any = addTextTextarea.value;
                    if (additionalValidationName && additionalValidationName == "Decimal") {
                        txtName = '';
                        if (additionalValidationOption && additionalValidationOption == "1") { //Both
                            txtName = "Both";
                        }
                        else if (additionalValidationOption && additionalValidationOption == "2") { //Comma
                            txtName = "Comma"
                        }
                        else if (additionalValidationOption && additionalValidationOption == "3") { //Period
                            txtName = "Period";
                        }

                        var regExpComma = /^[0-9,]+$/;
                        var regExpPeriod = /^[0-9.]+$/;
                        var regExpPeriodComma = /^[0-9.,]+$/;
                        if (txtName == "Comma") {
                            if (!regExpComma.test(isNumericValue)) {
                                this.toastrService.warning('*Numeric 0 to 9 only and comma.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (isNumericValue.split(",").length - 1 > 1) {
                                this.toastrService.warning('*Only one comma allowed.', 'Warning');
                                addTextTextarea.value = '';
                            }
                        }
                        else if (txtName == "Period") {
                            if (!regExpPeriod.test(isNumericValue)) {
                                this.toastrService.warning('*Numeric 0 to 9 only and period.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (isNumericValue.split(".").length - 1 > 1) {
                                this.toastrService.warning('*Only one decimal allowed.', 'Warning');
                                addTextTextarea.value = '';
                            }

                            if (isNumericValue && isNumericValue.indexOf(".") > -1) {
                                var regEx = /^\d+(?:\.\d{1,2})?$/;
                                if (!regEx.test(isNumericValue)) {
                                    this.toastrService.warning('*Only two digits allowed after decimal.', 'Warning');
                                }
                            }
                        }
                        else if (txtName == "Both") {
                            var number = isNumericValue.split('.');
                            let dotlen: any = [...isNumericValue].filter(l => l === '.').length;
                            let commalen: any = [...isNumericValue].filter(l => l === ',').length;
                            if (dotlen > 1) {
                                this.toastrService.warning('*Only one decimal allowed.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (number[1] && number[1].length > 2) {
                                this.toastrService.warning('*Only two digits allowed after decimal.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (commalen > 1) {
                                this.toastrService.warning('*Only one comma allowed.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (!regExpPeriodComma.test(isNumericValue)) {
                                this.toastrService.warning('*Numeric 0 to 9 only with comma and decimal.', 'Warning');
                                addTextTextarea.value = '';
                            }
                        }
                        else if (!this.isNumber(isNumericValue)) {
                            this.toastrService.warning('*Only numbers allowed.', 'Warning');
                            addTextTextarea.value = '';
                        }
                    }
                    else {
                        if (isNumericValue && !this.isNumber(isNumericValue)) {
                            this.toastrService.warning('*Only numbers allowed.', 'Warning');
                            addTextTextarea.value = '';
                        }
                    }
                }
                else if (textType == "SSN" || textType == "Zip") {
                    if (textType == "SSN") {
                        myMask = '___-__-____'; //123-34-567 //XXX-XX-XXXXX
                        ctrlType = "SSN";
                    }
                    else if (textType == "Zip") {
                        if (additionalValidationOption && additionalValidationOption == "1") {
                            myMask = "_____"; //12345 //XXXXX
                            ctrlType = "Zip";
                        }
                        else if (additionalValidationOption && additionalValidationOption == "2") {
                            myMask = "_____-____"; //12345-56789 //XXXXX-XXXXX
                            ctrlType = "Zip+4";
                        }
                    }

                    let value: any = addTextTextarea.value;
                    if (value.indexOf('_') > 0 || value.indexOf('_') > -1 || value.indexOf('-') > 5) {
                        if (ctrlType === 'Zip' || ctrlType === "Zip+4") {
                            this.toastrService.warning('*Invalid Zip.', 'Warning');
                            addTextTextarea.value = '';
                        }
                        else if (ctrlType === 'SSN') {
                            this.toastrService.warning('*Invalid SSN.', 'Warning');
                            addTextTextarea.value = '';
                        }
                    }
                    else {
                        var regExpNumber = /^[0-9]+$/; //for zip
                        if (ctrlType !== 'Zip') regExpNumber = /^[0-9-]*$/; //for ssn and zip4
                        if (!regExpNumber.test(value)) {
                            if (ctrlType === 'Zip' || ctrlType === "Zip+4") {
                                this.toastrService.warning('*Invalid Zip.', 'Warning');
                                addTextTextarea.value = '';
                            }
                            else if (ctrlType === 'SSN') {
                                this.toastrService.warning('*Invalid SSN.', 'Warning');
                                addTextTextarea.value = '';
                            }
                        }
                    }
                }
                else if (textType == "Date") {
                    inputLength = 10;
                    let dateFormatToPass: any = "mm/dd/yyyy";
                    if (additionalValidationOption == "1") { // mm/dd/yyyy
                        dateFormatToPass = "mm/dd/yyyy";
                    }
                    else if (additionalValidationOption == "2") { // dd/mm/yyyy
                        dateFormatToPass = "dd/mm/yyyy";
                    }
                    else if (additionalValidationOption == "3") { // yyyy/mm/dd
                        dateFormatToPass = "yyyy/mm/dd";
                    }
                    else if (additionalValidationOption == "4") { //dd-mmm-yyyy
                        inputLength = 11;
                        dateFormatToPass = "dd-mmm-yyyy";
                    }

                    if (!this.isValidDateCustom(addTextTextarea.value, dateFormatToPass)) {
                        this.toastrService.warning('*Invalid date format.', 'Warning');
                        addTextTextarea.value = '';
                    }
                    // else if (addTextTextarea.value && addTextTextarea.value.length == inputLength) {
                    //     this.toastrService.warning('*Invalid date format.', 'Warning');
                    //     addTextTextarea.value = '';
                    // }
                }
                else if (textType == "Email") {
                    if (addTextTextarea.value != '' && !this.validateEmail(addTextTextarea.value)) {
                        this.toastrService.warning('*Invalid email.', 'Warning');
                        addTextTextarea.value = '';
                    }
                }
            }
        }
    }
    //Prefill text property validations end here

    setTextAttributes(newResizableControlEle: any, maxcharallowed: any, maxemailcharallowed: any, isfixedwidthAttr: any, texttypeAttr: any) {
        let isFixedWidthChecked: boolean = (isfixedwidthAttr === true || isfixedwidthAttr === 'true') ? true : false;
        let textType: any = this.getTextControlType(texttypeAttr);
        if (maxcharallowed == "" || maxcharallowed == null || maxcharallowed == "null" || maxcharallowed == "undefined") {
            if (textType == "Text" || textType == "Numeric" || textType == "Alphabet") {
                if (isFixedWidthChecked) { maxcharallowed = "20"; maxemailcharallowed = "20"; }
                else { maxcharallowed = "4000"; maxemailcharallowed = "4000"; }
            }
            else if (textType == "SSN" || textType == "Zip" || textType == "Date") {
                maxcharallowed = "10"; maxemailcharallowed = "10";
            }
            else if (textType == "Email") {
                if (isFixedWidthChecked) { maxcharallowed = "20"; maxemailcharallowed = "20"; }
                else { maxcharallowed = "100"; maxemailcharallowed = "100"; }
            }

            newResizableControlEle?.setAttribute('maxcharallowed', maxcharallowed);
            newResizableControlEle?.setAttribute('maxemailcharallowed', maxemailcharallowed);
        }
    }

    toggleHideTextWithAsterisks(isChecked: any, currentSelectedControl: any) {
        const textInput: any = document.getElementById('addTextTextarea');
        if (textInput == null || textInput == undefined) {
            let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
            this.asteriskOriginalText = ctrlEle.getAttribute('data-asteriskOriginalText');
        }
        else {
            this.asteriskOriginalText = textInput.value.trim();
        }
        if (isChecked) {
            const trimmedText = this.asteriskOriginalText.trim(); // Trim extra spaces
            const asterisks = '*'.repeat(trimmedText.length); // Replace text with asterisks
            this.hiddenText = asterisks; // Store asterisks as the original value
        } else {
            textInput.value = this.asteriskOriginalText; // Restore original text (not asterisks)
        }
    }

}
