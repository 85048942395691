import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { BehaviourRuleConfig, CommonConstants, Control, TextRuleCondtionsConfig } from 'src/app/constants/common-constants';
import { CommonService } from 'src/app/services/common.service';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
    selector: 'app-add-dependencies',
    templateUrl: './add-dependencies.component.html',
    styleUrls: ['./add-dependencies.component.scss']
})
export class AddDependenciesComponent {
    @Input() dependencyIconClickedObj: any;
    @Output() addDependencieschangesMade: EventEmitter<any> = new EventEmitter();
    commonConstants = CommonConstants;
    showLoader: boolean = false;
    dependencyColorCodes: any = []
    controllingFields: any = [];
    controlDependencyFieldsArray: any = [];
    dependencyFieldsArray: any = [];
    controllingFieldSelectList: any = [];
    controllingDependentFieldSelectList: any = [];
    controllingdependentConditionsSelectList: any = [];
    controllingDependentActionSelectList: any = [];
    eligibleDependentControlsArray: any = [];
    tempDependencyControls: any = [];
    isDefaultSelected: boolean = false;
    constStaticControllingName = 'Page' + ' #PageNo: #ControlType, #ControlName';
    appendOtherDependentRowsList: any = [];
    controlColorCode: any = 0;
    controlColorCodeIndex: any = 0;
    selectedParentControlTypeID: any = '';
    selectedParentControlID: any = '';
    selectedParentControlFieldRequired: boolean = false;
    addedAsDependentFields: any = [];
    isViewAll: boolean = false;
    isSaveDependentFieldsClicked: boolean = false;
    addClass: boolean = false;
    addTopClass: boolean = false;
    addcontrolClass: boolean = false;
    allDependencySelectedControls: any = [];
    showDependenciesPopUpModal: boolean = false;
    addDependenctCtrlClickedObj: any;
    addNewDependenctCtrlClickedObj: any;
    groupCodeArrayUpdated: any = [];

    private ngUnsubscribe = new Subject();
    @Output() dependecieClose = new EventEmitter<boolean>();

    constructor(
        public modalRef: BsModalRef,
        private toastrService: ToastrService,
        private commonService: CommonService,
        private controlPropertyService: ControlPropertyService,
        private sessionStorageService: SessionStorageService
    ) {
        this.commonService.callDependencyPopupFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((updatedObject) => {
                this.dependencyIconClickedObj = updatedObject;
                this.ngOnInit();
            });

        this.commonService.callAddDependentFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((updatedObject) => {
                this.addNewDependenctCtrlClickedObj = updatedObject;
                this.addSelectedControlRowToExistingRows(this.addNewDependenctCtrlClickedObj);
            });
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.isViewAll = false;
        this.allDependencySelectedControls = [];
        this.appendOtherDependentRowsList = [];
        this.addedAsDependentFields = [];
        let divAddDependency: any = document.getElementsByClassName("divAddDependency");
        if (divAddDependency && divAddDependency.length > 0) {
            Array.from(divAddDependency).forEach((divAddDependencyItem: any, index: any) => {
                divAddDependencyItem.style.display = 'block';
            })
        }

        let divViewAll: any = document.getElementById("divViewAll");
        if (divViewAll) divViewAll.style.display = 'none';

        this.showDependenciesPopUpModal = false;
        this.dependencyColorCodes = this.commonConstants.DependencyColorCodes;

        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        //FIND MAX COLOR CODE
        this.updateColorCode(currentSelectedControl);

        if (this.dependencyIconClickedObj && this.dependencyIconClickedObj.type == "1") {
            currentSelectedControl = '';
        }
        let childelements: any = document.getElementById(currentSelectedControl);
        if (currentSelectedControl != '' && childelements) {
            let docContentId: any = childelements.getAttribute('data-document-content-id');
            let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
            let parentChildelementsArray: any = [];
            for (let index = 0; index < childelementsArray.length; index++) {
                let ele: any = childelementsArray[index].id;
                let resizable_ele: any = document.getElementById('resizable_' + ele);
                if (resizable_ele && resizable_ele.style["display"] != 'none') {
                    parentChildelementsArray.push(resizable_ele);
                }
            }
            if (parentChildelementsArray && parentChildelementsArray.length > 0) {
                for (let index = 0; index < parentChildelementsArray.length; index++) {
                    this.appendOtherDependentRowsList.push({ rIndex: index, });
                }
            }
            this.allDependencySelectedControls.push(docContentId);
        }
        this.addAdditionalDependentRow(0);

        setTimeout(() => {
            this.getControllingFieldsFromDocuments();
            this.showHideFirstRowDependentDropdowns();
            this.showLoader = false;
        }, 50); //100

        //This is to be do once Dependency icon added to the control
        if (this.dependencyIconClickedObj && this.dependencyIconClickedObj.type == "1") {
            this.showHideAllOtherDependencyDropdowns();
        }
        this.showExistingContent();
    }

    getControllingFieldsFromDocuments() {
        this.showLoader = true;
        let constControllingName = this.constStaticControllingName;
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        if (this.dependencyIconClickedObj && this.dependencyIconClickedObj.type == "1") {
            currentSelectedControl = '';
        }

        let rcpId: any = '';
        if (currentSelectedControl != '') {
            let ctrl: any = document.getElementById('resizable_' + currentSelectedControl);
            if (ctrl) rcpId = ctrl.getAttribute('data-rcptid');
        }

        this.bindControllingFieldSelectList(currentSelectedControl, constControllingName);
        this.bindDependentFieldSelectList(currentSelectedControl, constControllingName, rcpId);
        if (this.dependencyIconClickedObj && this.dependencyIconClickedObj.type == "") { //todo  uncomment this one once icon added
            // if (this.dependencyIconClickedObj.isControlingField == true) {
            let curSelectedControl: any = document.getElementById(currentSelectedControl);
            if (currentSelectedControl != '' && curSelectedControl) {
                let grpColorCode: any = curSelectedControl.getAttribute('group-code');
                let parentItemField: any = document.getElementById("spn" + currentSelectedControl);
                let parentColor: any = parentItemField.style['background-color'];
                let colorCode: any = '';
                if (parentColor != '' && parentColor != null) {
                    colorCode = (grpColorCode != null && grpColorCode != '' && grpColorCode != undefined) ? parentColor : this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color;
                }
                else {
                    colorCode = (grpColorCode != null && grpColorCode != '' && grpColorCode != undefined) ? this.commonConstants.DependencyColorCodes[parseInt(grpColorCode) % 10].Color : this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color;
                }
                this.controlColorCode = colorCode;
                this.controlColorCodeIndex = (grpColorCode != null && grpColorCode != '' && grpColorCode != undefined) ? grpColorCode : this.controlColorCodeIndex;
                if (grpColorCode != null && grpColorCode != '' && grpColorCode != undefined) {
                    this.controlPropertyService.applyColorCodeForParent(currentSelectedControl, colorCode, "false");
                }
            }
            // }
        }

        setTimeout(() => {
            this.bindExistingSelectedControlFieldDependeyData();
            this.showLoader = false;
        }, 10);
    }

    showHideAllOtherDependencyDropdowns() {
        this.controllingDependentFieldSelectList = [];
        this.controllingdependentConditionsSelectList = [];
        this.controllingDependentActionSelectList = [];
        this.appendOtherDependentRowsList = [];
    }

    bindControllingFieldSelectList(currentSelectedControl: any, constControllingName: any) {
        this.showLoader = true;
        // let ControlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        let ControlDependencyFieldsString: any = this.sessionStorageService.getControlDependencyFieldsArray();
        if (ControlDependencyFieldsString && ControlDependencyFieldsString != null && ControlDependencyFieldsString != undefined) {
            this.controlDependencyFieldsArray = JSON.parse(ControlDependencyFieldsString);
            this.controlDependencyFieldsArray = this.controlDependencyFieldsArray.sort((a: any, b: any) => a.PageNo > b.PageNo ? 1 : -1);
            this.controllingFieldSelectList = [];

            if (this.controlDependencyFieldsArray && this.controlDependencyFieldsArray.length > 0) {
                for (let index = 0; index < this.controlDependencyFieldsArray.length; index++) {
                    const element = this.controlDependencyFieldsArray[index];
                    let isPushToArrReq: boolean = true;
                    if (element.DataGuid.toLowerCase() == Control.Checkbox.toLocaleLowerCase()) {
                        let chekDataSelected = (<HTMLInputElement>document.getElementById("resizable_" + element.ControlId));
                        if (chekDataSelected) {
                            let isDataSelected: any = chekDataSelected.getAttribute('data-selected');
                            if (isDataSelected == true || isDataSelected == "true") isPushToArrReq = false;
                        }
                    }

                    if (isPushToArrReq) {
                        let textControlText = "";
                        if (element.DataGuid.toLowerCase() == Control.Text.toLocaleLowerCase()) {
                            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + element.ControlId));
                            if (spnCurrentSelectedControl) {
                                textControlText = (<HTMLInputElement>document.getElementById("spn" + element.ControlId)).innerHTML;
                            }
                        }

                        this.controllingFieldSelectList.push({
                            conditionID: element.ConditionID,
                            value: element.DataDocumentContentId,
                            title: element.ControlId,
                            dataGuid: element.DataGuid,
                            text: constControllingName.replace('#PageNo', element.PageNo)
                                .replace('#ControlType', this.commonService.getHoverControlText(element.ControlName))
                                .replace('#ControlName', (element.DataGuid.toLowerCase() == Control.Text.toLocaleLowerCase() ? textControlText : element.CtrlNumber)),
                            isSelected: element.ControlId == currentSelectedControl ? true : false
                        });
                    }
                }
                this.appendConditionsAndActionsLists(currentSelectedControl);
            }

            if (this.controllingFieldSelectList && this.controllingFieldSelectList.length > 0) {
                this.isDefaultSelected = true;
            }
        }
        this.showLoader = false;
    }

    bindDependentFieldSelectList(currentSelectedControl: any, constControllingName: any, rcpId: any) {
        this.showLoader = true;
        this.getExistingDependencyData(currentSelectedControl);
        // let allImageControlsArray: any = localStorage.getItem('AllImageControls');
        let allImageControlsArray: any = this.sessionStorageService.getAllImageControls();
        if (allImageControlsArray != null) {
            this.dependencyFieldsArray = JSON.parse(allImageControlsArray);
            this.dependencyFieldsArray = this.dependencyFieldsArray.sort((a: any, b: any) => a.PageNo > b.PageNo ? 1 : -1);
            this.controllingDependentFieldSelectList = [];

            if (this.dependencyFieldsArray && this.dependencyFieldsArray.length > 0) {
                for (let index = 0; index < this.dependencyFieldsArray.length; index++) {
                    const element = this.dependencyFieldsArray[index];

                    if (rcpId != '' && element.RecipientId.toLowerCase() == rcpId.toLowerCase()) {
                        if (element.ControlId != currentSelectedControl && (element.DataGuid.toLowerCase() != Control.Label.toLocaleLowerCase() && element.DataGuid.toLowerCase() != Control.Hyperlink.toLocaleLowerCase() && element.DataGuid.toLowerCase() != Control.DateTimeStamp.toLocaleLowerCase())) {
                            let textControlText = "";
                            if (element.DataGuid.toLowerCase() == Control.Text.toLocaleLowerCase()) {
                                let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + element.ControlId));
                                if (spnCurrentSelectedControl) {
                                    textControlText = (<HTMLInputElement>document.getElementById("spn" + element.ControlId)).innerHTML;
                                }
                            }
                            this.controllingDependentFieldSelectList.push({
                                conditionID: element.ConditionID,
                                value: element.DataDocumentContentId,
                                title: element.ControlId,
                                dataGuid: element.DataGuid,
                                text: constControllingName.replace('#PageNo', element.PageNo)
                                    .replace('#ControlType', this.commonService.getHoverControlText(element.ControlName))
                                    .replace('#ControlName', (element.DataGuid.toLowerCase() == Control.Text.toLocaleLowerCase() ? textControlText : element.CtrlNumber)),
                                isSelected: false
                            });
                        }
                    }
                }
            }
        }
        this.showLoader = false;
    }

    getExistingDependencyData(currentSelectedControl: any) {
        let childelements: any = document.getElementById(currentSelectedControl);
        if (currentSelectedControl != '' && childelements) {
            let docContentId: any = childelements.getAttribute('data-document-content-id');
            let attr_id: any = childelements.getAttribute('id');
            let resizableCtrl: any = document.getElementById('resizable_' + attr_id);
            let attr_dataGuid: any = '';
            if (resizableCtrl)
                attr_dataGuid = resizableCtrl.getAttribute('data-guid');

            this.eligibleDependentControlsArray = [];
            let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
            Array.from(childelementsArray).forEach((childelementItem: any, v: any) => {
                let childattr_docContentId: any = childelementItem.getAttribute('data-document-content-id');
                let child_controlID: any = childattr_docContentId;
                let child_condtionID = childelementItem.getAttribute('data-parent-rule-id');
                let child_supportText = (childelementItem.getAttribute('data-parent-support-text-content') == null || childelementItem.getAttribute('data-parent-support-text-content') == undefined) ? '' : childelementItem.getAttribute('data-parent-support-text-content');
                let child_attr_id: any = childelementItem.getAttribute('id');
                let child_resizableCtrl: any = document.getElementById('resizable_' + child_attr_id);

                if (child_resizableCtrl && child_resizableCtrl.style['display'] != 'none') {
                    let child_attr_dataGuid: any = ''; let child_dataSelectedVal: any = '';
                    if (child_resizableCtrl) {
                        child_attr_dataGuid = child_resizableCtrl.getAttribute('data-guid');
                        let child_dataSelected = child_resizableCtrl.getAttribute('data-selected');
                        if (child_dataSelected && child_dataSelected != null) child_dataSelectedVal = child_dataSelected.toLowerCase()
                        else child_dataSelectedVal = '';
                    }

                    let child_dataPageNumber: any = childelementItem.getAttribute('data-pageno');
                    let child_dataControlName: any = childelementItem.getAttribute('data-controlname');
                    let child_dataControlNum: any = childelementItem.getAttribute('data-ctrlnum');

                    let isRequired = (child_attr_dataGuid == Control.DropDown.toLowerCase() || child_attr_dataGuid == Control.Radio.toLowerCase()) ? BehaviourRuleConfig.VisibleNotRequired :
                        (child_dataSelectedVal == 'true') ? BehaviourRuleConfig.VisibleRequired : BehaviourRuleConfig.VisibleNotRequired;

                    let child_textControlText = "";
                    if (child_attr_dataGuid.toLowerCase() == Control.Text.toLocaleLowerCase()) {
                        let child_spnControl = (<HTMLInputElement>document.getElementById("spn" + child_attr_id));
                        if (child_spnControl) {
                            child_textControlText = (<HTMLInputElement>document.getElementById("spn" + child_attr_id)).innerHTML;
                        }
                    }

                    var childDisplayName = this.getDisplayName(child_attr_dataGuid, child_dataPageNumber, child_dataControlName, child_dataControlNum, child_textControlText);
                    var condition = this.getCondtion(attr_dataGuid, child_condtionID, docContentId)
                    this.eligibleDependentControlsArray.push({
                        'ContentId': childattr_docContentId,
                        'ControlId': child_controlID,
                        'Id': child_attr_id,
                        'ConditionId': child_condtionID,
                        'DataGUID': child_attr_dataGuid,
                        'SuportText': child_supportText,
                        'PageNumber': child_dataPageNumber,
                        'ControlName': child_dataControlName,
                        'ControlNum': child_dataControlNum,
                        'IsRequired': isRequired,
                        'DisplayName': childDisplayName,
                        'Condition': condition,
                        'ParentDataGUID': attr_dataGuid,
                        'ParentControlContentID': docContentId,
                        'ParentID': attr_id
                    });
                }
            });
        }
    }

    bindExistingSelectedControlFieldDependeyData() {
        let isControlingField: any = false;
        if (this.dependencyIconClickedObj.currentControlId == "" || this.dependencyIconClickedObj.currentControlId == null || this.dependencyIconClickedObj.currentControlId == undefined) {
            isControlingField = false;
        }
        let parentControlField: any = document.getElementById("slControllingFieldSelectList");
        if (parentControlField) {
            let selectedParentItem = parentControlField.value;
            if (selectedParentItem != "0" && selectedParentItem != "" && selectedParentItem != null && selectedParentItem != undefined) {
                isControlingField = true;
            }

            if (isControlingField == true) {
                let dependentFieldArray: any = document.querySelectorAll('.dependentFieldSelectList');
                if (dependentFieldArray && dependentFieldArray.length > 0) {
                    let checkBoxDependentCondiitons = this.commonConstants.CheckBoxRuleConfig;
                    let textBoxDependentCondiitons = this.commonConstants.TextRuleConfig;
                    for (let index = 0; index < dependentFieldArray.length; index++) {
                        let resultObj: any = this.getRequiredConditionForDependencyControl(index);
                        if (resultObj && resultObj != null) {
                            this.addedAsDependentFields.push({ parentItem: resultObj.ParentControlContentID, title: resultObj.Id, value: resultObj.ContentId, rowIndex: index });

                            //dependentField
                            let currentDependentSelectCtrl: any = (document.getElementById("slDependentFieldSelectList_" + index)) as HTMLSelectElement;
                            if (currentDependentSelectCtrl && currentDependentSelectCtrl.options.length > 0) {
                                for (let index = 0; index < currentDependentSelectCtrl.options.length; index++) {
                                    const element = currentDependentSelectCtrl.options[index];
                                    if (element.value.toLowerCase() == resultObj.ContentId.toLowerCase()) {
                                        currentDependentSelectCtrl.options[index]?.setAttribute('Selected', 'selected');
                                        currentDependentSelectCtrl.selectedIndex = index;
                                        currentDependentSelectCtrl.options[index]?.setAttribute('conditionid', resultObj.ConditionId);
                                    }
                                }
                            }

                            //Condition
                            let currentConditionSelectCtrl: any = (document.getElementById("slDependentConditionsSelectList_" + index)) as HTMLSelectElement;
                            if (currentConditionSelectCtrl) {
                                if (resultObj.ParentDataGUID.toLowerCase() == Control.Radio.toLowerCase()) {
                                    currentConditionSelectCtrl.options[0]?.setAttribute('Selected', 'selected');
                                    currentConditionSelectCtrl.selectedIndex = 0;
                                    currentDependentSelectCtrl.options[index]?.setAttribute('conditionid', resultObj.ConditionId);
                                }
                                else if (resultObj.ParentDataGUID.toLowerCase() == Control.Checkbox.toLowerCase()) {
                                    for (let index = 0; index < checkBoxDependentCondiitons.length; index++) {
                                        if (resultObj.ConditionId.toLowerCase() == checkBoxDependentCondiitons[index].ID.toLowerCase()) {
                                            currentConditionSelectCtrl.options[index]?.setAttribute('Selected', 'selected');
                                            currentConditionSelectCtrl.selectedIndex = index;
                                            currentConditionSelectCtrl.options[index]?.setAttribute('conditionid', resultObj.ConditionId.toLowerCase());
                                        }
                                    }
                                }
                                else if (resultObj.ParentDataGUID.toLowerCase() == Control.Text.toLowerCase()) {
                                    let textField: any = document.getElementById("txtTextField_" + index);
                                    for (let index = 0; index < textBoxDependentCondiitons.length; index++) {
                                        if (resultObj.ConditionId.toLowerCase() == textBoxDependentCondiitons[index].ID.toLowerCase()) {
                                            currentConditionSelectCtrl.options[index]?.setAttribute('Selected', 'selected');
                                            currentConditionSelectCtrl.selectedIndex = index;
                                            currentConditionSelectCtrl.options[index]?.setAttribute('conditionid', resultObj.ConditionId.toLowerCase());
                                            if (index == 1 || index == 2) {
                                                if (textField) {
                                                    textField.value = resultObj.SuportText; textField.removeAttribute('disabled');
                                                }
                                            }
                                            else if (index == 3) {
                                                if (textField) { textField.value = textBoxDependentCondiitons[index].RuleText; }
                                            }
                                        }
                                    }
                                }
                                else if (resultObj.ParentDataGUID.toLowerCase() == Control.DropDown.toLowerCase()) {
                                    if (currentConditionSelectCtrl && currentConditionSelectCtrl.options.length > 0) {
                                        for (let index = 0; index < currentConditionSelectCtrl.options.length; index++) {
                                            const element = currentConditionSelectCtrl.options[index];
                                            if (element.value.toLowerCase() == resultObj.ConditionId.toLowerCase()) {
                                                currentConditionSelectCtrl.options[index]?.setAttribute('Selected', 'selected');
                                                currentConditionSelectCtrl.selectedIndex = index;
                                                currentConditionSelectCtrl.options[index]?.setAttribute('conditionid', resultObj.ConditionId.toLowerCase());
                                            }
                                        }
                                    }
                                }
                            }

                            //Behaviour
                            let currentBehaviourSelectCtrl: any = (document.getElementById("slDependentActionSelectList_" + index)) as HTMLSelectElement;
                            if (currentBehaviourSelectCtrl) {
                                if (resultObj.IsRequired.toLowerCase() == BehaviourRuleConfig.VisibleRequired.toLowerCase()) {
                                    currentBehaviourSelectCtrl.options[0]?.setAttribute('Selected', 'selected');
                                    currentBehaviourSelectCtrl.selectedIndex = 0;
                                }
                                else if (resultObj.IsRequired.toLowerCase() == BehaviourRuleConfig.VisibleNotRequired.toLowerCase()) {
                                    currentBehaviourSelectCtrl.options[1]?.setAttribute('Selected', 'selected');
                                    currentBehaviourSelectCtrl.selectedIndex = 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    getRequiredConditionForDependencyControl(index: any) {
        let result: any;
        if (this.eligibleDependentControlsArray && this.eligibleDependentControlsArray.length > 0) {
            let resultObj: any = this.eligibleDependentControlsArray[index];
            if (resultObj) {
                result = resultObj;
            }
        }
        return result;
    }

    appendConditionsAndActionsLists(title: any) {
        let isErrorToProceed: boolean = false;
        let controlTypeID = "";
        let controlID = "";
        let contentId = "";
        let slControlingField: any = this.controlDependencyFieldsArray.filter(function (obj: any) { return obj.ControlId.toLowerCase() == title.toLowerCase() });
        if (slControlingField && slControlingField.length > 0) {
            controlTypeID = slControlingField[0].DataGuid;
            controlID = slControlingField[0].ControlId;
            contentId = slControlingField[0].DataDocumentContentId;
        }

        this.controllingdependentConditionsSelectList = [];
        this.controllingDependentActionSelectList = [];

        if (controlTypeID.toLowerCase() == Control.DropDown.toLowerCase()) { //DROPDOWN
            let currentDrpCtrl: any = (document.getElementById("spn" + controlID)) as HTMLSelectElement;
            if (currentDrpCtrl && currentDrpCtrl.options && currentDrpCtrl.options.length > 0) {
                for (let index = 0; index < currentDrpCtrl.options.length; index++) {
                    const element = currentDrpCtrl.options[index];
                    let dataSelectOptionAttrId: any = currentDrpCtrl.options[index]?.getAttribute('data-select-option-id');
                    let isSelected = false;
                    if (index == 0) {
                        isSelected = true;
                    }
                    this.controllingdependentConditionsSelectList.push({ conditionID: '', value: dataSelectOptionAttrId, title: 'Selected', text: element.text, isSelected: isSelected, isDisabled: false });
                }
            }
            else {
                isErrorToProceed = true;
                this.toastrService.warning("Please add options for the selected dropdown control.", 'Warning'); this.showLoader = false;
            }
            if (!isErrorToProceed) this.enableDisableConditionsList(controlTypeID, false);
        }
        else if (controlTypeID.toLowerCase() == Control.Radio.toLowerCase()) { //RADIO
            this.controllingdependentConditionsSelectList.push({ conditionID: '', value: contentId, title: 'Selected', text: 'Selected', isSelected: true, isDisabled: false });
            this.enableDisableConditionsList(controlTypeID, false);
        }
        else if (controlTypeID.toLowerCase() == Control.Checkbox.toLowerCase()) {//CHECKBOX
            let checkBoxDependentCondiitons = this.commonConstants.CheckBoxRuleConfig;
            for (let index = 0; index < checkBoxDependentCondiitons.length; index++) {
                let element = checkBoxDependentCondiitons[index];
                let isSelected = false;
                if (element.RuleText == 'Checked') { isSelected = true; }
                this.controllingdependentConditionsSelectList.push({ conditionID: '', value: element.ID, title: element.RuleText, text: element.RuleText, isSelected: isSelected, isDisabled: false });
            }
            this.enableDisableConditionsList(controlTypeID, false);
        }
        else if (controlTypeID.toLowerCase() == Control.Text.toLowerCase()) { //Text
            let textBoxDependentCondiitons = this.commonConstants.TextRuleConfig;
            let spnCurrentTextControl = (<HTMLInputElement>document.getElementById("resizable_" + controlID));
            let isRequiredCtrl = false;
            if (spnCurrentTextControl) {
                let isDataSelected: any = spnCurrentTextControl.getAttribute('data-selected');
                if (isDataSelected == true || isDataSelected == "true") { isRequiredCtrl = true; }

                for (let index = 0; index < textBoxDependentCondiitons.length; index++) {
                    let element = textBoxDependentCondiitons[index];
                    let isSelected = false; let isDisabled = false;
                    if (element.RuleText == 'Any Text') { isSelected = true; isDisabled = isRequiredCtrl ? true : false }
                    else if (element.RuleText == 'Specific Text' && isRequiredCtrl) { isSelected = true; }
                    else if (element.RuleText == 'Empty') { isDisabled = isRequiredCtrl ? true : false }
                    this.controllingdependentConditionsSelectList.push({ conditionID: '', value: element.ID, title: element.RuleText, text: element.RuleText, isSelected: isSelected, isDisabled: isDisabled });
                }

                this.enableDisableConditionsList(controlTypeID, isRequiredCtrl);
            }
        }

        if (!isErrorToProceed) {
            let checkBoxDependentAction = [BehaviourRuleConfig.VisibleRequired, BehaviourRuleConfig.VisibleNotRequired];
            for (let index = 0; index < checkBoxDependentAction.length; index++) {
                let element = checkBoxDependentAction[index];
                let isSelected = false;
                if (element == BehaviourRuleConfig.VisibleRequired) { isSelected = true; }
                this.controllingDependentActionSelectList.push({ conditionID: '', value: element, title: element, text: element, isSelected: isSelected });
            }
        }
    }

    enableDisableConditionsList(controlTypeID: any, isRequiredCtrl: boolean) {
        for (let index = 0; index < this.appendOtherDependentRowsList.length; index++) {
            let currentDependentConditionsSelect: any = (document.getElementById("slDependentConditionsSelectList_" + index)) as HTMLSelectElement;
            if (controlTypeID.toLowerCase() == Control.DropDown.toLowerCase() || controlTypeID.toLowerCase() == Control.Checkbox.toLowerCase() || controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
                if (currentDependentConditionsSelect) currentDependentConditionsSelect.removeAttribute('disabled');
                if (controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
                    if (isRequiredCtrl) this.showTextField('Specific Text', index, TextRuleCondtionsConfig.SpecificText);
                    else this.showTextField('Any Text', index, TextRuleCondtionsConfig.AnyText);
                }
                else
                    this.hideTextField(controlTypeID, index);
            }
            else if (controlTypeID.toLowerCase() == Control.Radio.toLowerCase()) {
                if (currentDependentConditionsSelect) currentDependentConditionsSelect.setAttribute('disabled', 'disabled');
                this.hideTextField(controlTypeID, index);
            }
        }
    }

    addTextField(event: any, rowindex: any) {
        let currentSelectOption: any = (document.getElementById("slDependentConditionsSelectList_" + rowindex)) as HTMLSelectElement;
        let sel = currentSelectOption.selectedIndex;
        let opt = currentSelectOption.options[sel];
        let value = (<HTMLSelectElement>opt).value; //DataDocumentContentId
        let title = (<HTMLSelectElement>opt).title;

        let currentControllingFieldSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentControllingFieldSelectCtrl) {
            let csel = currentControllingFieldSelectCtrl.selectedIndex;
            let copt = currentControllingFieldSelectCtrl.options[csel];
            let controlFielddataDocumentContentId = (<HTMLSelectElement>copt).value; //DataDocumentContentId

            let slControlingField: any = this.controllingFieldSelectList.filter(function (obj: any) { return obj.value.toLowerCase() == controlFielddataDocumentContentId.toLowerCase() });
            if (slControlingField && slControlingField.length > 0) {
                if (slControlingField[0].dataGuid.toLowerCase() != Control.Text.toLowerCase())
                    this.hideTextField(title, rowindex);
                else this.showTextField(title, rowindex, value);
            }
        }
    }

    showTextField(title: any, rowindex: any, configId: any) {
        let tdAddTextField: any = document.getElementById("tdAddTextField_" + rowindex);
        if (tdAddTextField) tdAddTextField.style.display = 'block';
        let txtTextField: any = document.getElementById("txtTextField_" + rowindex);
        if (txtTextField) txtTextField.setAttribute('placeholder', title);
        let thAddTextField: any = document.getElementById("thAddTextField");
        if (thAddTextField) thAddTextField.style.display = 'block';
        if (configId == TextRuleCondtionsConfig.Empty || configId == TextRuleCondtionsConfig.AnyText) {
            if (txtTextField) {
                txtTextField.setAttribute('disabled', 'disabled');
                txtTextField.value = '';
            }
        }
        else {
            if (txtTextField) txtTextField.removeAttribute('disabled');
        }
    }

    hideTextField(controlTypeID: any, rowindex: any) {
        let tdAddTextField: any = document.getElementById("tdAddTextField_" + rowindex);
        if (tdAddTextField) tdAddTextField.style.display = 'none';
        let txtTextField: any = document.getElementById("txtTextField_" + rowindex);
        if (txtTextField) {
            txtTextField.setAttribute('placeholder', '');
            txtTextField.val = '';
        }
        let thAddTextField: any = document.getElementById("thAddTextField");
        if (thAddTextField) thAddTextField.style.display = 'none';
    }

    findFirstItemFromListByPropAndValue(inArr: any, prop: any, value: any) {
        if (inArr != null && inArr != undefined) {
            for (let i = 0; i < inArr.length; i++) {
                if (inArr[i][prop] == value) {
                    return inArr[i];
                }
            }
        }
        return undefined;
    }

    getConditionId(contentId: any, arryList: any) {
        let conditionID = "";
        let slField: any = arryList.filter(function (obj: any) { return obj.value.toLowerCase() == contentId.toLowerCase() });
        if (slField && slField.length > 0 && slField[0].conditionID != '00000000-0000-0000-0000-000000000000') conditionID = slField[0].conditionID;
        else conditionID = this.commonService.generateUUID();
        return (conditionID != '' && conditionID != null) ? conditionID.toLocaleLowerCase() : conditionID;
    }

    updateAllDependentDropdowns() {
        let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentSelectCtrl) {
            let sel = currentSelectCtrl.selectedIndex; //event.target.selectedIndex
            let opt = currentSelectCtrl.options[sel];
            let value = (<HTMLSelectElement>opt).value; //DataDocumentContentId
            let title = (<HTMLSelectElement>opt).title;
            // if (title) {
            //     let controlName = title.replace(/\d+/g, '');
            //     if (controlName == 'dropDownControl' || controlName == 'checkboxControl' || controlName == 'radioControl') {
            //         this.addClass = true;
            //         this.addcontrolClass = false;
            //         this.addTopClass = false;
            //     }
            //     else if (controlName == 'textControl') {
            //         this.addcontrolClass = true;
            //         this.addClass = false;
            //         this.addTopClass = true;
            //     }
            //     else {
            //         this.addClass = false;
            //         this.addcontrolClass = false;
            //         this.addTopClass = false;
            //     }
            // }
            if (title && title !== '') {
                let controlTypeID = "";
                let controlID = "";
                let slControlingField: any = this.controlDependencyFieldsArray.filter(function (obj: any) { return obj.ControlId.toLowerCase() == title.toLowerCase() });
                if (slControlingField && slControlingField.length > 0) {
                    controlTypeID = slControlingField[0].DataGuid;
                    controlID = slControlingField[0].ControlId;
                }
                let spnCurrentTextControl = (<HTMLInputElement>document.getElementById("resizable_" + controlID));
                let isRequiredCtrl = false;
                if (spnCurrentTextControl) {
                    let isDataSelected: any = spnCurrentTextControl.getAttribute('data-selected');
                    if (isDataSelected == true || isDataSelected == "true") { isRequiredCtrl = true; }
                }

                this.selectedParentControlTypeID = controlTypeID;
                this.selectedParentControlID = controlID;
                this.selectedParentControlFieldRequired = isRequiredCtrl;

                let rcpId: any = spnCurrentTextControl.getAttribute('data-rcptid');
                this.bindDependentFieldSelectList(title, this.constStaticControllingName, rcpId);
                if (this.appendOtherDependentRowsList.length == 0) {
                    let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + value + '"]');
                    let parentChildelementsArray: any = [];
                    for (let index = 0; index < childelementsArray.length; index++) {
                        let ele: any = childelementsArray[index].id;
                        let resizable_ele: any = document.getElementById('resizable_' + ele);
                        if (resizable_ele && resizable_ele.style["display"] != 'none') {
                            parentChildelementsArray.push(resizable_ele);
                        }
                    }
                    // parentChildelementsArray = childelementsArray;

                    if (parentChildelementsArray && parentChildelementsArray.length > 0) {
                        for (let index = 0; index < parentChildelementsArray.length; index++) {
                            this.appendOtherDependentRowsList.push({ rIndex: index, });
                        }
                    }
                    else {
                        this.addAdditionalDependentRow(0);
                    }
                }
                else if (this.appendOtherDependentRowsList.length > 0) {
                    this.appendOtherDependentRowsList = [];

                    if (this.eligibleDependentControlsArray && this.eligibleDependentControlsArray.length > 0) {
                        for (let index = 0; index < this.eligibleDependentControlsArray.length; index++) {
                            this.addAdditionalDependentRow(1); //if selected control has many dependent fields then add that rows
                        }
                    }
                    else {
                        this.addAdditionalDependentRow(0);
                    }
                }
                setTimeout(() => {
                    this.appendConditionsAndActionsLists(title);
                    if (this.eligibleDependentControlsArray && this.eligibleDependentControlsArray.length == 0) {
                        this.controlPropertyService.applyColorCodeForItem(title, this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color);
                    }
                    setTimeout(() => {
                        this.bindExistingSelectedControlFieldDependeyData();
                    }, 10);
                }, 20); //50
            }
            else {
                this.showHideAllOtherDependencyDropdowns();
                // if (this.addedAsDependentFields && this.addedAsDependentFields.length > 0) {
                //     for (let index = 0; index < this.addedAsDependentFields.length; index++) {
                //         const element = this.addedAsDependentFields[index];
                //         this.removeColorAndAttributes(element.title, element.value);
                //     }
                // }
            }

            this.allDependencySelectedControls.push(value);
        }
    }

    addAdditionalDependentRow(type: any) {
        let arrLength: any;
        arrLength = this.appendOtherDependentRowsList.length + 1;
        if (this.appendOtherDependentRowsList.length == 0 || type == 1) this.appendOtherDependentRowsList.push({ rIndex: arrLength });
        setTimeout(() => {
            let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
            if (currentSelectCtrl) {
                let sel = currentSelectCtrl.selectedIndex;
                let opt = currentSelectCtrl.options[sel];
                let value = (<HTMLSelectElement>opt).value; //DataDocumentContentId
                let title = (<HTMLSelectElement>opt).title;
                if (title && title !== '') {
                    let controlTypeID = "";
                    let controlID = "";
                    let slControlingField: any = this.controlDependencyFieldsArray.filter(function (obj: any) { return obj.ControlId.toLowerCase() == title.toLowerCase() });
                    if (slControlingField && slControlingField.length > 0) {
                        controlTypeID = slControlingField[0].DataGuid;
                        controlID = slControlingField[0].ControlId;
                        let currentDependentConditionsSelect: any = (document.getElementById("slDependentConditionsSelectList_" + (arrLength - 1))) as HTMLSelectElement;
                        if (controlTypeID.toLowerCase() == Control.Radio.toLowerCase()) { //RADIO
                            if (currentDependentConditionsSelect) currentDependentConditionsSelect.setAttribute('disabled', 'disabled');
                            this.hideTextField(controlTypeID, (arrLength - 1));
                        }
                        else if (controlTypeID.toLowerCase() == Control.DropDown.toLowerCase() || controlTypeID.toLowerCase() == Control.Checkbox.toLowerCase() || controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
                            if (currentDependentConditionsSelect) currentDependentConditionsSelect.removeAttribute('disabled');
                            if (controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
                                let spnCurrentTextControl = (<HTMLInputElement>document.getElementById("resizable_" + controlID));
                                let isRequiredCtrl = false;
                                if (spnCurrentTextControl) {
                                    let isDataSelected: any = spnCurrentTextControl.getAttribute('data-selected');
                                    if (isDataSelected == true || isDataSelected == "true") { isRequiredCtrl = true; }
                                }
                                if (isRequiredCtrl) this.showTextField('Specific Text', (arrLength - 1), TextRuleCondtionsConfig.SpecificText);
                                else this.showTextField('Any Text', (arrLength - 1), TextRuleCondtionsConfig.AnyText);
                            }
                            else
                                this.hideTextField(controlTypeID, (arrLength - 1));
                        }
                    }
                    return true;
                }
                else {
                    if (type == "1") {
                        this.appendOtherDependentRowsList.pop();
                        this.showLoader = false;
                        this.toastrService.warning("Please select a controlling field.", 'Warning'); return false;
                    }
                }
            }
            return true;
        }, 15);
    }

    showHideFirstRowDependentDropdowns() {
        let arrLength: any = this.appendOtherDependentRowsList.length - 1;
        if (this.controllingFieldSelectList && this.controllingFieldSelectList.length > 0) {
            // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
            let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
            if (this.dependencyIconClickedObj.type == "1") { currentSelectedControl = ''; }
            if (currentSelectedControl != '') {
                let dguid: any = '';
                let slControlingFieldObj: any = this.controllingFieldSelectList.filter(function (obj: any) { return obj.title.toLowerCase() == currentSelectedControl.toLowerCase() });
                if (slControlingFieldObj && slControlingFieldObj.length > 0) {
                    dguid = slControlingFieldObj[0].dataGuid;
                }
                if (dguid.toLowerCase() == Control.Text.toLowerCase()) {
                    let spnCurrentTextControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
                    let isRequiredCtrl = false;
                    if (spnCurrentTextControl) {
                        let isDataSelected: any = spnCurrentTextControl.getAttribute('data-selected');
                        if (isDataSelected == true || isDataSelected == "true") { isRequiredCtrl = true; }
                    }
                    if (isRequiredCtrl) this.showTextField('Specific Text', arrLength, TextRuleCondtionsConfig.SpecificText);
                    else this.showTextField('Any Text', arrLength, TextRuleCondtionsConfig.AnyText);
                }
            }
        }
    }

    checkIsAlreadyAddedAsDependent(rindex: any, slIndex: any) {
        let parentControlField: any = document.getElementById("slControllingFieldSelectList");
        let selectedParentItem = parentControlField.value;
        let currentSelectCtrl: any = (document.getElementById("slDependentFieldSelectList_" + rindex)) as HTMLSelectElement;
        let title: any = '';

        if (currentSelectCtrl) {
            if (slIndex != 0 && slIndex != undefined) {
                currentSelectCtrl.selectedIndex = slIndex;
            }
            let selectedChildItem = (<HTMLSelectElement>currentSelectCtrl.options[currentSelectCtrl.selectedIndex]).value; //DataDocumentContentId
            title = (<HTMLSelectElement>currentSelectCtrl.options[currentSelectCtrl.selectedIndex]).title;
            let rowDependentFields: any = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.title.toLowerCase() == title.toLowerCase() });
            //let rowDependentFields: any = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.rowIndex == rindex });
            if (title && title !== '') {
                if (document.querySelector('[data-int-control-id="' + title + '"]')?.classList.contains('dropdownItem') == true) {
                    let selectDropdownControlField: any = (document.getElementById('spn' + title)) as HTMLSelectElement;
                    if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length == 0) {
                        this.toastrService.warning("Please add list items for the selected dropdown control.", 'Warning'); this.showLoader = false; return false;
                    }
                }

                if (document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.contains('addedAsChild') == true) {
                    currentSelectCtrl.options[0]?.setAttribute('Selected', 'selected');
                    currentSelectCtrl.selectedIndex = 0;
                    if (rowDependentFields && rowDependentFields.length > 0) {
                        this.removeColorAndAttributes(rowDependentFields[0].title, rowDependentFields[0].value);

                        //remove dependency
                        this.controlPropertyService.removeAttributes(rowDependentFields[0].value);

                        this.addedAsDependentFields = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.rowIndex != rindex })
                    }
                    this.toastrService.warning("The selected value is an existing dependent control.", 'Warning'); this.showLoader = false; return false;
                }
                else {
                    document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.add('alreadyDependentField');
                    document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.add('addedAsChild');
                    //this.removePreviousControlColorIndex(selectedChildItem, rindex);
                    if (rowDependentFields && rowDependentFields.length > 0) {
                        this.removeColorAndAttributes(rowDependentFields[0].title, rowDependentFields[0].value);
                        this.addedAsDependentFields = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.rowIndex != rindex });
                    }
                    this.addedAsDependentFields.push({ parentItem: selectedParentItem, title: title, value: selectedChildItem, rowIndex: rindex });
                    this.tempDependencyControls.push({ parentControlId: selectedParentItem, dependentControlId: selectedChildItem });

                    //Apply color to child
                    let isChildrenPresent: any;
                    let parentColor: any = '';
                    let childItemField: any = document.getElementById(title);
                    if (childItemField) {
                        isChildrenPresent = childItemField.getAttribute('is-child-present');
                    }
                    let slControlingFieldObj: any = this.controllingFieldSelectList.filter(function (obj: any) { return obj.value.toLowerCase() == selectedParentItem.toLowerCase() });
                    if (slControlingFieldObj && slControlingFieldObj.length > 0) {
                        let parentItemField: any = document.getElementById("spn" + slControlingFieldObj[0].title);
                        let parentDivField: any = document.getElementById(slControlingFieldObj[0].title);
                        parentColor = parentItemField.style['background-color'];
                        if (parentColor != '' && parentColor != "rgba(220, 220, 220, 1)" && parentColor != "rgb(220, 220, 220)" && parentColor != 'rgb(255, 255, 255)') {
                            this.controlPropertyService.applyColorCodeForItem(title, parentColor);
                        }
                        else {
                            let controlGroupCode: any = parentDivField.getAttribute('group-code');
                            if (controlGroupCode != null && controlGroupCode != "" && controlGroupCode != undefined && controlGroupCode != "null") this.controlColorCodeIndex = controlGroupCode;

                            //getting error color
                            this.controlPropertyService.applyColorCodeForItem(title, this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color);
                            this.controlPropertyService.applyColorCodeForItem(slControlingFieldObj[0].title, this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color);
                        }
                        if (isChildrenPresent == 'true') {
                            let resizableItem: any = document.getElementById("resizable_" + title);
                            resizableItem.style["border-width"] = '';
                        }
                    }
                }
            }
            else if (title == '' || title == null) {
                let curItem: any = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.rowIndex == rindex });
                if (curItem && curItem.length > 0) {
                    this.removeColorAndAttributes(curItem[0].title, curItem[0].value);
                }
                this.addedAsDependentFields = this.addedAsDependentFields.filter(function (obj: any) { return obj.parentItem == selectedParentItem && obj.rowIndex != rindex });
            }
        }
        return true;
    }

    removeColorAndAttributes(title: any, contentId: any) {
        //Check for any dependent controls are exists for parent to assign the back ground color.
        //This comes because
        //for a controlling filed X, assigned  other control Y as dependent
        //Now this controlling filed X selected from dependent dropdown, Then reassign the color.
        let checkIsParentToOtherControlsArray: any = document.querySelectorAll('[data-parent-id="' + contentId + '"]');
        if (checkIsParentToOtherControlsArray && checkIsParentToOtherControlsArray.length > 0) {
            //get the first dependent field
            let attr_id: any = checkIsParentToOtherControlsArray[0]?.getAttribute('id');
            var isChildrenPresent = checkIsParentToOtherControlsArray[0]?.getAttribute('is-child-present');
            var isParentPresent = checkIsParentToOtherControlsArray[0]?.getAttribute('is-parent-present');
            let parentItem: any = document.querySelector('[data-document-content-id="' + contentId + '"]');
            if (parentItem) {
                let isParentParentPresent = parentItem.getAttribute('is-parent-present');
                if (attr_id && (isChildrenPresent == "false" || isChildrenPresent == null) && (isParentPresent == "true") && (isParentParentPresent == "false" || isParentParentPresent == null)) {
                    //find the parent dependent field
                    let resizableItem: any = document.getElementById("resizable_" + attr_id);
                    if (resizableItem) {
                        //get the dependent control background-color style
                        let back_color: any = resizableItem.style["background-color"];
                        //find the parent controlling field
                        let parentResizableItem: any = document.querySelector('[data-document-content-id="' + contentId + '"]');
                        let res_attr_id: any = parentResizableItem?.getAttribute('id');
                        //reassigning the color
                        this.controlPropertyService.applyColorCodeForItem(res_attr_id, back_color);
                    }
                }
            }
        }
        else {
            this.controlPropertyService.removeColorForItem(title);
        }
        document.querySelector('[data-document-content-id="' + contentId + '"]')?.classList.remove('addedAsChild');
        document.querySelector('[data-document-content-id="' + contentId + '"]')?.classList.remove('alreadyDependentField');
        document.querySelector('[data-document-content-id="' + contentId + '"]')?.classList.remove('is-parent-present');
    }

    deleteAdditionalDependentRow(rowIndex: any, valIndex: any) {
        let tobeDeletedDependentItem: any = document.getElementById("slDependentFieldSelectList_" + rowIndex);
        if (tobeDeletedDependentItem) {
            let selectedChildItem = tobeDeletedDependentItem.value;
            if (selectedChildItem) {
                this.controlPropertyService.removeAttributes(selectedChildItem);
                this.controlPropertyService.removeElementColor(selectedChildItem);
            }
            //to bind the remaining dependent rows
            this.appendOtherDependentRowsList = this.appendOtherDependentRowsList.filter((c: any) => { return c.rIndex !== valIndex });
            //to get the remaining dependent rows
            this.addedAsDependentFields = this.addedAsDependentFields.filter(function (obj: any) { return obj.value.toLowerCase() != selectedChildItem.toLowerCase() });

            if (this.addedAsDependentFields && this.addedAsDependentFields.length == 0) {
                let parentControlField: any = document.getElementById("slControllingFieldSelectList");
                let selectedParentItem = parentControlField.value;
                let dependentControlsToParentControlsArray: any = document.querySelectorAll('[data-parent-id="' + selectedParentItem + '"]');
                if (selectedParentItem) {
                    if (dependentControlsToParentControlsArray != null && dependentControlsToParentControlsArray.length == 0) {
                        this.controlPropertyService.removeElementColor(selectedParentItem);
                        this.controlPropertyService.removeAttributes(selectedParentItem);
                    }
                    document.querySelector('[data-document-content-id="' + selectedParentItem + '"]')?.setAttribute('is-child-present', '');
                }
            }
        }
    }

    getDisplayName(attr_dataGuid: any, dataPageNumber: any, dataControlname: any, dataControlNum: any, textControlText: any) {
        var constControllingName = 'Page' + ' #PageNo: #ControlType, #ControlName';
        var displayName = constControllingName.replace('#PageNo', dataPageNumber)
            .replace('#ControlType', this.commonService.getHoverControlText(dataControlname))
            .replace('#ControlName', (attr_dataGuid.toLowerCase() == Control.Text.toLowerCase() ? textControlText : dataControlNum));
        return displayName;
    }

    getCondtion(typeId: any, condtionId: any, controlId: any) {
        var condition = '';
        let dropDownControls: any = document.querySelectorAll('[data-document-content-id="' + controlId + '"]');
        if (typeId.toLowerCase() == Control.DropDown.toLocaleLowerCase()) {
            if (dropDownControls && dropDownControls.length > 0) {
                Array.from(dropDownControls).forEach((drpItem: any, v: any) => {
                    let attr_drpId: any = drpItem.getAttribute('id');
                    let selectDropdownControlField: any = (document.getElementById('spn' + attr_drpId)) as HTMLSelectElement;
                    if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                        for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                            var text = selectDropdownControlField.options[index].text;
                            var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                            if (ruleId.toLowerCase() == condtionId.toLowerCase()) {
                                condition = text;
                                break;
                            }
                        }
                    }
                })
            }
        }
        else if (typeId.toLowerCase() == Control.Radio.toLocaleLowerCase()) {
            condition = "Selected";
        }
        else {
            var found = this.commonConstants.RuleConfig.filter(function (item: any) { return item.ID === condtionId.toUpperCase(); })
            condition = found[0].RuleText;
        }
        return condition;
    }

    hideExistingContent() {
        this.isViewAll = true;
        let cancelDependencyBtn: any = document.getElementById("cancelDependencyBtn");
        if (cancelDependencyBtn) {
            cancelDependencyBtn.cursor = 'no-drop';
            cancelDependencyBtn.setAttribute('disabled', 'disabled');
        }

        let saveDependencyBtn: any = document.getElementById("saveDependencyBtn");
        if (saveDependencyBtn) {
            saveDependencyBtn.cursor = 'no-drop';
            saveDependencyBtn.setAttribute('disabled', 'disabled');
        }

        let staticBackdropLabel: any = document.getElementById("staticBackdropLabel");
        if (staticBackdropLabel) {
            staticBackdropLabel.innerHTML = 'View Dependencies';
        }
    }

    showExistingContent() {
        this.isViewAll = false;
        let cancelDependencyBtn: any = document.getElementById("cancelDependencyBtn");
        if (cancelDependencyBtn) {
            cancelDependencyBtn.cursor = 'pointer';
            cancelDependencyBtn.removeAttribute('disabled');
        }

        let saveDependencyBtn: any = document.getElementById("saveDependencyBtn");
        if (saveDependencyBtn) {
            saveDependencyBtn.cursor = 'pointer';
            saveDependencyBtn.removeAttribute('disabled');
        }

        let staticBackdropLabel: any = document.getElementById("staticBackdropLabel");
        if (staticBackdropLabel) {
            staticBackdropLabel.innerHTML = 'Dependencies:';
        }
    }

    saveDependentFields() {
        this.showLoader = true;
        let ccontrolFieldText: any = '';
        let selectedControlFieldType: any = '';
        let controlFielddataDocumentContentId: any = '';
        //Parent Controlling Field
        let currentControllingFieldSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentControllingFieldSelectCtrl) {
            let csel = currentControllingFieldSelectCtrl.selectedIndex;
            let copt = currentControllingFieldSelectCtrl.options[csel];
            controlFielddataDocumentContentId = (<HTMLSelectElement>copt).value; //DataDocumentContentId
            ccontrolFieldText = (<HTMLSelectElement>copt).textContent;
            let slControlingFieldObj: any = this.controllingFieldSelectList.filter(function (obj: any) { return obj.value.toLowerCase() == controlFielddataDocumentContentId.toLowerCase() });
            if (slControlingFieldObj && slControlingFieldObj.length > 0) {
                selectedControlFieldType = slControlingFieldObj[0].dataGuid.toLowerCase();
            }
        }

        if (ccontrolFieldText == "" || (ccontrolFieldText != '' && ccontrolFieldText.trim().toLowerCase() == "select")) {
            isDependentFault = true; this.showLoader = false;
            this.toastrService.warning("Please select a controlling field.", 'Warning'); return false;
        }

        var isDependentFault = false;
        var dependents: any = [];
        let existingColorValue: any;
        let isChildPresent = "false";
        //Getting all added dependent rows
        let dependentFieldSelectClassArray: any = (document.getElementsByClassName("dependentFieldSelectList")) as HTMLSelectElement;
        if (dependentFieldSelectClassArray && dependentFieldSelectClassArray.length > 0) {
            Array.from(dependentFieldSelectClassArray).forEach((dependentField: any, index: any) => {
                //Dependent Control
                let dsel = dependentField.selectedIndex;
                let dopt = dependentField.options[dsel];
                let dependentDocumentContentId = (<HTMLSelectElement>dopt).value; //DataDocumentContentId
                let dcontrolFieldText: any = (<HTMLSelectElement>dopt).textContent;

                if (dependentDocumentContentId == '0' || dcontrolFieldText == '' || dcontrolFieldText.toLowerCase() == "select") {
                    isDependentFault = true;
                    this.isSaveDependentFieldsClicked = false; this.showLoader = false;
                    this.toastrService.warning("Please select dependent field to save conditions.", 'Warning');
                    return false;
                }
                else {
                    //Condition
                    let conditionVal = "";
                    let conditionValTextContent = "";
                    let conditionIdCtrl: any = (document.getElementById("slDependentConditionsSelectList_" + index)) as HTMLSelectElement;
                    if (conditionIdCtrl) {
                        let condsel = conditionIdCtrl.selectedIndex;
                        let condopt = conditionIdCtrl.options[condsel];
                        conditionValTextContent = conditionIdCtrl.options[condsel].textContent;
                        conditionVal = (<HTMLSelectElement>condopt).value;
                    }

                    //Behaviour
                    let behaviourVal = "";
                    let behaviourCtrl: any = (document.getElementById("slDependentActionSelectList_" + index)) as HTMLSelectElement;
                    if (behaviourCtrl) {
                        let behsel = behaviourCtrl.selectedIndex;
                        let behopt = behaviourCtrl.options[behsel];
                        behaviourVal = (<HTMLSelectElement>behopt).value;
                    }

                    //Support Text
                    let supportTextVal: any;
                    if (selectedControlFieldType && selectedControlFieldType.toLowerCase() == Control.Text.toLowerCase()) {
                        let spnSupportTextCtl = (<HTMLInputElement>document.getElementById("txtTextField_" + index));
                        if (spnSupportTextCtl) {
                            if (spnSupportTextCtl.value != '') supportTextVal = spnSupportTextCtl.value;
                            else if (conditionVal.toUpperCase() != '0B069009-E271-4534-8C8B-21D876CC31DE' && conditionVal.toUpperCase() != 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D') {
                                //Empty and Any Text
                                isDependentFault = true; this.toastrService.warning(conditionValTextContent + " cannot be blank.", 'Warning'); this.showLoader = false; return false;
                            }
                        }
                    }

                    //Pushing dependent row data to array
                    dependents.push({
                        ID: this.getConditionId(dependentDocumentContentId, this.controllingDependentFieldSelectList),
                        ControlID: (dependentDocumentContentId != "" && dependentDocumentContentId != null) ? dependentDocumentContentId.toLocaleLowerCase() : dependentDocumentContentId,
                        ConditionID: (conditionVal != "" && conditionVal != null) ? conditionVal.toLocaleLowerCase() : conditionVal,
                        SupportText: (supportTextVal == undefined || supportTextVal == '') ? null : supportTextVal,
                        GroupCode: null,
                        IsRequired: behaviourVal == BehaviourRuleConfig.VisibleRequired ? true : false
                    });
                }
                return true;
            });
            isChildPresent = "true";
        }
        else {
            isDependentFault = true;
            this.isSaveDependentFieldsClicked = false; this.showLoader = false;
            this.toastrService.warning("Please select dependent field to save conditions.", 'Warning'); return false;
        }

        if (isDependentFault) {
            dependents = []; return false;
        }

        //todo color code pending
        let currentPanelContrlID = (controlFielddataDocumentContentId != "" && controlFielddataDocumentContentId != null) ? controlFielddataDocumentContentId.toLocaleLowerCase() : controlFielddataDocumentContentId;

        //existingColorCodeValue
        const elem = document.querySelector('[data-document-content-id="' + currentPanelContrlID + '"]');
        if (elem) {
            let groupCode = elem?.getAttribute('group-code');
            if (groupCode != null && groupCode != '') {
                existingColorValue = groupCode;
            }
        }
        let existingColorCode = existingColorValue;

        //Bind all dependent data to parent controls - conditionalControlsDetails
        var conditionalControlsDetails = {
            ID: this.getConditionId(currentPanelContrlID, this.controllingFieldSelectList),
            EnvelopeID: '', //To do $('#PrepareGlobalEnvelopeID').val(),
            ControlID: currentPanelContrlID,
            ControllingFieldID: '',
            ControllingConditionID: '',
            ControllingSupportText: '',
            EnvelopeStage: '', //To do
            DependentFields: dependents,
            GroupCode: (existingColorCode != null && existingColorCode != '' && existingColorCode != undefined) ? parseInt(existingColorCode) : this.dependencyColorCodes[(parseInt(this.controlColorCodeIndex) > 0 ? parseInt(this.controlColorCodeIndex) : 0) % 10].ID,
        };

        //increasing color code
        if (existingColorCode == null || existingColorCode == '' || existingColorCode == undefined) {
            this.controlColorCodeIndex = parseInt(this.controlColorCodeIndex) + 1;
        }

        //Set group-code and data-condition-id to parent control field
        document.querySelector('[data-document-content-id="' + currentPanelContrlID + '"]')?.setAttribute('group-code', conditionalControlsDetails.GroupCode)
        document.querySelector('[data-document-content-id="' + currentPanelContrlID + '"]')?.setAttribute('data-condition-id', conditionalControlsDetails.ID)
        document.querySelector('[data-document-content-id="' + currentPanelContrlID + '"]')?.setAttribute('is-child-present', isChildPresent)

        //assigning group code to all dependent fields
        if (dependentFieldSelectClassArray && dependentFieldSelectClassArray.length > 0) {
            Array.from(dependentFieldSelectClassArray).forEach((dependentField: any, index: any) => {
                let dsel = dependentField.selectedIndex;
                let dopt = dependentField.options[dsel];
                let dependentDocumentContentId = (<HTMLSelectElement>dopt).value; //DataDocumentContentId
                document.querySelector('[data-document-content-id="' + dependentDocumentContentId + '"]')?.setAttribute('group-code', conditionalControlsDetails.GroupCode);
                let dependentParentId: any = document.querySelector('[data-document-content-id="' + dependentDocumentContentId + '"]')?.getAttribute('id');

                //Reassign the color
                let colorCode: any = this.commonConstants.DependencyColorCodes[parseInt(conditionalControlsDetails.GroupCode) % 10].Color;
                this.controlPropertyService.applyColorCodeForItem(dependentParentId, colorCode);

                //selected dependent field having child dependent fields or not
                let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + dependentDocumentContentId + '"]');
                if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
                    Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                        childDependentItem.setAttribute('group-code', conditionalControlsDetails.GroupCode);
                        this.controlPropertyService.removeColorForItem(childDependentItem.getAttribute('id'));
                        let childChildDependentControl: any = document.getElementById("spn" + dependentParentId);
                        this.controlPropertyService.applyColorCodeForItem(childDependentItem.getAttribute('id'), childChildDependentControl?.style['background-color']);

                        this.checkIsHavingDependentFields(childDependentItem, conditionalControlsDetails.GroupCode);
                    });
                }
                if (dependentParentId) {
                    let ctrlItem: any = document.getElementById(dependentParentId);
                    ctrlItem.setAttribute('group-code', conditionalControlsDetails.GroupCode);
                }
            });
        }

        //assign conditionid to selected option for parentControlField
        let parentControlField: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (parentControlField) {
            parentControlField.options[parentControlField.options.selectedIndex]?.setAttribute('conditionid', conditionalControlsDetails.ID);
        }

        //updating the dependency for existing data-parent-id
        let conditionalControlsDetailsArray: any = document.querySelectorAll('[data-parent-id="' + conditionalControlsDetails.ControlID + '"]');
        if (conditionalControlsDetailsArray && conditionalControlsDetailsArray.length > 0) {
            Array.from(conditionalControlsDetailsArray).forEach((existingParentIdCtrl: any, v: any) => {
                let attr_docContentId: any = existingParentIdCtrl.getAttribute('data-document-content-id');
                var item = this.findFirstItemFromListByPropAndValue(conditionalControlsDetails.DependentFields, 'ControlID', attr_docContentId);
                if (item != undefined || item != null) {
                    this.controlPropertyService.removeAttributes(attr_docContentId);
                    document.querySelector('[data-document-content-id="' + attr_docContentId + '"]')?.removeAttribute('is-child-present');
                }
            });
        }

        //set data-parent-id, data-parent-rule-id and data-parent-support-text-content for DependentFields
        if (conditionalControlsDetails && conditionalControlsDetails != null && conditionalControlsDetails.DependentFields && conditionalControlsDetails.DependentFields.length > 0) {
            Array.from(conditionalControlsDetails.DependentFields).forEach((dependentItem: any, k: any) => {
                document.querySelector('select.controllingFieldSelectList option[value="' + dependentItem.ControlID + '"]')?.setAttribute('conditionid', dependentItem.ID);
                document.querySelector('select.dependentFieldSelectList option[value="' + dependentItem.ControlID + '"]')?.setAttribute('conditionid', dependentItem.ID);

                this.controlPropertyService.setAttributes(dependentItem, selectedControlFieldType, currentPanelContrlID);
                if (dependentItem.ControlID) {
                    let parentGrpCode: any = document.querySelector('[data-document-content-id="' + currentPanelContrlID + '"]')?.getAttribute('group-code');
                    document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('group-code', parentGrpCode);
                }

                Array.from(conditionalControlsDetails.DependentFields).forEach((value: any, index: any) => {
                    if (dependentItem.ControlID == value.ControlID) {
                        let ctrlContentItem = document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]');
                        if (ctrlContentItem) {
                            let ctrlId = ctrlContentItem?.getAttribute('id');
                            let resizableCtrl = "resizable_" + ctrlId;
                            if (value.IsRequired == true) {
                                document.querySelector('[id="' + resizableCtrl + '"]')?.setAttribute('data-selected', 'true');
                                let ctrl_attr_guid: any = document.querySelector('[id="' + resizableCtrl + '"]')?.getAttribute('data-guid');
                                if (ctrl_attr_guid.toLocaleLowerCase() == Control.Checkbox.toLocaleLowerCase()) {
                                    document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('is-child-present', '');
                                    let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + dependentItem.ControlID + '"]');
                                    if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
                                        Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                                            let docContentId: any = childDependentItem.getAttribute('data-document-content-id');
                                            this.controlPropertyService.removeAttributes(docContentId);
                                            document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');
                                            this.controlPropertyService.removeColorForItem(childDependentItem.getAttribute('id'));
                                            this.removeColorForDependentFields(docContentId);
                                        });
                                    }
                                }
                            }
                            else document.querySelector('[id="' + resizableCtrl + '"]')?.setAttribute('data-selected', 'false');
                        }
                    }
                });
            });
        }

        this.tempDependencyControls = [];
        this.isSaveDependentFieldsClicked = true;
        this.controlColorCode = Number(this.controlColorCodeIndex);
        this.eligibleDependentControlsArray = [];
        this.showLoader = false;
        this.toastrService.success('Dependencies saved successfully.', 'Success');
        this.addDependencieschangesMade.emit(this);
        this.modalRef.hide();
        return true;
    }

    viewAllDependecies() {
        this.isViewAll = true;
        this.showLoader = true;
        let tableViewAllBody: any = document.getElementById("tableViewAllBody");
        if (tableViewAllBody) tableViewAllBody.innerHTML = '';
        let divViewAll: any = document.getElementById("divViewAll");
        if (divViewAll) divViewAll.style.display = 'block';

        let divAddDependency: any = document.getElementsByClassName("divAddDependency");
        if (divAddDependency && divAddDependency.length > 0) {
            Array.from(divAddDependency).forEach((divAddDependencyItem: any, index: any) => {
                divAddDependencyItem.style.display = 'none';
            })
        }

        let rowHTML = "";
        let isChildPresentArray: any = document.querySelectorAll('[is-child-present="' + true + '"]');
        if (isChildPresentArray && isChildPresentArray.length > 0) {
            let controllingFieldArray: any = document.querySelectorAll('.controllingField');
            if (controllingFieldArray && controllingFieldArray.length > 0) {
                Array.from(controllingFieldArray).forEach((item: any, v: any) => {
                    let controlID: any = item.getAttribute('data-document-content-id');
                    let attr_id: any = item.getAttribute('id');
                    let resizableCtrl: any = document.getElementById('resizable_' + attr_id);
                    let attr_dataGuid: any = '';
                    if (resizableCtrl) attr_dataGuid = resizableCtrl.getAttribute('data-guid');
                    let dataPageNumber: any = item.getAttribute('data-pageno');
                    let dataControlname: any = item.getAttribute('data-controlname');
                    let dataControlNum: any = item.getAttribute('data-ctrlnum');
                    let hasChildren: any = item.getAttribute('is-child-present');

                    let textControlText = "";
                    if (attr_dataGuid.toLowerCase() == Control.Text.toLocaleLowerCase()) {
                        let spnControl = (<HTMLInputElement>document.getElementById("spn" + attr_id));
                        if (spnControl) {
                            textControlText = (<HTMLInputElement>document.getElementById("spn" + attr_id)).innerHTML;
                        }
                    }

                    if (hasChildren == "true") {
                        let displayName = this.getDisplayName(attr_dataGuid, dataPageNumber, dataControlname, dataControlNum, textControlText);
                        rowHTML += '<tr><td><p title="' + displayName + '">' + displayName + '</p></td>';

                        let count = 0;
                        //Get the all dependencied fields to this parent controlling field
                        let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + controlID + '"]');
                        if (childelementsArray && childelementsArray.length > 0) {
                            Array.from(childelementsArray).forEach((childelementItem: any, v: any) => {
                                // let childattr_docContentId: any = childelementItem.getAttribute('data-document-content-id');
                                // let child_controlID: any = childelementItem.getAttribute('data-document-content-id');
                                let child_attr_id: any = childelementItem.getAttribute('id');
                                let child_resizableCtrl: any = document.getElementById('resizable_' + child_attr_id);
                                if (child_resizableCtrl && child_resizableCtrl.style["display"] != 'none') {
                                    let child_attr_dataGuid: any = ''; let child_dataSelectedVal: any = '';
                                    if (child_resizableCtrl) {
                                        child_attr_dataGuid = child_resizableCtrl.getAttribute('data-guid');
                                        let child_dataSelected = child_resizableCtrl.getAttribute('data-selected');
                                        if (child_dataSelected && child_dataSelected != null) child_dataSelectedVal = child_dataSelected.toLowerCase()
                                        else child_dataSelectedVal = '';
                                    }

                                    let child_dataPageNumber: any = childelementItem.getAttribute('data-pageno');
                                    let child_dataControlName: any = childelementItem.getAttribute('data-controlname');
                                    let child_dataControlNum: any = childelementItem.getAttribute('data-ctrlnum');
                                    let child_condtionID = childelementItem.getAttribute('data-parent-rule-id');

                                    let child_supportText = (childelementItem.getAttribute('data-parent-support-text-content') == null || childelementItem.getAttribute('data-parent-support-text-content') == undefined) ? '' : childelementItem.getAttribute('data-parent-support-text-content');
                                    let isRequired = (child_attr_dataGuid == Control.DropDown.toLowerCase() || child_attr_dataGuid == Control.Radio.toLowerCase()) ? BehaviourRuleConfig.VisibleNotRequired :
                                        (child_dataSelectedVal == 'true') ? BehaviourRuleConfig.VisibleRequired : BehaviourRuleConfig.VisibleNotRequired;

                                    let child_textControlText = "";
                                    if (child_attr_dataGuid.toLowerCase() == Control.Text.toLocaleLowerCase()) {
                                        let child_spnControl = (<HTMLInputElement>document.getElementById("spn" + child_attr_id));
                                        if (child_spnControl) {
                                            child_textControlText = (<HTMLInputElement>document.getElementById("spn" + child_attr_id)).innerHTML;
                                        }
                                    }

                                    var childDisplayName = this.getDisplayName(child_attr_dataGuid, child_dataPageNumber, child_dataControlName, child_dataControlNum, child_textControlText);
                                    var condition = this.getCondtion(attr_dataGuid, child_condtionID, controlID)
                                    if (count == 0) { //add first child
                                        rowHTML += '<td><p>' + condition + '</p></td>';
                                        rowHTML += '<td><p title="' + childDisplayName + '">' + childDisplayName + '</p></td>';
                                        rowHTML += '<td><p>' + isRequired + '</p></td>';
                                        if (child_supportText != '' && child_supportText != "null" && child_supportText != null) {
                                            rowHTML += '<td><p>' + child_supportText + '</p></td>';
                                        }
                                        count++;
                                    }
                                    else { //Add next childs
                                        rowHTML += '<tr>';
                                        rowHTML += '<td>' + "" + '</td>';
                                        rowHTML += '<td><p>' + condition + '</p></td>';
                                        rowHTML += '<td><p>' + childDisplayName + '</p></td>';
                                        rowHTML += '<td><p>' + isRequired + '</p></td>';
                                        if (child_supportText != '' && child_supportText != "null" && child_supportText != null) {
                                            rowHTML += '<td><p>' + child_supportText + '</p></td>';
                                        }
                                    }
                                    rowHTML += "</tr>"
                                }
                            });
                        }
                    }
                });
                tableViewAllBody.innerHTML = rowHTML;
                this.hideExistingContent();
                this.showLoader = false;
            }
        }
        else {
            rowHTML += '<tr><td colspan="4" style="border: none;"><p style=" text-align: center; vertical-align: center; padding-top: 8px 0px;font-weight: 600; color: #333;"> There are no dependencies added for the controls.</p></td>';
            tableViewAllBody.innerHTML = rowHTML;
            this.hideExistingContent();
            this.showLoader = false;
        }
    }

    closePopUp() {
        //  this.isViewAll = false;
        this.showLoader = true;
        let currentControllingFieldSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        let presentValue: any = "";
        if (currentControllingFieldSelectCtrl) {
            let csel = currentControllingFieldSelectCtrl.selectedIndex;
            let copt = currentControllingFieldSelectCtrl.options[csel];
            let ccontrolFieldText: any = (<HTMLSelectElement>copt).textContent;
            presentValue = (copt && copt.value && copt.value != "") ? copt.value : "";
            if (ccontrolFieldText != "" && ccontrolFieldText.trim().toLowerCase() != "select") {
                if (this.isSaveDependentFieldsClicked == false) {
                    //Remove color and other attributes to selected parent and child controls
                    Array.from(this.tempDependencyControls).forEach((item: any, index: any) => {
                        var controlID = item.dependentControlId;
                        var dependentControlsToParentControls: any = document.querySelectorAll('[data-parent-id="' + controlID + '"]');
                        if (dependentControlsToParentControls && dependentControlsToParentControls.length == 0) {
                            //let $controlObj = document.querySelector('[data-document-content-id="' + controlID + '"]');
                            this.controlPropertyService.removeAttributes(controlID);
                            this.controlPropertyService.removeElementColor(controlID);
                        }
                        else {
                            let parentElement = document.querySelector('[data-document-content-id="' + controlID + '"]');
                            if (parentElement) {
                                let resizableParentItem: any = document.getElementById("resizable_" + dependentControlsToParentControls[0].id);
                                if (resizableParentItem) {
                                    this.controlPropertyService.applyColorCodeForItem(parentElement.getAttribute('id'), resizableParentItem.style["background-color"]);
                                }
                                this.controlPropertyService.removeAttributes(controlID);
                            }
                        }
                    });
                }
            }

            if (this.tempDependencyControls && this.tempDependencyControls.length > 0) {
                var controllingFieldID = this.tempDependencyControls[0];
                var dependentControlsToParentControls: any = document.querySelectorAll('[data-parent-id="' + controllingFieldID.parentControlId + '"]');
                if (dependentControlsToParentControls && dependentControlsToParentControls.length == 0) {
                    var parentElement: any = document.querySelector('[data-document-content-id="' + controllingFieldID.parentControlId + '"]');
                    var isParentPresent = parentElement?.getAttribute('is-parent-present');
                    if ((isParentPresent == "false" || isParentPresent == null || isParentPresent == "null" || typeof isParentPresent == "undefined" || isParentPresent == "")) {
                        this.controlPropertyService.removeColorForItem(parentElement?.getAttribute('id'));
                    }
                }
                this.tempDependencyControls = [];
            }
            else {
                if (currentControllingFieldSelectCtrl.options[csel] && currentControllingFieldSelectCtrl.options[csel].value != "" && currentControllingFieldSelectCtrl.options[csel].value != null) {
                    var parentElement: any = document.querySelector('[data-document-content-id="' + presentValue + '"]');
                    var isParentPresent = parentElement?.getAttribute('is-parent-present');
                    var isChildPresent = parentElement?.getAttribute('is-child-present');
                    if ((isParentPresent == "false" || isParentPresent == null || typeof isParentPresent == "undefined" || isParentPresent == '') &&
                        (isChildPresent == "false" || isChildPresent == null || typeof isChildPresent == "undefined" || isChildPresent == '')) {
                        this.controlPropertyService.removeElementColor(currentControllingFieldSelectCtrl.options[csel].value);
                    }
                }
            }


            this.isSaveDependentFieldsClicked = false;
        }
        if (this.allDependencySelectedControls && this.allDependencySelectedControls.length > 0) {
            Array.from(this.allDependencySelectedControls).forEach((slVal: any, ind: any) => {
                let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + slVal + '"]');
                if (slVal != presentValue && isHavingDependentFieldsArray && isHavingDependentFieldsArray.length == 0) {
                    var parentElement: any = document.querySelector('[data-document-content-id="' + slVal + '"]');
                    var isParentPresent = parentElement?.getAttribute('is-parent-present');
                    var isChildPresent = parentElement?.getAttribute('is-child-present');
                    if ((isParentPresent == "false" || isParentPresent == null || typeof isParentPresent == "undefined") &&
                        (isChildPresent == "false" || isChildPresent == null || typeof isChildPresent == "undefined")) {
                        this.controlPropertyService.removeColorForItem(parentElement?.getAttribute('id'));
                    }
                }
            });
        }

        this.showLoader = false;
        // this.addDependencieschangesMade.emit(this);
        // this.modalRef.hide();
    }

    checkIsHavingDependentFields(childDependentItem: any, groupCode: any) {
        let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + childDependentItem.getAttribute('data-document-content-id') + '"]');
        if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
            Array.from(isHavingDependentFieldsArray).forEach((childDependentItemObj: any, ind: any) => {
                childDependentItemObj.setAttribute('group-code', groupCode);
                this.controlPropertyService.removeColorForItem(childDependentItemObj.getAttribute('id'));
                let childChildDependentControl: any = document.getElementById("spn" + childDependentItem.getAttribute('id'));
                this.controlPropertyService.applyColorCodeForItem(childDependentItemObj.getAttribute('id'), childChildDependentControl?.style['background-color']);

                this.checkIsHavingDependentFields(childDependentItemObj, groupCode);
            });
        }
    }

    removeColorForDependentFields(docContentId: any) {
        let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
        if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
            Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                let docContentid: any = childDependentItem.getAttribute('data-document-content-id');
                this.controlPropertyService.removeAttributes(docContentid);
                document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');
                this.controlPropertyService.removeColorForItem(childDependentItem.getAttribute('id'));
                this.removeColorForDependentFields(childDependentItem);
            });
        }
    }

    updateClassDependentField() {
        let className: any = 'th2';
        let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentSelectCtrl) {
            let sel = currentSelectCtrl.selectedIndex;
            let opt = currentSelectCtrl.options[sel];
            let title = (<HTMLSelectElement>opt).title;
            if (title) {
                let controlName = title.replace(/\d+/g, '');
                if (controlName == 'dropDownControl' || controlName == 'checkboxControl' || controlName == 'radioControl') {
                    className = 'condition-th2';
                }
                else if (controlName == 'textControl') {
                    className = 'ml-n8';
                }
            }
            return className;
        }
    }

    updateClassAction() {
        let className: any = 'th3';
        let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentSelectCtrl) {
            let sel = currentSelectCtrl.selectedIndex;
            let opt = currentSelectCtrl.options[sel];
            let title = (<HTMLSelectElement>opt).title;
            if (title) {
                let controlName = title.replace(/\d+/g, '');
                if (controlName == 'checkboxControl' || controlName == 'radioControl') {
                    className = 'condition-th3';
                }
                else if (controlName == 'textControl') {
                    className = 'ml-8p';
                }
            }
            return className;
        }
    }

    updateClassBehaviour() {
        let className: any = 'th4';
        let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentSelectCtrl) {
            let sel = currentSelectCtrl.selectedIndex;
            let opt = currentSelectCtrl.options[sel];
            let title = (<HTMLSelectElement>opt).title;
            if (title) {
                let controlName = title.replace(/\d+/g, '');
                if (controlName == 'checkboxControl' || controlName == 'radioControl') {
                    className = 'condition-th4';
                }
                else if (controlName == 'textControl') {
                    className = 'd-content';
                }
            }
            return className;
        }
    }

    updateClasscontrollingField() {
        let className: any = '';
        let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
        if (currentSelectCtrl) {
            let sel = currentSelectCtrl.selectedIndex;
            let opt = currentSelectCtrl.options[sel];
            let title = (<HTMLSelectElement>opt).title;
            if (title) {
                let controlName = title.replace(/\d+/g, '');
                if (controlName == 'checkboxControl' || controlName == 'radioControl') {
                    className = '';
                }
                else if (controlName == 'textControl') {
                    className = 'top-n3';
                }
            }
            return className;
        }
    }

    showDependenciesPopUp() {
        // this.showDependenciesPopUpModal = true;
        // this.dependecieClose.emit(true);
        let divAddDependency: any = document.getElementsByClassName("divAddDependency");
        if (divAddDependency && divAddDependency.length > 0) {
            Array.from(divAddDependency).forEach((divAddDependencyItem: any, index: any) => {
                divAddDependencyItem.style.display = 'block';
            })
        }

        let divViewAll: any = document.getElementById("divViewAll");
        if (divViewAll) divViewAll.style.display = 'none';
        this.showExistingContent();
    }

    closeDependecies() {
        this.closePopUp();
        this.dependecieClose.emit(false);
        let button: any = document.getElementById("depencieclose");
        if (button.classList.contains("collapsed")) {
            let accordionCollapses: any = document.getElementById("collapseDependence");
            button.classList.remove("collapsed");
            accordionCollapses.classList.add("show");
        }
    }

    addSelectedControlRowToExistingRows(updatedObj: any) {
        // let depCtrlMoved: any = localStorage.getItem('dependCtrlMoved');
        let depCtrlMoved: any = this.sessionStorageService.getDependCtrlMoved();
        if (depCtrlMoved !== 'true') {
            let currentSelectCtrl: any = (document.getElementById("slControllingFieldSelectList")) as HTMLSelectElement;
            if (currentSelectCtrl) {
                let sel = currentSelectCtrl.selectedIndex;
                let opt = currentSelectCtrl.options[sel];
                let title = (<HTMLSelectElement>opt).title;
                let currentDependentSelectedControl: any = updatedObj.currentDependentSelectedControl;
                let isAddedAlreadyObj: any = [];
                if (title && title !== '') {
                    let docContentId = "";
                    let controlTypeID = "";
                    let controlID = "";
                    let currentParentControl: any = document.getElementById(title);
                    let selectedParentItem: any = currentParentControl.getAttribute('data-document-content-id');
                    let slControlingField: any = this.controlDependencyFieldsArray.filter(function (obj: any) { return obj.ControlId.toLowerCase() == title.toLowerCase() });
                    if (slControlingField && slControlingField.length > 0) {
                        let arrLength: any = this.appendOtherDependentRowsList.length;
                        if (arrLength != 0) {
                            let dependentSlCtrl: any = (document.getElementById("slDependentFieldSelectList_" + (arrLength - 1))) as HTMLSelectElement;
                            let depopt = dependentSlCtrl.options[dependentSlCtrl.selectedIndex];
                            let deptitle = (<HTMLSelectElement>depopt).title;
                            isAddedAlreadyObj = this.addedAsDependentFields.filter(function (obj: any) { return obj.title.toLowerCase() == currentDependentSelectedControl.toLowerCase() });
                            if (currentDependentSelectedControl != deptitle && isAddedAlreadyObj != null && isAddedAlreadyObj.length == 0) {
                                if (dependentSlCtrl) {
                                    if (deptitle != "" && deptitle != null && deptitle != undefined) {
                                        if (currentDependentSelectedControl != title) {
                                            this.appendOtherDependentRowsList.push({ rIndex: arrLength + 1 });
                                        }
                                        arrLength = this.appendOtherDependentRowsList.length;
                                    }
                                }
                            }
                            else {
                                if (isAddedAlreadyObj != null && isAddedAlreadyObj.length > 0) {
                                    let findIndex: any = '';
                                    for (let index = 0; index < this.addedAsDependentFields.length; index++) {
                                        const element = this.addedAsDependentFields[index];
                                        if (element.rowIndex == isAddedAlreadyObj[0].rowIndex) {
                                            findIndex = String(index);
                                            break;
                                        }
                                    }

                                    if (findIndex != '') {
                                        let deleteRowIndex: any = document.getElementById("hdnDelete_" + (findIndex));
                                        if (deleteRowIndex) {
                                            let data_valueIndex: any = deleteRowIndex.value;
                                            this.deleteAdditionalDependentRow(findIndex, parseInt(data_valueIndex));
                                            let currenctrl: any = document.getElementById(currentDependentSelectedControl);
                                            let currenctrlContentId: any = currenctrl.getAttribute('data-document-content-id');
                                            this.controlPropertyService.removeAttributes(currenctrlContentId);
                                            this.controlPropertyService.removeElementColor(currenctrlContentId);
                                        }
                                    }
                                }
                            }

                            setTimeout(() => {
                                if (isAddedAlreadyObj != null && isAddedAlreadyObj.length == 0 && currentDependentSelectedControl != title) {
                                    this.populateControlFromDropdown(arrLength, updatedObj.contentId, slControlingField, "1");
                                }
                            }, 70);
                        }
                        else {
                            this.appendOtherDependentRowsList.push({ rIndex: 1 });
                            arrLength = this.appendOtherDependentRowsList.length;
                            if (currentDependentSelectedControl) {
                                let currentSlControl: any = document.getElementById(currentDependentSelectedControl);
                                let selectedChildItem = currentSlControl.getAttribute('data-document-content-id');
                                let title = currentDependentSelectedControl;
                                if (document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.contains('addedAsChild') == true) {
                                    // currentSelectCtrl.options[0]?.setAttribute('Selected', 'selected');
                                    // currentSelectCtrl.selectedIndex = 0;
                                    this.toastrService.warning("The selected value is an existing dependent control.", 'Warning');
                                }
                                else {
                                    document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.add('alreadyDependentField');
                                    document.querySelector('[data-document-content-id="' + selectedChildItem + '"]')?.classList.add('addedAsChild');
                                    let addedAsDependentFieldsArray = this.addedAsDependentFields.filter(function (obj: any) { return obj.value == selectedChildItem });
                                    if (addedAsDependentFieldsArray != null && addedAsDependentFieldsArray.length == 0) {
                                        this.addedAsDependentFields.push({ parentItem: selectedParentItem, title: title, value: selectedChildItem, rowIndex: 0 });
                                    }
                                    this.tempDependencyControls.push({ parentControlId: selectedParentItem, dependentControlId: selectedChildItem });

                                    //Apply color to child
                                    let isChildrenPresent: any;
                                    let parentColor: any = '';
                                    let childItemField: any = document.getElementById(currentDependentSelectedControl);
                                    if (childItemField) {
                                        isChildrenPresent = childItemField.getAttribute('is-child-present');
                                    }

                                    let slControlingFieldObj: any = this.controllingFieldSelectList.filter(function (obj: any) { return obj.value.toLowerCase() == selectedParentItem.toLowerCase() });
                                    if (slControlingFieldObj && slControlingFieldObj.length > 0) {
                                        let parentItemField: any = document.getElementById("spn" + slControlingFieldObj[0].title);
                                        let parentDivField: any = document.getElementById(slControlingFieldObj[0].title);
                                        parentColor = parentItemField.style['background-color'];
                                        if (parentColor != '' && parentColor != "rgba(220, 220, 220, 1)" && parentColor != "rgb(220, 220, 220)" && parentColor != 'rgb(255, 255, 255)') {
                                            this.controlPropertyService.applyColorCodeForItem(title, parentColor);
                                        }
                                        else {
                                            let controlGroupCode: any = parentDivField.getAttribute('group-code');
                                            if (controlGroupCode != null && controlGroupCode != "" && controlGroupCode != undefined && controlGroupCode != "null") this.controlColorCodeIndex = controlGroupCode;
                                            else this.updateColorCode(null);
                                            this.controlPropertyService.applyColorCodeForItem(title, this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color);
                                            this.controlPropertyService.applyColorCodeForItem(slControlingFieldObj[0].title, this.commonConstants.DependencyColorCodes[parseInt(this.controlColorCodeIndex) % 10].Color);
                                        }
                                        if (isChildrenPresent == 'true') {
                                            let resizableItem: any = document.getElementById("resizable_" + title);
                                            resizableItem.style["border-width"] = '';
                                        }
                                        setTimeout(() => {
                                            this.populateControlFromDropdown(arrLength, selectedChildItem, slControlingField, "0");
                                        }, 70);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            // localStorage.setItem('dependCtrlMoved', 'false');
            this.sessionStorageService.setDependCtrlMoved('false');
        }
    }

    populateControlFromDropdown(arrLength: any, selectedChildItem: any, slControlingField: any, type: any) {
        let controlTypeID = "";
        let controlID = "";
        let currentDependentSelectCtrl: any = (document.getElementById("slDependentFieldSelectList_" + (arrLength - 1))) as HTMLSelectElement;
        let slIndex: any;
        if (currentDependentSelectCtrl && currentDependentSelectCtrl.options.length > 0) {
            for (let index = 0; index < currentDependentSelectCtrl.options.length; index++) {
                const element = currentDependentSelectCtrl.options[index];
                if (element.value.toLowerCase() == selectedChildItem.toLowerCase()) {
                    currentDependentSelectCtrl.options[index]?.setAttribute('Selected', 'selected');
                    currentDependentSelectCtrl.selectedIndex = index;
                    currentDependentSelectCtrl.options[index]?.setAttribute('conditionid', '00000000-0000-0000-0000-000000000000');
                    slIndex = index;
                    break;
                }
            }
        }

        controlTypeID = slControlingField[0].DataGuid;
        controlID = slControlingField[0].ControlId;
        let currentDependentConditionsSelect: any = (document.getElementById("slDependentConditionsSelectList_" + (arrLength - 1))) as HTMLSelectElement;
        if (controlTypeID.toLowerCase() == Control.Radio.toLowerCase()) { //RADIO
            if (currentDependentConditionsSelect) currentDependentConditionsSelect.setAttribute('disabled', 'disabled');
            this.hideTextField(controlTypeID, (arrLength - 1));
        }
        else if (controlTypeID.toLowerCase() == Control.DropDown.toLowerCase() || controlTypeID.toLowerCase() == Control.Checkbox.toLowerCase() || controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
            if (currentDependentConditionsSelect) currentDependentConditionsSelect.removeAttribute('disabled');
            if (controlTypeID.toLowerCase() == Control.Text.toLowerCase()) {
                let spnCurrentTextControl = (<HTMLInputElement>document.getElementById("resizable_" + controlID));
                let isRequiredCtrl = false;
                if (spnCurrentTextControl) {
                    let isDataSelected: any = spnCurrentTextControl.getAttribute('data-selected');
                    if (isDataSelected == true || isDataSelected == "true") { isRequiredCtrl = true; }
                }
                if (isRequiredCtrl) this.showTextField('Specific Text', (arrLength - 1), TextRuleCondtionsConfig.SpecificText);
                else this.showTextField('Any Text', (arrLength - 1), TextRuleCondtionsConfig.AnyText);
            }
            else
                this.hideTextField(controlTypeID, (arrLength - 1));
        }

        if (type == "1")
            this.checkIsAlreadyAddedAsDependent(arrLength - 1, slIndex);
    }

    updateColorCode(currentSelectedControl: any) {
        // let groupCodeArrayUpdated: any = [];
        let isChildPresentArray: any = document.querySelectorAll('[is-child-present="' + true + '"]');
        if (isChildPresentArray && isChildPresentArray.length > 0) {
            let controllingFieldArray: any = document.querySelectorAll('.controllingField');
            if (controllingFieldArray && controllingFieldArray.length > 0) {
                Array.from(controllingFieldArray).forEach((item: any, v: any) => {
                    let attr_group_code: any = item.getAttribute('group-code');
                    if (attr_group_code && attr_group_code != '' && attr_group_code != 'null' && attr_group_code != undefined) {
                        if (!this.groupCodeArrayUpdated.includes(parseInt(attr_group_code))) {
                            this.groupCodeArrayUpdated.push(parseInt(attr_group_code));
                        }
                    }
                });

                if (this.groupCodeArrayUpdated.length == 0) this.controlColorCodeIndex = 1;
                else this.controlColorCodeIndex = Math.max(...this.groupCodeArrayUpdated) + 1;

                this.groupCodeArrayUpdated.push(this.controlColorCodeIndex);
            }
        }
        else {
            this.controlColorCodeIndex = this.dependencyIconClickedObj?.groupColorCode;
        }

        if (currentSelectedControl) {
            let currentSelectedControlElement: any = document.getElementById(currentSelectedControl);
            if (currentSelectedControlElement)
                currentSelectedControlElement.setAttribute("temp-group-code", this.controlColorCodeIndex);
        }
    }
}
