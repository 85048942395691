import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EmbeddedViewRef, EventEmitter, Injector, OnInit, Output, Renderer2, TemplateRef, ViewChild, AfterViewInit, enableProdMode } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, fromEvent, takeUntil } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LocationStrategy, PlatformLocation } from '@angular/common';
import { ActionTypes, CommonConstants, Control, ControlConfig, ControlResourceKeys, EnvelopeType, RSignStage, RecipientType } from '../../constants/common-constants';
import { CommonService } from 'src/app/services/common.service';
import { ControlComponent } from '../../components/control/control.component';
import { AddDropdownListComponent } from '../../modals/add-dropdown-list/add-dropdown-list.component';
import { ViewSettingComponent } from '../../modals/view-setting/view-setting.component';
import { AddDependenciesComponent } from '../../modals/add-dependencies/add-dependencies.component';
import { ClearallControlsComponent } from '../../modals/clearall-controls/clearall-controls.component';
import { DiscardEnvelopeComponent } from '../../modals/discard-envelope/discard-envelope.component';
import { GotoStepOneComponent } from '../../modals/goto-step-one/goto-step-one.component';
import { KeyboardShortcutsComponent } from '../../modals/keyboard-shortcuts/keyboard-shortcuts.component';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { AlignControlsService } from '../../services/align-controls.service';
import { ContextualMenu } from '../contextual-menu/contextual-menu';
import { APIService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultControlStyleService } from '../../services/default-control-style.service';
import { HostListener, isDevMode } from '@angular/core';
import { MultiBrandingComponent } from 'src/app/modals/multi-branding/multi-branding.component';
import { TranslationService } from 'src/app/services/translation.service';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { PaginationComponent } from 'src/app/shared/pagination/pagination.component';
import { MultipleSignerUpdateComponent } from 'src/app/modals/multiple-signer-update/multiple-signer-update.component';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { Title } from '@angular/platform-browser';
import { InitialsCreationComponent } from 'src/app/modals/initials-creation/initials-creation.component';
import { CashedService } from 'src/app/services/cashed.service';
import { UndoRedoStackService } from 'src/app/services/undo-redo-stack.service';
declare var $: any;

type IMenu = {
    title: string;
    id: number,
    svgPath1: any;
    svgPath2: any;
    svgPath3: any;
    viewBox: any;
    temp?: boolean;
    langGuid: any;
};

@Component({
    selector: 'app-template-prepare',
    templateUrl: './template-prepare.component.html',
    styleUrls: ['./template-prepare.component.scss']
})
export class TemplatePrepareComponent {
    baseUrl = environment.apiBaseUrl;
    showLoader: boolean = false;
    commonConstants = CommonConstants;
    resourceKeys = ResourceKeys;
    @Output() documentData: any;
    @Output() receipentListArr: any = this.commonConstants.ReceipentListArr;
    @Output() receipentColorArr: any = this.commonConstants.ReceipentColorArr;
    @Output() recipientsObj: any;
    @Output() dependencyIconClickedObj: any;

    @ViewChild('documentPageContainer', { static: false })
    documentPageContainer!: ElementRef;
    textzoom = parseInt('100%');

    private ngUnsubscribe = new Subject<void>();
    private dynamicComponentRefs: Map<string, ComponentRef<ControlComponent>> = new Map();
    private clickTimeout: any;
    private delay = 300; // Adjust the delay time as needed
    hidePagesViewSection: boolean = false;
    hideControlView: boolean = false;
    displayProperties: boolean = true;
    isVisited: boolean = false;
    isUnderlined: boolean = false;
    isItalic: boolean = false;
    isBold: boolean = false;
    isAlignleft: boolean = false;
    isAlignmiddle: boolean = false;
    isAlignright: boolean = false;
    isfont: boolean = false;
    displayGroupCheckBox: boolean = false;
    displayGroupRadio: boolean = false;
    showRemove: boolean = false;
    showSave: boolean = true;
    isClose: boolean = false;
    isActive: boolean = false;

    selectDefaultSizeValue: any;
    selectDefaultDateFormat: any;
    selectValidation: any;
    selectAddtionalValidation: any;
    datacount: any;
    activeCtrlId: any;

    checkboxoptions = this.commonConstants.Checkboxoptions;
    validationsoptions = this.commonConstants.Validationsoptions;
    additionalValidation = this.commonConstants.AdditionalValidation;
    additionalValidationType = this.commonConstants.AdditionalValidationType;
    validationsZipOptions = this.commonConstants.ValidationsZipOptions;
    validationsDateOptions = this.commonConstants.ValidationsDateOptions;
    dateformat = this.commonConstants.Dateformat;
    dateformatValue = this.commonConstants.DateformatValue;
    controlsObj = this.commonConstants.ControlsObj;
    defaultColorPicker = this.commonConstants.DefaultColorPicker;
    ZoomPercentageObj = this.commonConstants.ZoomPercentage;
    groupColorCode: any = 1;
    hostListenerStatus: any = false;

    selectedRecipentId: string = '';
    selectedPanelRecipentId: string = '';
    selectedRecipentName: string = '';
    selectedRecipentColor: string = '';
    selectedPanelRecipentName: string = '';
    selectedPanelRecipentColor: string = '';
    searchText = '';
    selectedPanelRecipentTextAreaText: string = '';
    checkboxText: string = 'Save as Default';

    checkboxSizeControl = new FormControl(3);

    form = new FormGroup({
        checkboxsize: this.checkboxSizeControl
    });

    // dragAnddrop code starts
    dragItems: Array<IMenu> = this.commonConstants.DragItems;
    table: Array<IMenu> = [];
    imagesArr: any[] = [];
    // dragAnddrop code ends
    documentName: any;
    previewObj: any;
    dragItemBgColor: any;
    currentControlId: any;
    zoomList: any[] = [];
    percentageValue: string = '100%';
    imageSize: any = '100%';
    arrControlIdNumber: any = [];
    controlFieldDependenceControlArray: any = [];
    displayDependenciesButton: boolean = false;
    zoomValue: any = 100;
    exampleForm: FormGroup;
    slDropDownControlItems: any = [];
    isRequiredChecked: boolean = false;
    isFixedWidthChecked: boolean = false;
    showTextFieldsContainer: any = 'none';
    showTextDecimalType: any = 'none';
    showTextDecimalOptions: any = 'none';
    showTextZipFormat: any = 'none';
    showTextZipFormatOptions: any = 'none';
    showTextDateFormat: any = 'none';
    showTextDateFormatOptions: any = 'none';
    arrCopyPasteCtrl: any = [];
    arrUndoRedoCtrl: any = [];
    envelope: any;
    envelopeCultureInfo: any;
    integrationResponse: any = {};
    isSelecting: boolean = false;
    selectionBox: any = HTMLElement;
    @ViewChild('container') container!: ElementRef;

    startPointX: any;
    startPointY: any;
    endPointX: any;
    endPointY: any;
    selectedItems: any[] = [];
    selectedDocumentImage: any; //to get the document image id

    planType: any = '';
    planRange: any = '';
    planName: any = '';
    fullPlanName: any = '';
    unit: any = '';
    unitsRemaining: any = '';
    upgradeLink: any = '';
    showUpgradeLink: boolean = false;
    allImagesProcessed: boolean = false;
    lastImageId: any;
    totalDocPageImagesCount: any;
    subscription: any;
    PageZoomClass: any;
    textareaEdited: boolean = false;
    isDataLoaded: boolean = false;
    isEnvelopeEdited: boolean = false;
    signatureControlRequiredForStaticTemplate: any = "false";
    isCreateRules: any = "false";
    envelopeStage: any = 'PrepareEditTemplate';
    isDisplayTemplateRule: any = "false";
    externalIntegrationType = '';
    externalEntityList: any[] = [];
    externalEntityFieldList: any[] = [];
    userSelectedEntity: boolean = true;
    isPrefillExists: boolean = false;
    enableDependenciesFeature: boolean = false;
    isStoreControlId: boolean = false;
    isEnvelopeEditable: boolean = true;
    signatureControlRequired: boolean = true;
    enableFileReview: boolean = false;
    filesShowDiv: boolean = true;
    filesReviewCount: number = 0;
    filesReview: any[] = [];
    hideFixedwidthCheckbox: any = true;
    lengthOrMaxCharLimitLabel: string = 'Length';
    // saveAsDefaultCheckbox: boolean = true;
    defaultFontFamily: string = 'Courier';
    defaultTypographySize: string = "px";
    defaultUserSettingsTypographySize: string = "px";
    defaultFontSize: any = 12;
    updateSaveAsDefaultChecked: any = true;
    isRadioRequiredChecked: boolean = false;
    isdrpFirstLineBlank: boolean = false;
    isChkPreSelected: boolean = false;
    ischeckboxGroup: boolean = false;
    checkboxGroupName: any = '';
    checkboxName: any = '';
    hideControlViewSection: boolean = true;
    arrCopyPasteCtrlsTemp: any[] = [];
    isSendEnvelope: boolean = false;
    textCtrlMaxValidation: any = 4000;
    userToken: any;
    disableNavigationBar: any = 'false';
    disableBackArrowButton: any = 'false';
    disableSaveDraftButton: any = 'false';
    enableFieldAlignmentClass: any = "false";
    @ViewChild(PaginationComponent) paginationComponent: PaginationComponent;
    isPageLoadingFirstTime: any = true;
    isAddColumnSpace: any;
    bodyElement: HTMLElement = document.body;
    isTemplate: boolean = false;
    isCopiedSelecteditems: boolean = false;
    hideDependencies: any = true;
    dependecieClose: any;
    arrUndoCtrlsCount: any[] = [];
    isTemplateSaveDropdown: boolean = false;
    private valueChangesSubscription: Subscription;
    depenciestatus: boolean = false;
    isStaticTemplate: any = false;
    enableMultiBranding: boolean = true;
    branding: any;
    defaultBranding: any;
    slides: any[] = [];
    tempTotalCountPerPage: any[] = [];
    isCtrlAndControlMouseSelection: any = false;
    ctrlAndClickedControlsArr: any = [];
    isPortrait: boolean = false;
    isLandscape: boolean = false;
    conversionIteration: number = 0;
    //undo and redo variables starts
    undoRedoItems: any[] = [];
    copiedContent: any;
    allControlsArr: any[] = [];
    undoFlag: any = 'false';
    redoFlag: any = 'false';
    isMultipleControlsActionFlag: boolean = false;
    copyPastedcontrolsArr: any[] = [];
    copyPastedcontrolsArrFlag: any = false;
    parseDefaultControlProperty: any;
    temptotalControlCount: any = 0;
    deleteCtrlItemsFlag: boolean = false;
    //undo redo variables ends
    asteriskOriginalText: string = ''; // related to hide text with asterisks.
    hiddenText: string = '';
    isCheckboxDisabled: boolean = false

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
        public modalService: BsModalService,
        private toastrService: ToastrService,
        private commonService: CommonService,
        private renderer2: Renderer2,
        private fb: FormBuilder,
        private controlPropertyService: ControlPropertyService,
        private authService: AuthService,
        private alignControlsService: AlignControlsService,
        private location: PlatformLocation,
        private apiService: APIService,
        private router: Router,
        private defaultControlStyleService: DefaultControlStyleService,
        private sessionStorageService: SessionStorageService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private cashedService: CashedService,
        private undoRedoStackService: UndoRedoStackService
    ) { }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event: Event) {
        this.PostCall(event);
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander(event: Event) {
        // let ClickedSignMeOut:any = localStorage.getItem("ClickedSignMeOut");
        let ClickedSignMeOut: any = this.sessionStorageService.getClickedSignMeOut();
        if (this.hostListenerStatus == true || ClickedSignMeOut == "true") {
            this.PostCall(event);
            //localStorage.clear();
            //sessionStorage.clear();
            return true;
        }
        else {
            return false;
        }
    }

    @HostListener('click', ['$event.target']) onClick(e: any) {
        if (e && e.className && e.className.includes('dispImg')) {
            this.onClickOutsideSelectionArea();
        }
    }
    @HostListener('document:click', ['$event'])
    onSingleClick(event: KeyboardEvent) {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }

        this.clickTimeout = setTimeout(() => {
            this.handleSingleClick(event);
            this.clickTimeout = null;
        }, this.delay);
    }

    @HostListener('document:dblclick', ['$event'])
    onDoubleClick(event: KeyboardEvent) {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            this.handleDoubleClick(event);
        }
    }

    handleSingleClick(event: KeyboardEvent) {
        if (event && event.target != null) {
            let CurrentClickedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
            let spncontrolEle: any = (<HTMLInputElement>document.getElementById("spn" + CurrentClickedControl));
            let resizablecontrolEle: any = (<HTMLInputElement>document.getElementById("resizable_" + CurrentClickedControl));
            if (spncontrolEle && resizablecontrolEle) {
                let originalText: any = resizablecontrolEle.getAttribute('data-asteriskOriginalText');
                let clickedAttribute: any = spncontrolEle.getAttribute('data-clickedAttribute');
                let isHideTextWithAsterisk: any = resizablecontrolEle.getAttribute('data-isHideTextWithAsterisk');
                if (isHideTextWithAsterisk && (isHideTextWithAsterisk == 'true' || isHideTextWithAsterisk == true)) {
                    if (originalText) {
                        const asterisks = '*'.repeat(originalText.length); // Replace text with asterisks
                        if (spncontrolEle.innerHTML !== '*'.repeat(originalText.length)) {
                            spncontrolEle.innerHTML = asterisks;
                        }
                        spncontrolEle.classList.add('add-text-ellipsis');
                        if (!clickedAttribute && (clickedAttribute == 'false' || clickedAttribute == false)) {
                            spncontrolEle.setAttribute('data-clickedAttribute', true);
                        }
                    }
                }
            }
        }
    }

    handleDoubleClick(event: KeyboardEvent) {
    }
    @HostListener('document:click', ['$event'])
    handleClickEvent(event: KeyboardEvent) {
        if (this.clickTimeout) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }
        this.clickTimeout = setTimeout(() => {
            this.handleSingleClick(event);
            this.clickTimeout = null;
        }, this.delay);
        if (event.ctrlKey && event.target != null) {
            const targetElement = event.target as HTMLElement;
            if (this.isCtrlAndControlMouseSelection === true && event.ctrlKey != true) {
                this.selectedItems = [];
                this.commonService.selectedItems = [];
                this.ctrlAndClickedControlsArr = [];
            }
            if (targetElement.offsetParent) {
                const id = (targetElement.offsetParent as HTMLElement).id;
                if (id && id.startsWith('resizable_')) {
                    this.selectedItems.push(id);
                    this.isCtrlAndControlMouseSelection = false;
                    this.ctrlAndClickedControlsArr.push(id);
                    let resizableEle: any = document.getElementById(id);
                    if (resizableEle) {
                        resizableEle.style.background = 'bisque';
                        let length: any = this.selectedItems.length;
                        // localStorage.setItem('selecteditemsLength', length);
                        this.sessionStorageService.setSelecteditemsLength(length);
                        let attr_data_guid: any = resizableEle.getAttribute('data-guid');
                        let spnSelectEle: any = document.getElementById('spn' + id.replace('resizable_', ''));
                        if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                            spnSelectEle.classList.add('mouse-selected');
                        }
                        spnSelectEle.style.background = '';
                    }
                }
            }
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.controlPropertyService.handleKeyboardEvent(event, this.selectedItems);
    }

    PostCall(event: any) {
        //event.preventDefault();
    }

    get pickerCtrl() {
        return this.exampleForm.get('pickerCtrl') as FormControl;
    }

    @Output() elements: ContextualMenu[] = [
        // new ContextualMenu('Undo', () => console.log('test 1')),
        // new ContextualMenu('Redo', () => console.log('test 2')),
        new ContextualMenu('Copy', () => console.log('test 3')),
        new ContextualMenu('Paste', () => console.log('test 4')),
        // new ContextualMenu('Cut', () => console.log('test 5')),
        new ContextualMenu('Duplicate', () => console.clear()),
        new ContextualMenu('Delete', () => console.clear()),
    ];

    ngOnInit(): void {
        this.titleService.setTitle("RSign | Prepare Template");
        this.showLoader = true;

        let routeEnvelopeQueryParam: any = '';
        let routeEnvelopeId: any = '', originalSourceType: any = '';
        this.activatedRoute.queryParams.subscribe(params => {
            // console.log('encrypted==>', params['id']);
            let paramsId: any = params['id'];
            if (paramsId == undefined) {
                window.location.href = environment.rsignUrl + NavigateUrlConfig.Send;
            }
            else {
                routeEnvelopeQueryParam = this.commonService.decrypt(params['id']);
                routeEnvelopeId = routeEnvelopeQueryParam.split('&')[0];
                let originalDomainVal = routeEnvelopeQueryParam.split('&')[1];
                originalSourceType = originalDomainVal.split('=')[1];

                //console.log('decrypt==>', routeEnvelopeId);
                //console.log('OriginalSourceType==>', originalSourceType);

                this.sessionStorageService.setIntializePrepareDetails('Token', localStorage.getItem("Token"));
                this.sessionStorageService.setIntializePrepareDetails('PrepareType', "Template");
                this.sessionStorageService.setIntializePrepareDetails('EnvelopeOrTemplateId', routeEnvelopeId);
                this.sessionStorageService.setIntializePrepareDetails('SourceUrlType', originalSourceType);
                let sourceURL: any = this.commonService.generateDomainRediRectURL(originalSourceType);
                this.sessionStorageService.setIntializePrepareDetails('SourceURL', sourceURL);
                if (this.authService.isTokenExists() == "false") {
                    this.hostListenerStatus = true;
                    this.commonService.redirectToMainApp(sourceURL);
                }
                else {
                    enableProdMode();
                    this.selectedRecipentColor = this.receipentColorArr[0];
                    this.selectedPanelRecipentColor = this.receipentColorArr[0];
                    this.dragItemBgColor = this.receipentColorArr[0];
                    this.selectDefaultSizeValue = '2';
                    this.selectValidation = '1';
                    this.selectAddtionalValidation = '1';
                    this.resetPaginationCount();
                    this.recipientsObj = { selectedRecipentName: '', selectedRecipentColor: this.selectedRecipentColor }

                    this.dependencyIconClickedObj = {
                        type: '1',
                        groupColorCode: this.groupColorCode,
                        currentControlId: '',
                        isControlingField: true
                    }

                    this.exampleForm = this.fb.group({
                        pickerCtrl: [this.defaultColorPicker]
                    });

                    this.valueChangesSubscription = this.exampleForm.controls['pickerCtrl'].valueChanges.pipe(
                        debounceTime(300)
                    ).subscribe(color => {
                        //console.log('Picker control value changed:', color);
                        this.applyColorPicker(color);
                    });

                    this.getTemplateDetails();

                    // todo: need to remove else block once developement is done
                    //this.getDefaultValues();
                }
            }
        });
    }

    getTemplateDetails() {
        this.isTemplate = true;
        let preparePageUrlData = this.sessionStorageService.getIntializePrepareDetails();
        if (preparePageUrlData && preparePageUrlData.PreparePageUrl) {
            console.log("Template Prepare:" + preparePageUrlData.PreparePageUrl)
        }
        let envelopeTokenDetails: any = this.authService.getDetails();
        if (envelopeTokenDetails) {
            let tokenDetails = JSON.parse(envelopeTokenDetails);
            let prepareType: any = '', envelopeOrTemplateId: any;
            if (tokenDetails) {
                prepareType = tokenDetails.prepareType;
                envelopeOrTemplateId = tokenDetails.envelopeOrTemplateId;
                let getDetailsUrl: any = ApiUrlConfig.GetTemplateDetailsUrl + "?templateId=" + envelopeOrTemplateId;
                console.log("Calling GetTemplateDetails API:" + new Date().toLocaleString());
                //setTimeout(() => {
                this.apiService.get(getDetailsUrl).subscribe(
                    (resp: any) => {
                        if (resp && resp.statusCode == 200) {
                            console.log("GetTemplateDetails response loaded:" + new Date().toLocaleString());

                            let dropdownContenttemp: any = document.getElementsByClassName("dropdown-contenttemp");
                            if (dropdownContenttemp && dropdownContenttemp.length > 0) {
                                Array.from(dropdownContenttemp as HTMLCollectionOf<HTMLElement>).forEach((eleChild: any) => {
                                    eleChild.style.display = 'none';
                                })
                            }

                            if (resp && resp != '') {
                                this.authService.setUserDetails(resp.envelopeDetails.userViewModel);
                                this.commonService.updateHeader();

                                // if (resp.envelopeDetails.tokenViewModel) {
                                //     localStorage.setItem("RefreshTokenModel", JSON.stringify(resp.envelopeDetails.tokenViewModel));
                                // }

                                if (resp.envelopeDetails.disableOptions) {
                                    this.sessionStorageService.setDisableTabOptions(resp.envelopeDetails.disableOptions);
                                    this.commonService.getDisableNavBtns(resp.envelopeDetails.disableOptions);
                                }
                                this.sessionStorageService.setCulture(resp.envelopeDetails.cultureInfo)
                                if (resp.envelopeDetails.cultureInfo == "es-es") {
                                    let modifiedValidationsDateOptions: any[] = [];
                                    for (let index = 0; index < this.validationsDateOptions.length; index++) {
                                        modifiedValidationsDateOptions.push({ label: this.validationsDateOptions[index].label.replaceAll('y', 'a'), value: index + 1 });
                                    }
                                    this.validationsDateOptions = "";
                                    this.validationsDateOptions = modifiedValidationsDateOptions;
                                    console.log(this.validationsDateOptions);
                                    this.sessionStorageService.setValidationsDateOptions(this.validationsDateOptions);

                                    let modifiedDateFormatOptions: any[] = [];
                                    for (let index = 0; index < this.dateformat.length; index++) {
                                        modifiedDateFormatOptions.push({
                                            value: this.dateformat[index].value,
                                            viewValue: this.dateformat[index].viewValue.replaceAll('y', 'a').replaceAll('US', 'EEUU'),
                                            dateFormatId: this.dateformat[index].dateFormatId
                                        });
                                    }
                                    this.dateformat = "";
                                    this.dateformat = modifiedDateFormatOptions;
                                    this.sessionStorageService.setDateformat(this.dateformat);


                                    let modifiedDateFormatValueOptions: any[] = [];
                                    for (let index = 0; index < this.dateformatValue.length; index++) {
                                        modifiedDateFormatValueOptions.push({
                                            value: this.dateformatValue[index].value,
                                            viewValue: this.dateformatValue[index].viewValue.replaceAll('y', 'a')
                                        });
                                    }
                                    this.dateformatValue = "";
                                    this.dateformatValue = modifiedDateFormatValueOptions;

                                    console.log(this.dateformat);
                                    console.log(this.dateformatValue);
                                    this.sessionStorageService.setDateformatValue(this.dateformatValue);
                                }
                                else {
                                    this.validationsDateOptions = this.commonConstants.ValidationsDateOptions;
                                    this.dateformat = this.commonConstants.Dateformat;
                                    this.dateformatValue = this.commonConstants.DateformatValue;
                                    this.sessionStorageService.setValidationsDateOptions(this.validationsDateOptions);
                                    this.sessionStorageService.setDateformat(this.dateformat);
                                    this.sessionStorageService.setDateformatValue(this.dateformatValue);
                                }

                                this.envelope = resp;
                                if (this.envelope && this.envelope.envelopeId != null && this.envelope.envelopeId != '' && this.envelope.envelopeDetails && this.envelope.envelopeDetails.envelopeImageInfo) {
                                    console.log(this.envelope.envelopeId != null && this.envelope.envelopeId != '' ? "TemplateId:" + this.envelope.envelopeId.toLowerCase() : '')
                                    console.log("TemplateCode:" + this.envelope.envelopeDetails.templateCode);

                                    this.isStaticTemplate = this.envelope.envelopeDetails.isStatic;
                                    this.hideFixedwidthCheckbox = this.envelope.envelopeDetails.hideFixedwidthCheckbox;
                                    this.controlPropertyService.setHideFixedwidthCheckbox(this.hideFixedwidthCheckbox);
                                    this.integrationResponse = this.envelope;
                                    this.isEnvelopeEdited = (this.envelope.envelopeDetails.isEdited != null && this.envelope.envelopeDetails.isEdited != false) ? true : false; //Update and Resend
                                    this.signatureControlRequiredForStaticTemplate = this.envelope.envelopeDetails.signatureControlRequiredForStaticTemplate;
                                    //console.log("signatureControlRequiredForStaticTemplate:" + this.signatureControlRequiredForStaticTemplate);
                                    this.isCreateRules = this.envelope.envelopeDetails.isCreateRules;
                                    this.envelopeStage = this.envelope.envelopeDetails.envelopeStage;
                                    let documentImageConversionMaxLimit: any = this.envelope.envelopeDetails.documentImageConversionMaxLimit; //CommonConstants.DocumentImageConversionMaxLimit

                                    if (this.envelopeStage == "PrepareEditTemplate" && (this.isStaticTemplate == true || this.isCreateRules == 'false'))
                                        this.isDisplayTemplateRule = "true";
                                    else this.isDisplayTemplateRule = "false";

                                    //when refreshing the browser
                                    // let allImagesProcessed: any = localStorage.getItem("AllImagesProcessed");
                                    let allImagesProcessed: any = this.sessionStorageService.getAllImagesProcessed();
                                    if (allImagesProcessed == null || allImagesProcessed == "false") this.allImagesProcessed = false;
                                    else if (allImagesProcessed && allImagesProcessed != null && allImagesProcessed == "true") this.allImagesProcessed = false;

                                    // if (this.allImagesProcessed == true) {
                                    //     // let allImagesArray: any = localStorage.getItem("AllImagesArray");
                                    //     let allImagesArray: any = this.sessionStorageService.getAllImagesArray();
                                    //     allImagesArray = JSON.parse(allImagesArray);
                                    //     if (allImagesArray && allImagesArray.length > 0) {
                                    //         this.imagesArr = [];
                                    //         this.totalDocPageImagesCount = allImagesArray.length;
                                    //         this.imagesArr.length = this.totalDocPageImagesCount - 1;
                                    //         let conversionImagesArray: any = [];
                                    //         allImagesArray.forEach((element: any) => {
                                    //             let path: any = "?id=" + element.id + "&envelopeId=" + this.envelope.envelopeDetails.envelopeID + "&uncPath=" + this.envelope.envelopeDetails.uncPath;
                                    //             let imgPath: any = this.baseUrl + ApiUrlConfig.GetTemplateImages + path;

                                    //             if (element.id <= documentImageConversionMaxLimit) {

                                    //             }
                                    //             else {
                                    //                 conversionImagesArray.push({
                                    //                     imagePathUrl: imgPath,
                                    //                     id: element.id,
                                    //                     documentId: element.documentId,
                                    //                     width: element.width,
                                    //                     height: element.height,
                                    //                     isTemplateDatedBeforePortraitLandscapeFeature: element.isTemplateDatedBeforePortraitLandscapeFeature,
                                    //                     docPageNo: element.docPageNo,
                                    //                     document: {
                                    //                         id: element.document.id,
                                    //                         name: element.document.name
                                    //                     },
                                    //                     controlsCount: 0
                                    //                 });
                                    //             }
                                    //             this.imagesArr[element.id - 1] = {
                                    //                 path: imgPath,
                                    //                 isActive: false,
                                    //                 id: element.id,
                                    //                 imageId: element.id,
                                    //                 documentId: element.documentId,
                                    //                 width: element.width,
                                    //                 height: element.height,
                                    //                 isVisible: true,
                                    //                 isTemplateDatedBeforePortraitLandscapeFeature: element.isTemplateDatedBeforePortraitLandscapeFeature,
                                    //                 docPageNo: element.docPageNo,
                                    //                 document: {
                                    //                     id: element.document.id,
                                    //                     name: element.document.name
                                    //                 },
                                    //                 controlsCount: 0
                                    //             };
                                    //         });

                                    //         if (conversionImagesArray && conversionImagesArray.length > 0) {
                                    //             this.addPaginationDisabledClass();
                                    //             this.allImagesProcessed = false;
                                    //             this.checkForAllImagesLoaded(conversionImagesArray, documentImageConversionMaxLimit, "fromrefresh");
                                    //         }
                                    //         else {
                                    //             this.updateLocalStorageInfo();
                                    //             //this.updateEnvelopeDocumentControlInformation();
                                    //         }
                                    //     }
                                    // }
                                    //else {
                                    //Get envelope Image Information

                                    if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.envelopeImageInfo) {
                                        let envelopeImageDetails: any = this.envelope.envelopeDetails.envelopeImageInfo;
                                        if (envelopeImageDetails.length > 0) {
                                            this.imagesArr = [];
                                            let conversionInitialImagesArray: any = [];
                                            for (let index = 0; index < envelopeImageDetails.length; index++) {
                                                const element = envelopeImageDetails[index];
                                                //initially get documentImageConversionMaxLimit pages only for get the correct order of images
                                                let width: any = '';
                                                if (element.id <= documentImageConversionMaxLimit) {
                                                    let path: any = "?id=" + element.id + "&envelopeId=" + this.envelope.envelopeDetails.envelopeID + "&uncPath=" + this.envelope.envelopeDetails.uncPath;
                                                    let imagePathUrl: any = this.baseUrl + ApiUrlConfig.GetTemplateImages + path;

                                                    if (element.isTemplateDatedBeforePortraitLandscapeFeature == false) {
                                                        if (element.dimension.width > element.dimension.height) {
                                                            width = "1015px";
                                                            this.isLandscape = true;
                                                        }
                                                        else {
                                                            this.isPortrait = true;
                                                            width = "915px";
                                                        }
                                                    }
                                                    else if (element.isTemplateDatedBeforePortraitLandscapeFeature == true) {
                                                        width = '950px';
                                                        this.isLandscape = true;
                                                    }
                                                    this.imagesArr.push({
                                                        path: imagePathUrl, isActive: false, id: index + 1, imageId: element.id, documentId: element.document.id, width: width, height: element.dimension.height,
                                                        isVisible: true, isTemplateDatedBeforePortraitLandscapeFeature: element.isTemplateDatedBeforePortraitLandscapeFeature,
                                                        docPageNo: element.docPageNo,
                                                        document: {
                                                            id: element.document.id,
                                                            name: element.document.name
                                                        },
                                                        controlsCount: 0
                                                    });

                                                    if (element.id > 10) {
                                                        conversionInitialImagesArray.push({
                                                            imagePathUrl: imagePathUrl, id: index + 1, imageId: element.id, documentId: element.document.id, width: width, height: element.dimension.height,
                                                            isTemplateDatedBeforePortraitLandscapeFeature: element.isTemplateDatedBeforePortraitLandscapeFeature,
                                                            docPageNo: element.docPageNo,
                                                            document: {
                                                                id: element.document.id,
                                                                name: element.document.name
                                                            },
                                                            controlsCount: 0
                                                        });
                                                    }
                                                }
                                                if (index == 0) { this.previewObj = { 'documentName': element.document.name != '' ? element.document.name : '' } }
                                            }

                                            if (conversionInitialImagesArray && conversionInitialImagesArray.length > 0) {
                                                this.addPaginationDisabledClass();
                                                this.allImagesProcessed = false;
                                                this.checkForAllImagesLoaded(conversionInitialImagesArray, documentImageConversionMaxLimit, "InitalLoad");
                                            }
                                        }
                                    }

                                    this.totalDocPageImagesCount = this.envelope.envelopeDetails.totalDocPageImagesCount;
                                    if (this.imagesArr && this.imagesArr.length > 0) {
                                        if (this.totalDocPageImagesCount != this.imagesArr.length && this.allImagesProcessed == false) {
                                            this.allImagesProcessed = false;
                                            if (this.allImagesProcessed == false) {
                                                let initialImagesArrayLength = this.imagesArr.length;
                                                this.imagesArr.length = this.totalDocPageImagesCount - 1;
                                                for (let index = initialImagesArrayLength; index <= this.totalDocPageImagesCount - 1; index++) {
                                                    let imgIndex: Number = Number(index) + 1;
                                                    let path: any = "?id=" + imgIndex + "&envelopeId=" + this.envelope.envelopeDetails.envelopeID + "&uncPath=" + this.envelope.envelopeDetails.uncPath;
                                                    let imagePathUrl: any = this.baseUrl + ApiUrlConfig.GetTemplateImages + path;

                                                    var currentImgItem: any = this.envelope.envelopeDetails.envelopeImageInfo[index];
                                                    let width: any = '100%', height: any = '', imgObj: any, documentId: any = '', docPageNo: any = '', isTemplateDatedBeforePortraitLandscapeFeature = false;
                                                    let documentObj: any = { id: '', name: '' };

                                                    if (currentImgItem) {
                                                        if (currentImgItem.isTemplateDatedBeforePortraitLandscapeFeature == false) {
                                                            if (currentImgItem.dimension.width > currentImgItem.dimension.height) width = "1015px";
                                                            else width = "915px";
                                                        }
                                                        else if (currentImgItem.isTemplateDatedBeforePortraitLandscapeFeature == true) {
                                                            width = '950px';
                                                        }
                                                        height = currentImgItem.dimension.height;
                                                        documentId = currentImgItem.document.id;
                                                        isTemplateDatedBeforePortraitLandscapeFeature = currentImgItem.isTemplateDatedBeforePortraitLandscapeFeature;
                                                        docPageNo = currentImgItem.docPageNo;
                                                        documentObj = { id: currentImgItem.document.id, name: currentImgItem.document.name };
                                                    }

                                                    imgObj = {
                                                        path: imagePathUrl, isActive: false, id: imgIndex, imageId: imgIndex, documentId: documentId, width: width,
                                                        height: height, isVisible: false, isTemplateDatedBeforePortraitLandscapeFeature: isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: docPageNo,
                                                        document: documentObj,
                                                        controlsCount: 0
                                                    };

                                                    this.imagesArr[index] = imgObj;

                                                    setTimeout(() => {
                                                        let imgControl: any = document.getElementById("imgControl_" + imgIndex);
                                                        if (imgControl && imgControl != null) {
                                                            imgControl.style.display = 'none';
                                                        }
                                                    }, 20);
                                                }
                                                // console.log("All " + this.totalDocPageImagesCount + " images are not loaded");
                                                this.addPaginationDisabledClass();
                                                let timeoutVal: any = this.totalDocPageImagesCount.length >= 50 ? 10000 : 3000;
                                                setTimeout(() => {
                                                    this.retriveConversionImages(initialImagesArrayLength);
                                                }, timeoutVal);
                                            }
                                        }
                                        else {
                                            setTimeout(() => {
                                                this.updateLocalStorageInfo();
                                            }, 1500);
                                        }
                                    }
                                    //}

                                    this.isPrefillExists = false;
                                    // get template roles
                                    if (this.isTemplate && this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.roleList) {
                                        let roleDetails: any = this.envelope.envelopeDetails.roleList.filter((rec: any) => (rec.roleTypeID.toLowerCase() == RecipientType.Signer || rec.roleTypeID.toLowerCase() == RecipientType.Prefill));
                                        if (roleDetails && roleDetails.length > 0) {
                                            if (roleDetails.length > 100) {
                                                for (let index = 101; index <= roleDetails.length; index++) {
                                                    this.receipentColorArr.push(this.receipentColorArr[Math.round(Math.random() * 24)]);
                                                }
                                            }
                                        }

                                        this.receipentListArr = [];
                                        let prefillRecpDetails: any = this.envelope.envelopeDetails.roleList.filter((rec: any) => (rec.roleTypeID.toLowerCase() == RecipientType.Prefill));
                                        if (prefillRecpDetails && prefillRecpDetails.length > 0) {
                                            prefillRecpDetails.forEach((precp: any) => {
                                                let recpName = precp.roleName + this.commonConstants.PrefillNotation;
                                                this.receipentListArr.push({ id: precp.id, name: recpName, order: precp.order });
                                            });
                                            this.isPrefillExists = true;
                                        }

                                        roleDetails.forEach((recp: any) => {
                                            if (recp.roleTypeID.toLowerCase() != RecipientType.Prefill) {
                                                this.receipentListArr.push({ id: recp.id, name: recp.roleName, order: recp.order });
                                            }
                                        });

                                        this.authService.setRecipientColorDetails(this.receipentColorArr);
                                        this.authService.setRecipientDetails(this.receipentListArr);
                                        this.selectedRecipentName = this.receipentListArr[0].name;
                                        this.selectedPanelRecipentName = this.receipentListArr[0].name;
                                        this.selectedRecipentId = this.receipentListArr[0].id;
                                        this.selectedPanelRecipentId = this.receipentListArr[0].id;
                                        this.recipientsObj = {
                                            selectedRecipentName: this.selectedRecipentName,
                                            selectedRecipentColor: this.selectedRecipentColor
                                        }
                                    }
                                    //Get envelope recipients Information
                                    if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.recipientList) {
                                        let recpDetails: any = this.envelope.envelopeDetails.recipientList.filter((rec: any) => (rec.recipientTypeID.toLowerCase() == RecipientType.Signer || rec.recipientTypeID.toLowerCase() == RecipientType.Prefill));
                                        if (recpDetails && recpDetails.length > 0) {
                                            if (recpDetails.length > 100) {
                                                for (let index = 101; index <= recpDetails.length; index++) {
                                                    this.receipentColorArr.push(this.receipentColorArr[Math.round(Math.random() * 24)]);
                                                }
                                            }

                                            this.receipentListArr = [];
                                            let prefillRecpDetails: any = this.envelope.envelopeDetails.recipientList.filter((rec: any) => (rec.recipientTypeID.toLowerCase() == RecipientType.Prefill));
                                            if (prefillRecpDetails && prefillRecpDetails.length > 0) {
                                                prefillRecpDetails.forEach((precp: any) => {
                                                    let recpName = precp.recipientName + this.commonConstants.PrefillNotation;
                                                    this.receipentListArr.push({ id: precp.id, name: recpName, order: precp.order });
                                                });
                                                this.isPrefillExists = true;
                                            }

                                            recpDetails.forEach((recp: any) => {
                                                if (recp.recipientTypeID.toLowerCase() != RecipientType.Prefill) {
                                                    this.receipentListArr.push({ id: recp.id, name: recp.recipientName, order: recp.order });
                                                }
                                            });

                                            this.authService.setRecipientColorDetails(this.receipentColorArr);
                                            this.authService.setRecipientDetails(this.receipentListArr);
                                            this.selectedRecipentName = this.receipentListArr[0].name;
                                            this.selectedPanelRecipentName = this.receipentListArr[0].name;
                                            this.selectedRecipentId = this.receipentListArr[0].id;
                                            this.selectedPanelRecipentId = this.receipentListArr[0].id;
                                            this.recipientsObj = {
                                                selectedRecipentName: this.selectedRecipentName,
                                                selectedRecipentColor: this.selectedRecipentColor
                                            }
                                        }
                                    }

                                    // let disableButtonStr: any = localStorage.getItem("DisableStr");
                                    let disableButtonStr: any = this.sessionStorageService.getDisableTabOptions();
                                    if (disableButtonStr && disableButtonStr != '' && disableButtonStr != null) {
                                        let splitDisableButtonArr: any = JSON.parse(disableButtonStr);
                                        if (splitDisableButtonArr) {
                                            if (splitDisableButtonArr.DisableNavigationBar == true) this.disableNavigationBar = 'true';
                                            if (splitDisableButtonArr.DisableBackArrow == true) this.disableBackArrowButton = 'true';
                                            if (splitDisableButtonArr.DisableSaveDraft == true) this.disableSaveDraftButton = 'true';
                                        }
                                    }
                                    this.enableMultiBranding = this.envelope.envelopeDetails.enableMultiBranding;
                                    if (this.enableMultiBranding) {
                                        this.getBrandingDetails();
                                        let isAdddropBorder: any = document.getElementById('isAdddropBorder');
                                        if (isAdddropBorder) {
                                            isAdddropBorder.classList.add('drop-border');
                                        }
                                    }

                                    //Get envelope details
                                    this.selectDefaultDateFormat = this.controlPropertyService.getDefaultDateOption(this.envelope.envelopeDetails.dateFormatID);
                                    this.envelopeCultureInfo = this.envelope.envelopeDetails.cultureInfo;

                                    // this.updateEnvelopeInformation();
                                    let defaultControlProperty: any = this.envelope.envelopeDetails.defaultControlStyle;
                                    if (defaultControlProperty && defaultControlProperty !== '') {
                                        this.parseDefaultControlProperty = JSON.parse(defaultControlProperty);
                                        this.parseDefaultControlProperty = this.defaultControlStyleService.removeLowercaseDefaultStyles(this.parseDefaultControlProperty);
                                        this.sessionStorageService.setDefaultControlStyles(this.parseDefaultControlProperty);
                                        this.updatedSaveAsDefaultSavedData(this.parseDefaultControlProperty, this.envelopeCultureInfo);
                                    }

                                    this.isEnvelopeEditable = true; //this.envelope.envelopeDetails.isTemplateEditable === true ? true : false;
                                    this.enableDependenciesFeature = this.envelope.envelopeDetails.enableDependenciesFeature;
                                    this.isStoreControlId = this.envelope.envelopeDetails.isStoreControlId;
                                    this.enableFileReview = this.envelope.envelopeDetails.enableFileReview;
                                    this.controlPropertyService.enableShowControlId(this.isStoreControlId);
                                    this.defaultUserSettingsTypographySize = this.envelope.envelopeDetails.typography;
                                    this.defaultTypographySize = this.envelope.envelopeDetails.typography;
                                    if (this.envelope.envelopeDetails.typography == null || this.envelope.envelopeDetails.typography == undefined) this.defaultTypographySize = "px";
                                    else this.defaultTypographySize = this.envelope.envelopeDetails.typography;
                                    this.autoSaveEnvasDraft();
                                    this.signatureControlRequired = this.envelope.envelopeDetails.signatureControlRequired;
                                    // localStorage.setItem("isEnvelopeEditable", this.isEnvelopeEditable == true ? "true" : "false");
                                    this.sessionStorageService.setIsEnvelopeEditable(this.isEnvelopeEditable == true ? 'true' : 'false');
                                    this.sessionStorageService.setEnableDependenciesFeature(this.enableDependenciesFeature == true ? 'true' : 'false');
                                    //localStorage.setItem("enableDependenciesFeature", this.enableDependenciesFeature == true ? "true" : "false");
                                    this.handleConfigureDataEvents();
                                }
                                else {
                                    let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
                                    if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
                                        let sourceUrlType: any = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
                                        window.location.href = sourceUrlType + NavigateUrlConfig.Template;
                                    }
                                    else {
                                        window.location.href = environment.rsignUrl + NavigateUrlConfig.Template;
                                    }
                                }
                            }
                            else {
                                let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
                                if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
                                    let sourceUrlType: any = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
                                    window.location.href = sourceUrlType + NavigateUrlConfig.Template;
                                }
                                else {
                                    window.location.href = environment.rsignUrl + NavigateUrlConfig.Template;
                                }
                            }

                            this.documentData = { pages: this.imagesArr, totalImagesCount: this.totalDocPageImagesCount };
                            this.updateTempTotalCountPerPage();
                            this.commonService.updatePrevObject(
                                {
                                    'totalControls': 0, 'pageNumber': 1,
                                    'tempTotalCountPerPage': this.tempTotalCountPerPage,
                                    // 'documentList': this.imagesArr,
                                    'totalImagesCount': this.totalDocPageImagesCount
                                });
                            this.commonService.callMultiDocumentsPreview(this.imagesArr);
                            this.showLoader = false;
                        }
                        else if (resp && resp.originalError && resp.originalError.statusText && resp.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                        else {
                            this.showLoader = false;
                            let getUserDetails: any = this.authService.getUserDetails();
                            let userEmail: any = '';
                            if (getUserDetails && getUserDetails.emailID) {
                                userEmail = getUserDetails.emailID;
                            }
                            if (resp.authMessage) this.toastrService.warning(resp.authMessage, 'Warning');
                            else this.toastrService.warning(CommonConstants.TokenValidatedMsg, 'Warning');
                            this.sessionStorageService.setIntializePrepareDetails('EnvelopeID', envelopeOrTemplateId);
                            this.sessionStorageService.setIntializePrepareDetails('EmailId', userEmail);
                            this.sessionStorageService.setIntializePrepareDetails('PageName', 'initialize prepare template');

                            this.router.navigate(['/global-error']);
                        }
                    });
                //}, 10);
            }
        }
    }

    handleConfigureDataEvents() {

        // Getting control fields
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.activeCtrlId = this.getDragActiveID(data);
                let previousSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl()
                if (previousSelectedControl) {
                    let spncontrolEle: any = (<HTMLInputElement>document.getElementById("spn" + previousSelectedControl));
                    let resizablecontrolEle: any = (<HTMLInputElement>document.getElementById("resizable_" + previousSelectedControl));
                    let originalText: any = resizablecontrolEle.getAttribute('data-asteriskOriginalText');
                    let clickedAttribute: any = spncontrolEle.getAttribute('data-clickedAttribute');
                    let isHideTextWithAsterisk: any = resizablecontrolEle.getAttribute('data-isHideTextWithAsterisk');
                    if (isHideTextWithAsterisk && (isHideTextWithAsterisk == 'true' || isHideTextWithAsterisk == true)) {
                        if (originalText) {
                            const asterisks = '*'.repeat(originalText.length); // Replace text with asterisks
                            if (spncontrolEle.innerHTML !== '*'.repeat(originalText.length)) {
                                spncontrolEle.innerHTML = asterisks;
                            }
                            spncontrolEle.classList.add('add-text-ellipsis');
                            if (!clickedAttribute && (clickedAttribute == 'false' || clickedAttribute == false)) {
                                spncontrolEle.setAttribute('data-clickedAttribute', true);
                            }
                        }
                    }
                }
                // localStorage.setItem('CurrentClickedDroppedControl', data.id);
                this.sessionStorageService.setCurrentClickedDroppedControl(data.id);
                this.updateRecipientPanelControlProperties();
                this.showControlFields(data.originalTitle.toLowerCase());
                let depencieContainerResponsive: any = (document.getElementById('depencieChanges')) as HTMLSelectElement;
                if (event.ctrlKey === false) {
                    this.controlPropertyService.removePreviousControlSelection(this.ctrlAndClickedControlsArr);
                    this.selectedItems = [];
                    this.commonService.selectedItems = [];
                    this.selectedItems.push('resizable_' + data.id);
                    this.ctrlAndClickedControlsArr = [];
                    this.ctrlAndClickedControlsArr.push('resizable_' + data.id);
                }
                setTimeout(() => {
                    this.applyStylesToSelectedRecipient(this.selectedPanelRecipentId, "2", "clicked");
                    this.controlPropertyService.applyIdAndHtmlId(data);
                    this.updateSaveAsDefaultCheckedOrNot(data.id);
                    this.hidePagesViewSection = true;
                    if (this.isClose === false && this.hideDependencies == false) {
                        depencieContainerResponsive.classList.remove('depencie-W');
                        depencieContainerResponsive.classList.add('depencie-AdjustW');
                    }
                    this.updateLengthOrMaxCharLimitLabel();

                    //updated init controle is checked or not based on attribute
                    if (data && data.dataGuid && data.dataGuid.toLowerCase() === Control.Initials.toLowerCase()) {
                        this.controlPropertyService.checkeInitOnEachPageProperties(data.id);
                    }
                    if (data && data.dataGuid && data.dataGuid.toLowerCase() === Control.Text.toLowerCase()) {
                        let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + data.id);
                        let addTextTextarea: any = document.getElementById('addTextTextarea');
                        let dataPrefill: any = resizableCurrentSelectedControl.getAttribute('data-prefill');
                        let datadisplayLabel: any = resizableCurrentSelectedControl.getAttribute('data-displayLabel');
                        if (dataPrefill == "true") {
                            if (addTextTextarea) addTextTextarea.setAttribute('maxlength', '4000');
                        }
                        else {
                            if (addTextTextarea) addTextTextarea.setAttribute('maxlength', '400');
                        }
                    }
                }, 100);
            });

        //remove control
        this.commonService.callRemoveControlComponent$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { data: any; label: any; }) => {
                const { data, label } = value;
                // let currentControlId: any = localStorage.getItem('CurrentClickedDroppedControl');
                let currentControlId: any = this.sessionStorageService.getCurrentClickedDroppedControl();
                if (label === 'ctrlHover') {
                    this.removeControlComponent(value, currentControlId);
                } else {
                    if (this.selectedItems && this.selectedItems.length > 0) {
                        this.selectedItems.forEach((ele: any) => {
                            const dynamicComponentRef = this.dynamicComponentRefs.get(ele.replace('resizable_', ''));
                            if (dynamicComponentRef) {
                                this.removeControlComponent(value, ele.replace('resizable_', ''));
                            } else {
                                this.removeControlComponent(value, currentControlId);
                            }
                        })
                    } else {
                        this.removeControlComponent(value, currentControlId);
                    }
                    this.resetCopyPasteUndoRedoList();
                }
            });

        //open dependency modal
        this.commonService.callOpenDependencyPopup$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event: any) => {
                // this.openModal('', 'adddependencies', '');
                this.showDependenciesFields('');
            });

        // Getting recipent obj
        this.commonService.callOnRecipentChange$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any) => {
                this.onRecipentChange(data);
            });

        //onmouseover changiing the recipient
        this.commonService.callOnMouseOverRecipentChange$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any) => {
                this.onRecipentOverSelect(data.recipent, data.selectedColor);
            });

        //creating duplicate control event
        this.commonService.callOpenCreateDuplicateControl$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any) => {
                this.copyPastedcontrolsArr = [];
                this.createControl(data, ControlConfig.Duplicate, 'false');
            });

        this.commonService.callUpdateSignerMultipleControl$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any) => {
                this.openModal('', 'multiplesignerupdate', '')
            });

        this.zoomList = ['50', '75', '100', '125', '150', '175', '200'];
        //Check show or hide Dependencies Button
        this.showHideDependenciesButton();
        this.updateColorCode(this.groupColorCode);

        //Disable Redo and Undo Buttons
        this.arrCopyPasteCtrl = [];
        this.arrUndoRedoCtrl = [];
        this.arrCopyPasteCtrlsTemp = [];
        // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
        // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(this.arrUndoRedoCtrl));
        // localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify(this.arrCopyPasteCtrlsTemp));

        this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
        this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrUndoRedoCtrl));
        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));

        this.disableRedoButtons();
        this.disableUndoButtons();
        setTimeout(() => {
            this.applyStylesToSelectedRecipient(this.selectedRecipentId, "1", "");
        }, 100);

        //localStorage.setItem('ReceipentLists', JSON.stringify(this.receipentListArr));
        this.showLoader = false;

        this.commonService.setTextOrNameAreaSetting$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: any) => {
                setTimeout(() => {
                    this.setTextOrNameAreaSetting(data);
                }, 300);
            })

        //right click and if user select delete option
        this.commonService.selectedRightClickOption$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { data: any; label: any; }) => {
                const { data, label } = value;
                if (data.which != 3) {
                    if (this.selectedItems && this.selectedItems.length) {
                        if (label === 'Delete') {
                            if (this.selectedItems && this.selectedItems.length) {
                                this.deleteItems(this.selectedItems);
                            }
                            this.selectedItems.forEach((ele: any) => {
                                if (ele) {
                                    const dynamicComponentRef = this.dynamicComponentRefs.get(ele.replace('resizable_', ''));
                                    if (dynamicComponentRef) {
                                        this.removeControl(ele.replace('resizable_', ''), data);
                                    }
                                }
                                this.updateUndoCtrlsCount();
                                this.arrCopyPasteCtrl = [];
                                // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
                                this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
                                this.disableRedoButtons();
                                this.disableUndoButtons();
                            });
                        } else if (label === 'Duplicate') {
                            this.copyPastedcontrolsArr = [];
                            this.createDupicateControl(data);
                            this.onClickOutsideSelectionArea();
                            // this.resetCopyPasteUndoRedoList();
                            this.updateUndoCtrlsCount();
                        }
                        else if (label === 'Copy') {
                            this.onCopyFun();
                        }
                        else if (label === 'Undo') {
                            // this.undo(data);
                            this.undoCtrl(data);
                        }
                        else if (label === 'Redo') {
                            // this.onRedoFun({});
                            this.redoCtrl(data);
                        }
                        else if (label === 'Paste') {
                            // this.onPasteFun(data);
                            this.onPaste();
                        }
                    }
                    else {
                        if (label === 'Undo') {
                            // this.undo(data);
                            this.undoCtrl(data);
                        }
                        else if (label === 'Redo') {
                            // this.onRedoFun(data);
                            this.redoCtrl(data);
                        }
                        else if (label === 'Copy') {
                            this.onCopyFun();
                        }
                        else if (label === 'Paste') {
                            if (this.selectedItems && this.selectedItems.length) {
                                // this.onPasteFun(data);
                                this.onPaste();
                            }
                        }
                    }
                }
            });

        // Mouse selection controls
        this.controlSelection();
    }

    updateEnvelopeDocumentControlInformation() {
        //Get template sign and review document and controls Information
        let docSignName = "";
        setTimeout(() => {
            this.showLoader = true;
            if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.documentDetails) {
                let documentDetails: any = this.envelope.envelopeDetails.documentDetails;
                if (documentDetails && documentDetails.length > 0) {
                    documentDetails = documentDetails.sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                    for (let index = 0; index < documentDetails.length; index++) {
                        const documentElement = documentDetails[index];
                        if (documentElement.actionType != "Review" && docSignName == "") {
                            docSignName = documentElement.documentName != '' ? documentElement.documentName : '';
                            this.previewObj = { 'documentName': docSignName };
                        }
                    }
                }

                let documentDetailsList = documentDetails;
                if (documentDetailsList && documentDetailsList.length > 0) {
                    for (let index = 0; index < documentDetailsList.length; index++) {
                        const docItem: any = documentDetailsList[index];
                        let document_id: any = docItem.id;
                        let documentContentDetails: any = docItem.documentContentDetails;
                        if (documentContentDetails && documentContentDetails.length > 0) {
                            for (let cindex = 0; cindex < documentContentDetails.length; cindex++) {
                                const docContentItem: any = documentContentDetails[cindex];
                                //let documentImagesArray: any = document.querySelectorAll('[id="' + docContentItem.pageNo + '"]');
                                let documentImagesArray: any = document.querySelectorAll('[img-doc-id="' + document_id + '"]');
                                Array.from(documentImagesArray).forEach((ele: any, index: any) => {
                                    let imagDocPageNo: any = ele.getAttribute('docpageno');
                                    let imagePage_No: any = ele.getAttribute('page-no');
                                    let img_document_id: any = ele.getAttribute('img-doc-id');
                                    if (docContentItem.documentPageNo == imagDocPageNo && docContentItem.documentID == document_id && imagePage_No != null) {
                                        let recpColorIndex: any = '';
                                        for (let index = 0; index < this.receipentListArr.length; index++) {
                                            const element = this.receipentListArr[index];
                                            if ((docContentItem.controlID.toLowerCase() != Control.Label.toLowerCase() && docContentItem.controlID.toLowerCase() != Control.Hyperlink.toLowerCase())
                                                && docContentItem.recipientID && (element.id.toLowerCase() == docContentItem.recipientID.toLowerCase())) {
                                                recpColorIndex = index;
                                                break;
                                            }
                                            else if (this.receipentListArr && this.receipentListArr.length == 1) {
                                                recpColorIndex = 0;
                                                break;
                                            }
                                        }

                                        let textFormatting: any = {};
                                        if (docContentItem && docContentItem.controlStyle && docContentItem.controlStyle != null) {
                                            textFormatting = {
                                                color: this.isUndefinedOrNull(docContentItem.controlStyle.fontColor, 1),
                                                fontID: this.isUndefinedOrNull(docContentItem.controlStyle.fontID, 2),
                                                fontFamily: this.isUndefinedOrNull(docContentItem.controlStyle.fontName, 3),
                                                fontSize: this.isUndefinedOrNull(docContentItem.controlStyle.fontSize, 4),
                                                fontWeight: docContentItem.controlStyle.isBold == true ? 'bold' : 'normal',
                                                textDecoration: docContentItem.controlStyle.isUnderline == true ? 'underline' : 'none',
                                                fontStyle: docContentItem.controlStyle.isItalic == true ? 'italic' : 'normal',
                                                lineHeight: this.isUndefinedOrNull(docContentItem.controlStyle.fontSize, 5),
                                                textAlign: 'left'
                                            }
                                        }

                                        let controlObj: any = {
                                            id: docContentItem.controlHtmlID,
                                            controlName: this.commonService.getControlNameBasedOnGuid(docContentItem.controlID),
                                            pageNo: imagePage_No,
                                            dataDocumentContentId: docContentItem.id,
                                            dataGuid: docContentItem.controlID,
                                            recipientId: (this.receipentListArr && this.receipentListArr.length == 1) ? this.receipentListArr[0].id : docContentItem.recipientID,
                                            recpColor: (docContentItem.controlID.toLowerCase() != Control.Label.toLowerCase() && docContentItem.controlID.toLowerCase() != Control.Hyperlink.toLowerCase()) ? this.receipentColorArr[recpColorIndex] : "#d21513",
                                            top: docContentItem.topIndex,
                                            left: docContentItem.leftIndex,
                                            height: docContentItem.height,
                                            width: (docContentItem.controlID.toLowerCase() == Control.DateTimeStamp.toLowerCase() && docContentItem.width == '122') ? 150 : docContentItem.width,
                                            controlData: docContentItem,
                                            textFormatting: textFormatting
                                        };
                                        this.createControl(controlObj, ControlConfig.Existing, 'false');
                                        this.isPageLoadingFirstTime = false;
                                    }
                                });
                            }
                        }
                    }
                }

                setTimeout(() => {
                    this.showLoader = true;
                    this.controlPropertyService.updateExistingControlDependencyFields(documentDetails);
                    this.showLoader = false;
                }, 1000);

                //File Review
                if (this.enableFileReview == true) {
                    let filesReviewInfoDetails = this.envelope.envelopeDetails.documentDetails.filter((rec: any) => (rec.actionType == ActionTypes.Review));
                    if (filesReviewInfoDetails && filesReviewInfoDetails.length > 0) {
                        this.filesReviewCount = filesReviewInfoDetails.length;
                        for (let index = 0; index < filesReviewInfoDetails.length; index++) {
                            const filesReviewItem: any = filesReviewInfoDetails[index];
                            this.filesReview.push(filesReviewItem.documentName);
                        }
                    }
                }
            }
            this.showLoader = false;
        }, 300); //500
    }

    getDefaultValues() {
        //need to remove else block once developement is done
        this.documentName = 'Sample Document';
        this.previewObj = {
            'documentName': this.documentName
        }

        this.authService.setRecipientColorDetails(this.receipentColorArr);
        this.authService.setRecipientDetails(this.receipentListArr); //need to comment
        this.selectedRecipentName = this.receipentListArr[0].name;
        this.selectedPanelRecipentName = this.receipentListArr[0].name;
        this.selectedRecipentId = this.receipentListArr[0].id;
        this.selectedPanelRecipentId = this.receipentListArr[0].id;
        this.recipientsObj = {
            selectedRecipentName: this.selectedRecipentName,
            selectedRecipentColor: this.selectedRecipentColor
        }
        this.imagesArr = [
            {
                path: '../assets/images/1.jpg', isActive: false, id: 1, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: '17e9fe80-60cf-4583-99a8-dab600cae715', name: 'Sample Doc.pdf' },
                controlsCount: 0
            },
            {
                path: '../assets/images/2.jpg', isActive: false, id: 2, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: '17e9fe80-60cf-4583-99a8-dab600cae715', name: 'Sample Doc.pdf' },
                controlsCount: 0
            },
            {
                path: '../assets/images/3.jpg', isActive: false, id: 3, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: '17e9fe80-60cf-4583-99a8-dab600cae715', name: 'Sample Doc.pdf' },
                controlsCount: 0
            },
            {
                path: '../assets/images/4.jpg', isActive: false, id: 4, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: 'a0482889-7d6c-4270-8f94-bd790e2214c7', name: 'Sample Document1.pdf' },
                controlsCount: 0
            },
            {
                path: '../assets/images/5.jpg', isActive: false, id: 5, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: 'a0482889-7d6c-4270-8f94-bd790e2214c7', name: 'Sample Document1.pdf' },
                controlsCount: 0
            },
            {
                path: '../assets/images/6.jpg', isActive: false, id: 6, documentId: '372D0338-B464-457E-8E93-2A8FC80EC504', width: '915px',
                document: { id: 'a0482889-7d6c-4270-8f94-bd790e2214c7', name: 'Sample Document2.pdf' },
                controlsCount: 0
            }
        ];
        this.selectDefaultDateFormat = '1';
        this.hideFixedwidthCheckbox = false;
        this.controlPropertyService.setHideFixedwidthCheckbox(this.hideFixedwidthCheckbox);

        this.removePaginationDisabledClass();
        this.totalDocPageImagesCount = this.imagesArr.length;
        // localStorage.setItem("isEnvelopeEditable", this.isEnvelopeEditable == true ? "true" : "true");
        this.sessionStorageService.setIsEnvelopeEditable(this.isEnvelopeEditable == true ? 'true' : 'false');
        this.handleConfigureDataEvents();
        this.updateTempTotalCountPerPage();
        this.commonService.updatePrevObject(
            {
                'totalControls': 0,
                'pageNumber': 1,
                'tempTotalCountPerPage': this.tempTotalCountPerPage,
                // 'documentList': this.imagesArr,
                'totalImagesCount': this.totalDocPageImagesCount
            });
        this.showLoader = false;
        // localStorage.setItem("AllImagesProcessed", "true");
        this.sessionStorageService.setAllImagesProcessed('true');

        this.commonService.callMultiDocumentsPreview(this.imagesArr);
        this.commonService.removeButtonDisabledClass();
    }

    public showSuccess(): void {
        this.showSave = false;
        this.showRemove = !this.showRemove;
        this.toastrService.success(this.commonConstants.DataIsSavedSuccessfully, 'Success');
    }

    onShowRemove() {
        this.showRemove = false;
        this.showSave = !this.showSave;
        this.toastrService.info(this.commonConstants.DataIsRemovedSuccessfully, 'Success');
    }
    toggleFilesReview() {
        this.filesShowDiv = !this.filesShowDiv;
    }

    onPreviewPagesView() {
        this.hidePagesViewSection = !this.hidePagesViewSection;
        this.isClose = true;
        this.depenciestatus = this.hidePagesViewSection;
        this.activeCtrlId = 0;
        this.hideControlViewSection = this.isClose;
        this.controlPropertyService.removeControlActiveClass("");
        this.updateExtendDocumentWidth();
        // setTimeout(() => {
        // 	this.updateExtendDocumentWidth();
        // }, 100);

        let depencieContainerResponsive: any = (document.getElementById('depencieChanges')) as HTMLSelectElement;
        if (this.hideDependencies == false && this.hidePagesViewSection === true) {
            depencieContainerResponsive.classList.remove('depencie-AdjustW');
            depencieContainerResponsive.classList.add('depencie-W');
        }
        if (this.hideDependencies == false && this.hidePagesViewSection === false) {
            depencieContainerResponsive.classList.remove('depencie-W');
            depencieContainerResponsive.classList.add('depencie-AdjustW');
        }

    }

    // onControlIdView() {
    //     this.hideControlView = !this.hideControlView;
    // }

    /* To copy Text from Textbox */
    copyInputMessage(inputElement: any) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this.toastrService.success(this.commonConstants.CopiedTtoClipboard, 'Success');
    }

    fadeInOut(event: any) {
        this.isClose = !this.isClose;
        this.activeCtrlId = 0;
        this.controlPropertyService.removeControlActiveClass("");
        this.hidePagesViewSection = !this.hidePagesViewSection;
        this.hideControlViewSection = this.isClose;
        setTimeout(() => {
            this.updateExtendDocumentWidth();
        }, 100);
    }

    checkBold() {
        this.isBold = !this.isBold;
        this.applyFontStyles();
    }

    checkItalic() {
        this.isItalic = !this.isItalic;
        this.applyFontStyles();
    }

    checkUnderline() {
        this.isUnderlined = !this.isUnderlined;
        this.applyFontStyles();
    }

    checkAlignLeft() {
        this.isAlignleft = true;
        this.isAlignmiddle = false;
        this.isAlignright = false;
        this.applyTextAlign('left');
    }

    checkAlignMiddle() {
        this.isAlignleft = false;
        this.isAlignmiddle = true;
        this.isAlignright = false;
        this.applyTextAlign('center');
    }

    checkAlignRight() {
        this.isAlignleft = false;
        this.isAlignmiddle = false;
        this.isAlignright = true;
        this.applyTextAlign('right');
    }

    rotate(event: any) {
        this.isVisited = !this.isVisited;
        if (!this.isVisited === false) {
            event.srcElement.classList.remove('rotate');
        }
        else {
            event.srcElement.classList.add('rotate');
        }
    }

    onRecipentChange(recipentObj: any) {
        this.selectedRecipentName = recipentObj.recipent.name;
        this.selectedRecipentId = recipentObj.recipent.id;
        this.selectedRecipentColor = recipentObj.selectedColor;
        this.dragItemBgColor = recipentObj.selectedColor;

        this.recipientsObj = {
            selectedRecipentName: this.selectedRecipentName,
            selectedRecipentColor: this.selectedRecipentColor
        }
        this.applyStylesToSelectedRecipient(recipentObj.recipent.id, "1", '');
    }

    onRecipentSelect(recipient: any, selectedColor1: any) {
        this.selectedPanelRecipentName = recipient.name;
        this.selectedPanelRecipentColor = selectedColor1;
        this.selectedPanelRecipentId = recipient.id;
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.applyRecipientDetailsToControl(currentSelectedControl);
        this.applyStylesToSelectedRecipient(recipient.id, "2", '');
    }

    onRecipentOverSelect(recipient: any, selectedColor: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyRecipientDetailsOnMouseOverChange(recipient, selectedColor, currentSelectedControl);
        this.selectedPanelRecipentName = recipient.name;
        this.selectedPanelRecipentColor = selectedColor;
    }


    //Apply styles to the control which is clicked or dropped
    applyCheckboxGroup(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();

        if (event.target.checked == true) { //apply
            this.displayGroupCheckBox = true;
            this.controlPropertyService.setRadioCheckBoxGroupChecked(currentSelectedControl, null);
        }
        else {
            let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + currentSelectedControl);
            let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
            let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
            let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));

            if (inputGroupCtrl) inputGroupCtrl.value = '';
            if (inputGroupNameCtrl) inputGroupNameCtrl.value = '';
            if (resizableCurrentSelectedControl) {
                resizableCurrentSelectedControl.setAttribute('data-groupname', '');
                resizableCurrentSelectedControl.setAttribute('data-isGroupChecked', 'false');
            }
            if (spnCurrentSelectedControl) {
                spnCurrentSelectedControl.setAttribute('name', '');
            }
            this.displayGroupCheckBox = false;
        }

        this.ischeckboxGroup = event.target.checked;
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    getControls(properties: any) {
        let trueProperties: any[] = [];
        for (const prop in properties) {
            if (properties[prop] === true) {
                trueProperties.push(prop);
            }
        }
        return trueProperties[0];
    }

    saveAsDefault(event: any, controlsObj: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        //this.saveAsDefaultCheckbox = (event.target.checked == true) ? true : false;

        // Below code is to get control name
        let ctrlNameProperties: any = this.getControls(controlsObj);
        let ctrlName: any = '';
        if (ctrlNameProperties && ctrlNameProperties.length) {
            ctrlName = ctrlNameProperties.split('P')[0];
            if (ctrlName.toLowerCase() === 'signature') {
                ctrlName = 'sign';
            }
            if (ctrlName.toLowerCase() === 'newinitials') {
                ctrlName = 'initials';
            }
            if (ctrlName.toLowerCase() === 'datetime' || ctrlName.toLowerCase() === 'datetimestamp') {
                ctrlName = 'datetimestamp';
            }
        }

        let spnctrlEle: any = document.getElementById('spn' + currentSelectedControl);
        if (event.target.checked == true) {
            this.toastrService.clear();
            this.toastrService.success(this.commonConstants.DataIsSavedSuccessfully, 'Success');
            // this.checkboxText = 'Save as Default';

            if (spnctrlEle) {
                spnctrlEle.setAttribute('data-saveasdefault', true);
                this.updateSaveAsDefaultChecked = true;
                this.defaultControlStyleService.updateDictionary(ctrlName, {
                    saveAsDefault: true,
                    required: ctrlEle.getAttribute('data-selected'),
                    fontFamily: spnctrlEle.style.fontFamily,
                    fontWeight: spnctrlEle.style.fontWeight,
                    textDecoration: spnctrlEle.style.textDecoration,
                    fontStyle: spnctrlEle.style.fontStyle,
                    fontSize: spnctrlEle.style.fontSize,
                    color: spnctrlEle.style.color,
                    textAlign: spnctrlEle.style.textAlign,
                    lineHeight: ctrlName.toLowerCase() === 'date' ? '' : spnctrlEle.style.fontSize,
                    validationType: '',
                    adlnName: '',
                    adlnOption: '',
                    preSelected: ctrlEle.getAttribute('data-selected1'),
                    checkboxGroup: ctrlEle.getAttribute('data-isgroupchecked'),
                    checkboxGroupName: ctrlEle.getAttribute('data-groupname'),
                    checkboxName: spnctrlEle.getAttribute('name'),
                    dateFormat: this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat),
                    size: 'standard',
                    fontTypeMeasurement: this.defaultTypographySize,
                    firstLineBlank: this.isdrpFirstLineBlank,
                    labelControlValue: spnctrlEle.innerText ? spnctrlEle.innerText : ''
                })

                let newData = this.defaultControlStyleService.updateSaveAsDefaultPayload(currentSelectedControl, ctrlName, spnctrlEle, spnctrlEle.style.fontFamily, this.isRequiredChecked, this.defaultTypographySize);
                // console.log('save as default true', newData);
            }
        }
        else {
            // this.checkboxText = 'Remove Default';
            this.toastrService.clear();
            this.toastrService.success(this.commonConstants.DataIsRemovedSuccessfully, 'Success');
            if (spnctrlEle) {
                this.defaultControlStyleService.updateDictionary(ctrlName, {
                    saveAsDefault: false,
                    required: (ctrlName.toLowerCase() === 'dropdown' || ctrlName.toLowerCase() === 'checkbox' || ctrlName.toLowerCase() === 'radio') ? false : (ctrlName.toLowerCase() === 'sign') ? this.signatureControlRequired : true,
                    fontFamily: 'Courier',
                    fontWeight: 'normal',
                    textDecoration: 'none',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    color: '#000',
                    textAlign: 'left',
                    lineHeight: ctrlName.toLowerCase() === 'date' ? '' : '12px',
                    validationType: '',
                    adlnName: '',
                    adlnOption: '',
                    preSelected: false,
                    checkboxGroup: false,
                    checkboxGroupName: '',
                    checkboxName: '',
                    dateFormat: '',
                    size: 'standard',
                    fontTypeMeasurement: this.defaultUserSettingsTypographySize,
                    firstLineBlank: false,
                    labelControlValue: spnctrlEle.innerText ? spnctrlEle.innerText : ''
                })

                spnctrlEle.setAttribute('data-saveasdefault', false);
                if (ctrlName.toLowerCase() === 'initials' || ctrlName.toLowerCase() === 'name' || ctrlName.toLowerCase() === 'title' ||
                    ctrlName.toLowerCase() === 'company' || ctrlName.toLowerCase() === 'email' ||
                    ctrlName.toLowerCase() === 'date' || ctrlName.toLowerCase() === 'text') {
                    this.isRequiredChecked = true;
                    ctrlEle.setAttribute('data-selected', true);
                }
                else if (ctrlName.toLowerCase() === 'dropdown' || ctrlName.toLowerCase() === 'checkbox' || ctrlName.toLowerCase() === 'radio') {
                    this.isRequiredChecked = false;
                    ctrlEle.setAttribute('data-selected', false);
                }
                else if (ctrlName.toLowerCase() === 'sign') {
                    let chRequiredSelected: any = (<HTMLInputElement>document.getElementById("chRequiredSelected"));
                    if (chRequiredSelected) {
                        chRequiredSelected.checked = this.signatureControlRequired;
                    }
                    this.isRequiredChecked = this.signatureControlRequired;
                    ctrlEle.setAttribute('data-selected', this.signatureControlRequired);
                    this.controlPropertyService.showRequiredIcon(currentSelectedControl);
                }

                //Assign Default Property
                if (ctrlName.toLowerCase() === 'text') {
                    this.setShowTextFiledToNone();
                    this.selectValidation = '1';
                    this.applyTextTypeControl("1");
                }
                //Assign Default Property

                this.defaultColorPicker = this.commonConstants.DefaultColorPicker;
                spnctrlEle.style.color = this.defaultColorPicker;
                spnctrlEle.style.fontFamily = 'Courier';
                spnctrlEle.style.fontWeight = 'normal';
                spnctrlEle.style.textDecoration = 'none';
                spnctrlEle.style.fontStyle = 'normal';
                spnctrlEle.style.fontSize = '12px';
                spnctrlEle.style.textAlign = 'Left';
                spnctrlEle.style.lineHeight = '12px';

                if (ctrlEle && ctrlName.toLowerCase() === 'date') {
                    this.selectDefaultDateFormat = this.controlPropertyService.getDefaultDateOption(this.envelope.envelopeDetails.dateFormatID);
                    spnctrlEle.innerHTML = this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat);
                    this.updateCtrlDateFormat(currentSelectedControl, 1, '');
                    ctrlEle.style.height = '16px';
                    ctrlEle.setAttribute('data-defaultFormat', this.controlPropertyService.getDateFormat(this.selectDefaultDateFormat));
                }

                this.isBold = false;
                this.isItalic = false;
                this.isUnderlined = false;
                this.isAlignleft = false;
                this.isAlignmiddle = false;
                this.isAlignright = false;
                this.defaultFontSize = '12';
                this.defaultFontFamily = 'Courier';
                this.defaultTypographySize = this.defaultUserSettingsTypographySize; //get based on usersettings

                this.defaultControlStyleService.updateSaveAsDefaultPayload(currentSelectedControl, ctrlName, spnctrlEle, 'Courier', this.isRequiredChecked, this.defaultUserSettingsTypographySize);

                let tempData: any = this.defaultControlStyleService.getSaveAsDefaultData();
                if (tempData && tempData.length) {
                    tempData = tempData.filter(function (el: any) { return el.Control != ctrlName.toLowerCase(); });
                }

                this.defaultControlStyleService.updateSaveAsDefaultData(tempData);
                ctrlEle.setAttribute("data-typography", this.defaultTypographySize);
                this.applyFontSize(this.defaultFontSize, 0);
            }
        }
    }

    onOptionsSelected(value: any) {
        //console.log('The selected value is ' + value);
        this.selectDefaultSizeValue = value;
        this.datacount = this.checkboxSizeControl.value;
        //console.log('Default value:', this.datacount);
    }

    radiocheckboxSizes() {
        //console.log('user selection', this.selectDefaultSizeValue);
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyRadioCheckboxSizes(this.selectDefaultSizeValue, this.currentControlId);
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    validationType() {
        //console.log('user selection', this.selectValidation);
    }

    onAdditionalValidation() {
        //console.log('user selection', this.selectAddtionalValidation);
    }

    // dragAnddrop funtionlaity starts

    // setting cursor positions
    computeDragRenderPos(pos: any, DragRef: any) {
        // console.log('DragRef',DragRef.data.data.title);
        let x: any = pos.x;
        let y: any = pos.y - 12;

        if (DragRef && DragRef.data && DragRef.data.data) {
            if (DragRef.data.data.title === 'Checkbox' || DragRef.data.data.title === 'Radio') {
                y = pos.y - 6;
                x = pos.x - 6;
            } else {
                y = pos.y - 12;
            }
        }
        return { x: x, y: y };
    }

    //Getting random id and adding as control id
    getId() {
        return Math.round(1 + Math.floor(Math.random() * 100000));
    }

    onDragStarted(e: any) {
        this.bodyElement.style.cursor = 'move';
        if (e && e.source && e.source.data && e.source.data.id) {
            this.activeCtrlId = e.source.data.id;
        }
    }

    drop(event: any) {
        this.hideDependencies = true;
        //console.log('Item dropped on the document page:', event.event.target.id);
        this.bodyElement.style.cursor = 'unset';
        this.controlPropertyService.removeCtrlSelectionBgColor(this.selectedItems);
        this.setShowTextFiledToNone();
        this.controlPropertyService.setdefaultTextValidation();
        // this.onClickOutsideSelectionArea();
        this.resetCopyPasteUndoRedoList();
        this.isCopiedSelecteditems = false;
        this.updateUndoCtrlsCount();
        // localStorage.setItem('isCopiedSelecteditems', 'false');
        this.sessionStorageService.setIsCopiedSelecteditems('false');
        // this.commonService.setSelectedItems([]);

        if (event && event.previousContainer !== event.container) {
            if (event && event.item && event.item.data) {
                let imageCtrl: any = document.getElementById('imgControl_' + event.event.target.id);
                let componentType: any = ControlComponent; //this.getComponentType(event.item.data.title);
                if (imageCtrl) {
                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
                    const componentRef: any = componentFactory.create(this.injector);
                    // Set input properties as needed
                    let controlTopAdjustmentDiff: any = 10;
                    let controlLeftAdjustmentDiff: any = 0;
                    let imageWidth: any = imageCtrl.offsetWidth;
                    let imageHeight: any = imageCtrl.offsetHeight;
                    let controlClassName: any = event.item.data.title.toLowerCase();
                    let controlName: any = event.item.data.title;
                    let controlId: any = event.item.data.title.toLowerCase();
                    let defaultWidth: any = 100;
                    let defaultHeight: any = 20;
                    let minWidth: any = 100;
                    let minHeight: any = 10;
                    // let ctrlBgColor: any = '#eee';
                    let ctrlBgColor: any = '#fff';
                    let controlDateFormat: any = '';
                    let placeHolderText: any = event.item.data.originalTitle;
                    let controlResourceKeys: any = '';
                    let defaultDate: boolean = false;
                    if (event.item.data.guid.toLowerCase() === Control.Signature.toLowerCase()) {
                        controlClassName = 'sign';
                        controlName = 'Signature';
                        controlId = 'sign';
                        defaultWidth = 215;
                        // defaultHeight = 37;
                        defaultHeight = 35;
                        minWidth = 100;
                        // minHeight = 37;
                        minHeight = 35;
                        // controlTopAdjustmentDiff = 27;
                        controlTopAdjustmentDiff = 12;
                        placeHolderText = 'Sign';
                        controlResourceKeys = ControlResourceKeys.Signature;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Initials.toLowerCase()) {
                        controlClassName = 'initials';
                        controlId = 'newInitials';
                        defaultWidth = 47;
                        defaultHeight = 23;
                        minWidth = 47;
                        controlTopAdjustmentDiff = 12;
                        controlName = 'NewInitials';
                        placeHolderText = 'Initials';
                        controlResourceKeys = ControlResourceKeys.Initials; //translations text
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Name.toLowerCase()) {
                        defaultWidth = 167;
                        defaultHeight = 20;
                        minWidth = 0;
                        controlClassName = 'name';
                        controlResourceKeys = ControlResourceKeys.Name;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Text.toLowerCase()) {
                        defaultWidth = 167;
                        defaultHeight = 20;
                        minWidth = 0;
                        controlClassName = 'text';
                        controlResourceKeys = ControlResourceKeys.Text;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.DropDown.toLowerCase()) {
                        controlClassName = 'dropdown';
                        controlId = 'dropDown';
                        defaultWidth = 100;
                        minWidth = 100;
                        controlResourceKeys = ControlResourceKeys.DropDown;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Date.toLowerCase()) {
                        controlDateFormat = this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat); //'mm/dd/yyyy';
                        controlName = 'Date';
                        controlClassName = 'date';
                        controlTopAdjustmentDiff = 9;
                        defaultDate = false;
                        defaultHeight = 16;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.DateTimeStamp.toLowerCase()) {
                        controlClassName = 'datetimestamp';
                        controlName = 'DateTimeStamp';
                        controlId = 'dateTimeStamp';
                        defaultWidth = 135;
                        minHeight = 15;
                        controlTopAdjustmentDiff = 9;
                        ctrlBgColor = 'yellow';
                        placeHolderText = 'Date & Time Stamp';
                        controlResourceKeys = ControlResourceKeys.DateTimeStamp;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Label.toLowerCase()) {
                        ctrlBgColor = 'yellow';
                        controlClassName = 'label';
                        controlResourceKeys = ControlResourceKeys.Label;
                        minWidth = 100;
                        defaultWidth = 100;
                        controlTopAdjustmentDiff = 12;
                        minHeight = 20;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Radio.toLowerCase()) {
                        defaultWidth = 22;
                        defaultHeight = 22;
                        minWidth = 18;
                        minHeight = 18;
                        // controlTopAdjustmentDiff = 8;
                        // controlLeftAdjustmentDiff = 6;
                        controlTopAdjustmentDiff = 11;
                        controlLeftAdjustmentDiff = 12;
                        ctrlBgColor = 'white';
                        controlClassName = 'radio';
                        controlResourceKeys = ControlResourceKeys.Radio;
                        this.isRadioRequiredChecked = false;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Checkbox.toLowerCase()) {
                        defaultWidth = 22;
                        defaultHeight = 22;
                        minWidth = 18;
                        minHeight = 18;
                        // controlTopAdjustmentDiff = 8;
                        // controlLeftAdjustmentDiff = 6;
                        controlTopAdjustmentDiff = 12;
                        controlLeftAdjustmentDiff = 12;
                        ctrlBgColor = 'white';
                        controlClassName = 'checkbox';
                        controlResourceKeys = ControlResourceKeys.Checkbox;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Company.toLowerCase()) {
                        controlClassName = 'company';
                        defaultWidth = 100;
                        defaultHeight = 20;
                        minWidth = 100;
                        controlResourceKeys = ControlResourceKeys.Company;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Title.toLowerCase()) {
                        defaultWidth = 167;
                        defaultHeight = 20;
                        minWidth = 100;
                        controlClassName = 'title';
                        controlResourceKeys = ControlResourceKeys.Title;
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Hyperlink.toLowerCase()) {
                        controlClassName = 'hyperlink';
                        controlResourceKeys = ControlResourceKeys.Hyperlink;
                        ctrlBgColor = 'yellow';
                    }
                    else if (event.item.data.guid.toLowerCase() === Control.Email.toLowerCase()) {
                        controlClassName = 'email';
                        controlResourceKeys = ControlResourceKeys.Email;
                        defaultWidth = 100;
                        defaultHeight = 20;
                        minWidth = 100;
                    }

                    placeHolderText = this.commonService.getTranslatedText(placeHolderText, controlResourceKeys);
                    let ctrlGuid: any = this.commonService.getGuid(controlName, controlClassName);
                    let saveAsDefaultVal: any = this.defaultControlStyleService.getCtrlBasedSaveAsDefaultValue(controlClassName);
                    componentRef.instance.ctrlObj = {
                        top: (event.event.offsetY - controlTopAdjustmentDiff),
                        left: (event.event.offsetX - controlLeftAdjustmentDiff),
                        width: defaultWidth,
                        height: defaultHeight,
                        controlName: controlName,
                        className: controlClassName,
                        id: controlId + 'Control' + this.getId(),
                        title: placeHolderText,
                        pageNo: event.event.target.id,
                        minWidth: minWidth,
                        minHeight: minHeight,
                        bgColor: (controlClassName != "label" && controlClassName != "hyperlink") ? ctrlBgColor : ctrlBgColor,
                        outlineColor: (controlClassName != "label" && controlClassName != "hyperlink") ? '2px solid ' + this.selectedRecipentColor : "2px solid #999",
                        recipientId: (controlClassName != "label" && controlClassName != "hyperlink") ? this.selectedRecipentId : "",
                        recpColor: (controlClassName != "label" && controlClassName != "hyperlink") ? this.selectedRecipentColor : "1px solid #999",
                        dataGuid: ctrlGuid,
                        dataDocumentContentId: this.commonService.generateUUID(), //this is different UUID
                        ctrlNumber: this.getSameControlNumberCount(ctrlGuid, event.event.target.id),
                        controlDateFormat: controlDateFormat,
                        typography: this.defaultUserSettingsTypographySize,
                        placeHolderText: placeHolderText,
                        recipientsObj: this.recipientsObj,
                        originalTitle: event.item.data.originalTitle.toLowerCase(),
                        defaultDate: false,
                        isRequired: this.isRequiredChecked == true ? "true" : "false",
                        textFormatting: (saveAsDefaultVal === false || saveAsDefaultVal === 'false' || saveAsDefaultVal === true || saveAsDefaultVal === 'true') ? this.defaultControlStyleService.getDictionaryByKey(controlClassName) : this.defaultControlStyleService.getDefaultControlTextObject(),
                        isEnvelopeEditable: this.isEnvelopeEditable
                    };

                    if (saveAsDefaultVal === true || saveAsDefaultVal === 'true') {
                        componentRef.instance.ctrlObj.typography = componentRef.instance.ctrlObj.textFormatting.fontTypeMeasurement;
                    }

                    //console.log('Outline color:', componentRef.instance.outlineColor);
                    if ((event.event.offsetX + defaultWidth) > imageWidth) {
                        let tempLeft: any = (event.event.offsetX + defaultWidth) - imageWidth;
                        let rightBoundaryDiff: any = 6;
                        componentRef.instance.ctrlObj.left = (event.event.offsetX - (tempLeft + rightBoundaryDiff));
                    }

                    if (event.event.offsetY <= 5) {
                        let topBoundaryDiff: any = 6;
                        componentRef.instance.ctrlObj.top = 0;
                        componentRef.instance.ctrlObj.top = topBoundaryDiff;
                    }
                    if ((event.event.offsetY + defaultHeight) > imageHeight) {
                        let tempTop: any = (event.event.offsetY + defaultHeight) - imageHeight;
                        let bottomBoundaryDiff: any = 6;
                        if (event.item.data.guid.toLowerCase() === Control.Signature.toLowerCase()) {
                            bottomBoundaryDiff = 9;
                        }
                        componentRef.instance.ctrlObj.top = (event.event.offsetY - (tempTop + bottomBoundaryDiff));
                    }

                    this.dynamicComponentRefs.set(componentRef.instance.ctrlObj.id, componentRef);
                    this.currentControlId = componentRef.instance.ctrlObj.id;
                    // localStorage.setItem('CurrentClickedDroppedControl', componentRef.instance.ctrlObj.id);
                    this.sessionStorageService.setCurrentClickedDroppedControl(componentRef.instance.ctrlObj.id);

                    // Attach the component to the DOM
                    this.appRef.attachView(componentRef.hostView);
                    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
                    imageCtrl?.appendChild(domElem);
                    this.selectedItems = [];
                    this.commonService.selectedItems = [];
                    this.selectedItems.push('resizable_' + componentRef.instance.ctrlObj.id);
                    this.ctrlAndClickedControlsArr = [];
                    this.ctrlAndClickedControlsArr.push('resizable_' + componentRef.instance.ctrlObj.id);

                    if (event.item.data.guid) {
                        this.showControlFields(event.item.data.originalTitle.toLowerCase());
                        this.selectedPanelRecipentName = this.selectedRecipentName;
                        this.selectedPanelRecipentColor = this.selectedRecipentColor;
                        setTimeout(() => {
                            this.defaultColorPicker = componentRef.instance.ctrlObj.textFormatting.color;
                            this.updateSaveAsDefault(componentRef.instance.ctrlObj);
                            this.applyStylesToSelectedRecipient(this.selectedRecipentId, "2", '');
                            this.checkTooltipIsApplicable(event.item.data.title.toLowerCase(), componentRef.instance.ctrlObj.id);
                            this.controlPropertyService.applyIdAndHtmlId(componentRef.instance.ctrlObj);
                            this.checkLengthNameAndTextControl(componentRef.instance.ctrlObj);
                            this.isdrpFirstLineBlank = componentRef.instance.ctrlObj.textFormatting.firstLineBlank;
                            this.isChkPreSelected = componentRef.instance.ctrlObj.textFormatting.preSelected;
                            this.ischeckboxGroup = componentRef.instance.ctrlObj.textFormatting.checkboxGroup;
                            this.checkboxGroupName = componentRef.instance.ctrlObj.textFormatting.checkboxGroupName;
                            this.checkboxName = componentRef.instance.ctrlObj.textFormatting.checkboxName;

                            if (event.item.data.guid.toLowerCase() === Control.Date.toLowerCase()) {
                                this.updateCtrlDateFormat(componentRef.instance.ctrlObj.id, 0, componentRef.instance.ctrlObj.textFormatting);
                            }
                            if (event.item.data.guid.toLowerCase() === Control.Label.toLowerCase()) {
                                if (componentRef.instance.ctrlObj.textFormatting.saveAsDefault === 'true') {
                                    let spnSelectEle: any = document.getElementById('spn' + componentRef.instance.ctrlObj.id);
                                    if (spnSelectEle) {
                                        spnSelectEle.setAttribute('data-label-text', componentRef.instance.ctrlObj.textFormatting.labelControlValue);
                                        spnSelectEle.setAttribute('isupdated', true);
                                        spnSelectEle.innerText = componentRef.instance.ctrlObj.textFormatting.labelControlValue;
                                        if (componentRef.instance.ctrlObj.textFormatting.labelControlValue === '') {
                                            spnSelectEle.innerText = 'Label';
                                        }
                                    }
                                }
                            }
                            if (event.item.data.guid.toLowerCase() !== Control.Radio.toLowerCase() &&
                                event.item.data.guid.toLowerCase() !== Control.Checkbox.toLowerCase() &&
                                event.item.data.guid.toLowerCase() !== Control.Signature.toLowerCase() &&
                                event.item.data.guid.toLowerCase() !== Control.Initials.toLowerCase() &&
                                event.item.data.guid.toLowerCase() !== Control.DropDown.toLowerCase()) {
                                let fontSize = componentRef.instance.ctrlObj.textFormatting.fontSize;
                                let curFontSize: any;
                                if (!Number.isNaN(componentRef.instance.ctrlObj.textFormatting.fontSize)) {
                                    curFontSize = componentRef.instance.ctrlObj.textFormatting.fontSize
                                }
                                else {
                                    curFontSize = componentRef.instance.ctrlObj.textFormatting.fontSize.split('px')[0];
                                }
                                let finalFontSize: any;
                                let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + componentRef.instance.ctrlObj.id));
                                if (resizableCurrentSelectedControl && resizableCurrentSelectedControl.style) {
                                    if (event.item.data.guid.toLowerCase() == Control.Text.toLowerCase()) {
                                        let adlnOption: any = componentRef.instance.ctrlObj.textFormatting.adlnOption;
                                        let adlnName: any = componentRef.instance.ctrlObj.textFormatting.adlnName;
                                        let validationType: any = componentRef.instance.ctrlObj.textFormatting.validationType;
                                        if (adlnName && adlnName != "null" && adlnName != "undefined") {
                                            let additionalValidationItem: any = "";
                                            if (adlnName == "Zip Format") {
                                                if (adlnOption == "Zip+4") adlnOption = "Zip + 4";
                                                additionalValidationItem = this.commonConstants.ValidationsZipOptions.find((item: { label: any; }) => item.label === adlnOption);
                                            }
                                            else if (adlnName == "Decimal") {
                                                additionalValidationItem = this.commonConstants.AdditionalValidationType.find((item: { label: any; }) => item.label === adlnOption);
                                            }
                                            else if (adlnName == "Date Format") {
                                                additionalValidationItem = this.validationsDateOptions.find((item: { label: any; }) => item.label === adlnOption);
                                            }
                                            if (additionalValidationItem && additionalValidationItem.value)
                                                resizableCurrentSelectedControl.setAttribute('data-additional-validation-option', additionalValidationItem.value);
                                            else
                                                resizableCurrentSelectedControl.setAttribute('data-additional-validation-option', "1");

                                            if (validationType) {
                                                let validationTypeText: any = 'Text';
                                                let arrayObj: any = this.commonConstants.ValidationsText.filter((obj: any) => obj.value.toLowerCase() == validationType.toLowerCase());
                                                if (arrayObj && arrayObj.length > 0) validationTypeText = arrayObj[0].label;
                                                resizableCurrentSelectedControl.setAttribute('data-validation-type', validationTypeText);
                                            }
                                            else
                                                resizableCurrentSelectedControl.setAttribute('data-validation-type', "Text"); //text

                                            resizableCurrentSelectedControl.setAttribute('data-additional-validation-name', adlnName);

                                            if (adlnName == "Number") {
                                                resizableCurrentSelectedControl.removeAttribute('data-additional-validation-option');
                                            }
                                        }
                                        else {
                                            resizableCurrentSelectedControl.setAttribute('data-additional-validation-name', "");
                                        }
                                    }

                                    let fontSizePtOrPxSelect: any = resizableCurrentSelectedControl.getAttribute('data-typography');
                                    if (fontSizePtOrPxSelect && fontSizePtOrPxSelect == "pt") {
                                        finalFontSize = this.controlPropertyService.getTypographyConversionValue(curFontSize);
                                        if (finalFontSize == undefined) finalFontSize = curFontSize;
                                        this.defaultTypographySize = "pt";
                                    }
                                    else {
                                        this.defaultTypographySize = "px";
                                        finalFontSize = curFontSize;
                                    }
                                }
                                this.applyFontSize(finalFontSize, 0);
                            }
                            if (componentRef.instance.ctrlObj.textFormatting.saveAsDefault === 'true') {
                                this.updateSaveAsDefaultChecked = true;
                            }
                            if (componentRef.instance.ctrlObj.textFormatting.saveAsDefault === 'false') {
                                this.updateSaveAsDefaultChecked = false;
                            }
                            if (event.item.data.guid.toLowerCase() !== Control.DateTimeStamp.toLowerCase() && event.item.data.guid.toLowerCase() !== Control.Label.toLowerCase() && event.item.data.guid.toLowerCase() !== Control.Hyperlink.toLowerCase()) {
                                let isRequiredCheckedVal: any = this.isRequiredChecked;
                                if (componentRef.instance.ctrlObj.textFormatting.required !== undefined) {
                                    if (event.item.data.guid.toLowerCase() === Control.Radio.toLowerCase()) {
                                        this.isRadioRequiredChecked = componentRef.instance.ctrlObj.textFormatting.required;
                                        isRequiredCheckedVal = componentRef.instance.ctrlObj.textFormatting.required;
                                    } else {
                                        this.isRequiredChecked = componentRef.instance.ctrlObj.textFormatting.required;
                                        isRequiredCheckedVal = componentRef.instance.ctrlObj.textFormatting.required;
                                    }
                                }
                                this.controlPropertyService.addDefaultRequiredField(componentRef.instance.ctrlObj, isRequiredCheckedVal);
                                setTimeout(() => {
                                    if (event.item.data.guid.toLowerCase() === Control.DropDown.toLowerCase()) {
                                        let drpFirstLineBlankEle: any = document.getElementById('drpFirstLineBlank');
                                        if (drpFirstLineBlankEle) {
                                            drpFirstLineBlankEle.checked = this.isdrpFirstLineBlank;
                                            let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + this.currentControlId))
                                            if (controlEle) {
                                                if (this.isdrpFirstLineBlank == true) controlEle.setAttribute('data-firstlineblank', "true");
                                                else if (this.isdrpFirstLineBlank == false) controlEle.setAttribute('data-firstlineblank', "false");
                                            }
                                        }
                                    }
                                    if (event.item.data.guid.toLowerCase() === Control.Checkbox.toLowerCase()) {
                                        let isChkPreSelectedEle: any = document.getElementById('chkPreSelected');
                                        if (isChkPreSelectedEle) {
                                            isChkPreSelectedEle.checked = this.isChkPreSelected;
                                            if (this.isChkPreSelected) {
                                                let resizablCurrentSelectedControl: any = document.getElementById('resizable_' + componentRef.instance.ctrlObj.id);
                                                let spanCurrentSelectedControl: any = document.getElementById('spn' + componentRef.instance.ctrlObj.id);
                                                if (spanCurrentSelectedControl) {
                                                    spanCurrentSelectedControl.classList.add('checked-visible');
                                                    spanCurrentSelectedControl.setAttribute('checked', 'true');
                                                    spanCurrentSelectedControl.style["background-color"] = 'green';
                                                    resizablCurrentSelectedControl.setAttribute('data-selected1', 'true');
                                                }
                                            }
                                        }
                                        let ischeckboxGroupEle: any = document.getElementById('checkboxgroup');
                                        if (ischeckboxGroupEle) {
                                            ischeckboxGroupEle.checked = this.ischeckboxGroup;
                                        }

                                        this.commonConstants.Checkboxoptions.forEach((ele: any) => {
                                            if (ele && ele.viewValue.toLowerCase() === componentRef.instance.ctrlObj.textFormatting.size.toLowerCase()) {
                                                this.selectDefaultSizeValue = ele.value;
                                            }
                                        });
                                    }
                                    if (event.item.data.guid.toLowerCase() === Control.Radio.toLowerCase()) {
                                        let isChkPreSelectedEle: any = document.getElementById('chkPreSelected');
                                        if (isChkPreSelectedEle) {
                                            isChkPreSelectedEle.checked = this.isChkPreSelected;
                                            if (this.isChkPreSelected) {
                                                let resizablCurrentSelectedControl: any = document.getElementById('resizable_' + componentRef.instance.ctrlObj.id);
                                                let spanCurrentSelectedControl: any = document.getElementById('spn' + componentRef.instance.ctrlObj.id);
                                                if (spanCurrentSelectedControl) {
                                                    spanCurrentSelectedControl.classList.add('checked-visible');
                                                    spanCurrentSelectedControl.setAttribute('checked', 'true');
                                                    spanCurrentSelectedControl.style["background-color"] = 'green';
                                                    resizablCurrentSelectedControl.setAttribute('data-selected1', 'true');
                                                }
                                            }
                                        }

                                        this.commonConstants.Checkboxoptions.forEach((ele: any) => {
                                            if (ele && ele.viewValue.toLowerCase() === componentRef.instance.ctrlObj.textFormatting.size.toLowerCase()) {
                                                this.selectDefaultSizeValue = ele.value;
                                            }
                                        });
                                    }
                                }, 200);
                            }
                            if (event.item.data.guid.toLowerCase() === Control.Name.toLowerCase() || event.item.data.guid.toLowerCase() === Control.Title.toLowerCase() || event.item.data.guid.toLowerCase() === Control.Text.toLowerCase()) {
                                setTimeout(() => {
                                    if (this.hideFixedwidthCheckbox == false || this.hideFixedwidthCheckbox == 'false') {
                                        this.lengthOrMaxCharLimitLabel = 'Max Character Limit';
                                        this.isFixedWidthChecked = false;
                                    }
                                    let addTextTextareaEle: any = document.getElementById('addTextTextarea');
                                    if (addTextTextareaEle) {
                                        addTextTextareaEle.value = '';
                                    }
                                    this.controlPropertyService.addDefaultFixedWidth(componentRef.instance.ctrlObj, this.hideFixedwidthCheckbox, this.isFixedWidthChecked);
                                }, 200);
                            }
                            this.controlPropertyService.updateDefaultFontFamilyId(this.currentControlId, componentRef.instance.ctrlObj.textFormatting.fontFamily);
                            this.updateAlignments(controlClassName);
                            this.updateTextFormattingStyles(componentRef.instance.ctrlObj);

                            if (this.externalIntegrationType !== '' && componentRef.instance.ctrlObj.controlName === 'Text') {
                                this.resetChangesForIntegration(componentRef.instance.ctrlObj.id);
                            }

                            let resizableEle: any = document.getElementById('resizable_' + componentRef.instance.ctrlObj.id);
                            let spnSelectEle: any = document.getElementById('spn' + componentRef.instance.ctrlObj.id);
                            if (resizableEle) {
                                resizableEle.style.background = 'bisque';
                                let attr_data_guid: any = resizableEle.getAttribute('data-guid');
                                if (spnSelectEle && attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                                    spnSelectEle.classList.add('mouse-selected');
                                }
                                if (spnSelectEle) spnSelectEle.style.background = '';
                            }
                            let length: any = this.selectedItems.length;
                            this.sessionStorageService.setSelecteditemsLength(length);
                            this.commonService.setSelectedItems(this.selectedItems);

                            if (event.item.data.guid.toLowerCase() === Control.Initials.toLowerCase()) {
                                let chInitRequiredContainer: any = document.getElementById('chInitRequiredContainer');
                                if (chInitRequiredContainer) {
                                    chInitRequiredContainer.classList.remove('place-on-initials-btn');
                                }
                            }

                            if (event.item.data.guid.toLowerCase() === Control.Radio.toLowerCase() || event.item.data.guid.toLowerCase() === Control.Checkbox.toLowerCase()) {
                                this.controlPropertyService.setRadioCheckBoxGroupChecked(componentRef.instance.ctrlObj.id, componentRef.instance.ctrlObj.textFormatting);

                                let radioCheckboxSize: any = componentRef.instance.ctrlObj.textFormatting.size;
                                if (radioCheckboxSize) {
                                    let defaultSize: any = this.controlPropertyService.getRadioCheckBoxSizeVal(radioCheckboxSize);
                                    this.controlPropertyService.applyRadioAndCheckBoxSizes(defaultSize, resizableEle, spnSelectEle);
                                }
                            }

                            this.controlPropertyService.showRequiredIcon(componentRef.instance.ctrlObj.id);
                            setTimeout(() => {
                                this.updateCtrlTextFormatting(componentRef.instance.ctrlObj.id, '0');
                            }, 250);
                        }, 100);
                    }
                    this.updateJsonArrays(controlName, controlClassName, componentRef.instance.ctrlObj, componentRef.instance.ctrlObj.dataGuid);
                    this.performUndoRedoAction('ADD_ITEM', [componentRef.instance.ctrlObj]);
                    this.allControlsArr.push(componentRef.instance.ctrlObj);
                }
            }
        }
        if (event.previousContainer.data) {
            this.dragItems = this.dragItems.filter((f) => !f.temp);
        }
        this.getControlsCount(event, '', '');

        this.slDropDownControlItems = [];
        if (event && event.item && event.item.data && event.item.data.guid.toLowerCase() === Control.Date.toLowerCase()) {
            if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.dateFormatID) {
                this.selectDefaultDateFormat = this.controlPropertyService.getDefaultDateOption(this.envelope.envelopeDetails.dateFormatID);
            } else {
                this.selectDefaultDateFormat = "1";
            }
        }
        this.selectedPanelRecipentTextAreaText = '';
        this.arrCopyPasteCtrl = [];
        this.arrUndoRedoCtrl = [];
        this.arrCopyPasteCtrlsTemp = [];
        this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
        this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrUndoRedoCtrl));
        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));
        this.hidePagesViewSection = true;
        setTimeout(() => {
            this.controlPropertyService.expandAllProp();
            //if (event.item.data.guid.toLowerCase() === Control.DropDown.toLowerCase()) {
            //this.openModal('', 'dropdownlist', ''); //will do in next sprint
            //}
            if (event.item.data.guid.toLowerCase() === Control.Text.toLowerCase()) {
                let addTextTextarea: any = document.getElementById('addTextTextarea');
                addTextTextarea.setAttribute('maxlength', '400');
            }
        }, 50);
    }

    updateJsonArrays(controlName: any, controlClassName: any, ctrlObj: any, data_guid: any) {
        this.controlPropertyService.updateJsonArrays(controlName, controlClassName, ctrlObj, data_guid);
        if (data_guid.toLowerCase() === Control.Radio.toLowerCase() || data_guid.toLowerCase() === Control.Checkbox.toLowerCase()
            || data_guid.toLowerCase() === Control.DropDown.toLowerCase() || data_guid.toLowerCase() === Control.Text.toLowerCase()) {
            this.showHideDependenciesButton();
        }
    }

    getSameControlNumberCount(ctrlGuid: any, pageNum: any) {
        // let allImageControlsString: any = localStorage.getItem('AllImageControls');
        let allImageControlsString: any = this.sessionStorageService.getAllImageControls();
        if (allImageControlsString) {
            this.arrControlIdNumber = JSON.parse(allImageControlsString);
        }

        let sameCtrlArray: any = this.arrControlIdNumber.filter(function (obj: any) { return obj.DataGuid.toLowerCase() == ctrlGuid.toLowerCase() && obj.PageNo == pageNum });

        if (sameCtrlArray && sameCtrlArray.length > 0) return sameCtrlArray.length + 1;
        else return 1;
    }

    getControlsCount(e: any, ctrlPageNumber: any, type: any) {
        let id: any;
        if (e && e.event && e.event.target && e.event.target.id) id = e.event.target.id;
        else if (ctrlPageNumber && ctrlPageNumber != '') id = ctrlPageNumber;

        let totalControlCount: any = 0;
        let count: any = 0;
        let documentImages: any = document.querySelectorAll('.img-control');
        Array.from(documentImages).forEach((ele: any, index: any) => {
            if (ele.id.split('_')[0] === 'imgControl') {
                let imgCtrl: any = document.getElementById('imgControl_' + ele.id.split('_')[1]);
                let signerCtrls: any = imgCtrl.getElementsByClassName('signControl');
                count = signerCtrls.length;
                if (count > 0) {
                    this.imagesArr[index].isActive = true;
                } else {
                    this.imagesArr[index].isActive = false;
                }
                this.imagesArr[index].controlsCount = count;
            }
        });

        let documentImagesControlsArray: any = document.querySelectorAll('.signControl');
        let temptotalControlCount: any = 0;
        setTimeout(() => {
            if (documentImagesControlsArray && documentImagesControlsArray.length > 0) {
                documentImagesControlsArray.forEach((ele: any, index: any) => {
                    let resizableCtrlEle: any = document.getElementById('resizable_' + ele.id);
                    if (resizableCtrlEle && resizableCtrlEle.style.display !== 'none') {
                        temptotalControlCount = temptotalControlCount + 1;
                    }
                })
                totalControlCount = temptotalControlCount;
                this.temptotalControlCount = temptotalControlCount;
                if (this.deleteCtrlItemsFlag === false) {
                    if (this.allControlsArr.length === 0 && temptotalControlCount === 0) {
                        this.undoFlag = 'false';
                    } else if (temptotalControlCount > 0) {
                        this.undoFlag = 'true';
                    } else {
                        this.undoFlag = 'false';
                    }
                }

                this.controlPropertyService.disableOrEnableClearAllIcon(temptotalControlCount);
            }
            //checking id is getting NaN, then updating page number which is last dropped control page
            if (isNaN(parseInt(id))) {
                for (const [ctrlObjId, componentRef] of this.dynamicComponentRefs) {
                    if (componentRef.instance && componentRef.instance.ctrlObj && componentRef.instance.ctrlObj.pageNo) {
                        id = parseInt(componentRef.instance.ctrlObj.pageNo);
                    }
                }
            }

            let pgNumber: any = parseInt(id);
            if (type === ControlConfig.Existing) {
                pgNumber = 1;
            }
            else {
                if (isNaN(parseInt(id))) {
                    // let pNumber: any = localStorage.getItem('currentPagination');
                    let pNumber: any = this.sessionStorageService.getCurrentPagination();
                    pgNumber = parseInt(pNumber);
                }
            }
            this.updateTempTotalCountPerPage();
            this.commonService.updatePrevObject({
                'totalControls': totalControlCount,
                'pageNumber': pgNumber,
                'tempTotalCountPerPage': this.tempTotalCountPerPage
                // 'documentList': this.imagesArr
            });
        }, 200);
    }

    exited(event: any) {
        const currentIdx = event.container.data.findIndex(
            (f: any) => f.id === event.item.data.id
        );
        this.dragItems.splice(currentIdx + 1, 0, {
            ...event.item.data,
            temp: true,
        });
    }

    entered() {
        this.dragItems = this.dragItems.filter((f) => !f.temp);
    }

    showControlFields(cName: any) {
        this.isClose = false;

        // Map between control name values and properties
        const cNamePropertiesMap: any = {
            'signature': 'signatureProperties',
            'initials': 'initialsProperties',
            'newinitials': 'initialsProperties',
            'date & time stamp': 'datetimeProperties',
            'datetimestamp': 'datetimeProperties',
            'name': 'nameProperties',
            'title': 'titleProperties',
            'company': 'companyProperties',
            'email': 'emailProperties',
            'dropdown': 'dropdownProperties',
            'checkbox': 'checkboxProperties',
            'radio': 'radioProperties',
            'date': 'dateProperties',
            'text': 'textProperties',
            'label': 'labelProperties',
            'hyperlink': 'hyperlinkProperties'
        };

        // Initialize all properties to false
        this.controlsObj = Object.fromEntries(Object.keys(cNamePropertiesMap).map(key => [cNamePropertiesMap[key], false]));

        // Set the corresponding property to true based on control name
        const propertyKey = cNamePropertiesMap[cName];
        if (propertyKey) {
            this.controlsObj[propertyKey] = true;
        }

        this.hideControlViewSection = false;
        if (this.isAddColumnSpace === true) {
            this.hideControlViewSection = true;
        }
        this.updateExtendDocumentWidth();

        if (cName === 'text') {
            setTimeout(() => {
                this.checkTextFields();

                this.controlPropertyService.resetTextAreaInputs();

                if (this.integrationResponse && this.integrationResponse.envelopeDetails) {
                    this.externalIntegrationType = this.integrationResponse.envelopeDetails.integrationType; //Vincere, Bullhorn
                    if (this.externalIntegrationType && (this.externalIntegrationType.toLowerCase() === 'vincere' || this.externalIntegrationType.toLowerCase() === 'bullhorn')) {
                        setTimeout(() => {
                            // let currentControlId: any = localStorage.getItem('CurrentClickedDroppedControl');
                            let currentControlId: any = this.sessionStorageService.getCurrentClickedDroppedControl();
                            let ctrlEle: any = document.getElementById('resizable_' + currentControlId);
                            let dataEntityField: string = '';
                            let dataEntity: string = '';
                            if (ctrlEle) {
                                dataEntityField = ctrlEle.getAttribute('data-EntityField');
                                dataEntity = ctrlEle.getAttribute('data-Entity');
                            }
                            if (dataEntity && dataEntityField) {
                                this.updateIntegrationFields(dataEntity, dataEntityField);
                            }
                        }, 10);
                        this.externalEntityList = this.integrationResponse.envelopeDetails.entityList;
                        this.externalEntityFieldList = this.integrationResponse.envelopeDetails.entityFieldsList;
                    } else {
                        this.externalIntegrationType = '';
                    }
                } else {
                    this.externalIntegrationType = '';
                }

            }, 300);
        }
    }

    //search functionalty
    onFilterApply(event: any) {
        let array: any[] = this.commonConstants.DragItems;
        let searchtext: string;

        let inputVal: any = document.getElementById('search-text');
        if (inputVal && inputVal.value.length) {
            searchtext = inputVal.value;
            // this.dragItems = array.filter(item => item['title'].toLowerCase().includes(searchtext.toLowerCase()));
            this.dragItems = array.filter(item => this.getLanguageTranslationValue(item.langGuid).toLowerCase().includes(searchtext.toLowerCase()));
        } else {
            this.dragItems = this.commonConstants.DragItems;
        }

        const removeDuplicates = (arr: any[], property: string) => {
            return arr.filter((value, index, self) => {
                return self.findIndex((v) => v[property] === value[property]) === index;
            });
        };

        // Use the function to remove duplicates
        this.dragItems = removeDuplicates(this.dragItems, 'title');
    }

    //Method to be called when scrolling
    onScroll(event: any) {
        let scrollTop: any = event?.target.scrollTop; // Scroll top value
        const images = document.querySelectorAll('.img-control');

        if (images && images.length) {
            // Initialize a variable to store the index of the topmost visible image
            let topImageIndex = images.length;
            let pageVal: any = -1;

            // Iterate over the images to find the topmost visible image
            for (let i = 0; i <= images.length; i++) {
                const image: any = images[i];
                // Check if the top of the image is within the visible area of the container
                if (image && image.offsetTop && image.offsetTop > (scrollTop + 200)) {
                    topImageIndex = i;
                    pageVal = topImageIndex;
                    this.paginationComponent.updatedPageNoOnScroll(event, pageVal);
                    break; // Once we find the topmost visible image, we can stop iterating
                } else {
                    if (pageVal == -1) {
                        this.paginationComponent.updatedPageNoOnScroll(event, images.length);
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Remove click event listener from document body
        document.body.removeEventListener('click', this.onClickOutsideSelectionArea);

        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    isControlingField(currentControlId: any) {
        return this.controlPropertyService.isControlingField(currentControlId);
    }

    addDropdownOption() {
        let dropdownListEle: any = document.getElementById('dropdownList');
        let dropDownList: any = [];
        let isFirstLineBlank: any = "false";
        let selectDropdownControlField: any = (document.getElementById('spn' + this.currentControlId)) as HTMLSelectElement;
        let resizable_DropdownControlField: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
        if (resizable_DropdownControlField) {
            isFirstLineBlank = resizable_DropdownControlField.getAttribute('data-firstlineblank');
            if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                    var text = selectDropdownControlField.options[index].text;
                    var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                    dropDownList.push({ value: text, editMode: false, editValue: '', ruleId: ruleId });
                }

                if (isFirstLineBlank == "true") {
                    let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == '');
                    if (arrayObj && arrayObj.length == 0) { //add empty filed
                        dropDownList.splice(0, 0, { value: '', editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                    }
                }
                else {
                    //remove empty filed
                    dropDownList = dropDownList.filter((obj: any) => obj.value.toLowerCase().trim() != '');
                }
            }
            else {
                if (isFirstLineBlank == "true") {
                    let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == '');
                    if (arrayObj && arrayObj.length == 0) { //add empty filed
                        dropDownList.push({ value: '', editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                    }
                }
            }

            if (dropdownListEle && dropdownListEle.value && dropdownListEle.value != '' && dropdownListEle.value.trim() != '') {
                let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == dropdownListEle.value.toLowerCase());
                if (arrayObj && arrayObj.length == 0) {
                    dropDownList.push({ value: dropdownListEle.value, editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                    dropdownListEle.value = '';
                }
                else {
                    dropdownListEle.value = ''; this.toastrService.warning('Value already exists.', 'Warning'); return;
                }
            }
            else {
                this.toastrService.warning("Value should not be empty or blank.", 'Warning'); return;
            }

            //Now append new options to exisitng options
            this.slDropDownControlItems = [];
            if (selectDropdownControlField && selectDropdownControlField.options) { selectDropdownControlField.options.length = 0; }
            for (var i = 0; i < dropDownList.length; i++) {
                var option = '<option data-select-option-id= "' + dropDownList[i].ruleId + '" value="' + (i) + '" >' + dropDownList[i].value + '</option>';
                if (selectDropdownControlField)
                    selectDropdownControlField.insertAdjacentHTML('beforeend', option);
                this.slDropDownControlItems.push({ value: dropDownList[i].ruleId, text: dropDownList[i].value });
            }

            dropDownList = dropDownList.sort((a: any, b: any) => a.value.length < b.value.length ? 1 : -1);
            let drpOptionLongText: any = dropDownList && dropDownList.length > 0 ? dropDownList[0] : ''

            if (drpOptionLongText) {
                this.commonService.expandDropdownWidth(resizable_DropdownControlField, selectDropdownControlField, drpOptionLongText.value);
            }
        }
    }

    openModal(e: any, modalName: any, type: any) {
        // this.inputControl.nativeElement.focus();
        //this.currentControlId = localStorage.getItem('CurrentClickedDroppedControl');
        if (e) {
            e.preventDefault();
        }
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.currentControlId = data.id;
                // localStorage.setItem('CurrentClickedDroppedControl', this.currentControlId);
                this.sessionStorageService.setCurrentClickedDroppedControl(this.currentControlId);
            });

        if (modalName === 'dropdownlist') {
            let dropdownListEle: any = document.getElementById('dropdownList');
            // let textFocus:any = document.getElementById('text-focus');
            // textFocus.focus();
            let dropDownList: any = [];
            let isFirstLineBlank: any = "false";
            let selectDropdownControlField: any = (document.getElementById('spn' + this.currentControlId)) as HTMLSelectElement;
            let resizable_DropdownControlField: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
            if (resizable_DropdownControlField) {
                isFirstLineBlank = resizable_DropdownControlField.getAttribute('data-firstlineblank');
            }

            if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                    var text = selectDropdownControlField.options[index].text;
                    var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                    dropDownList.push({ value: text, editMode: false, editValue: '', ruleId: ruleId });
                }

                if (isFirstLineBlank == "true") {
                    let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == '');
                    if (arrayObj && arrayObj.length == 0) { //add empty filed
                        dropDownList.splice(0, 0, { value: '', editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                    }
                }
                else {
                    //remove empty filed
                    dropDownList = dropDownList.filter((obj: any) => obj.value.toLowerCase().trim() != '');
                }
            }
            else {
                if (isFirstLineBlank == "true") {
                    let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == '');
                    if (arrayObj && arrayObj.length == 0) { //add empty filed
                        dropDownList.push({ value: '', editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                    }
                }
            }

            if (dropdownListEle && dropdownListEle.value && dropdownListEle.value != '') {
                let arrayObj: any = dropDownList.filter((obj: any) => obj.value.toLowerCase() == dropdownListEle.value.toLowerCase());
                if (arrayObj && arrayObj.length == 0) {
                    dropDownList.push({ value: dropdownListEle.value, editMode: false, editValue: '', ruleId: this.commonService.generateUUID() });
                }
                dropdownListEle.value = '';
            }

            let dropdownListObj: any = {
                option: dropDownList.length > 0 ? dropDownList : '',
                currentDropDownControlId: this.currentControlId
            }
            let bsModalDropdownlistRef: any = this.modalService.show(AddDropdownListComponent, {
                initialState: { dropdownListObj: dropdownListObj }, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw'
            });

            bsModalDropdownlistRef.content.dropdownListChangesMade.subscribe((data: any) => {
                // Update parent component based on the changes
                if (data && data.finalCurrentControlDropDownOptions && data.finalCurrentControlDropDownOptions.dropDownListOptions && data.finalCurrentControlDropDownOptions.dropDownListOptions.length > 0) {
                    let drpOptions = data.finalCurrentControlDropDownOptions.dropDownListOptions;
                    let selectDropdownControlField: any = (document.getElementById('spn' + data.currentDropDownControlId)) as HTMLSelectElement;

                    //Get the existing option values for this control. If any new option is not there in existing option then remove that option which is set as 'data-parent-rule-id'
                    if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                        for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                            var text = selectDropdownControlField.options[index].text;
                            var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                            var ditem = drpOptions.filter((i: any) => { if (i.optionVal == text) { return i; } });
                            if (ditem && ditem.length == 0) {
                                let removeParentRuleItem: any = document.querySelectorAll('[data-parent-rule-id="' + ruleId + '"]');
                                if (removeParentRuleItem && removeParentRuleItem.length > 0) {
                                    Array.from(removeParentRuleItem).forEach((ritem: any, index: any) => {
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-id', '');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-rule-id', '');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-support-text-content', '');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-select-option-id', '');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('is-parent-present', '');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.classList.remove('alreadyDependentField');
                                        document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.classList.remove('addedAsChild');
                                        this.controlPropertyService.removeColorForItem(ritem.getAttribute('id'));
                                    });
                                }
                            }
                        }

                        //remove all existing options
                        selectDropdownControlField.options.length = 0;
                    }

                    //Now append new options to exisitng options
                    this.slDropDownControlItems = [];
                    for (var i = 0; i < drpOptions.length; i++) {
                        var option = '<option data-select-option-id= "' + drpOptions[i].id + '" value="' + (i) + '" >' + drpOptions[i].optionVal + '</option>';
                        if (selectDropdownControlField)
                            selectDropdownControlField.insertAdjacentHTML('beforeend', option);
                        this.slDropDownControlItems.push({ value: drpOptions[i].id, text: drpOptions[i].optionVal });
                    }
                }
                else {
                    let dropdownControlField: any = (document.getElementById('spn' + this.currentControlId)) as HTMLSelectElement;
                    if (dropdownControlField && dropdownControlField.options) {
                        dropdownControlField.options.length = 0;
                        this.slDropDownControlItems = [];
                    }
                }

                if (data && data.finalCurrentControlDropDownOptions && data.finalCurrentControlDropDownOptions.optionLongText) {
                    this.commonService.expandDropdownWidth(resizable_DropdownControlField, selectDropdownControlField, data.finalCurrentControlDropDownOptions.optionLongText);
                }

                let isEmptyOptionExists: any = this.slDropDownControlItems.filter((obj: any) => obj.text.toLowerCase() == '');
                if (isEmptyOptionExists && isEmptyOptionExists.length == 0) {
                    if (resizable_DropdownControlField) {
                        resizable_DropdownControlField.setAttribute('data-firstlineblank', "false");
                    }
                }

                //if no dependencies are available then delete attribute and color
                let mainDropdownControlField: any = (document.getElementById(this.currentControlId)) as HTMLSelectElement;
                let data_document_content_id: any = mainDropdownControlField.getAttribute('data-document-content-id');
                let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + data_document_content_id + '"]');
                if (childelementsArray && childelementsArray.length == 0) {
                    this.controlPropertyService.removeAttributes(data_document_content_id);
                    this.controlPropertyService.removeColorForItem(mainDropdownControlField.getAttribute('id'));
                }

                //console.log('Changes from modal:', data);
            });

        }
        else if (modalName === 'adddependencies') {
            setTimeout(() => {
                if (this.displayDependenciesButton == true) {
                    let isValid: boolean = true;
                    if (document.querySelector('[data-int-control-id="' + this.currentControlId + '"]')?.classList.contains('dropdownItem') == true) {
                        let selectDropdownControlField: any = (document.getElementById('spn' + this.currentControlId)) as HTMLSelectElement;
                        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length == 0) {
                            this.toastrService.warning("Please add list items for the selected dropdown control.", 'Warning');
                            isValid = false;
                        }
                    }

                    if (isValid) {
                        let dependencyObj: any = {
                            type: type == '1' ? '1' : '',
                            groupColorCode: this.groupColorCode,
                            currentControlId: this.currentControlId,
                            isControlingField: this.isControlingField(this.currentControlId)
                        }
                        let bsModalAddDependenciesRef: any = this.modalService.show(AddDependenciesComponent, {
                            initialState: { dependencyIconClickedObj: dependencyObj }, keyboard: false, backdrop: 'static', class: 'modal-xl modal-mt'
                        });

                        bsModalAddDependenciesRef.content.addDependencieschangesMade.subscribe((data: any) => {
                            // Update your parent component based on the changes
                            this.groupColorCode = data.controlColorCodeIndex;
                            let showDependenciesPopUpModal: boolean = data.showDependenciesPopUpModal;
                            if (showDependenciesPopUpModal == true) {
                                this.openModal('', 'adddependencies', '1');
                            }
                            //console.log('Changes from modal:', data);
                        });
                    }
                }
            }, 350); //300
        }
        else if (modalName === 'viewsetting') {
            let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
            if (this.authService.isTokenExists() == "false") {
                this.hostListenerStatus = true;
                this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
            }
            else {
                this.modalService.show(ViewSettingComponent, { initialState: {}, keyboard: false, backdrop: 'static', class: 'modal-lg modal-mt' });
            }
        }
        else if (modalName === 'clearallcontrols') {
            if (this.isEnvelopeEditable == true) {
                let bsModalClearallControlsRef: any = this.modalService.show(ClearallControlsComponent, { initialState: {}, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });
                bsModalClearallControlsRef.content.clearallChangesMade.subscribe((data: any) => {
                    this.clearAllControls(data);
                });
            }
        }
        // else if (modalName === 'discardenvelope') {
        //     if (this.isEnvelopeEdited != true) {
        //         const modalRef = this.modalService.show(DiscardEnvelopeComponent, { initialState: {}, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });

        //         modalRef.content?.onCheckDiscardEnvelope.subscribe((popUpStatus: any) => {
        //             //console.log("onCheckDiscardEnvelope", popUpStatus.status);
        //             if (popUpStatus.status.toLowerCase() === 'ok') {
        //                 this.hostListenerStatus = true;
        //             }
        //             else {
        //                 this.hostListenerStatus = false;
        //             }
        //         });
        //     }
        // }
        else if (modalName === 'keybordKeys') {
            this.modalService.show(KeyboardShortcutsComponent, { initialState: {}, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });
        }
        else if (modalName === 'backModal') {
            if (this.disableBackArrowButton == "false") {
                let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
                if (this.authService.isTokenExists() == "false") {
                    this.hostListenerStatus = true;
                    this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
                }
                else {
                    let modalRef: any = this.modalService.show(GotoStepOneComponent, { initialState: {}, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });
                    modalRef.content?.gotoStepOneChangesMade.subscribe((popUpStatus: any) => {
                        this.onGotoStepOne(popUpStatus.status);
                    });
                }
            }
        }
        else if (modalName === 'branding') {
            if (this.slides != null && this.slides != undefined && this.slides.length > 0) {
                let bsModalbrandingRef: any = this.modalService.show(MultiBrandingComponent, { initialState: { slides: this.slides, isEnvlpEdited: this.isEnvelopeEdited }, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw branding' });
                bsModalbrandingRef.content.multiBrandingChangesMade.subscribe((data: any) => {
                    // Update your parent component based on the changes
                    data.slides.map((item: any) => {
                        if (item.isDefault) {
                            this.branding = `[${JSON.stringify(item)}]`;
                        }
                    })
                });
            } else {
                this.toastrService.warning(this.commonConstants.NoBrandingMessage, 'Attention');
            }
        }
        else if (modalName === 'multiplesignerupdate') {
            let selectedControlItemsObj: any = {
                selectedItems: this.selectedItems,
                currentControlId: this.currentControlId,
            }
            let modalRef: any = this.modalService.show(MultipleSignerUpdateComponent, { initialState: { selectedItemsObj: selectedControlItemsObj }, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });
            modalRef.content?.multipleSignerUpdateChangesMade.subscribe((popUpStatus: any) => {
                if (popUpStatus.status == "1") {
                    this.controlPropertyService.removeCtrlSelectionBgColor(this.selectedItems);
                    // this.alignControlsService.applyAlignChanges('', this.selectedItems, this.selectedDocumentImage, this.container)
                    this.selectedItems = [];
                    this.commonService.selectedItems = [];
                }
            });
        }
    }

    removeControl(ctrlId: any, event: any) {
        const dynamicComponentRef = this.dynamicComponentRefs.get(ctrlId);
        if (dynamicComponentRef) {
            let ctrlContentId: any = '';
            let tobeDeletedCtrl = (<HTMLInputElement>document.getElementById(ctrlId));
            let ctrlPageNumber: any;
            if (tobeDeletedCtrl) {
                ctrlPageNumber = tobeDeletedCtrl.getAttribute('data-pageno');
                ctrlContentId = tobeDeletedCtrl.getAttribute('data-document-content-id');
            }

            // dynamicComponentRef.destroy();
            // this.dynamicComponentRefs.delete(ctrlId);
            this.isClose = true;
            this.hideControlViewSection = this.isClose;
            this.activeCtrlId = 0;
            // this.selectedItems = [];
            if (this.selectedItems && this.selectedItems.length > 0) {
                this.selectedItems = this.selectedItems.filter(item => item !== 'resizable_' + ctrlId);
            }
            if (this.ctrlAndClickedControlsArr && this.ctrlAndClickedControlsArr.length > 0) {
                this.ctrlAndClickedControlsArr = this.ctrlAndClickedControlsArr.filter((item: any) => item !== 'resizable_' + ctrlId);
            }

            //Delete the control from local storage arrays
            this.controlPropertyService.updateDependecyForDependentControls(ctrlId, ctrlContentId);
            setTimeout(() => {
                this.showHideDependenciesButton();
                this.getControlsCount(event, ctrlPageNumber, '');
                //this.controlPropertyService.updateUndoRedoCopyPasteControlArray(ctrlId);
                this.updateExtendDocumentWidth();
            }, 10); //50
        }
    }

    removeControlComponent(event: any, id: any) {
        this.isAddColumnSpace = this.hideControlViewSection;
        if (id === '') {
            // id = localStorage.getItem('CurrentClickedDroppedControl');
            id = this.sessionStorageService.getCurrentClickedDroppedControl();
        }

        setTimeout(() => {
            const dynamicComponentRef = this.dynamicComponentRefs.get(id);
            if (dynamicComponentRef) {
                // this.removeControl(id, event);
                this.deleteItems(['resizable_' + id]);

                this.isClose = true;
                this.activeCtrlId = 0;
                this.hidePagesViewSection = false;
                this.hideControlViewSection = this.isClose;
            }
        }, 25);
    }

    getDragActiveID(currentObj: any) {
        return this.dragItems.findIndex((item) => item.title === currentObj.controlName) + 1;
    }

    applyRecipientDetailsToControl(currentSelectedControl: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            resizableCurrentControl.setAttribute('data-rcptid', this.selectedPanelRecipentId);
            resizableCurrentControl.setAttribute('data-original-rcptid', this.selectedPanelRecipentId);
            resizableCurrentControl.setAttribute('data-recp-color', this.selectedPanelRecipentColor);
            resizableCurrentControl.style.outline = 'none';
            resizableCurrentControl.style.outline = this.selectedPanelRecipentColor + ' solid 1px';
            resizableCurrentControl.setAttribute('data-original-title', this.controlPropertyService.getControlOriginalTitle(this.selectedPanelRecipentId, currentSelectedControl));

            this.controlPropertyService.updateRecipientsInStorage(currentSelectedControl, this.selectedPanelRecipentId);
        }
    }

    //Apply Recipient details
    updateRecipientPanelControlProperties() {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            let recpId: any = resizableCurrentControl.getAttribute("data-rcptid");
            let recpColor: any = resizableCurrentControl.getAttribute("data-recp-color");
            let dataGuid: any = resizableCurrentControl.getAttribute("data-guid");
            if (dataGuid.toLowerCase() == Control.Text.toLowerCase()) {
                this.selectedPanelRecipentColor = recpColor;
                let recpArrayObj = this.receipentListArr.filter((c: any) => c.id == recpId);
                if (recpArrayObj.length > 0) {
                    this.selectedPanelRecipentName = recpArrayObj[0].name;
                    this.selectedPanelRecipentId = recpArrayObj[0].id;
                }

                setTimeout(() => {
                    let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                    let innerText: any = spnCurrentSelectedControl?.innerHTML;
                    let addTextTextarea: any = document.getElementById("addTextTextarea");
                    let addtextareaval: any = resizableCurrentControl.getAttribute('data-addtextareaval');
                    if (addtextareaval === null || addtextareaval === undefined || addtextareaval === '') {
                        addTextTextarea.value = '';
                    }
                    else {
                        if (spnCurrentSelectedControl && innerText != undefined && innerText != null && innerText != '' && innerText.toLowerCase() != 'text') {
                            this.selectedPanelRecipentTextAreaText = innerText.toLowerCase() != 'text' ? innerText : '';
                            addTextTextarea.value = resizableCurrentControl.getAttribute('data-asteriskOriginalText');
                        }
                        else {
                            this.selectedPanelRecipentTextAreaText = '';
                            if (resizableCurrentControl && resizableCurrentControl.getAttribute('data-asteriskOriginalText')) {
                                addTextTextarea.value = resizableCurrentControl.getAttribute('data-asteriskOriginalText');
                            } else {
                                addTextTextarea.value = '';
                            }
                        }
                    }
                }, 500);
            }
        }
    }

    applyStylesToSelectedRecipient(recipientId: any, type: any, isClicked: any) {
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.currentControlId = data.id;
                // localStorage.setItem('CurrentClickedDroppedControl', this.currentControlId);
                this.sessionStorageService.setCurrentClickedDroppedControl(this.currentControlId);
            });

        //apply other styles
        setTimeout(() => {
            // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
            let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
            let resizableCurrentSelectedControl: any = document.getElementById("resizable_" + currentSelectedControl);
            let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
            if (resizableCurrentSelectedControl) {
                this.selectedPanelRecipentName = this.controlPropertyService.getRecipientName(resizableCurrentSelectedControl.getAttribute('data-rcptid'));
                this.selectedPanelRecipentColor = resizableCurrentSelectedControl.getAttribute('data-recp-color');
                this.controlPropertyService.removeControlActiveClass("clicked");

                resizableCurrentSelectedControl.style.outline = 'none';
                resizableCurrentSelectedControl.style.outline = this.selectedPanelRecipentColor + ' solid 3px';
                resizableCurrentSelectedControl.classList.add('control-active');
                if (this.isEnvelopeEditable) {
                    resizableCurrentSelectedControl.classList.add('control-active-bg-color');
                }

                let dataguid: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                if (dataguid.toLowerCase() == Control.Label.toLowerCase() || dataguid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
                    resizableCurrentSelectedControl.style.outline = 'none';
                    resizableCurrentSelectedControl.style.outline = '#d21513 solid 3px';
                }

                let attr_id: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                if (attr_id.toLowerCase() == Control.Radio.toLowerCase()) {//display group secion
                    this.displayGroupRadio = true; this.displayGroupCheckBox = false;
                }
                else if (attr_id.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    if (spnCurrentSelectedControl) {
                        let attr_name: any = spnCurrentSelectedControl.getAttribute('name');
                        let checkboxgroup: any = (<HTMLInputElement>document.getElementById("checkboxgroup"));
                        if (attr_name == '' || attr_name == null) {
                            this.displayGroupCheckBox = false; this.displayGroupRadio = false;
                            if (checkboxgroup) checkboxgroup.checked = false;
                        }
                        else {
                            this.displayGroupCheckBox = true; this.displayGroupRadio = false;
                            if (resizableCurrentSelectedControl.getAttribute('data-groupname') == null || resizableCurrentSelectedControl.getAttribute('data-groupname') == '') {
                                if (checkboxgroup) { checkboxgroup.checked = false; }
                            }
                            else {
                                if (checkboxgroup) { checkboxgroup.checked = true; }
                            }
                            //if (checkboxgroup) { checkboxgroup.checked = true; }
                        }
                    }
                }
                else { this.displayGroupCheckBox = false; this.displayGroupRadio = false; }

                setTimeout(() => {
                    this.controlPropertyService.expandAllProp();
                    //If it is dropdown control then add existing options
                    if (attr_id && attr_id.toLowerCase() == Control.DropDown.toLowerCase() && resizableCurrentSelectedControl) {
                        //Now append new options to exisitng options
                        this.slDropDownControlItems = [];
                        let selectDropdownControlField: any = (document.getElementById('spn' + currentSelectedControl)) as HTMLSelectElement;
                        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                            for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                                var text = selectDropdownControlField.options[index].text;
                                var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                                this.slDropDownControlItems.push({ value: ruleId, text: text });
                            }
                        }
                    }

                    //Applying radio and checkbox sizes
                    if (attr_id && (attr_id.toLowerCase() == Control.Checkbox.toLowerCase() || attr_id.toLowerCase() == Control.Radio.toLowerCase()) && resizableCurrentSelectedControl) {
                        let dataSizeVal: any = resizableCurrentSelectedControl.getAttribute('data-size');
                        if (dataSizeVal) this.selectDefaultSizeValue = this.controlPropertyService.getRadioCheckBoxSizeVal(dataSizeVal);
                        else resizableCurrentSelectedControl.setAttribute('data-size', this.controlPropertyService.getRadioCheckBoxSize(this.selectDefaultSizeValue));

                        if (attr_id && attr_id.toLowerCase() == Control.Checkbox.toLowerCase() && resizableCurrentSelectedControl) {
                            let dataIsGroupChecked: any = resizableCurrentSelectedControl.getAttribute('data-isgroupchecked');
                            if (dataIsGroupChecked == "true" || this.ischeckboxGroup == true) {
                                resizableCurrentSelectedControl.setAttribute('data-isgroupchecked', "true");
                                this.displayGroupCheckBox = true;
                                if (resizableCurrentSelectedControl.getAttribute('data-groupname') == null || resizableCurrentSelectedControl.getAttribute('data-groupname') == '') {
                                    resizableCurrentSelectedControl.setAttribute('data-groupname', this.checkboxGroupName == null || this.checkboxGroupName == undefined ? '' : this.checkboxGroupName);
                                    spnCurrentSelectedControl.setAttribute('name', this.checkboxName == null || this.checkboxName == undefined ? '' : this.checkboxName);
                                }

                            }
                            else {
                                this.ischeckboxGroup = false;
                                this.displayGroupCheckBox = false;
                            }
                        }

                        if (attr_id && attr_id.toLowerCase() == Control.Radio.toLowerCase() && resizableCurrentSelectedControl) {
                            if (resizableCurrentSelectedControl.getAttribute('data-groupname') == null || resizableCurrentSelectedControl.getAttribute('data-groupname') == '') {
                                resizableCurrentSelectedControl.setAttribute('data-groupname', this.checkboxGroupName == null || this.checkboxGroupName == undefined ? '' : this.checkboxGroupName);
                                spnCurrentSelectedControl.setAttribute('name', this.checkboxName == null || this.checkboxName == undefined ? '' : this.checkboxName);
                            }
                        }
                    }

                    //Applying Date default format
                    if (attr_id && attr_id.toLowerCase() == Control.Date.toLowerCase() && resizableCurrentSelectedControl) {
                        let dateFormat: any = resizableCurrentSelectedControl.getAttribute('data-defaultFormat');
                        if (dateFormat) {
                            this.selectDefaultDateFormat = this.controlPropertyService.getDateFormatVal(dateFormat);
                            let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentSelectedControl))
                            if (spnControlEle) {
                                spnControlEle.innerHTML = this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat);
                            }
                        }
                        else resizableCurrentSelectedControl.setAttribute('data-defaultFormat', this.controlPropertyService.getDateFormat(this.selectDefaultDateFormat));
                    }

                    //Applying for Label or Hyperlink
                    if (attr_id && (attr_id.toLowerCase() == Control.Label.toLowerCase() || attr_id.toLowerCase() == Control.Hyperlink.toLowerCase()) && resizableCurrentSelectedControl) {
                        let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentSelectedControl));
                        let inputHyperlinkText: any = (<HTMLInputElement>document.getElementById('inputHyperlinkText'));
                        let inputHyperLinkURL: any = (<HTMLInputElement>document.getElementById('inputHyperLinkURL'));

                        let inputLabel: any = (<HTMLInputElement>document.getElementById('inputLabel'));
                        if (spnControlEle) {
                            if (spnControlEle.getAttribute('isupdated') == "true") {
                                if (inputHyperlinkText) inputHyperlinkText.value = spnControlEle.innerHTML;
                                if (inputLabel) inputLabel.value = spnControlEle.innerHTML;
                            }
                            else {
                                if (inputLabel) inputLabel.value = '';
                                if (inputHyperlinkText) inputHyperlinkText.value = '';
                            }
                        }

                        if (inputHyperLinkURL) {
                            let spnControlEle: any = (<HTMLInputElement>document.getElementById(currentSelectedControl));
                            if (spnControlEle) {
                                if (spnControlEle.getAttribute('data-hyperlink')) inputHyperLinkURL.value = spnControlEle.getAttribute('data-hyperlink');
                                else inputHyperLinkURL.value = '';
                            }
                        }
                    }

                    //Applying radio and checkbox sizes
                    if (attr_id && attr_id.toLowerCase() == Control.Email.toLowerCase()) {
                        resizableCurrentSelectedControl.setAttribute('data-confirm-submit', "0");
                    }
                    this.controlPropertyService.applyControlProperties(currentSelectedControl, resizableCurrentSelectedControl, attr_id);
                    if (this.hideDependencies == false) {
                        if (attr_id && attr_id.toLowerCase() != Control.Label.toLowerCase()
                            && attr_id.toLowerCase() != Control.Hyperlink.toLowerCase()
                            && attr_id.toLowerCase() != Control.DateTimeStamp.toLowerCase()
                        ) {
                            let divSelectedControl: any = document.getElementById(currentSelectedControl);
                            if (divSelectedControl) {
                                let dependencyObj: any = {
                                    currentDependentSelectedControl: currentSelectedControl,
                                    contentId: divSelectedControl.getAttribute('data-document-content-id'),
                                    isControlingField: this.isControlingField(currentSelectedControl)
                                };
                                this.commonService.callAddDependentFields(dependencyObj);
                            }
                        }
                    }

                }, 10);
            }
        }, 100); //150
    }

    showHideDependenciesButton() {
        let className: any = 'disabledCtrl';
        if (this.enableDependenciesFeature == true) {
            let resultObj: any = this.controlPropertyService.showHideDependenciesButton();
            this.displayDependenciesButton = resultObj.displayDependenciesButton;
            return className = resultObj.className;
        }
        else return className;
    }

    updateTextAreaText(event: any): void {
        let textAreaVal: string = event.target.value.trim(); // Trim spaces
        let currentSelectedControl: any = this.currentControlId;
        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        let spnStrlEle: any = document.getElementById('spn' + currentSelectedControl);
        if (ctrlEle && textAreaVal !== "") {
            let textInput: any = document.getElementById('addTextTextarea');
            let checkbox: any = document.getElementById('checkboxHideText');
            let OriginalText: any = ctrlEle.getAttribute('data-asteriskOriginalText');
            if (ctrlEle && OriginalText && !textAreaVal.includes('*')) {
                if (OriginalText !== textAreaVal) {
                    ctrlEle.setAttribute('data-asteriskOriginalText', textAreaVal);
                    textAreaVal = textAreaVal;
                }
                else {
                    ctrlEle.setAttribute('data-asteriskOriginalText', OriginalText);
                    textAreaVal = OriginalText;
                }
            }
            if (ctrlEle && !textAreaVal.includes('*')) {
                ctrlEle.setAttribute('data-isHideTextWithAsterisk', (checkbox && checkbox != null && (checkbox.checked == 'true' || checkbox.checked == true)) ? 'true' : 'false');
                let oldValue: any = ctrlEle.getAttribute('data-asteriskOriginalText');
                ctrlEle.setAttribute('data-asteriskOriginalText', textAreaVal.includes('*') ? oldValue !== "null" ? oldValue : '' : textAreaVal);
            }
            // Call toggleHideTextWithAsterisks if the checkbox is checked
            let isHideTextWithAsterisk: any = ctrlEle.getAttribute('data-isHideTextWithAsterisk');
            if (isHideTextWithAsterisk && (isHideTextWithAsterisk == 'true' || isHideTextWithAsterisk == true)) {
                this.toggleHideTextWithAsterisks(true);
            }
            //textAreaVal = (textAreaVal == this.hiddenText) ? this.asteriskOriginalText : textAreaVal;
            if (textInput) {
                textInput.value = textAreaVal;
            }
            // Save the text only if not empty (but we handle asterisks separately)
            this.controlPropertyService.updateTextAreaText(isHideTextWithAsterisk == 'true' || isHideTextWithAsterisk == true ? this.hiddenText : textAreaVal, currentSelectedControl);
        }
        else {
            if (ctrlEle && spnStrlEle) {
                ctrlEle.setAttribute('data-asteriskOriginalText', '');
                ctrlEle.setAttribute('data-addtextareaval', '');
                spnStrlEle.innerText = '';
                spnStrlEle.setAttribute('data-addtextareaval', '');
                this.asteriskOriginalText = '';
                this.hiddenText = '';
            }
            this.toastrService.warning('Add text value cannot be blank.', 'Warning');
        }
    }

    applyZoomChanges(e: any) {
        let zoomText: any = '100';
        if (e && e.target && e.target.text) {
            zoomText = e.target.text;
        }
        else if (e && e.target && e.target.innerHTML) {
            zoomText = e.target.innerHTML;
        }
        else if (e && e.target && e.target.innerText) {
            zoomText = e.target.innerText;
        }

        if (zoomText) {
            this.imageSize = parseInt(zoomText) / 100;
            this.zoomValue = parseInt(zoomText);
            let element: any = (document.getElementById('zoompage')) as HTMLSelectElement;
            if (this.imageSize === 0.5) {
                this.isActive = true;
                this.ZoomPercentageObj.zoomVal50 = true;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.add("zoom-50");
                element.classList.remove("zoom-75");
                element.classList.remove("zoom-more");
            }
            else if (this.imageSize === 0.75) {
                this.isActive = true;
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = true;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.remove("zoom-50");
                element.classList.add("zoom-75");
                element.classList.remove("zoom-more");
            }
            else if (this.imageSize === 1) {
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = true;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.remove("zoom-50");
                element.classList.remove("zoom-75");
            }
            else if (this.imageSize === 1.25) {
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = true;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.remove("zoom-50");
                element.classList.remove("zoom-75");
                element.classList.add("zoom-more");
            }
            else if (this.imageSize === 1.5) {
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = true;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.remove("zoom-50");
                element.classList.remove("zoom-75");
                element.classList.add("zoom-more");
            }
            else if (this.imageSize === 1.75) {
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = true;
                this.ZoomPercentageObj.zoomVal200 = false;
                element.classList.remove("zoom-50");
                element.classList.remove("zoom-75");
                element.classList.add("zoom-more");
                this.isActive = false;
            }
            else {
                this.ZoomPercentageObj.zoomVal50 = false;
                this.ZoomPercentageObj.zoomVal75 = false;
                this.ZoomPercentageObj.zoomVal100 = false;
                this.ZoomPercentageObj.zoomVal125 = false;
                this.ZoomPercentageObj.zoomVal150 = false;
                this.ZoomPercentageObj.zoomVal175 = false;
                this.ZoomPercentageObj.zoomVal200 = true;
                this.isActive = false;
                element.classList.remove("zoom-50");
                element.classList.remove("zoom-75");
                element.classList.add("zoom-more");
            }

        }
        this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'transform', `scale(${this.imageSize}, ${this.imageSize})`);
    }

    applyClickedProperty(event: any, type: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyClickedProperty(event.target.checked, type);
        let spanCurrentSelectedControl: any = document.getElementById('spn' + currentSelectedControl);
        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        let guid: any = resizableCurrentSelectedControl.getAttribute('data-guid');
        if (type === '1') {
            this.isRequiredChecked = event.target.checked;

            //required code
            this.controlPropertyService.showRequiredIcon(currentSelectedControl);
            //required code
        }
        else if (type === '2') {
            this.isChkPreSelected = event.target.checked;
            if (this.isChkPreSelected == true) {
                let mainParentEle: any = document.getElementById(currentSelectedControl);
                if (mainParentEle) {
                    spanCurrentSelectedControl.classList.add('checked-visible');
                    spanCurrentSelectedControl.setAttribute('checked', 'true');
                    let isChildrenPresent: any = mainParentEle.getAttribute('is-child-present');
                    let isParentPresent: any = mainParentEle.getAttribute('is-parent-present');
                    if (isParentPresent == "true" || isChildrenPresent == "true") {
                        let controlGroupCode: any = mainParentEle.getAttribute('group-code');
                        if (controlGroupCode != null && controlGroupCode != "null" && controlGroupCode != undefined && controlGroupCode != "undefined" && controlGroupCode != "") {
                            if (controlGroupCode != "0" && controlGroupCode != 0) {
                                let colorCode: any = this.commonConstants.DependencyColorCodes[parseInt(controlGroupCode) % 10].Color;
                                this.controlPropertyService.applyColorCodeForParent(currentSelectedControl, colorCode, "false");
                            }
                        }
                    }
                    else {
                        spanCurrentSelectedControl.style["background-color"] = 'green';
                    }
                    this.controlPropertyService.removeSameRadioNamePreselected(currentSelectedControl);
                }
            }
            else {
                spanCurrentSelectedControl.classList.remove('checked-visible');
                spanCurrentSelectedControl.removeAttribute("checked");
                spanCurrentSelectedControl.style["background-color"] = 'white';
            }
        }
        else if (type === '3') {
            this.isFixedWidthChecked = event.target.checked;
            if (event.target.checked === false || event.target.checked === 'false') {
                let ctelEle: any = document.getElementById('resizable_' + currentSelectedControl);
                if (ctelEle) {
                    ctelEle.setAttribute('maxlengthallowed', 20);
                }

            }
        }
        else if (type === '4') {
            const textAreaVal: any = document.getElementById('addTextTextarea');
            textAreaVal.value = textAreaVal.value == this.hiddenText ? this.asteriskOriginalText != 'null' ? this.asteriskOriginalText : '' : textAreaVal.value;
            if (event && event.target) {
                let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
                if (ctrlEle) {
                    this.asteriskOriginalText = ctrlEle.getAttribute('data-asteriskOriginalText');
                    ctrlEle.setAttribute('data-isHideTextWithAsterisk', event.target.checked);
                    ctrlEle.setAttribute('data-asteriskOriginalText', this.asteriskOriginalText !== '' ? this.asteriskOriginalText : textAreaVal.value);

                    if (event.target.checked) {
                        this.toggleHideTextWithAsterisks(event.target.checked);
                    }
                    if (textAreaVal !== null || textAreaVal !== "") {
                        this.controlPropertyService.updateTextAreaText(event.target.checked ? this.hiddenText : this.asteriskOriginalText != 'null' ? this.asteriskOriginalText : '', currentSelectedControl);
                        textAreaVal.value = this.asteriskOriginalText;
                    } else {
                        this.toastrService.warning('Add text value cannot be blank.', 'Warning');
                    }
                }
            }
        }

        if (type === '1' || type === '2') {
            if (guid.toLowerCase() == Control.Checkbox.toLowerCase() && type == "1" && event.target.checked == true) {
                this.closeDependecie(event);
            }
        }

        this.updateCtrlTextFormatting(currentSelectedControl, '1');

        if (type === '3') {
            this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
        }
    }

    applyCheckboxRadioGroupName(event: any, type: any) {
        var textAreaVal: any = event.target.value;
        const regex = /^[0-9a-zA-Z\ _]+$/;
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let resizableCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        if (event.target.value == "") {
            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
            this.toastrService.warning("Group Name should not be empty.", 'Warning');
        }
        else if (event.target.value != "" && event.target.value.length > 50) {
            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
            this.toastrService.warning("Group Name should not be more than 50 characters.", 'Warning');
        }
        else if (event.target.value != "" && !textAreaVal.match(regex)) {
            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
            this.toastrService.warning("Group Name should be only characters and numbers.", 'Warning');
        }
        else {
            this.controlPropertyService.applyCheckboxRadioGroupName(textAreaVal, type);
            this.updateCtrlTextFormatting(currentSelectedControl, '1');
        }
    }

    updateControlTooltip(e: any) {
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.currentControlId = data.id;
            });

        let controlTooltipEle: any = document.getElementById('controlInputTooltip');
        let resizableCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
        if (e && e.target && e.target.value && resizableCurrentSelectedControl) {
            resizableCurrentSelectedControl.setAttribute('data-customtooltip', e.target.value);
        }
    }

    checkTooltipIsApplicable(cName: any, cId: any) {
        this.controlPropertyService.checkTooltipIsApplicable(cName, cId);
    }

    applyFontFamily(e: any) {
        let fontFamily: any = e.target.value;
        this.defaultFontFamily = e.target.value;
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.updateDefaultFontFamilyId(currentSelectedControl, fontFamily);

        let fontSizeSelect: any = (<HTMLInputElement>document.getElementById('fontSizeSelect'));
        this.applyFontSize(fontSizeSelect.value, 1);

        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyFontSize(fontSize: any, type: any) {
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.currentControlId = data.id;
            });

        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        fontSize = parseInt(fontSize);
        let currentControlEle: any = (<HTMLInputElement>document.getElementById(this.currentControlId));
        let ctrlname: any = this.currentControlId.split('Control')[0];
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + this.currentControlId));
        let fontSizePtOrPxSelect: any;
        if (spnCurrentSelectedControl) {
            let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
            let newFontSize: any, lineHeight: any;
            if (resizableCurrentSelectedControl && resizableCurrentSelectedControl.style) {
                fontSizePtOrPxSelect = resizableCurrentSelectedControl.getAttribute('data-typography');
                if (fontSizePtOrPxSelect && fontSizePtOrPxSelect == "pt") {
                    newFontSize = this.controlPropertyService.setTypographyConversionValue(fontSize);
                    lineHeight = parseInt(newFontSize) + 2;
                }
                else {
                    newFontSize = fontSize;
                    lineHeight = fontSize;
                }
                fontSize = newFontSize;
                spnCurrentSelectedControl.style.fontSize = fontSize + '' + "px";

                if (ctrlname.toLowerCase() === 'name') {
                    spnCurrentSelectedControl.style.lineHeight = '';
                }
                else {
                    spnCurrentSelectedControl.style.lineHeight = lineHeight + '' + "px";
                }
            }

            if (resizableCurrentSelectedControl && resizableCurrentSelectedControl.style) {
                resizableCurrentSelectedControl.setAttribute('data-typography', fontSizePtOrPxSelect);
                let fontFamily: any = spnCurrentSelectedControl.style.fontFamily;
                this.setControlHeight(this.currentControlId);
                if (ctrlname === 'text' || ctrlname === 'name' || ctrlname === 'title') {
                    let charLength: any = 20;
                    let leftscroll: any = 0;
                    if (fontSize >= 16) {
                        let d: any = this.getDuplicateHeightWidth(fontFamily, fontSize);
                        resizableCurrentSelectedControl.style.height = d.Height + 'px';
                    }

                    let nameCharLengthId: any = document.getElementById('nameInputLength');
                    let textCharLengthId: any = document.getElementById('textInputLength');
                    let nameOrTextInputLengthEle: any = (ctrlname === 'text') ? textCharLengthId : nameCharLengthId;
                    let titleInputVal: any;
                    if (ctrlname === 'title') {
                        nameOrTextInputLengthEle = document.getElementById('titleInputLength');
                        if (nameOrTextInputLengthEle == null || nameOrTextInputLengthEle == undefined) {
                            titleInputVal = "20";
                        }
                        else {
                            titleInputVal = parseInt(nameOrTextInputLengthEle.value);
                        }
                    }
                    else {
                        titleInputVal = parseInt(nameOrTextInputLengthEle.value);
                    }
                    if (resizableCurrentSelectedControl.offsetHeight <= 20) {
                        if (nameOrTextInputLengthEle != null)
                            this.setNameOrTextControlDimension(parseInt(titleInputVal));
                        setTimeout(() => {
                            let aprxLength: any = this.getApproximateCharateLength(this.currentControlId);
                            let updatedNameOrTextInputLengthEle: any = resizableCurrentSelectedControl.getAttribute('maxlengthallowed');
                            if (aprxLength != parseInt(updatedNameOrTextInputLengthEle)) {
                                let errmsg: any = 'Control size out of bounds :' + ctrlname.toLowerCase() + ' control length reduced to ' + aprxLength + " characters.";
                                // this.toastrService.warning(errmsg, 'Warning');
                                resizableCurrentSelectedControl.setAttribute('maxlengthallowed', aprxLength);
                                nameOrTextInputLengthEle.value = aprxLength;

                                let title: any = 'approximately ' + aprxLength + ' characters or control boundary.';
                                resizableCurrentSelectedControl.setAttribute('title', title);
                            }
                        }, 500);
                    }
                    else {
                        if (titleInputVal) {
                            this.setNameOrTextControlDimension(parseInt(titleInputVal));
                        }
                    }
                }
                else {
                    if (spnCurrentSelectedControl && resizableCurrentSelectedControl) {
                        if (ctrlname === 'hyperlink') {
                            let spnPaddingLeft: any = parseInt(spnCurrentSelectedControl.style.paddingLeft.split('px')[0]);
                            resizableCurrentSelectedControl.style.width = (spnCurrentSelectedControl.offsetWidth + spnPaddingLeft) + 'px';
                            resizableCurrentSelectedControl.style.height = spnCurrentSelectedControl.offsetHeight + 'px';
                        } else {
                            resizableCurrentSelectedControl.style.width = spnCurrentSelectedControl.clientWidth + 'px';
                            if ((ctrlname == "email" || ctrlname == "company") && type == "0" && fontSize == 12)
                                resizableCurrentSelectedControl.style.height = '20px';
                            else if ((ctrlname == "date") && type == "0" && fontSize == 12)
                                resizableCurrentSelectedControl.style.height = '16px';
                            else resizableCurrentSelectedControl.style.height = spnCurrentSelectedControl.clientHeight + 'px';
                        }
                    }
                }
            }

            this.updateCtrlTextFormatting(currentSelectedControl, '1');
        }
    }

    applyFontSizePxOrPt(selectedValue: any) {
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();;
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
        if (spnCurrentSelectedControl) {
            let fontSizeSelect: any = (<HTMLInputElement>document.getElementById('fontSizeSelect'));
            if (selectedValue && fontSizeSelect && fontSizeSelect.value) {
                let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
                resizableCurrentSelectedControl.setAttribute('data-typography', selectedValue);

                this.updateCtrlTextFormatting(currentSelectedControl, '1');
                this.applyFontSize(fontSizeSelect.value, 1);
            }
        }

    }

    // common function for fontWeight,fontStyle and textDecoration
    applyFontStyles() {
        let styleObj: any = {
            isBold: this.isBold,
            isItalic: this.isItalic,
            isUnderlined: this.isUnderlined
        }
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyFontStyles(currentSelectedControl, styleObj);

        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyTextAlign(type: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyTextAlign(currentSelectedControl, type);

        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyColorPicker(color: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyColorPicker(currentSelectedControl, color);
        setTimeout(() => {
            this.updateCtrlTextFormatting(currentSelectedControl, '1');
        }, 500);
    }

    updateColorCode(groupColorCode: any) {
        //let grpCode: any = this.controlPropertyService.updateColorCode(groupColorCode);
        //this.groupColorCode = grpCode;
    }

    getDimensionBasedOnFontSizeAndStyle(fontFamily: any, fontSize: any) {
        return this.controlPropertyService.getDimensionBasedOnFontSizeAndStyle(fontFamily, fontSize);
    };

    getDuplicateHeightWidth(fontFamily: any, fontSize: any) {
        return this.controlPropertyService.getDuplicateHeightWidth(fontFamily, fontSize);
    };

    getElePositions(ctrlId: any) {
        return this.controlPropertyService.getElePositions(ctrlId);
    }

    getParentWidthAndHeight(currentEle: any) {
        let response: any;
        if (currentEle && currentEle.parentNode && currentEle.parentNode.parentNode && currentEle.parentNode.parentNode.id) {
            response = this.controlPropertyService.getParentWidthAndHeight(currentEle);
        }
        return response;
    }

    clearAllControls(data: any) {
        let allDeleteCtrlsList: any = [];
        for (const [ctrlObjId, componentRef] of this.dynamicComponentRefs) {
            let resizableEle: any = document.getElementById('resizable_' + ctrlObjId);
            if (resizableEle) {
                resizableEle.style.background = '#ffffff';
                if (resizableEle.style.display != 'none') {
                    allDeleteCtrlsList.push('resizable_' + ctrlObjId);
                }
            }
        }
        this.deleteItems(allDeleteCtrlsList);
        this.isClose = true;
        this.hideControlViewSection = true;
        this.updateExtendDocumentWidth();
        this.activeCtrlId = 0;
        let pageNumber: any = 1;

        if (data && data.modalRef && data.modalRef.content && data.modalRef.content.commonService && data.modalRef.content.commonService._previewObject &&
            data.modalRef.content.commonService._previewObject.pageNumber) {
            pageNumber = data.modalRef.content.commonService._previewObject.pageNumber;
        }

        this.onClickOutsideSelectionArea();
        this.selectedItems = [];
        this.commonService.selectedItems = [];
        this.imagesArr.forEach((item: any) => {
            item.isActive = false;
            item.controlsCount = 0;
        })

        this.updateTempTotalCountPerPage();
        this.commonService.updatePrevObject({
            'totalControls': 0,
            'pageNumber': parseInt(pageNumber),
            'tempTotalCountPerPage': this.tempTotalCountPerPage
            // 'documentList': this.imagesArr
        });
        this.sessionStorageService.setAllImageControls('');
        this.sessionStorageService.setArrCopyPasteCtrl('');
        this.sessionStorageService.setArrUndoRedoCtrl('');
        this.sessionStorageService.setArrCopyPasteCtrlsTemp('');
        this.sessionStorageService.setControlDependencyFieldsArray('');
        this.sessionStorageService.setControlPageGuidNumArray('');
    }

    checkLengthNameAndTextControl(ctrlObj: any) {
        if (ctrlObj.dataGuid.toLowerCase() === Control.Name.toLowerCase() || ctrlObj.dataGuid.toLowerCase() === Control.Title.toLowerCase() || ctrlObj.dataGuid.toLowerCase() === Control.Text.toLowerCase()) {
            let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
            if (controlEle) {
                controlEle.setAttribute('maxlengthallowed', 20);
                let title: any = 'approximately ' + 20 + ' characters or control boundary.';
                controlEle.setAttribute('title', title);
                let idEle: any = 'nameInputLength';
                if ((ctrlObj.dataGuid.toLowerCase() === Control.Title.toLowerCase())) {
                    idEle = 'titleInputLength';
                }
                else if ((ctrlObj.dataGuid.toLowerCase() === Control.Text.toLowerCase())) {
                    idEle = 'textInputLength';
                }
                let inputLength: any = (<HTMLInputElement>document.getElementById(idEle));
                if (inputLength) {
                    inputLength.value = 20;
                }

                if (ctrlObj.dataGuid.toLowerCase() === Control.Text.toLowerCase()) {
                    controlEle.setAttribute('data-texttype', this.controlPropertyService.getTextTypeValue('text'));
                    controlEle.setAttribute('data-validation-type', 'Text');
                }
            }
        }
    }

    applyRadioGroup(event: any) {
        //let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyRadioGroup(event, currentSelectedControl);
        let isSaveAsDefault: any = this.defaultControlStyleService.getCtrlBasedSaveAsDefaultValue('radio');
        if (isSaveAsDefault === true || isSaveAsDefault === 'true') {
            this.isRadioRequiredChecked = event.target.checked;
        } else {
            this.isRadioRequiredChecked = false;
        }
        //required code
        this.controlPropertyService.showRequiredIcon(currentSelectedControl);
        //required code
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    setNameOrTextControlDimension(charLength: any) {
        let selControl: any = document.getElementById(this.currentControlId);
        let leftscroll: any = 0;
        let fontFamily: any;
        let fontSize: any;
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById('spn' + this.currentControlId));
        if (spnCurrentSelectedControl) {
            fontFamily = spnCurrentSelectedControl.style.fontFamily;
            fontSize = parseInt(spnCurrentSelectedControl.style.fontSize.split('px')[0]);
        }
        let isSingleCharacter: any = charLength > 1 ? false : true;

        let dim: any = fontSize < 16 ? this.getDimensionBasedOnFontSizeAndStyle(fontFamily, fontSize) : this.getDuplicateHeightWidth(fontFamily, fontSize);
        let dimW: any = this.getDimensionBasedOnFontSizeAndStyle(fontFamily, fontSize);
        let Width: any = parseInt(Math.floor(Math.ceil(dimW.Width) * charLength) + dimW.Width);
        let parentWidth: any = parseInt(selControl.parentNode.parentNode.offsetWidth + leftscroll);
        let parentHeight: any = parseInt(selControl.parentNode.parentNode.offsetHeight);

        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
        let controlPositions: any = this.getElePositions(resizableCurrentSelectedControl);
        let controlTopPosition: any = parseInt(controlPositions.Top);
        let controlLeftPosition: any = parseInt(controlPositions.Left);
        let controlTotalWidth: any = (controlLeftPosition + Width);
        let newheight: any = 0;
        let cHeight = parseInt(resizableCurrentSelectedControl.style.height);
        let cWidth = parseInt(resizableCurrentSelectedControl.style.width);
        let ismultipleline: any = Math.floor(cHeight / dim.Height) > 1 ? 'true' : 'false';
        let calcHeight: any = (ismultipleline == 'true') ? (charLength * Math.ceil(dimW.Width) * dim.Height) / (cWidth) : (dim.Height);
        calcHeight = calcHeight < dim.Height ? dim.Height : calcHeight;
        if (isSingleCharacter) {
            if (fontSize === 60) {
                resizableCurrentSelectedControl.style.width = (fontSize - 5) + 'px';
            }
            else {
                resizableCurrentSelectedControl.style.width = (fontSize + 2) + 'px';
            }
            newheight = fontSize >= 16 ? (Math.ceil(calcHeight / dim.Height) * dim.Height) : 22 - 2;
            resizableCurrentSelectedControl.style.height = newheight + 'px';
        }
        else {
            if (ismultipleline == 'false') {
                if (parentWidth < controlTotalWidth) {
                    resizableCurrentSelectedControl.style.width = (parentWidth - controlLeftPosition) + 'px';
                    this.setControlLocation(this.currentControlId, 0);
                }
                else
                    resizableCurrentSelectedControl.style.width = Width + 'px';
                newheight = fontSize >= 16 ? (Math.ceil(calcHeight / dim.Height) * dim.Height) : 22 - 2;
            }
            else {
                // let nameCharLengthId: any = document.getElementById('nameInputLength');
                // let textCharLengthId: any = document.getElementById('textInputLength');
                // let ctrlname: any = this.currentControlId.split('Control')[0];
                // if (ctrlname === 'title') {
                //     nameCharLengthId = document.getElementById('titleInputLength');
                // }
                // let textMaxLength: any = (ctrlname === 'text') ? parseInt(textCharLengthId.value) : parseInt(nameCharLengthId.value);
                // let textFontSize: any = spnCurrentSelectedControl.style.fontSize.split('px')[0];
                // let textFontFamily: any = spnCurrentSelectedControl.style.fontFamily;

                // if (textMaxLength != charLength || textFontSize != fontSize || textFontFamily != fontFamily) {
                //     newheight = (Math.ceil(calcHeight / dim.Height) * dim.Height) + (Math.ceil(calcHeight / dim.Height) * 2);
                // } else {
                //     let dataGuid: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                //     if (dataGuid.toLowerCase() == Control.Text.toLowerCase()) this.textareaEdited = true;

                //     if (dataGuid.toLowerCase() == Control.Text.toLowerCase() ||
                //         dataGuid.toLowerCase() == Control.Name.toLowerCase() ||
                //         dataGuid.toLowerCase() == Control.Title.toLowerCase()) {
                //         newheight = 20;
                //     }
                // }
                newheight = (Math.ceil(calcHeight / dim.Height) * dim.Height) + (Math.ceil(calcHeight / dim.Height) * 2);
                if (Width <= cWidth) {
                    resizableCurrentSelectedControl.style.width = Width + 'px';
                }
            }

            if (parentHeight < (newheight + controlTopPosition))
                resizableCurrentSelectedControl.style.height = (parentHeight - controlTopPosition) + 'px';
            else
                resizableCurrentSelectedControl.style.height = (newheight) + 'px';
        }
    }

    setControlLocation(ctrlId: any, leftscroll: any) {
        let selControl: any = document.getElementById(ctrlId);
        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
        let parentWidth: any = parseInt(selControl.parentNode.parentNode.offsetWidth + leftscroll);
        let controlWidth: any = parseInt(resizableCurrentSelectedControl.style.width);
        let controlPositions: any = this.getElePositions(resizableCurrentSelectedControl);
        let controlLeftPosition: any = parseInt(controlPositions.Left);
        let controlTotolWidth: any = (controlLeftPosition + controlWidth);
        let finalPosition: any;
        if (parentWidth <= controlTotolWidth) {
            finalPosition = controlLeftPosition - (controlTotolWidth - parentWidth);
            this.updateLeftValue(resizableCurrentSelectedControl, finalPosition);
        }
        let pageNumber: any = parseInt(resizableCurrentSelectedControl.getAttribute('pageno'));
        let imgEle: any = document.getElementById('imgControl_' + pageNumber);
        var imageWidth = imgEle.offsetWidth;
        var imageHeight = imgEle.offsetHeight;
        if (imageWidth < imageHeight) {
            if ((controlLeftPosition + controlWidth) >= imageWidth) {
                let maxLeft: any = imageWidth - 5;
                let newPosition: any = (maxLeft - controlWidth);
                this.updateLeftValue(resizableCurrentSelectedControl, newPosition);
            }
        }
    }

    getApproximateCharateLength(ctrlId: any) {
        let ctrlSpanEle: any = document.getElementById('spn' + this.currentControlId);
        let fontFamily: any = ctrlSpanEle.style.fontFamily;
        let fontSize: any = ctrlSpanEle.style.fontSize.split('px')[0];
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + this.currentControlId));
        let cW: any = controlEle.offsetWidth;
        let cH: any = controlEle.offsetHeight;
        var d = this.getDimensionBasedOnFontSizeAndStyle(fontFamily, parseInt(fontSize));
        var floorWidthWord = cW / Math.ceil(d.Width);
        var floorHeightWord = Math.floor(cH / Math.ceil(d.Height));
        floorHeightWord = floorHeightWord == 0 ? 1 : floorHeightWord;

        // For handling character count less than one.
        var charLength = Math.floor(floorWidthWord * floorHeightWord);

        if ((Math.floor(floorWidthWord * floorHeightWord)) < 1)
            return 1;
        return Math.floor(charLength);
    }

    updateLeftValue(resizableCurrentSelectedControl: any, left: any) {
        const transformString = resizableCurrentSelectedControl.style.transform;
        const regex = /translate3d\(([-\d.]+)px,\s+([-\d.]+)px,\s+([-\d.]+)px\)/;
        let match = transformString.match(regex);
        let translateX = 0;
        let translateY = 0;
        let translateZ = 0;

        if (match) {
            translateX = parseFloat(match[1]);
            translateY = parseFloat(match[2]);
            translateZ = parseFloat(match[3]);
        }

        let element: any = document.getElementById(resizableCurrentSelectedControl.getAttribute("id"));
        let posObj: any = this.controlPropertyService.getElePositions(element);
        element.style.left = posObj.Left + 'px';
        element.style.top = posObj.Top + 'px';

        // resizableCurrentSelectedControl.style.transform = `translate3d(${left}px, ${translateY}px, ${translateZ}px)`;
    }

    updateControlNameOrTextLength(e: any, type: any) {
        this.commonService.callParentShowControlFields$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((value: { event: any; data: any; }) => {
                const { event, data } = value;
                this.currentControlId = data.id;
            });

        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + this.currentControlId));
        if (controlEle) {
            let maxlengthallowed: any = controlEle.getAttribute('maxlengthallowed');
            let nameOrTextInputLengthEle: any = document.getElementById(type + 'InputLength');
            if (nameOrTextInputLengthEle && nameOrTextInputLengthEle.value) {
                let nameOrTextInputLengthEleVal: any = parseInt(nameOrTextInputLengthEle.value);
                if (type === 'name' || type === 'title') {
                    if (nameOrTextInputLengthEleVal > 100) {
                        this.toastrService.warning('Value should be less than or equal to 100.', 'Warning');
                        controlEle.setAttribute('maxlengthallowed', 20);
                        nameOrTextInputLengthEleVal = 20;
                        nameOrTextInputLengthEle.value = 100;
                    } else {
                        controlEle.setAttribute('maxlengthallowed', parseInt(nameOrTextInputLengthEle.value));
                        if (controlEle.offsetHeight <= 20) {
                            this.setNameOrTextControlDimension(parseInt(nameOrTextInputLengthEle.value));
                            let title: any = 'approximately ' + parseInt(nameOrTextInputLengthEle.value) + ' characters or control boundary.';
                            controlEle.setAttribute('title', title);

                            setTimeout(() => {
                                let aprxLength: any = this.getApproximateCharateLength(this.currentControlId);
                                let updatedNameOrTextInputLengthEle: any = controlEle.getAttribute('maxlengthallowed');
                                if (aprxLength != parseInt(updatedNameOrTextInputLengthEle)) {
                                    let errmsg: any = 'Control size out of bounds :' + type + ' control length reduced to ' + aprxLength + " characters."
                                    this.toastrService.warning(errmsg, 'Warning');
                                    controlEle.setAttribute('maxlengthallowed', aprxLength);
                                    nameOrTextInputLengthEle.value = aprxLength;

                                    let title: any = 'approximately ' + aprxLength + ' characters or control boundary.';
                                    controlEle.setAttribute('title', title);
                                }
                            }, 500);
                        } else {
                            this.setNameOrTextControlDimension(parseInt(nameOrTextInputLengthEle.value));
                            let title: any = 'approximately ' + parseInt(nameOrTextInputLengthEle.value) + ' characters or control boundary.';
                            controlEle.setAttribute('title', title);
                        }
                    }
                }
                else if (type === 'text') {
                    if (nameOrTextInputLengthEleVal > 999) {
                        this.toastrService.warning('Value should be less than 1000.', 'Warning');
                        controlEle.setAttribute('maxlengthallowed', 20);
                        nameOrTextInputLengthEleVal = 20;
                        nameOrTextInputLengthEle.value = 999;
                    } else {
                        controlEle.setAttribute('maxlengthallowed', parseInt(nameOrTextInputLengthEle.value));
                        if (controlEle.offsetHeight <= 20) {
                            this.setNameOrTextControlDimension(parseInt(nameOrTextInputLengthEle.value));
                            let title: any = 'approximately ' + parseInt(nameOrTextInputLengthEle.value) + ' characters or control boundary.';
                            controlEle.setAttribute('title', title);

                            setTimeout(() => {
                                let aprxLength: any = this.getApproximateCharateLength(this.currentControlId);
                                let updatedNameOrTextInputLengthEle: any = controlEle.getAttribute('maxlengthallowed');
                                if (aprxLength != parseInt(updatedNameOrTextInputLengthEle)) {
                                    let errmsg: any = 'Control size out of bounds : text control length reduced to ' + aprxLength + " characters."
                                    this.toastrService.warning(errmsg, 'Warning');
                                    controlEle.setAttribute('maxlengthallowed', aprxLength);
                                    nameOrTextInputLengthEle.value = aprxLength;

                                    let title: any = 'approximately ' + aprxLength + ' characters or control boundary.';
                                    controlEle.setAttribute('title', title);
                                }
                            }, 500);
                        } else {
                            this.setNameOrTextControlDimension(parseInt(nameOrTextInputLengthEle.value));
                            let title: any = 'approximately ' + parseInt(nameOrTextInputLengthEle.value) + ' characters or control boundary.';
                            controlEle.setAttribute('title', title);
                        }
                    }
                }
            }
        }
    }

    applyTextTypeControl(value: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        let textInputTypeEle: any = document.getElementById('textInputLength');
        let defaultLength: any = 20;
        let tenCharsLength: any = 10;
        let title: any = 'approximately ' + defaultLength + ' characters or control boundary.';
        let isFixedWidthVal: any = controlEle.getAttribute('data-isfixedwidth');
        if (controlEle) {
            if (value === '1' || value === '3' || value === '4' || value === '7') { //TEXT ALPHABET SSN EMAIL
                controlEle.removeAttribute('data-additional-validation-name');
                controlEle.removeAttribute('data-additional-validation-option');
            }

            //controlEle.removeAttribute('data-additional-validation-name', '');
            let divEle: any = document.getElementById('div' + currentSelectedControl);
            if (divEle) {
                divEle.classList.add('resize-action');
            }
            let isFixedWidthTextContainer: any = document.getElementById('isFixedWidthTextContainer');
            if (isFixedWidthTextContainer) {
                isFixedWidthTextContainer.style.display = 'block';
            }
            //4-SSN, 5-Zip, 6-Date
            if (value === '4' || value === '5' || value === '6') {
                if (textInputTypeEle) {
                    textInputTypeEle.value = tenCharsLength;
                    textInputTypeEle.setAttribute('disabled', true);
                    controlEle.setAttribute('maxlengthallowed', tenCharsLength);
                    controlEle.setAttribute('data-maxcharlimit', tenCharsLength);
                    controlEle.style.width = '87px';
                    controlEle.style.height = '20px';
                    title = 'approximately ' + textInputTypeEle.value + ' characters or control boundary.';
                    controlEle.setAttribute('title', title);
                    if (divEle) {
                        divEle.classList.remove('resize-action');
                    }
                    this.validateCharLimit();
                }
            }
            else if (value === '7') { // Email
                if (textInputTypeEle) {
                    textInputTypeEle.value = defaultLength;
                    controlEle.style.width = '167px';
                    textInputTypeEle.setAttribute('disabled', true);
                    controlEle.setAttribute('maxlengthallowed', defaultLength);
                    title = 'approximately ' + textInputTypeEle.value + ' characters or control boundary.';
                    controlEle.setAttribute('title', title);
                    controlEle.setAttribute('data-maxcharlimit', '100');
                    if (isFixedWidthVal === false || isFixedWidthVal === 'false') {
                        controlEle.setAttribute('maxcharallowed', '100');
                        controlEle.setAttribute('maxemailcharallowed', '100');
                        this.controlPropertyService.checkFixedWidthFalseAndMaxCharLimit('Email', 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer', this.isFixedWidthChecked);
                    } else {
                        textInputTypeEle.removeAttribute('disabled');
                        textInputTypeEle.classList.remove('disabled');
                        this.controlPropertyService.checkFixedWidthTrueAndMaxCharLimit('Email', 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer');
                    }
                }
            }
            else { //Text, Alphabet, Numeric
                if (textInputTypeEle) {
                    textInputTypeEle.value = defaultLength;
                    textInputTypeEle.removeAttribute('disabled');
                    textInputTypeEle.classList.remove('disabled');
                    controlEle.setAttribute('maxlengthallowed', defaultLength);
                    controlEle.setAttribute('data-maxcharlimit', '4000');
                    controlEle.style.width = '167px';
                    if (isFixedWidthVal === false || isFixedWidthVal === 'false') {
                        controlEle.setAttribute('maxcharallowed', '4000');
                        controlEle.setAttribute('maxemailcharallowed', '4000');
                        this.controlPropertyService.checkFixedWidthFalseAndMaxCharLimit('Text', 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer', this.isFixedWidthChecked);
                    } else {
                        this.controlPropertyService.checkFixedWidthTrueAndMaxCharLimit('Text', 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer');
                    }
                }
            }

            const valueToFind = parseInt(value); // The value you want to search for
            const foundOption = this.commonConstants.Validationsoptions.find((option: { value: number; }) => option.value === valueToFind);

            if (foundOption && foundOption.label) {
                controlEle.setAttribute('data-validation-type', foundOption.label);
                // controlEle.removeAttribute('data-additional-validation-option');
                this.showTextFieldsContainer = 'none';
                this.showTextDecimalType = 'none';
                this.showTextDecimalOptions = 'none';
                this.showTextZipFormat = 'none';
                this.showTextZipFormatOptions = 'none';
                this.showTextDateFormat = 'none';
                this.showTextDateFormatOptions = 'none';

                if (value === '2') {
                    this.showTextFieldsContainer = 'block';
                    this.showTextDecimalType = 'block';
                    let validationname: any = controlEle.getAttribute('data-additional-validation-name');
                    controlEle.setAttribute('data-additional-validation-name', validationname);

                    if (validationname && validationname == "Number") {
                        controlEle.removeAttribute('data-additional-validation-option');
                    }
                    else if (validationname && validationname == "Decimal") {
                        this.showTextDecimalOptions = 'block';
                    }
                    else {
                        controlEle.setAttribute('data-additional-validation-name', 'Number');
                        controlEle.removeAttribute('data-additional-validation-option');
                    }
                }
                else if (value === '5') {
                    this.showTextFieldsContainer = 'block';
                    this.showTextZipFormat = 'block';
                    this.showTextZipFormatOptions = 'block';
                    controlEle.setAttribute('data-additional-validation-name', 'Zip Format');
                    controlEle.setAttribute('data-additional-validation-option', '1');
                }
                else if (value === '6') {
                    this.showTextFieldsContainer = 'block';
                    this.showTextDateFormat = 'block';
                    this.showTextDateFormatOptions = 'block';
                    controlEle.setAttribute('data-additional-validation-name', 'Date Format');
                    let isCheckDataAdditionalValidationOption: any = controlEle.getAttribute('data-additional-validation-option');
                    if (isCheckDataAdditionalValidationOption === null) {
                        controlEle.setAttribute('data-additional-validation-option', '1');
                    }
                }
                controlEle.setAttribute('data-texttype', this.controlPropertyService.getTextTypeValue(foundOption.label));
            }

            this.updateCtrlTextFormatting(currentSelectedControl, '1');

            this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
        }
    }

    applyPrefillDefaultDateProperty(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyPrefillDefaultDateProperty(event, currentSelectedControl);
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyFirstLineBlank(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.currentControlId = currentSelectedControl;
        let retunVal: any = this.controlPropertyService.applyFirstLineBlank(event, this.currentControlId);
        let selectDropdownControlField: any = (document.getElementById('spn' + this.currentControlId)) as HTMLSelectElement;
        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
            this.openModal('', 'dropdownlist', '');
        }

        this.isdrpFirstLineBlank = event.target.checked;
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyLabelName(event: any, labelOrHyperlink: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyLabelName(event, currentSelectedControl, labelOrHyperlink);

        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentSelectedControl));
        let spaControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentSelectedControl));

        let pageNo: any = controlEle.getAttribute('pageno');
        let imageControlEle: any = document.getElementById('imgControl_' + parseInt(pageNo));
        let containerWidth: any = 0;
        let containerHeight: any = 0;
        if (imageControlEle) {
            containerHeight = (imageControlEle.offsetHeight - 7);
            containerWidth = (imageControlEle.offsetWidth - 20);
        }

        if (controlEle && controlEle.style && controlEle.style.width) {
            if (spaControlEle && spaControlEle.offsetWidth && spaControlEle.offsetWidth <= containerWidth) {
                spaControlEle.style.textWrap = 'nowrap';
                controlEle.style.width = spaControlEle.offsetWidth + 5 + 'px';
            } else {
                controlEle.style.width = containerWidth + 'px';
                spaControlEle.style.textWrap = 'wrap';
            }
        }
        this.setControlLocation(currentSelectedControl, 0);
        if (labelOrHyperlink === 'label') {
            setTimeout(() => {
                if (controlEle && controlEle.style && controlEle.style.height) {
                    if (spaControlEle && spaControlEle.offsetHeight) {
                        controlEle.style.height = spaControlEle.offsetHeight + 'px';
                    }
                }
            }, 100);

            this.updateCtrlTextFormatting(currentSelectedControl, '1');
        }
    }

    applyHyperLinkURL(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyHyperLinkURL(event, currentSelectedControl);
    }

    applyNewContentId(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyNewContentId(event, currentSelectedControl);
    }

    applyDefaultDateFormat(event: any) {
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyDefaultDateFormat(this.selectDefaultDateFormat, currentSelectedControl);

        this.updateCtrlTextFormatting(currentSelectedControl, '1');
    }

    applyTextTypeZip(selectedVal: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyTextTypeZip(currentSelectedControl, selectedVal);
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
        this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
    }

    applyTextTypeDate(selectedVal: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyTextTypeDate(currentSelectedControl, selectedVal);
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
        this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
    }

    applyTextTypeDecimal(selectedVal: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        if (selectedVal === '1') {
            this.showTextDecimalOptions = 'none';
        } else if (selectedVal === '2') {
            this.showTextDecimalOptions = 'block';
        }
        this.controlPropertyService.applyTextTypeDecimal(currentSelectedControl, selectedVal);

        this.updateCtrlTextFormatting(currentSelectedControl, '1');

        this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
    }

    applyTextTypeDecimalOption(selectedVal: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyTextTypeDecimalOption(currentSelectedControl, selectedVal);
        this.updateCtrlTextFormatting(currentSelectedControl, '1');
        this.controlPropertyService.applyTextPrefillData(currentSelectedControl);
    }

    getEachControlValidation(type: any, isAutoSave: any) {
        let resultObj: any = this.controlPropertyService.checkContolAttribuesData(type, 'template');
        let isValidateSignatureAndEmail: boolean = true;
        let isStaticLinkDisabled: any = this.envelope.envelopeDetails.isStatic;
        let IsAllowMultiSigner: any = this.envelope.envelopeDetails.allowMultiSigners;
        if (isStaticLinkDisabled && IsAllowMultiSigner) { //multi signer static template
            if (resultObj.controlArray.length != 0) {
                this.envelope.envelopeDetails.roleList.forEach((item: any) => {
                    const s = resultObj.controlArray.filter((rec: any) =>
                        (rec.ControlID) != null &&
                        (rec.ControlID) === 'e294c207-13fd-4508-95fc-90c5d9c555fa' &&
                        (rec.RecipientID) === item.id
                    )
                    if (s.length === 0) {
                        isValidateSignatureAndEmail = false;
                    }
                });
            }
            else {
                isValidateSignatureAndEmail = false;
            }
        }
        else if (isStaticLinkDisabled && (IsAllowMultiSigner == "false" || IsAllowMultiSigner == false) && this.signatureControlRequiredForStaticTemplate == "true") { //signle signer static template
            if (resultObj.controlArray.length != 0) {
                this.envelope.envelopeDetails.roleList.forEach((item: any) => {
                    const s = resultObj.controlArray.filter((rec: any) =>
                        (rec.ControlID) != null &&
                        (rec.ControlID) === 'e294c207-13fd-4508-95fc-90c5d9c555fa' &&
                        (rec.RecipientID) === item.id
                    )
                    if (s.length === 0) {
                        isValidateSignatureAndEmail = false;
                    }
                });
            }
            else {
                isValidateSignatureAndEmail = false;
            }
        }


        if (resultObj.isValid == true) {
            let finalControlArrayFilter: any = resultObj.controlArray.filter((c: any) => (c.XCoordinate === 0 && c.YCoordinate === 0 && c.ZCoordinate === 0));
            if (!isValidateSignatureAndEmail && isStaticLinkDisabled && IsAllowMultiSigner && isAutoSave) {
                this.toastrService.warning("Please add signature controls for all the signer(s).", "Warning");
                resultObj.isValid == false;
                return false;
            }
            else if (!isValidateSignatureAndEmail && isStaticLinkDisabled && (IsAllowMultiSigner == "false" || IsAllowMultiSigner == false) && isAutoSave) {
                this.toastrService.warning("Please add at least one signature control for the signer.", "Warning");
                resultObj.isValid == false;
                return false;
            }
        }
        return resultObj;
    }

    saveTemplateOrRule(type: any) {
        this.isSendEnvelope = true;
        this.sessionStorageService.setIsSendEnvelope('1');
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            setTimeout(() => {
                this.showLoader = true;
                var Source = 'Save';
                var isAutoSave = false;

                let resultObj: any = this.getEachControlValidation("send", true);
                if (resultObj.isValid == true) {
                    let controlArray = resultObj.controlArray;
                    let apiEnvelopeControls: any = {
                        TemplateID: this.envelope.envelopeDetails.envelopeID,
                        Controls: controlArray,
                        EnvelopeStage: RSignStage.InitializeTemplate,
                        IsPrefill: this.isPrefillExists == true ? true : false,
                        Source: Source,
                        IsAutoSave: isAutoSave,
                        EnvelopeTypeId: type == 'Rule' ? EnvelopeType.TemplateRule : EnvelopeType.Template,
                        EnableMultipleBranding: this.enableMultiBranding,
                        Branding: this.branding
                        //IsSFSend: false
                    };
                    this.apiService.SaveTemplateRuleUrl(apiEnvelopeControls).subscribe((data: any) => {
                        if (data && data.statusCode == 200) {
                            this.showLoader = true;
                            this.toastrService.success(data.statusMessage, 'Success');
                            this.hostListenerStatus = true;
                            if (data.returnURL && data.returnURL != "") {
                                setTimeout(() => {
                                    const containsUrl = data.returnURL.includes('http://') || data.returnURL.includes('https://');
                                    if (containsUrl) {
                                        window.location.href = data.returnURL;
                                    }
                                    else {
                                        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
                                        if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
                                            let sourceUrl: any = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
                                            window.location.href = sourceUrl + data.returnURL;
                                        }
                                        else {
                                            let rsignUrl: any = environment.rsignUrl;
                                            window.location.href = rsignUrl + data.returnURL;
                                        }
                                    }
                                    this.showLoader = false;
                                }, 1000);
                            }
                        }
                        else if (data && data.originalError && data.originalError.statusText && data.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                        else {
                            this.showLoader = false;
                            this.toastrService.warning("Unable to save template. Please inform RPost support.", 'Warning');
                        }
                    });
                }
                else {
                    this.showLoader = false;
                }
            }, 500);
        }
    }

    saveAndContinue() {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            setTimeout(() => {
                this.showLoader = true;
                var Source = 'Save';
                var isAutoSave = false;
                var EnvelopeTypeId = '';
                let resultObj: any = this.getEachControlValidation("send", false);
                if (resultObj.isValid == true) {
                    let controlArray = resultObj.controlArray;
                    let apiEnvelopeControls: any = {
                        TemplateID: this.envelope.envelopeDetails.envelopeID,
                        Controls: controlArray,
                        EnvelopeStage: RSignStage.InitializeTemplate,
                        IsPrefill: this.isPrefillExists == true ? true : false,
                        Source: Source,
                        IsAutoSave: isAutoSave,
                        Stage: 'PrepareEditTemplate'
                        //IsSFSend: false
                    };

                    this.apiService.SaveTemplateRuleUrl(apiEnvelopeControls).subscribe((data: any) => {
                        if (data && data.statusCode == 200) {
                            //TO Do create a popup and hide
                            if (isAutoSave == true)
                                this.showLoader = false;
                            // $("#dvAutoSave").html("").hide();
                            else if (Source == 'Back') {
                                setTimeout(() => {
                                    //this.showLoader = false;
                                    window.location.href = data.ReturnURL; //prefill navigation signing url
                                    this.showLoader = false;
                                }, 1000);
                            }
                            else {
                                this.showLoader = false;
                                this.toastrService.success(data.statusMessage, 'Success');
                            }
                        }
                        else if (data && data.originalError && data.originalError.statusText && data.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                        else {
                            if (isAutoSave == true)
                                this.showLoader = false;
                            //$("#dvAutoSave").html('auto-save failed').show().delay(4000).fadeOut();
                            else {
                                this.showLoader = true;
                                this.toastrService.warning(data.message, 'Warning');
                            }
                        }
                    });
                }
                else {
                    this.showLoader = false;
                }
            }, 500);
        }
    }

    getEnvelopeStage() {
        let envelope: any = this.envelope.envelopeDetails;
        let envelopeStage: any = envelope.isDraft == true ? RSignStage.PrepareDraft : ((envelope.isEnvelopeComplete == false || (envelope.isEnvelopeComplete == true && envelope.isEdited == true)) ? RSignStage.PrepareEnvelope : RSignStage.PrepareEditTemplate);
        return envelopeStage;
    }

    checkTextFields() {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        let controlSpnEle: any = (<HTMLInputElement>document.getElementById('spn' + currentSelectedControl));
        // if (controlSpnEle) {
        //     let addTextTextarea: any = document.getElementById('addTextTextarea');
        //     if (addTextTextarea)
        //         addTextTextarea.value = controlSpnEle.innerHTML;
        // }
        if (controlEle) {
            this.setShowTextFiledToNone();
            let textInputTypeEle: any = document.getElementById('textInputLength');
            if (textInputTypeEle) {
                textInputTypeEle.removeAttribute('disabled');
            }
            let attr_texttype: any = controlEle.getAttribute('data-texttype');
            let textType: any = this.controlPropertyService.getTextControlType(attr_texttype);
            let attr_integrationType: any = controlEle.getAttribute('data-integrationtype');
            let dataValidationType: any = (attr_integrationType && attr_integrationType != "undefined" && attr_integrationType != "null" && attr_integrationType.toLowerCase() === 'vincere') ? textType : controlEle.getAttribute('data-validation-type');
            // let dataValidationType: any = controlEle.getAttribute('data-validation-type');
            if (dataValidationType === 'Text') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '1';
                }
            }
            else if (dataValidationType === 'Numeric') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '2';
                }
                this.showTextFieldsContainer = 'block';
                this.showTextDecimalType = 'block';
                let additionalValidationName: any = controlEle.getAttribute('data-additional-validation-name');
                if (additionalValidationName === 'Number') {
                    this.showTextDecimalOptions = 'none';
                    let textTypeDecimal: any = (<HTMLInputElement>document.getElementById('textTypeDecimal'));
                    if (textTypeDecimal) {
                        textTypeDecimal.value = '1';
                    }
                } else if (additionalValidationName === 'Decimal') {
                    this.showTextDecimalOptions = 'block';
                    let additionalValidationOption: any = controlEle.getAttribute('data-additional-validation-option');
                    if (additionalValidationOption) {

                        let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                        if (textValidationsoptions) {
                            textValidationsoptions.value = '2';
                        }

                        let textTypeDecimal: any = (<HTMLInputElement>document.getElementById('textTypeDecimal'));
                        if (textTypeDecimal) {
                            textTypeDecimal.value = '2';
                        }

                        const valueToFind = additionalValidationOption;
                        const additionalValidationItem = this.commonConstants.AdditionalValidationType.find((item: { value: any; }) => item.value === valueToFind);
                        if (additionalValidationItem) {
                            let textTypeDecimalOptions: any = (<HTMLInputElement>document.getElementById('textTypeDecimalOptions'));
                            if (textTypeDecimalOptions) {
                                textTypeDecimalOptions.value = additionalValidationOption;
                            }
                        }
                    }
                }
            }
            else if (dataValidationType === 'Alphabet') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '3';
                }
            }
            else if (dataValidationType === 'SSN') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '4';
                }
                let textInputTypeEle: any = document.getElementById('textInputLength');
                if (textInputTypeEle) {
                    textInputTypeEle.setAttribute('disabled', true);
                }
            }
            else if (dataValidationType === 'Zip') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '5';
                }
                this.showTextFieldsContainer = 'block';
                this.showTextZipFormat = 'block';
                this.showTextZipFormatOptions = 'block';
                let additionalValidationOption: any = controlEle.getAttribute('data-additional-validation-option');
                if (additionalValidationOption) {
                    let textZipOptions: any = (<HTMLInputElement>document.getElementById('textZipOptions'));
                    if (textZipOptions) {
                        const valueToFind = additionalValidationOption;
                        const additionalValidationItem = this.commonConstants.ValidationsZipOptions.find((item: { value: any; }) => item.value === valueToFind);
                        if (additionalValidationItem) {
                            textZipOptions.value = additionalValidationOption;
                        }
                    }
                }
            }
            else if (dataValidationType === 'Date') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '6';
                }
                this.showTextFieldsContainer = 'block';
                this.showTextDateFormat = 'block';
                this.showTextDateFormatOptions = 'block';
                let additionalValidationOption: any = controlEle.getAttribute('data-additional-validation-option');
                if (additionalValidationOption) {
                    let textDateOptions: any = (<HTMLInputElement>document.getElementById('textDateOptions'));
                    if (textDateOptions) {
                        const valueToFind = additionalValidationOption;
                        for (let index = 0; index < this.validationsDateOptions.length; index++) {
                            const element = this.validationsDateOptions[index];
                            if (element.value == valueToFind) {
                                textDateOptions.value = additionalValidationOption;
                                break;
                            }
                        }
                        // const additionalValidationItem = this.validationsDateOptions.find((item: { value: any; }) => item.value === valueToFind);
                        // if (additionalValidationItem) {
                        //     textDateOptions.value = additionalValidationOption;
                        // }
                    }
                }
            }
            else if (dataValidationType === 'Email') {
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '7';
                }
            }
        }
    }

    setShowTextFiledToNone() {
        this.showTextFieldsContainer = 'none';
        this.showTextDecimalType = 'none';
        this.showTextDecimalOptions = 'none';
        this.showTextZipFormat = 'none';
        this.showTextZipFormatOptions = 'none';
        this.showTextDateFormat = 'none';
        this.showTextDateFormatOptions = 'none';
    }

    setTextOrNameAreaSetting(ctrlObj: any) {
        var controlInfoType = ctrlObj.controlName;
        if (controlInfoType === 'Text' || controlInfoType === 'Name' || controlInfoType === 'Title') {
            let ctrlSpanEle: any = document.getElementById('spn' + ctrlObj.id);
            let fontFamily: any = '';
            let fontSize: any = '';
            if (ctrlSpanEle && ctrlSpanEle.style && ctrlSpanEle.style.fontFamily) {
                fontFamily = ctrlSpanEle.style.fontFamily;
                fontSize = ctrlSpanEle.style.fontSize;
            }
            let d: any = this.getDimensionBasedOnFontSizeAndStyle(fontFamily, fontSize);
            let controlEle: any = document.getElementById('resizable_' + ctrlObj.id)
            var containerWidth = controlEle.offsetWidth;
            var containerHeight = controlEle.offsetHeight;

            var floorWidthWord = containerWidth / Math.ceil(d.Width);
            var floorHeightWord = Math.floor(containerHeight / Math.ceil(d.Height));
            floorHeightWord = floorHeightWord === 0 ? 1 : floorHeightWord;
            let characterAllowed: any = Math.floor(floorWidthWord * floorHeightWord);
            controlEle.setAttribute('maxlength', characterAllowed);
            controlEle.setAttribute('maxlengthallowed', characterAllowed);
            controlEle.setAttribute('title', 'approximately ' + characterAllowed + ' characters or control boundary.');
            let updateNameOrTextInputLength: any = document.getElementById('nameInputLength');
            if (controlInfoType === 'Text') {
                updateNameOrTextInputLength = document.getElementById('textInputLength');
            } else if (controlInfoType === 'Title') {
                updateNameOrTextInputLength = document.getElementById('titleInputLength');
            }
            if (updateNameOrTextInputLength) {
                updateNameOrTextInputLength.value = characterAllowed;
            }
        }
    }

    deleteDropdownOption(event: any, slVal: any) {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let selectDropdownControlField: any = (document.getElementById('spn' + currentSelectedControl)) as HTMLSelectElement;

        this.slDropDownControlItems = [];
        //Get the existing option values for this control. If any new option is not there in existing option then remove that option which is set as 'data-parent-rule-id'
        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
            for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                var text = selectDropdownControlField.options[index].text;
                var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                if (ruleId && ruleId == slVal) {
                    let removeParentRuleItem: any = document.querySelectorAll('[data-parent-rule-id="' + ruleId + '"]');
                    if (removeParentRuleItem && removeParentRuleItem.length > 0) {
                        Array.from(removeParentRuleItem).forEach((ritem: any, index: any) => {
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-id', '');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-rule-id', '');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-parent-support-text-content', '');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('data-select-option-id', '');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.setAttribute('is-parent-present', '');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.classList.remove('alreadyDependentField');
                            document.querySelector('[id="' + ritem.getAttribute('id') + '"]')?.classList.remove('addedAsChild');
                            this.controlPropertyService.removeColorForItem(ritem.getAttribute('id'));
                        });
                    }
                }
                else {
                    this.slDropDownControlItems.push({ value: ruleId, text: text });
                }
            }

            //remove all existing options
            selectDropdownControlField.options.length = 0;

            for (var i = 0; i < this.slDropDownControlItems.length; i++) {
                var text = this.slDropDownControlItems[i].text;
                var ruleId = this.slDropDownControlItems[i]?.value;
                var option = '<option data-select-option-id= "' + ruleId + '" value="' + (i) + '" >' + text + '</option>';
                selectDropdownControlField.insertAdjacentHTML('beforeend', option);
            }

            //if no dependencies are available then delete attribute and color
            let mainDropdownControlField: any = (document.getElementById(currentSelectedControl)) as HTMLSelectElement;
            let data_document_content_id: any = mainDropdownControlField.getAttribute('data-document-content-id');
            let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + data_document_content_id + '"]');
            if (childelementsArray && childelementsArray.length == 0) {
                this.controlPropertyService.removeAttributes(data_document_content_id);
                this.controlPropertyService.removeColorForItem(mainDropdownControlField.getAttribute('id'));
            }
        }
    }

    createControl(ctrlObj: any, type: any, isInitialCtrlFlag: any) {
        this.showLoader = true;
        //console.log(ctrlObj);
        let controlObj: any = {
            id: ctrlObj.id,
            controlName: ctrlObj.controlName,
            pageNo: ctrlObj.pageNo,
            contentId: ctrlObj.dataDocumentContentId,
            data_guid: ctrlObj.dataGuid,
            attr_data_rcptid: ctrlObj.recipientId,
            attr_data_recp_color: ctrlObj.recpColor,
            controlTopPosition: ctrlObj.top,
            controlLeftPosition: ctrlObj.left,
            controlHeight: ctrlObj.height,
            controlWidth: ctrlObj.width,
            isRequired: (ctrlObj.required == true || ctrlObj.required == "true") ? "true" : "false",
            textFormatting: ctrlObj.textFormatting,
            ctrlNumber: this.getSameControlNumberCount(ctrlObj.dataGuid, ctrlObj.pageNo),
            isEnvelopeEditable: this.isEnvelopeEditable
        };
        //console.log(controlObj);

        let componentType: any = ControlComponent; //this.getComponentType(ctrlObj.controlName);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const newcomponentRef: any = componentFactory.create(this.injector);

        let newControlObj: any = this.controlPropertyService.createControlOnDocument(newcomponentRef, this.dynamicComponentRefs, this.appRef, controlObj, type, isInitialCtrlFlag);
        //console.log(newControlObj);
        if (newControlObj && newControlObj != false) {
            setTimeout(() => {
                this.showLoader = true;
                if (type == ControlConfig.Duplicate) {
                    this.controlPropertyService.updateDuplicateControlProperties(newControlObj.id, ctrlObj.id, ctrlObj.dataGuid);
                    if (ctrlObj.controlName === 'NewInitials') {
                        let newResizableControlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + newControlObj.id));
                        newResizableControlEle?.setAttribute('fromParentInitialCtrl', 'true');
                    }

                    // if (isInitialCtrlFlag != 'true') {
                    //if (this.arrCopyPasteCtrl && this.arrCopyPasteCtrl.length == 0) {
                    this.arrCopyPasteCtrl = [];
                    //}
                    this.arrCopyPasteCtrl.push({ ParentControl: ctrlObj, Control: newcomponentRef.instance.ctrlObj, Action: 'P' });
                    // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
                    this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));

                    // let arrUndoRedoCtrlCtrl: any = localStorage.getItem('ArrUndoRedoCtrl');
                    let arrUndoRedoCtrlCtrl: any = this.sessionStorageService.getArrUndoRedoCtrl();
                    if (arrUndoRedoCtrlCtrl) {
                        this.arrUndoRedoCtrl = JSON.parse(arrUndoRedoCtrlCtrl);
                        if (this.arrUndoRedoCtrl && this.arrUndoRedoCtrl.length == 0) {
                            this.arrUndoRedoCtrl = [];
                        }
                        this.arrUndoRedoCtrl.push({ ParentControl: ctrlObj, Control: newcomponentRef.instance.ctrlObj, Action: 'U' });
                        // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(this.arrUndoRedoCtrl));
                        this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrUndoRedoCtrl));
                    }

                    // arrCopyPasteCtrlsTemp
                    // let arrCopyPasteCtrlsTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
                    let arrCopyPasteCtrlsTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
                    if (arrCopyPasteCtrlsTemp) {
                        this.arrCopyPasteCtrlsTemp = JSON.parse(arrCopyPasteCtrlsTemp);
                        if (this.arrCopyPasteCtrlsTemp && this.arrCopyPasteCtrlsTemp.length == 0) {
                            this.arrCopyPasteCtrlsTemp = [];
                        }
                        this.arrCopyPasteCtrlsTemp.push({ ParentControl: ctrlObj, Control: newcomponentRef.instance.ctrlObj, Action: 'P' });
                        // localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify(this.arrCopyPasteCtrlsTemp));
                        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));
                    }

                    this.allControlsArr.push(newControlObj);
                    this.copyPastedcontrolsArr.push(newControlObj);
                    this.copyPastedcontrolsArrFlag = true;
                    // }
                }
                else if (type == ControlConfig.Existing) { //Copy envelope or Update and resend from Manage Envelope
                    let integrationValues: any = '';
                    if (this.integrationResponse && this.integrationResponse.envelopeDetails) {
                        this.externalIntegrationType = this.integrationResponse.envelopeDetails.integrationType; //Vincere, Bullhorn
                        if (this.externalIntegrationType && (this.externalIntegrationType.toLowerCase() === 'vincere' || this.externalIntegrationType.toLowerCase() === 'bullhorn')) {
                            integrationValues = this.integrationResponse.envelopeDetails;
                        }
                    }
                    let iscopyEnvelopeId: any = true;
                    this.controlPropertyService.updateExistingControlProperties(newControlObj, ctrlObj.controlData, integrationValues, iscopyEnvelopeId, 'template');
                    if (this.externalIntegrationType && this.externalIntegrationType.toLowerCase() === 'vincere') {
                        this.currentControlId = newControlObj.id;
                        let currentctrlele: any = document.getElementById('resizable_' + newControlObj.id);
                        if (currentctrlele) {
                            let dataEntityFieldValue: any = currentctrlele.getAttribute("data-EntityFieldValue");
                            if (dataEntityFieldValue && currentctrlele && dataEntityFieldValue != 'null') {
                                //currentctrlele.setAttribute('data-customtooltip', ctrlObj.controlData.customToolTip);
                                currentctrlele.setAttribute('data-customtooltip', (ctrlObj.controlData.customToolTip && (ctrlObj.controlData.customToolTip == 'null' || ctrlObj.controlData.customToolTip == '') ? ctrlObj.controlData.customToolTip : dataEntityFieldValue));
                                this.setNameOrTextControlDimension(dataEntityFieldValue.length + 2);
                            }
                        }
                    }
                    this.allControlsArr.push(newControlObj);
                    this.copyPastedcontrolsArr.push(newControlObj);
                    this.copyPastedcontrolsArrFlag = true;
                }
                setTimeout(() => {
                    if (this.copyPastedcontrolsArrFlag === true) {
                        let itemsToCopy: any = [];
                        if (this.copyPastedcontrolsArr && this.copyPastedcontrolsArr.length) {
                            for (let i = 0; i < this.copyPastedcontrolsArr.length; i++) {
                                for (let j = 0; j < this.allControlsArr.length; j++) {
                                    if (this.copyPastedcontrolsArr[i].id === this.allControlsArr[j].id) {
                                        itemsToCopy.push(this.allControlsArr[j])
                                    }
                                }
                            }
                        }
                        this.performUndoRedoAction('ADD_ITEM', itemsToCopy);
                        this.copyPastedcontrolsArrFlag = false;
                    }

                    this.updateJsonArrays(newControlObj.controlName, newControlObj.className.toLowerCase(), newControlObj, newControlObj.dataGuid);
                    this.getControlsCount(event, ctrlObj.pageNo, type);
                    this.showLoader = false;
                }, 10);
                this.showLoader = false;
            }, 20);
        }
        this.showLoader = false;
    }

    undo(event: any) {
        let ctrlIdArr: any = this.controlPropertyService.undo();
        if (ctrlIdArr && ctrlIdArr.length > 0) {
            this.arrUndoCtrlsCount.push(ctrlIdArr);
            this.sessionStorageService.setArrUndoCtrlsCount(JSON.stringify(this.arrUndoCtrlsCount));
            ctrlIdArr.forEach((ele: any) => {
                this.removeControl(ele, event);
            });
            this.disableRedoButtons();
            this.disableUndoButtons();
        }
    }

    redo() {
        let arrUndoCtrlsCount: any = this.sessionStorageService.getArrUndoCtrlsCount();
        this.arrUndoCtrlsCount = JSON.parse(arrUndoCtrlsCount);
        if (this.arrUndoCtrlsCount && this.arrUndoCtrlsCount.length > 0) {
            this.arrUndoCtrlsCount.pop();
            this.sessionStorageService.setArrUndoCtrlsCount(JSON.stringify(this.arrUndoCtrlsCount));
            let arrCopyPasteCtrlsTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
            this.arrCopyPasteCtrlsTemp = JSON.parse(arrCopyPasteCtrlsTemp);
            if (this.arrCopyPasteCtrlsTemp && this.arrCopyPasteCtrlsTemp.length > 0) {
                this.updateCopyAndPasteControls();
            } else {
                this.createDupicateControl({});
            }
            this.disableRedoButtons();
            this.disableUndoButtons();
        }
    }

    // mouse selection to control and apply alignments -- starts
    applyAlignChanges(selectedOptionVal: any) {
        if (this.isEnvelopeEditable == true) {
            this.alignControlsService.applyAlignChanges(selectedOptionVal, this.selectedItems, this.selectedDocumentImage, this.container)
            this.onClickOutsideSelectionArea();
            this.selectedItems = [];
            this.commonService.selectedItems = [];
        }
    }
    // mouse selection to control and apply alignments -- ends

    //Getting Conversion Images
    retriveConversionImages(initialImagesArrayLength: any) {
        if (this.allImagesProcessed == false) {
            let fromIndex: any = initialImagesArrayLength + 1;
            let conversionImagesArray: any = [];
            for (let index = fromIndex; index <= this.totalDocPageImagesCount; index++) {
                let imgItem: any = this.imagesArr[index - 1];
                let path: any = "?id=" + index + "&envelopeId=" + this.envelope.envelopeDetails.envelopeID + "&uncPath=" + this.envelope.envelopeDetails.uncPath;
                let imgPath: any = this.baseUrl + ApiUrlConfig.GetTemplateImages + path;
                conversionImagesArray.push({
                    imagePathUrl: imgPath, id: index, documentId: imgItem.documentId, width: imgItem.width, height: imgItem.height, isTemplateDatedBeforePortraitLandscapeFeature: imgItem.isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: imgItem.docPageNo, document: {
                        id: imgItem.document.id,
                        name: imgItem.document.name
                    },
                    controlsCount: 0
                });
            }

            if (conversionImagesArray && conversionImagesArray.length > 0) {
                setTimeout(() => {
                    this.checkForAllImagesLoaded(conversionImagesArray, initialImagesArrayLength, '')
                }, 1000);
            }
            else {
                this.updateLocalStorageInfo();
            }
        }
        else {
            this.updateLocalStorageInfo();
        }
    }

    checkForAllImagesLoaded(conversionImagesArray: any, initialImagesArrayLength: any, source: any) {
        let notProcessedImages: any = [];
        if (this.allImagesProcessed == false) {
            for (var i = 0; i < conversionImagesArray.length; i++) {
                var newImage = new Image();
                if (conversionImagesArray[i] != null && conversionImagesArray[i] != undefined) {
                    newImage.src = conversionImagesArray[i].imagePathUrl;
                    let ind: number = i;
                    if (!newImage.complete) {
                        notProcessedImages.push({
                            imagePathUrl: conversionImagesArray[ind].imagePathUrl, id: conversionImagesArray[ind].id, documentId: conversionImagesArray[ind].documentId, width: conversionImagesArray[ind].width, height: conversionImagesArray[ind].height, isTemplateDatedBeforePortraitLandscapeFeature: conversionImagesArray[ind].isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: conversionImagesArray[ind].docPageNo,
                            document: { id: conversionImagesArray[ind].document.id, name: conversionImagesArray[ind].document.name }, controlsCount: 0
                        });
                    }
                    else if (newImage.complete) {
                        if (newImage.width > 0 && newImage.height > 30) {
                            let imageObj: any = conversionImagesArray[ind];
                            this.imagesArr[imageObj.id - 1] = {
                                path: imageObj.imagePathUrl, isActive: false, id: imageObj.id, imageId: imageObj.id, documentId: imageObj.documentId, width: imageObj.width, height: imageObj.height, isVisible: true, isTemplateDatedBeforePortraitLandscapeFeature: imageObj.isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: imageObj.docPageNo,
                                document: { id: imageObj.document.id, name: imageObj.document.name }, controlsCount: 0
                            };
                            setTimeout(() => {
                                if (source == '') {
                                    let imgControl: any = document.getElementById("imgControl_" + imageObj.id);
                                    if (imgControl && imgControl != null) {
                                        imgControl.style.display = '';
                                        imgControl.style.display = 'none';
                                    }
                                }
                            }, 10);
                        }
                        else {
                            notProcessedImages.push({
                                imagePathUrl: conversionImagesArray[ind].imagePathUrl, id: conversionImagesArray[ind].id, documentId: conversionImagesArray[ind].documentId, width: conversionImagesArray[ind].width, height: conversionImagesArray[ind].height, isTemplateDatedBeforePortraitLandscapeFeature: conversionImagesArray[ind].isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: conversionImagesArray[ind].docPageNo,
                                document: { id: conversionImagesArray[ind].document.id, name: conversionImagesArray[ind].document.name }, controlsCount: 0
                            });
                        }
                    }
                    else {
                        console.log("Image not loaded and id is:" + conversionImagesArray[ind].id);
                    }
                }
            }

            if (notProcessedImages && notProcessedImages.length > 0) {
                setTimeout(() => {
                    this.allImagesProcessed = false;
                    this.checkForAllImagesLoaded(notProcessedImages, initialImagesArrayLength, source);
                }, 2000);
            }
            else {
                let fromIndex: any = initialImagesArrayLength + 1;
                setTimeout(() => {
                    for (let index = fromIndex; index <= this.totalDocPageImagesCount; index++) {
                        let imgControl: any = document.getElementById("imgControl_" + index);
                        if (imgControl && imgControl != null) {
                            imgControl.style.display = '';
                            imgControl.style.display = 'block';
                        }
                    }

                    if (source == '') {
                        this.updateLocalStorageInfo();
                    }
                }, 10);
            }
        }
    }

    updateLocalStorageInfo() {
        if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.documentDetails && this.envelope.envelopeDetails.documentDetails.length > 0) {
            let documentDetails: any = this.envelope.envelopeDetails.documentDetails;
            documentDetails = documentDetails.sort((a: any, b: any) => a.order > b.order ? 1 : -1);
            var dict = [];
            for (let index = 0; index < documentDetails.length; index++) {
                const documentElement = documentDetails[index];
                if (documentElement.actionType != "Review" && documentElement.documentName != '') {
                    dict.push({
                        ID: documentElement.id,
                        DocId: documentElement.id,
                        DocumentName: documentElement.documentName,
                        Order: documentElement.order,
                        TemplateID: documentElement.templateID,
                        DocumentContentDetails: documentElement.documentContentDetails
                    });
                }
            }
            //this.showLoader = true;
            let requestObj: any = {
                EnvelopeID: this.envelope.envelopeDetails.envelopeID,
                UncPath: this.envelope.envelopeDetails.uncPath,
                DocumentImageDetails: dict,
                RecipientList: null,
                RoleList: this.envelope.envelopeDetails.roleList,
                CreatedDateTime: this.envelope.envelopeDetails.createdDateTime
            }
            let url = ApiUrlConfig.GetTemplateDocumentImageDetailsUrl;
            setTimeout(() => {
                this.conversionIteration = this.conversionIteration + 1;
                if (this.conversionIteration <= 5) {
                    this.apiService.post(url, requestObj).subscribe(
                        (resp: any) => {
                            let imgEleCollection: any = document.getElementsByClassName('dispImg');
                            if (resp && resp.statusCode == 200 && imgEleCollection && resp.data != null && resp.data.length == imgEleCollection.length) {
                                if (imgEleCollection && imgEleCollection.length > 0) {
                                    Array.from(imgEleCollection).forEach((eleChild: any) => {
                                        let id: any = eleChild.getAttribute('id');
                                        let recObj: any = resp.data.filter((rec: any) => (rec.id == id));
                                        if (recObj && recObj.length > 0) {
                                            recObj = recObj[0];
                                            eleChild.setAttribute('img-document-id', recObj.document.id);
                                            eleChild.setAttribute('img-doc-id', recObj.document.id);
                                            eleChild.setAttribute('docpageno', recObj.docPageNo);
                                            let slBox: any = document.getElementById("selectionBox" + id);
                                            if (slBox) {
                                                slBox.setAttribute('img-document-id', recObj.document.id);
                                                slBox.setAttribute('docpageno', recObj.docPageNo);
                                            }

                                            let imageObj: any = this.imagesArr[parseInt(id) - 1];
                                            if (recObj.isTemplateDatedBeforePortraitLandscapeFeature == false) {
                                                if (recObj.dimension.width > recObj.dimension.height) {
                                                    imageObj.width = "1015px";
                                                    this.isLandscape = true;
                                                }
                                                else {
                                                    imageObj.width = "915px";
                                                    this.isPortrait = true;
                                                }
                                            }
                                            else if (recObj.isTemplateDatedBeforePortraitLandscapeFeature == true) {
                                                imageObj.width = '950px';
                                                this.isLandscape = true;
                                            }

                                            eleChild.style.width = imageObj.width;
                                            let imgEle: any = document.getElementById('imgControl_' + id);
                                            if (imgEle) imgEle.style.width = imageObj.width;

                                            if (imageObj.id > 10) {
                                                this.imagesArr[parseInt(id) - 1] = {
                                                    path: imageObj.path, isActive: false, id: imageObj.id, imageId: imageObj.id, documentId: recObj.document.id, width: imageObj.width, height: recObj.dimension.height, isVisible: true,
                                                    isTemplateDatedBeforePortraitLandscapeFeature: recObj.isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: recObj.docPageNo,
                                                    document: { id: recObj.document.id, name: recObj.document.name }, controlsCount: 0
                                                };
                                            }
                                        }
                                    });

                                    this.updateEnvelopeDocumentControlInformation();
                                    this.sessionStorageService.setAllImagesProcessed('true');
                                    this.commonService.callMultiDocumentsPreview(this.imagesArr);
                                    setTimeout(() => {
                                        this.removePaginationDisabledClass();
                                        this.allImagesProcessed = true;
                                        this.sessionStorageService.setAllImagesArray(JSON.stringify(this.imagesArr));
                                        this.commonService.removeButtonDisabledClass();
                                    }, 50);
                                }
                            }
                            else {
                                setTimeout(() => {
                                    console.log("Retriving images from Second Iteration");
                                    this.updateLocalStorageInfo();
                                }, 1500);
                            }
                        });
                }
                else {
                    this.conversionIteration = 0;
                    console.log("Removing images which are not loaded.");
                    let nullImageEleCollection: any = document.getElementsByClassName('dispImg');
                    if (nullImageEleCollection && nullImageEleCollection.length > 0) {
                        Array.from(nullImageEleCollection).forEach((eleChild: any) => {
                            let id: any = eleChild.getAttribute('id');
                            let imgItem: any = document.getElementById("imgControl_" + id);
                            eleChild.style.display = "none";
                            if (imgItem) imgItem.style.display = "none";

                            this.imagesArr = this.imagesArr.filter(function (el: any) { return el.id != id; });
                        });

                        this.updateEnvelopeDocumentControlInformation();
                        this.sessionStorageService.setAllImagesProcessed('true');
                        this.commonService.callMultiDocumentsPreview(this.imagesArr);
                        setTimeout(() => {
                            this.removePaginationDisabledClass();
                            this.allImagesProcessed = true;
                            this.sessionStorageService.setAllImagesArray(JSON.stringify(this.imagesArr));
                            this.commonService.removeButtonDisabledClass();
                        }, 50);
                    }
                }
            }, 1500);
        }
    }

    removePaginationDisabledClass() {
        this.commonService.removePaginationDisabledClass();
    }

    addPaginationDisabledClass() {
        this.commonService.addPaginationDisabledClass();
    }
    //Getting Conversion Images

    setControlHeight(currentControlId: any) {
        let currentControlEle: any = (<HTMLInputElement>document.getElementById(currentControlId));
        let parentWidthAndheight: any = this.getParentWidthAndHeight(currentControlEle);
        var parentHeight = parentWidthAndheight.Height;
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId));
        var controlHeight = parseInt(spnCurrentSelectedControl.offsetHeight);
        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById('resizable_' + this.currentControlId));
        let controlPositions: any = this.getElePositions(resizableCurrentSelectedControl);
        let controlTopPosition: any = controlPositions.Top;
        var controlTotolHeight = (controlTopPosition + controlHeight);

        if (parentHeight < controlTotolHeight) {
            var finalPosition = controlTopPosition - (controlTotolHeight - parentHeight);
        }
    }

    getDisplayStyle(item: any) {
        if (this.sessionStorageService.getAllImagesProcessed() == "true" || this.sessionStorageService.getAllImagesProcessed() == true) {
            //console.log(item.id)
            return this.cashedService.getDisplayStyleWidthCached(item, this.sessionStorageService.getAllImagesProcessed());
        }
        else {
            //console.log("defaultWidth:"+item.id)
            let defaultWidth: any = '915px';
            if (item && item.width != null && item.width != '' && item.width != undefined) {
                defaultWidth = item.width;
            }
            return { 'width': defaultWidth };
        }
    }

    openDropdownPicker() {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            let userPlanDetails: any = this.sessionStorageService.getUserPlanDetails();
            let uplan = userPlanDetails.UserPlanType;
            let uplanrange = userPlanDetails.UserPlanRange;
            let upgradeLink = userPlanDetails.UpgradeLink;
            let planName = userPlanDetails.UserPlanName;
            let unit = userPlanDetails.UserUnits;

            if (uplan && uplan != null && uplan != "" && uplanrange && uplanrange != null && uplanrange != "") {
                this.showLoader = true;
                this.isDataLoaded = true;
                this.planType = uplan;
                this.planRange = uplanrange;
                //if (planName != null && planName != "" && planName.length > 21) this.planName = planName.substring(0, 20) + "...";
                //else
                this.planName = planName;
                this.fullPlanName = planName;
                this.unit = unit;
                if (upgradeLink != null) this.upgradeLink = upgradeLink;
                this.showLoader = false;
            }
            else {
                //Get user plan and upgrade details
                this.isDataLoaded = false;
                let getUserDetails: any = this.authService.getUserDetails();
                if (getUserDetails && getUserDetails.emailID) {
                    this.showLoader = true;
                    let apiUrl = ApiUrlConfig.GetUserPlanDetails + "?userEmail=" + getUserDetails.emailID;
                    this.apiService.get(apiUrl).subscribe((resp: any) => {
                        this.isDataLoaded = true;
                        this.showLoader = false;
                        if (resp && resp.statusCode == 200) {
                            if (resp.usageRemaining && resp.usageRemaining.resultContent && resp.usageRemaining.resultContent != null) {
                                let userUsageRemainingDetails: any = resp.usageRemaining.resultContent;
                                this.planType = userUsageRemainingDetails.planType;
                                this.planRange = userUsageRemainingDetails.planRange;
                                // localStorage.setItem("UserPlanType", this.planType);
                                // localStorage.setItem("UserPlanRange", this.planRange);
                                this.sessionStorageService.setUserPlanDetails('UserPlanType', this.planType);
                                this.sessionStorageService.setUserPlanDetails('UserPlanRange', this.planRange);
                                if (userUsageRemainingDetails.unitsRemaining && userUsageRemainingDetails.unitsRemaining != null) {
                                    this.unitsRemaining = userUsageRemainingDetails.unitsRemaining;
                                    this.upgradeLink = userUsageRemainingDetails.upgradeLink;
                                    if (this.upgradeLink && this.upgradeLink != '') {
                                        if (this.upgradeLink.startsWith("https://") == false)
                                            this.upgradeLink = "https://" + this.upgradeLink;
                                        this.showUpgradeLink = true;
                                        // localStorage.setItem("UpgradeLink", this.upgradeLink);
                                        this.sessionStorageService.setUserPlanDetails('UpgradeLink', this.upgradeLink);
                                    }
                                }
                            }

                            //if (resp.planName != null && resp.planName != "" && resp.planName.length > 21) this.planName = resp.planName.substring(0, 20) + "...";
                            //else
                            this.planName = resp.planName;
                            this.fullPlanName = resp.planName;
                            this.unit = resp.unit;
                            // localStorage.setItem("UserPlanName", resp.planName);
                            // localStorage.setItem("UserUnits", this.unit);

                            this.sessionStorageService.setUserPlanDetails('UserPlanName', resp.planName);
                            this.sessionStorageService.setUserPlanDetails('UserUnits', this.unit);
                        }
                        else if (resp && resp.originalError && resp.originalError.statusText && resp.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                    });
                }
                else {
                    this.showLoader = false;
                    this.toastrService.warning(this.commonConstants.UserPlanFailed, 'Warning');
                }
            }
        }
    }

    applyExternalTextOptions(e: any, externalTextoptions: any) {
        // console.log('applyExternalTextOptions',externalTextoptions);
        if (externalTextoptions != 'Select') {
            this.userSelectedEntity = false;
        } else {
            this.userSelectedEntity = true;
            this.controlPropertyService.resetTextAreaInputs();
            this.applyExternalTextOptionsFields(e, 'Select');
        }
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        if (ctrlEle) {
            ctrlEle.setAttribute('data-Entity', externalTextoptions);
            ctrlEle.setAttribute('data-IntegrationType', this.externalIntegrationType);
            ctrlEle.setAttribute('data-EntityField', '');
            ctrlEle.setAttribute('data-EntityFieldValue', '');
            this.controlPropertyService.resetTextToTextArea(currentSelectedControl);
        }
    }

    applyExternalTextOptionsFields(e: any, entityFieldsValue: any) {
        // console.log('applyExternalTextOptionsFields',entityFieldsValue);
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        if (entityFieldsValue != 'Select') {
            if (entityFieldsValue && (entityFieldsValue.toLowerCase().includes('date') || entityFieldsValue.toLowerCase() === 'availability start')) {
                ctrlEle.setAttribute('data-asteriskOriginalText', entityFieldsValue);
                this.showTextDateFormat = 'block';
                this.showTextFieldsContainer = 'block';
                ctrlEle.setAttribute('data-additional-validation-name', 'Date Format');
                let validationOption: any = this.validationsDateOptions[0].value;
                ctrlEle.setAttribute('data-additional-validation-option', validationOption);
                //5 Means Date of Text Control
                let validationsTextOption: any = this.commonConstants.ValidationsText[5].value;
                ctrlEle.setAttribute('data-texttype', validationsTextOption);
                this.controlPropertyService.updateDateFormatNameAndOptions(ctrlEle, validationOption);
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '6';
                }
            } else {
                this.showTextDateFormat = 'none';
                this.showTextFieldsContainer = 'none';
                this.userSelectedEntity = false;
                ctrlEle.setAttribute('data-additional-validation-name', '');
                let validationOption: any = this.validationsDateOptions[0].value;
                ctrlEle.setAttribute('data-additional-validation-option', validationOption);
                //5 Means Date of Text Control
                let validationsTextOption: any = this.commonConstants.ValidationsText[0].value;
                ctrlEle.setAttribute('data-texttype', validationsTextOption);
                let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
                if (textValidationsoptions) {
                    textValidationsoptions.value = '1';
                }
            }
            let addTextTextarea: any = document.getElementById('addTextTextarea');
            let isHideTextWithAsterisk: any = ctrlEle.getAttribute('data-ishidetextwithasterisk');
            if (addTextTextarea) {
                addTextTextarea.value = entityFieldsValue;
            }

            // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
            let spnCurrentSelectedControl: any = document.getElementById('spn' + currentSelectedControl);
            if (spnCurrentSelectedControl) {
                spnCurrentSelectedControl.innerText = (isHideTextWithAsterisk === true || isHideTextWithAsterisk === 'true') ? '*'.repeat(entityFieldsValue.length) : entityFieldsValue;
            }
            if (ctrlEle) {
                const getKeyValue = this.controlPropertyService.getKeyByValue(entityFieldsValue, this.externalEntityFieldList);
                ctrlEle.setAttribute('data-EntityField', entityFieldsValue);
                ctrlEle.setAttribute('data-EntityFieldValue', getKeyValue);
                ctrlEle.setAttribute('data-customtooltip', entityFieldsValue);
                ctrlEle.setAttribute('data-asteriskOriginalText', getKeyValue);
                ctrlEle.setAttribute('data-addtextareaval', getKeyValue);
                spnCurrentSelectedControl.setAttribute('data-addtextareaval', getKeyValue);
                let tooltipControl: any = document.getElementById('controlInputTooltip');
                if (tooltipControl) {
                    tooltipControl.value = entityFieldsValue;
                }
                let disableInputField: any = document.getElementById('textInputLength');
                if (disableInputField) {
                    disableInputField.classList.add('disabled');
                }
                let textValidationsoptions: any = document.getElementById('textValidationsoptions');
                if (textValidationsoptions) {
                    textValidationsoptions.classList.add('disabled');
                }
            }
        }
        else {
            this.controlPropertyService.resetTextAreaInputs();
            let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
            let spnCurrentSelectedControl: any = document.getElementById('spn' + currentSelectedControl);
            let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
            let integrationType: any = ctrlEle.getAttribute('data-IntegrationType');
            if (integrationType && integrationType.toLowerCase() === 'vincere') {
                ctrlEle.setAttribute('data-EntityField', '');
                ctrlEle.setAttribute('data-EntityFieldValue', '');
                ctrlEle.setAttribute('data-customtooltip', '');
                ctrlEle.setAttribute('data-asteriskOriginalText', '');
                ctrlEle.setAttribute('data-addtextareaval', '');
                spnCurrentSelectedControl.setAttribute('data-addtextareaval', '');
                if (spnCurrentSelectedControl) {
                    spnCurrentSelectedControl.innerText = 'Text';
                }
                let addTextTextarea: any = document.getElementById('addTextTextarea');
                if (addTextTextarea) {
                    addTextTextarea.value = '';
                }
                let tooltipControl: any = document.getElementById('controlInputTooltip');
                if (tooltipControl) {
                    tooltipControl.value = '';
                }
                this.applyTextTypeControl(1);
            }
        }
        this.applyTextTypeControl(1);
    }

    onSaveDraft(type: any) {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            if (this.disableSaveDraftButton == "false" || type == 1) {
                console.log("Template Save Draft:" + new Date().toLocaleString());
                if (type != 1) {
                    this.showLoader = true;
                }
                let resultObj: any = this.getEachControlValidation("draft", false);
                //console.log('onSaveDraft', resultObj);
                if (resultObj.isValid == true) {
                    let controlArray = resultObj.controlArray;
                    let apiEnvelopeControls: any = {
                        TemplateID: this.envelope.envelopeDetails.envelopeID,
                        Controls: controlArray,
                        EnvelopeStage: RSignStage.PrepareEditTemplate,
                        Savetype: type,
                        Source: 'Save',
                        IsAutoSave: true,
                        EnvelopeTypeId: EnvelopeType.Template,
                        EnableMultipleBranding: this.enableMultiBranding,
                        Branding: this.branding,
                    };
                    this.apiService.SaveTemplateRuleUrl(apiEnvelopeControls).subscribe((data: any) => {
                        if (data && data.statusCode == 200) {
                            //this.showLoader = false;
                            console.log("SaveAsDraftTemplate 200 " + new Date().toLocaleString());
                            // this.toastrService.success('Template/Rule auto saved successfully.', 'Success'); //Envelope saved to Drafts
                            let transText: any = this.getLanguageTranslationValue(ResourceKeys.TemplateAutoSaveSuccess);
                            if (transText) this.toastrService.success(transText, 'Success');
                            else this.toastrService.success("Template/Rule saved successfully.", 'Success');

                            if (type === 0) this.hostListenerStatus = true;
                            setTimeout(() => {
                                if (type === 1) {
                                    this.showLoader = false;
                                    setTimeout(() => {
                                        if (this.isSendEnvelope == false) {
                                            this.autoSaveEnvasDraft();
                                        }
                                    }, 1000);
                                }
                            }, 100);
                        }
                        else if (data && data.originalError && data.originalError.statusText && data.originalError.statusText == "Unauthorized") {
                            this.redirectRSignLogOnPage();
                        }
                        else {
                            console.log("SaveAsDraftTemplate FAILED 400 " + new Date().toLocaleString());
                            this.showLoader = false;
                            let transText: any = this.getLanguageTranslationValue(ResourceKeys.TemplateAutoSaveFailed);
                            if (transText) this.toastrService.warning(transText, 'Warning');
                            else this.toastrService.warning("Could not save Template/Rule.", 'Warning');
                            if (type === 1) {
                                this.showLoader = false;
                                setTimeout(() => {
                                    if (this.isSendEnvelope == false) {
                                        this.autoSaveEnvasDraft();
                                    }
                                }, 1000);
                            }
                        }
                    })
                }
                else {
                    this.showLoader = false;
                }
            }
        }
    }

    onGotoStepOne(type: any) {
        this.showLoader = true;
        let IsSaveControl: boolean = type === "1" ? true : false;
        let resultObj: any = this.getEachControlValidation('draft', IsSaveControl);
        if (resultObj.isValid == true) {
            let controlArray = resultObj.controlArray;
            let apiEnvelopeControls: any = {
                TemplateID: this.envelope.envelopeDetails.envelopeID,
                EnvelopeStage: this.isEnvelopeEdited == true ? RSignStage.PrepareEnvelope : this.getEnvelopeStage(),
                Controls: IsSaveControl ? controlArray : null,
                IsSaveControl: IsSaveControl,
                Source: 'Back',
                IsAutoSave: true,
                EnvelopeTypeId: EnvelopeType.Template,
                EnableMultipleBranding: (IsSaveControl === true) ? (this.enableMultiBranding == true ? true : this.branding != '' ? true : false) : false,
                Branding: IsSaveControl === true ? (this.branding != '' ? this.branding : this.defaultBranding) : '',
            };

            this.apiService.SaveTemplateRuleUrl(apiEnvelopeControls).subscribe((data: any) => {
                if (data && data.statusCode == 200) {
                    this.hostListenerStatus = true;
                    setTimeout(() => {
                        this.showLoader = false;
                        let rsignUrl: any;
                        this.externalIntegrationType = this.integrationResponse && this.integrationResponse.envelopeDetails.integrationType ? this.integrationResponse.envelopeDetails.integrationType : ''; //Vincere, Bullhorn
                        if (this.externalIntegrationType && (this.externalIntegrationType.toLowerCase() === 'vincere' || this.externalIntegrationType.toLowerCase() === 'bullhorn')) {
                            rsignUrl = environment.rsignSFAppUrl;
                        }
                        else {
                            let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
                            if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
                                rsignUrl = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
                            }
                            else rsignUrl = environment.rsignUrl;
                        }
                        window.location.href = rsignUrl + data.returnURL + '?' + data.encryptedEnvelopeID;
                    }, 1000);
                }
                else if (data && data.originalError && data.originalError.statusText && data.originalError.statusText == "Unauthorized") {
                    this.redirectRSignLogOnPage();
                }
                else {
                    this.showLoader = false;
                    let transText: any = this.getLanguageTranslationValue(ResourceKeys.TemplateAutoSaveFailed);
                    if (transText) this.toastrService.warning(transText, 'Warning');
                    else this.toastrService.warning("Could not save Template/Rule.", 'Warning');
                }
            });
        }
        else {
            this.showLoader = false;
        }
    }
    checkNumberLength(ctrlName: any, input: any) {
        let finalLength: any = (ctrlName === 'text') ? 4 : 3;
        if (this.selectValidation === '7') { //email of text
            this.textCtrlMaxValidation = 100;
            finalLength = 3;
            if (parseInt(input.value) > 100) {
                input.value = 100;
            }
            if (input.value.length > finalLength) {
                input.value = input.value.slice(0, finalLength); // Limiting to 3
            }
        } else {
            if (input.value.length > finalLength) {
                input.value = input.value.slice(0, finalLength); // Limiting to 3 or 4 digits
            }
        }
    }

    updateCharInputLimit(inputId: any, ctrlName: any) {
        let charInputLimit: any = document.getElementById(inputId);
        // let currentControlId: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentControlId: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let ctrlEle: any = document.getElementById('resizable_' + currentControlId);
        let dataValidationType: any = ctrlEle.getAttribute('data-validation-type');
        if (charInputLimit && charInputLimit.value) {
            let val: any = parseInt(charInputLimit.value);
            if (dataValidationType && dataValidationType === 'Email' && ctrlName === 'text') {
                if (val > 100) {
                    charInputLimit.value = 100;
                    this.toastrService.warning('Value should be less than or equal to 100.', 'Warning');
                } else {
                    ctrlEle.setAttribute('data-maxcharlimit', charInputLimit.value);
                }
            } else if (ctrlName === 'text') {
                if (val > 4000) {
                    charInputLimit.value = 4000;
                    this.toastrService.warning('Value should be less than or equal to 4000.', 'Warning');
                } else {
                    ctrlEle.setAttribute('data-maxcharlimit', charInputLimit.value);
                }
            } else {
                if (val > 100) {
                    charInputLimit.value = 100;
                    this.toastrService.warning('Value should be less than or equal to 100.', 'Warning');
                } else {
                    ctrlEle.setAttribute('data-maxcharlimit', charInputLimit.value);
                }
            }
        }
    }

    validateCharLimit() {
        let charInputLimit: any = document.getElementById('textCharInputLimit');
        if (charInputLimit) {
            this.controlPropertyService.hideCtrlCharLimitInput(charInputLimit);
        }
        let inputLength: any = document.getElementById('textInputLength');
        if (inputLength) {
            this.controlPropertyService.showCtrlLengthInput(inputLength);
        }
        let isFixedWidthTextContainer: any = document.getElementById('isFixedWidthTextContainer');
        if (isFixedWidthTextContainer) {
            isFixedWidthTextContainer.style.display = 'none';
        }
    }

    autoSaveEnvasDraft() {
        // console.log("Auto Save Draft:" + new Date().toLocaleString());
        let timeOutValue: any = 120000; //120000 (It means 2 mins)
        setTimeout(() => {
            if (this.isSendEnvelope == false && this.sessionStorageService.getAllImagesProcessed() == "true") {
                this.onSaveDraft(1);
            }
        }, timeOutValue);
    }

    createDupicateControl(e: any) {
        this.selectedItems.forEach((ele: any) => {
            if (ele) {
                const dynamicComponentRef = this.dynamicComponentRefs.get(ele.replace('resizable_', ''));
                if (dynamicComponentRef && dynamicComponentRef.instance && dynamicComponentRef.instance.ctrlObj) {
                    let ctrlObj: any = dynamicComponentRef.instance.ctrlObj;
                    if (ctrlObj.className == "dropdown") {
                        let selectDropdownControlField: any = (document.getElementById('spn' + ctrlObj.id)) as HTMLSelectElement;
                        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length == 0) {
                            this.toastrService.warning('Please add the options for dropdown control.', 'Warning');
                            //return false;
                        }
                    }

                    // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
                    let arrCopyPasteCtrl: any = this.sessionStorageService.getArrCopyPasteCtrl();
                    let childCtrlObj: any = ctrlObj;
                    if (arrCopyPasteCtrl) {
                        this.arrCopyPasteCtrl = JSON.parse(arrCopyPasteCtrl);
                        if (this.arrCopyPasteCtrl && this.arrCopyPasteCtrl.length > 0) {
                            if (this.arrCopyPasteCtrl[0].ParentControl.dataGuid == ctrlObj.dataGuid) {
                                childCtrlObj = this.arrCopyPasteCtrl[0].Control;
                                if (this.arrCopyPasteCtrl && this.arrCopyPasteCtrl.length === 0) {
                                    this.arrCopyPasteCtrl = [];
                                }
                                this.arrCopyPasteCtrl.push({ ParentControl: ctrlObj, Control: childCtrlObj, Action: 'P' });
                                // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
                                this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
                            }
                            else {
                                this.arrUndoRedoCtrl = [];
                                // localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(this.arrUndoRedoCtrl));
                                this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrUndoRedoCtrl));
                            }
                        }
                    }
                    this.createControl(dynamicComponentRef.instance.ctrlObj, ControlConfig.Duplicate, 'false');
                }
            }
        });

    }

    enableFieldAlignment() {
        let totalControlCount = this.getAllControlsCount();
        if (this.envelope && this.envelope.envelopeDetails && this.envelope.envelopeDetails.isFormFieldAlignmentEnabled) {
            if (this.isEnvelopeEditable == false) { return 'disabledCtrl' }
            else {
                if (totalControlCount > 1 && this.selectedItems.length > 1) return 'enabledCtrl';
                else return 'disabledCtrl';
            }
        }
        else return 'disabledCtrl'
    }

    isAlignmentFieldOpen() {
        if (this.isEnvelopeEditable == false) return false;
        else return true;
    }

    isEnvEditable() {
        let totalControlCount = this.getAllControlsCount();
        if (this.isEnvelopeEditable == false || totalControlCount == 0) return 'disabledCtrl';
        else return 'enabledCtrl';
    }

    updateAlignments(ctrlName: any) {
        let textFormatting: any = this.defaultControlStyleService.getDictionaryByKey(ctrlName);
        // let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyTextAlign(currentSelectedControl, textFormatting.textAlign);

        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentSelectedControl));
        let spnctrlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentSelectedControl));
        if (controlEle) {
            let nameOrTextInputLengthEle: any = document.getElementById(ctrlName.toLowerCase() + 'InputLength');
            if (nameOrTextInputLengthEle && nameOrTextInputLengthEle.value) {
                this.setNameOrTextControlDimension(parseInt(nameOrTextInputLengthEle.value));
            }
        }

        //console.log('textFormatting', textFormatting);
        if (textFormatting && textFormatting.saveAsDefault == undefined && spnctrlEle) {
            this.defaultControlStyleService.updateDictionary(ctrlName, {
                saveAsDefault: true,
                required: true,
                fontFamily: spnctrlEle.style.fontFamily,
                fontWeight: spnctrlEle.style.fontWeight,
                textDecoration: spnctrlEle.style.textDecoration,
                fontStyle: spnctrlEle.style.fontStyle,
                fontSize: spnctrlEle.style.fontSize,
                color: spnctrlEle.style.color,
                textAlign: spnctrlEle.style.textAlign,
                lineHeight: ctrlName.toLowerCase() === 'date' ? '' : spnctrlEle.style.fontSize,
                validationType: '',
                adlnName: '',
                adlnOption: '',
                preSelected: false,
                checkboxGroup: false,
                checkboxGroupName: '',
                checkboxName: '',
                dateFormat: '',
                size: 'standard',
                fontTypeMeasurement: 'px',
                firstLineBlank: false,
                labelControlValue: ''
            })
            this.updateSaveAsDefaultChecked = true;
        }
    }

    updateTextFormattingStyles(ctrlObj: any) {
        let ctrlEle: any = document.getElementById('spn' + ctrlObj.id);
        if (ctrlEle && ctrlEle.style) {
            this.checkSpnTextFormattingValues(ctrlEle, ctrlObj.id);
            if (ctrlObj.textFormatting.validationType) {
                this.commonConstants.ValidationsText.forEach((rec: any) => {
                    if (rec.value === ctrlObj.textFormatting.validationType) {
                        setTimeout(() => {
                            let textValidationsoptions: any = document.getElementById('textValidationsoptions');
                            if (textValidationsoptions) {
                                textValidationsoptions.value = rec.id;
                                this.applyTextTypeControl(rec.id);
                            }
                        }, 300);
                    }
                })
            }
        }
    }

    updateSaveAsDefault(ctrlObj: any) {
        let saveAsDefaultVal: any = ctrlObj.textFormatting.saveAsDefault;

        let spnCtrlEle: any = document.getElementById('spn' + ctrlObj.id);
        if (spnCtrlEle) {
            spnCtrlEle.setAttribute('data-saveasdefault', saveAsDefaultVal);
        }
        this.updateSaveAsDefaultChecked = saveAsDefaultVal;
        this.defaultControlStyleService.updateDictionary(ctrlObj.className,
            ctrlObj.textFormatting
        )
    }

    viewPdf() {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            this.showLoader = true;
            let resultObj: any = this.getEachControlValidation("viewPdf", false);
            if (resultObj.isValid == true) {
                let controlArray = resultObj.controlArray;
                let apiEnvelopeControls: any = {
                    EnvelopeId: this.envelope.envelopeDetails.envelopeID,
                    Controls: controlArray,
                    EnvelopeStage: RSignStage.PrepareEditTemplate,
                };
                this.apiService.viewTemplatePdf(apiEnvelopeControls).subscribe((resp: any) => {
                    this.showLoader = false;
                    console.log('dataresponse', resp.value);
                    if (resp && resp.statusCode == 200 && resp.base64FileData) {
                        const binaryString = window.atob(resp.base64FileData);
                        const bytes = new Uint8Array(binaryString.length);
                        for (let i = 0; i < binaryString.length; i++) { bytes[i] = binaryString.charCodeAt(i); }
                        //Create a Blob from the Uint8Array
                        const blob = new Blob([bytes.buffer],
                            { type: 'application/pdf' });     // Create a URL for the Blob
                        const url = URL.createObjectURL(blob);
                        // Open the URL in a new window
                        window.open(url, '_blank');
                        // Release the URL object when the window is closed
                        window.addEventListener('unload', () => { URL.revokeObjectURL(url); });
                    }
                    else if (resp && resp.originalError && resp.originalError.statusText && resp.originalError.statusText == "Unauthorized") {
                        this.redirectRSignLogOnPage();
                    }
                    else {
                        this.showLoader = false;
                        this.toastrService.warning('Unable to view the pdf document.', 'Warning');
                    }
                })
            }
            else {
                this.showLoader = false;
            }
        }
    }

    updateCtrlTextFormatting(currentSelectedControl: any, isApplicalSaveAs: any) {
        let resizableCtrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        let ctrlEle: any = document.getElementById(currentSelectedControl);
        let spnctrlEle: any = document.getElementById('spn' + currentSelectedControl);
        let dataValidationType: any;
        let dataAdditionalValidationName: string = '';
        let dataAdditionalValidationOption: string = '';
        let dataDefaultformat: any;
        let ctrlName: any;
        let typography: any;
        if (resizableCtrlEle) {
            typography = resizableCtrlEle.getAttribute('data-typography');
        }
        if (ctrlEle) {
            ctrlName = ctrlEle.getAttribute('data-controlname');
            let checkSaveAsDefaultisChecked: any = this.defaultControlStyleService.getCtrlBasedSaveAsDefaultValue(ctrlName.toLowerCase());
            if (checkSaveAsDefaultisChecked === 'true') {
                this.updateSaveAsDefaultChecked = true;
            }
            if (checkSaveAsDefaultisChecked === 'false') {
                this.updateSaveAsDefaultChecked = false;
            }
            if (checkSaveAsDefaultisChecked === true || checkSaveAsDefaultisChecked === 'true') {
                if (resizableCtrlEle && spnctrlEle) {
                    dataDefaultformat = resizableCtrlEle.getAttribute('data-defaultformat');
                    dataValidationType = resizableCtrlEle.getAttribute('data-validation-type');
                    dataAdditionalValidationName = resizableCtrlEle.getAttribute('data-additional-validation-name');
                    dataAdditionalValidationOption = resizableCtrlEle.getAttribute('data-additional-validation-option');
                    let validationOption: any = '';
                    if (dataValidationType && dataValidationType.toLowerCase() === 'zip' && dataAdditionalValidationOption && dataAdditionalValidationOption != "null" && dataAdditionalValidationOption != "undefined") {
                        validationOption = this.commonConstants.ValidationsZipOptions[parseInt(dataAdditionalValidationOption) - 1].viewname;
                    }
                    if (dataValidationType && dataValidationType.toLowerCase() === 'numeric' && dataAdditionalValidationOption && dataAdditionalValidationOption != "null" && dataAdditionalValidationOption != "undefined") {
                        validationOption = this.commonConstants.AdditionalValidationType[parseInt(dataAdditionalValidationOption) - 1].viewValue;
                    }
                    if (dataValidationType && dataValidationType.toLowerCase() === 'date' && dataAdditionalValidationOption && dataAdditionalValidationOption != "null" && dataAdditionalValidationOption != "undefined") {
                        validationOption = this.validationsDateOptions[parseInt(dataAdditionalValidationOption) - 1].label;
                    }

                    const fontFamilyWithQuotes = spnctrlEle.style.fontFamily;
                    const fontFamilyWithoutQuotes = fontFamilyWithQuotes.replace(/"/g, '');
                    this.defaultControlStyleService.updateDictionary(ctrlName.toLowerCase(), {
                        saveAsDefault: spnctrlEle.getAttribute('data-saveasdefault'),
                        required: (ctrlName.toLowerCase() === 'radio') ? this.isRadioRequiredChecked : this.isRequiredChecked,
                        fontFamily: fontFamilyWithoutQuotes,
                        fontWeight: spnctrlEle.style.fontWeight,
                        textDecoration: spnctrlEle.style.textDecoration,
                        fontStyle: spnctrlEle.style.fontStyle,
                        fontSize: spnctrlEle.style.fontSize,
                        color: spnctrlEle.style.color,
                        textAlign: spnctrlEle.style.textAlign,
                        lineHeight: ctrlName.toLowerCase() === 'date' ? '' : spnctrlEle.style.fontSize,
                        validationType: ctrlName.toLowerCase() === 'text' ? this.controlPropertyService.getTextTypeValue(dataValidationType) : '',
                        adlnName: ctrlName.toLowerCase() === 'text' ? dataAdditionalValidationName : '',
                        adlnOption: ctrlName.toLowerCase() === 'text' ? validationOption : '',
                        preSelected: this.isChkPreSelected ? this.isChkPreSelected : false,
                        checkboxGroup: this.ischeckboxGroup ? this.ischeckboxGroup : false,
                        checkboxGroupName: resizableCtrlEle.getAttribute('data-groupname'),
                        checkboxName: spnctrlEle.getAttribute('name'),
                        dateFormat: dataDefaultformat ? dataDefaultformat : '',
                        size: this.commonConstants.Checkboxoptions[parseInt(this.selectDefaultSizeValue) - 1].viewValue,
                        fontTypeMeasurement: typography == null || typography == undefined || typography == '' ? "px" : typography,
                        labelControlValue: spnctrlEle.innerText ? spnctrlEle.innerText : '',
                        firstLineBlank: (ctrlName.toLowerCase() === 'dropdown') ? this.isdrpFirstLineBlank : false
                    });

                    let newData = this.defaultControlStyleService.updateSaveAsDefaultPayload(currentSelectedControl, ctrlName, spnctrlEle, fontFamilyWithoutQuotes, this.isRequiredChecked, typography);
                    //console.log('saveAsDefaultData', newData);

                    if (isApplicalSaveAs === '1') {
                        this.saveAsDefaultSaveSettings(newData);
                    }
                }
            }
        }
    }

    isUndefinedOrNull(val: any, type: any) {
        if (val != null || val != undefined || val != '' || val != "null" || val != "undefined") {
            if (type == 1) return "#000000";
            else if (type == 2) return "d4a45ecd-3865-448a-92fa-929c2295ea34";
            else if (type == 3) return "Courier";
            else if (type == 4) return "12";
            else if (type == 5) return "12px";
        }
        else return val;
    }

    getAllControlsCount() {
        let totalControlCount = 0;
        let documentImagesControlsArray: any = document.querySelectorAll('.signControl');
        if (documentImagesControlsArray && documentImagesControlsArray.length > 0) {
            totalControlCount = documentImagesControlsArray.length;
        }
        return totalControlCount;
    }

    updateSaveAsDefaultCheckedOrNot(ctrlid: any) {
        let spnCtrlEle: any = document.getElementById('spn' + ctrlid);
        if (spnCtrlEle) {
            let dataSaveasdefault: any = spnCtrlEle.getAttribute('data-saveasdefault');
            if (dataSaveasdefault) {
                if (dataSaveasdefault === 'true') {
                    this.updateSaveAsDefaultChecked = true;
                }
                else {
                    this.updateSaveAsDefaultChecked = false;
                }
            }

            // this.defaultFontSize = spnCtrlEle.style.fontSize;
            this.defaultColorPicker = spnCtrlEle.style.color;
            this.checkSpnTextFormattingValues(spnCtrlEle, ctrlid);
        }
    }

    updateExtendDocumentWidth() {
        let addmiddleClass: any = (document.getElementById('middle-section')) as HTMLSelectElement;
        let depencieChanges: any = (document.getElementById('depencieChanges')) as HTMLSelectElement;
        if (this.hidePagesViewSection == false || this.hideControlViewSection == false) {
            addmiddleClass.classList.remove('col-lg-10');
            addmiddleClass.classList.add('col-lg-8');
        }

        if (this.hidePagesViewSection === true && this.hideControlViewSection === true) {
            addmiddleClass.classList.remove('col-lg-8');
            addmiddleClass.classList.add('col-lg-10');
        }
        if (this.hidePagesViewSection === false && this.hideControlViewSection === false && this.hideDependencies == true) {
            depencieChanges.classList.remove('depencie-AdjustW');
            depencieChanges.classList.add('depencie-W');
        }
        if (this.hidePagesViewSection === false && this.hideControlViewSection === false && this.hideDependencies == false) {
            depencieChanges.classList.remove('depencie-W');
            depencieChanges.classList.add('depencie-AdjustW');
        }
        // &&
        this.isAddColumnSpace = false;
        // this.updateExtendDependecieWidth();
    }

    updateExtendDependecieWidth() {
        let depencieChanges: any = (document.getElementById('depencieChanges')) as HTMLSelectElement;
        if (this.depenciestatus == false && this.hidePagesViewSection === false) {
            depencieChanges.classList.add(' depencie-AdjustW');
            depencieChanges.classList.remove(' depencie-W');
        }

    }

    //Truncated filename
    showReviewItem(file: any) {
        let charactersToDisplay: any = 16;
        if (file) {
            let fileName: any = file.split('.');
            let fileNameWithoutExtension: any = fileName[0];
            let fileExtension: any = fileName[1];
            let truncatedFileName: any = fileNameWithoutExtension.length >= 16 ? fileNameWithoutExtension.substring(0, charactersToDisplay) + '...' + fileExtension : file;
            return truncatedFileName;
        }
        return;
    }

    checkSpnTextFormattingValues(spnCtrlEle: any, id: any) {
        this.isAlignleft = false;
        this.isAlignmiddle = false;
        this.isAlignright = false;
        this.isItalic = false;
        this.isUnderlined = false;
        this.isBold = false;

        if (spnCtrlEle.style.fontStyle != 'normal') {
            this.isItalic = true;
        }
        if (spnCtrlEle.style.textDecoration != 'none') {
            this.isUnderlined = true;
        }
        if (spnCtrlEle.style.fontWeight == 600 || spnCtrlEle.style.fontWeight == 'bold') {
            this.isBold = true;
        }
        if (spnCtrlEle.style.textAlign === 'left') {
            this.isAlignleft = true;
        }
        if (spnCtrlEle.style.textAlign === 'center') {
            this.isAlignmiddle = true;
        }
        if (spnCtrlEle.style.textAlign === 'right') {
            this.isAlignright = true;
        }
        if (spnCtrlEle.style.color === '#000' || spnCtrlEle.style.color === 'rgb(0, 0, 0)') {
            this.defaultColorPicker = this.commonConstants.DefaultColorPicker;
        }
        if (spnCtrlEle.style.fontSize !== '') {
            let newFontSize: any = spnCtrlEle.style.fontSize.split('px')[0];
            let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + id));
            if (resizableCurrentSelectedControl && resizableCurrentSelectedControl.style) {
                let fontSizePtOrPxSelect: any = resizableCurrentSelectedControl.getAttribute('data-typography');
                if (fontSizePtOrPxSelect && fontSizePtOrPxSelect == "pt") {
                    newFontSize = this.controlPropertyService.getTypographyConversionValue(newFontSize);
                    this.defaultTypographySize = "pt";
                }
                else {
                    this.defaultTypographySize = "px";
                }
            }

            this.defaultFontSize = newFontSize;
        }
        if (spnCtrlEle.style.fontFamily !== '') {
            const fontFamilyWithQuotes = spnCtrlEle.style.fontFamily;
            const fontFamilyWithoutQuotes = fontFamilyWithQuotes.replace(/"/g, '');
            this.defaultFontFamily = fontFamilyWithoutQuotes;
        }
    }


    updateCtrlDateFormat(ctrlId: any, type: any, textFormatting: any) {
        let spnCtrlEle: any = document.getElementById('spn' + ctrlId);
        if (spnCtrlEle) {
            // Find the object with the desired date format
            const desiredFormat = spnCtrlEle.innerText;
            let dateFormatObject: any = this.commonConstants.Dateformat.find((format: any) => format.viewValue.includes(desiredFormat));

            // Retrieve the value if the format is found
            if (type === 0 && textFormatting != '') {
                //console.log('textFormatting', textFormatting.dateFormat);
                if (textFormatting.dateFormat === '' && this.envelope && this.envelope.envelopeDetails) {
                    this.selectDefaultDateFormat = this.controlPropertyService.getDefaultDateOption(this.envelope.envelopeDetails.dateFormatID);
                    spnCtrlEle.innerHTML = this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat);
                } else {
                    dateFormatObject = this.commonConstants.Dateformat.find((format: any) => format.viewValue.includes(textFormatting.dateFormat));
                    this.selectDefaultDateFormat = dateFormatObject.value;
                    spnCtrlEle.innerHTML = this.controlPropertyService.getDateFormatInnerHtmlVal(dateFormatObject.value);
                }
            }
            else {
                if (this.envelope && this.envelope.envelopeDetails) {
                    this.selectDefaultDateFormat = this.controlPropertyService.getDefaultDateOption(this.envelope.envelopeDetails.dateFormatID);
                    spnCtrlEle.innerHTML = this.controlPropertyService.getDateFormatInnerHtmlVal(this.selectDefaultDateFormat);
                }
            }
        }
    }

    updateCopyAndPasteControls() {
        this.arrCopyPasteCtrlsTemp.forEach((ele: any, index: any) => {
            let redoItem: any = this.arrCopyPasteCtrlsTemp[index];
            if (redoItem && redoItem.Control) {
                this.createControl(redoItem.Control, ControlConfig.Duplicate, 'false');
            }
        })
        this.arrCopyPasteCtrlsTemp = [];
        // localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify([]));
        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));
    }

    restrictInputLengthToThree(e: any) {
        if (e && e.target && e.target.value && e.target.value.length > 3) {
            return e.target.value = e.target.value.slice(0, 3); // Limit input to 3 characters
        }
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.commonService.getLabelTranslationVal(resourcekeyId);
    }

    resetPaginationCount() {
        this.isPageLoadingFirstTime = true;
        let paginationInput: any = document.getElementById('paginationInput');
        if (paginationInput) {
            paginationInput.value = 1;
            // localStorage.setItem('currentPagination', '1');
            this.sessionStorageService.setCurrentPagination('1');
        }
    }

    updateLengthOrMaxCharLimitLabel() {
        // let currentSelectedControl: any = localStorage.getItem('CurrentClickedDroppedControl');
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        if (currentSelectedControl) {
            let controlEle: any = (<HTMLInputElement>document.getElementById(currentSelectedControl));
            let resizableCtrlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentSelectedControl));
            if (controlEle) {
                let ctrlName: any = controlEle.getAttribute('data-controlname');
                if (ctrlName && ctrlName.toLowerCase() === 'name' || ctrlName.toLowerCase() === 'title' || ctrlName.toLowerCase() === 'text') {
                    setTimeout(() => {
                        if (this.hideFixedwidthCheckbox == false || this.hideFixedwidthCheckbox == 'false') {
                            this.lengthOrMaxCharLimitLabel = 'Max Character Limit';
                            if (resizableCtrlEle) {
                                let isFixedWidth: any = resizableCtrlEle.getAttribute('data-isfixedwidth');
                                if (isFixedWidth) {
                                    this.isFixedWidthChecked = (isFixedWidth === true || isFixedWidth === 'true') ? true : false;
                                }

                            }
                            else {
                                this.isFixedWidthChecked = false;
                            }
                        }
                        // else { //old signer's UI
                        //     this.validateCharLimit();
                        // }

                        // if (resizableCtrlEle) {
                        // 	this.controlPropertyService.setTextAttributes(resizableCtrlEle);
                        // }

                        // let addTextTextareaEle: any = document.getElementById('addTextTextarea');
                        // if (addTextTextareaEle) {
                        //     addTextTextareaEle.value = '';
                        // }
                    }, 200);

                    if (resizableCtrlEle) {
                        let maxCharLimitVal: any = resizableCtrlEle.getAttribute('maxcharallowed');
                        if (maxCharLimitVal == null) {
                            maxCharLimitVal = resizableCtrlEle.getAttribute('data-maxcharlimit');
                        }
                        let nameCharInputLimit: any = '';

                        if (ctrlName && ctrlName.toLowerCase() === 'name') {
                            nameCharInputLimit = 'nameCharInputLimit';
                        }
                        else if (ctrlName && ctrlName.toLowerCase() === 'title') {
                            nameCharInputLimit = 'titleCharInputLimit';
                        }
                        else if (ctrlName && ctrlName.toLowerCase() === 'text') {

                            nameCharInputLimit = 'textCharInputLimit';
                        }

                        if (nameCharInputLimit) {
                            let charInputLimit: any = document.getElementById(nameCharInputLimit);
                            if (charInputLimit && maxCharLimitVal && maxCharLimitVal != "null") {
                                charInputLimit.value = maxCharLimitVal;
                            }
                            else charInputLimit.value = "";
                        }

                    }
                }
                else if (ctrlName && ctrlName.toLowerCase() === 'hyperlink') {
                    let dataHperlink: any = controlEle.getAttribute('data-hyperlink');
                    let inputHyperlinkText: any = document.getElementById('inputHyperlinkText');
                    let spnControlEle: any = document.getElementById('spn' + currentSelectedControl);
                    let inputHyperLinkURL: any = document.getElementById('inputHyperLinkURL');
                    if (spnControlEle) {
                        if (spnControlEle.getAttribute('isupdated') === 'true' || (spnControlEle.innerHTML != "" && spnControlEle.innerHTML != null)) {
                            if (/^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(spnControlEle.innerHTML)) {
                                if (inputHyperlinkText) inputHyperlinkText.value = spnControlEle.innerHTML;
                                spnControlEle.setAttribute('isupdated', "true");
                            }
                            else {
                                if (inputHyperlinkText) inputHyperlinkText.value = "";
                            }
                        }
                        else {
                            if (inputHyperlinkText) inputHyperlinkText.value = '';
                        }
                        if (inputHyperLinkURL && dataHperlink) {
                            setTimeout(() => {
                                inputHyperLinkURL.value = dataHperlink && dataHperlink != "null" ? dataHperlink : '';
                            }, 200);
                        }
                    }
                }
            }
        }
    }

    resetChangesForIntegration(id: any) {
        this.userSelectedEntity = true;
        this.controlPropertyService.resetTextAreaInputs();
        let resetExternalTextoptions: any = document.getElementById('externalTextoptions');
        if (resetExternalTextoptions) {
            resetExternalTextoptions.value = 'Select';
        }
        this.controlPropertyService.resetTextToTextArea(id);
        let ctrlEle: any = document.getElementById('resizable_' + id);
        if (ctrlEle) {
            ctrlEle.setAttribute('data-EntityField', '');
            ctrlEle.setAttribute('data-EntityFieldValue', '');
        }
    }

    //Selecting controls using mouse selection
    controlSelection() {
        let selectableElement: any = $("#ctrlSelectable").selectable();
        let selectedIds: any = [];
        if (this.isEnvelopeEditable) {
            selectableElement.selectable({
                delay: 150,
                selecting: (event: any, ui: any) => {
                    $('.ui-selectable-helper').css('border', '2px dashed #3ab16d');
                    $('.ui-selectable-helper').css('background-color', 'rgba(58, 177, 109, 0.1)');
                    selectedIds = [];
                    this.selectedItems = [];
                    this.commonService.selectedItems = [];
                },
                selected: (event: any, ui: any) => {
                    $(ui.selected).each((index: number, element: any) => {
                        const id = $(element).attr('id');
                        if ($('.ui-selectable-helper') && $('.ui-selectable-helper')[0] && $('.ui-selectable-helper')[0].style.display !== 'none') {
                            if (id && id.startsWith('resizable_')) {
                                selectedIds.push(id);
                                this.selectedItems.push(id);
                                this.ctrlAndClickedControlsArr.push(id);
                                let resizableEle: any = document.getElementById(id);
                                if (resizableEle) {
                                    resizableEle.style.background = 'bisque';
                                    //resizableEle.style.borderColor = 'rgb(128, 0, 128);';

                                    let attr_data_guid: any = resizableEle.getAttribute('data-guid');
                                    let spnSelectEle: any = document.getElementById('spn' + id.replace('resizable_', ''));
                                    if (spnSelectEle) {
                                        if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                                            spnSelectEle.classList.add('mouse-selected');
                                        }
                                        spnSelectEle.style.background = '';
                                    }
                                }
                            }
                        }

                        //console.log('selectedIds', selectedIds);
                        let length: any = this.selectedItems.length;
                        // localStorage.setItem('selecteditemsLength', length);
                        this.sessionStorageService.setSelecteditemsLength(length);
                        this.commonService.setSelectedItems(this.selectedItems);
                        if (selectedIds && selectedIds.length === 0) {
                            selectableElement.children().removeClass('ui-selected');
                            this.onClickOutsideSelectionArea();
                        }
                    });
                },
                stop: function (event: any, ui: any) {
                    // Show helper after selection is done
                    $('.ui-selectable-helper').css('display', 'block');
                }
            });
        }
    }

    //user click on control outside area
    onClickOutsideSelectionArea() {
        if (this.selectedItems && this.selectedItems.length) {
            this.controlPropertyService.removeCtrlSelectionBgColor(this.selectedItems);
        }
        else if (this.ctrlAndClickedControlsArr && this.ctrlAndClickedControlsArr.length) {
            this.controlPropertyService.removePreviousControlSelection(this.ctrlAndClickedControlsArr);
        }

        this.ctrlAndClickedControlsArr = [];
        this.isClose = true;
        this.activeCtrlId = 0;
        this.controlPropertyService.removeControlActiveClass("");
        this.hidePagesViewSection = false;
        this.hideControlViewSection = this.isClose;
        this.selectedItems = [];
        this.commonService.selectedItems = [];
        this.sessionStorageService.setSelecteditemsLength('0');
        setTimeout(() => {
            this.enableFieldAlignment();
            this.updateExtendDocumentWidth();
        }, 100);
    };

    //After editing integration controls, updating respective fields values
    updateIntegrationFields(dataEntity: any, dataEntityField: any) {
        let resetEntityFields: any = document.getElementById('entityFields');
        let resetExternalTextoptions: any = document.getElementById('externalTextoptions');
        if (resetExternalTextoptions) {
            resetExternalTextoptions.value = dataEntity;
            if (resetExternalTextoptions.value !== 'Select' || resetExternalTextoptions.value !== '') {
                this.userSelectedEntity = false;
            }
        }
        if (resetEntityFields) {
            resetEntityFields.value = this.controlPropertyService.getValueByKey(dataEntityField, this.externalEntityFieldList);
        }
    }

    getTemplateOrEnvelopeClass() {
        let className: string = 'col-lg-8 col-md-8'
        console.log('getTemplateOrEnvelopeClass class invoked');
        if (this.isTemplate) {
            className = 'col-lg-6 col-md-6';
        }
        return className;
    }

    onCopyFun() {
        this.updateUndoCtrlsCount();
        this.arrCopyPasteCtrlsTemp = [];
        // localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify(this.arrCopyPasteCtrlsTemp));
        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));
        this.commonService.setSelectedItems(this.selectedItems);
        this.sessionStorageService.setSelecteditemsLength(this.selectedItems.length);
        this.isCopiedSelecteditems = true;
        // localStorage.setItem('isCopiedSelecteditems', 'true');
        this.sessionStorageService.setIsCopiedSelecteditems('true');
        let pasteBtnDisabled: any = document.getElementById('Paste_Btn');
        if (pasteBtnDisabled) {
            pasteBtnDisabled.classList.remove('disabled3');
        }
        this.isCtrlAndControlMouseSelection = true;
    }

    onPasteFun(data: any) {
        if (this.isCopiedSelecteditems === true) {
            // let arrCopyPasteCtrlsTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
            let arrCopyPasteCtrlsTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
            this.arrCopyPasteCtrlsTemp = JSON.parse(arrCopyPasteCtrlsTemp);
            if (this.arrCopyPasteCtrlsTemp && this.arrCopyPasteCtrlsTemp.length > 0) {
                this.updateCopyAndPasteControls();
            } else {
                this.createDupicateControl(data);
            }
            this.isCtrlAndControlMouseSelection = true;
            if (this.selectedItems && this.selectedItems.length) {
                this.controlPropertyService.removeCtrlSelectionBgColor(this.selectedItems);
            }
        }
    }

    onRedoFun(data: any) {
        // let arrUndoCtrlsCount: any = localStorage.getItem('arrUndoCtrlsCount');
        let arrUndoCtrlsCount: any = this.sessionStorageService.getArrUndoCtrlsCount();
        this.arrUndoCtrlsCount = JSON.parse(arrUndoCtrlsCount);
        // let arrCopyPasteCtrlsTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
        let arrCopyPasteCtrlsTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
        this.arrCopyPasteCtrlsTemp = JSON.parse(arrCopyPasteCtrlsTemp);
        if (this.arrUndoCtrlsCount && this.arrUndoCtrlsCount.length > 0) {
            this.arrUndoCtrlsCount.pop();
            // localStorage.setItem('arrUndoCtrlsCount', JSON.stringify(this.arrUndoCtrlsCount));
            this.sessionStorageService.setArrUndoCtrlsCount(JSON.stringify(this.arrUndoCtrlsCount));
            if (this.arrCopyPasteCtrlsTemp && this.arrCopyPasteCtrlsTemp.length > 0) {
                this.updateCopyAndPasteControls();
            } else {
                this.createDupicateControl(data);
            }
            this.disableRedoButtons();
            this.disableUndoButtons();
        }
    }

    resetCopyPasteUndoRedoList() {
        this.sessionStorageService.setSelecteditemsLength('0');
        this.arrCopyPasteCtrlsTemp = [];
        this.sessionStorageService.setArrCopyPasteCtrlsTemp(JSON.stringify(this.arrCopyPasteCtrlsTemp));
        this.arrUndoRedoCtrl = [];
        this.arrCopyPasteCtrl = [];
        this.sessionStorageService.setArrUndoRedoCtrl(JSON.stringify(this.arrCopyPasteCtrlsTemp));
        this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
        this.selectedItems = [];
        this.commonService.selectedItems = [];
    }

    applyPrefillLabelTextProp(type: any) {
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        this.controlPropertyService.applyPrefillLabelTextProp(currentSelectedControl, type);
    }

    showDependenciesFields(type: any) {
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let addmiddleClass: any = (document.getElementById('middle-section')) as HTMLSelectElement;
        let depencieContainerResponsive: any = (document.getElementById('depencieChanges')) as HTMLSelectElement;

        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        if (ctrlEle) {
            let controlGuid = ctrlEle.getAttribute('data-guid');
            if (controlGuid.toLowerCase() == Control.DropDown.toLowerCase()) {
                let currentDrpCtrl: any = (document.getElementById("spn" + currentSelectedControl)) as HTMLSelectElement;
                if (currentDrpCtrl && currentDrpCtrl.options && currentDrpCtrl.options.length == 0) {
                    this.toastrService.warning("Please add options for the selected dropdown control.", 'Warning'); return;
                }
            }
            else if (controlGuid.toLowerCase() == Control.Radio.toLowerCase()) {
                let controlGrpName: any = ctrlEle.getAttribute('data-groupname');
                if (controlGrpName == undefined || controlGrpName == "" || controlGrpName == null || controlGrpName == "null") {
                    this.toastrService.warning("Please add group name for selected radio control.", 'Warning'); return;
                }
            }
        }

        this.dependencyIconClickedObj = {
            type: type == '1' ? '1' : '',
            groupColorCode: this.groupColorCode,
            currentControlId: this.currentControlId,
            isControlingField: this.isControlingField(this.currentControlId)
        }
        this.commonService.callDependencyPopupFields(this.dependencyIconClickedObj);
        this.hideDependencies = !this.hideDependencies;
        // if(depencieContainerResponsive){
        // 	depencieContainerResponsive.classList.add('depencie-W');
        // }
        if (this.isClose === false && this.hideDependencies == false) {
            // depencieContainerResponsive.classList.remove('depencie-AdjustW');
            depencieContainerResponsive.classList.add('depencie-W');
            this.onPreviewPagesView();
        }

        if (this.hidePagesViewSection == true || this.hideControlViewSection == true) {
            setTimeout(() => {
                let divItem: any = document.getElementById("div" + this.currentControlId);
                if (divItem) {
                    divItem.style["border-color"] = "transparent transparent transparent bisque";
                }
                this.hidePagesViewSection = true;
                this.isClose = true;
                this.hideControlViewSection = this.isClose;
                addmiddleClass.classList.remove('col-lg-8');
                addmiddleClass.classList.add('col-lg-10');
                depencieContainerResponsive.classList.remove('depencie-AdjustW');
                depencieContainerResponsive.classList.add('depencie-W');
            }, 100);
        }
        this.accordaionExapand();
        // this.onPreviewPagesView();
    }

    closeDependecie($event: any) {
        this.dependecieClose = $event;
        let addmiddleClass: any = (document.getElementById('middle-section')) as HTMLSelectElement;
        if (this.dependecieClose == false || this.hidePagesViewSection == false) {
            this.hideDependencies = !this.hideDependencies;
            this.hidePagesViewSection = false;
            if (this.hidePagesViewSection == false || this.hideControlViewSection == true) {
                addmiddleClass.classList.remove('col-lg-10');
                addmiddleClass.classList.add('col-lg-8');
            }
        }
        this.accordaionExapand();
    }

    openTemplateSaveDropdown() {
        this.isTemplateSaveDropdown = true;
    }

    toggleAllAccordions() {
        this.controlPropertyService.toggleAllAccordions();
    }

    accordaionExapand() {
        this.controlPropertyService.accordaionExapand();
    }

    closeTemplateSaveDropdown() {
        this.isTemplateSaveDropdown = true;
    }

    updatedSaveAsDefaultSavedData(defaultControlProperty: any, envelopeCultureInfo: any) {
        this.defaultControlStyleService.updatedSaveAsDefaultSavedData(defaultControlProperty, envelopeCultureInfo);
    }

    saveAsDefaultSaveSettings(ctrlsObj: any) {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            let userDetails: any = this.authService.getUserDetails();
            let SettingsPrepareModal: any = this.defaultControlStyleService.saveAsDefaultSaveSettings(userDetails, ctrlsObj);
            this.apiService.saveSettings(SettingsPrepareModal).subscribe((data: any) => {
                //console.log("dsf");
            });
        }
    }

    updateUndoCtrlsCount() {
        this.arrUndoCtrlsCount = [];
        this.sessionStorageService.setArrUndoCtrlsCount(JSON.stringify(this.arrUndoCtrlsCount));
    }

    disableUndoButtons() {
        return this.controlPropertyService.disableUndoButtons(this.isEnvelopeEditable);
    }

    disableRedoButtons() {
        return this.controlPropertyService.disableRedoButtons(this.isEnvelopeEditable);
    }
    getBrandingDetails() {
        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (this.authService.isTokenExists() == "false") {
            this.hostListenerStatus = true;
            this.commonService.redirectToMainApp(getIntializePrepareDetails.SourceURL);
        }
        else {
            let getUserDetails: any = this.authService.getUserDetails();
            let companyId: any = getUserDetails.companyID;
            this.apiService.getBrandingDetails(companyId).subscribe((resp: any) => {
                if (resp) {
                    let brandingTemparray: any[] = [];
                    if (this.envelope.envelopeDetails.branding == null) {
                        this.slides = resp;
                    }
                    else {
                        let brandingDetails: any = JSON.parse(this.envelope.envelopeDetails.branding);
                        if (brandingDetails[0].isDefault === false) {
                            brandingDetails[0].isDefault = true;
                        }

                        if (brandingDetails[0].IsDefault === false) {
                            brandingDetails[0].IsDefault = true;
                        }

                        brandingTemparray.push(brandingDetails[0]);
                        resp.forEach((ele: any) => {
                            if (ele.id != (brandingDetails[0].id || brandingDetails[0].ID)) {
                                ele.isDefault = false;
                                brandingTemparray.push(ele);
                            }

                        });
                        this.slides = brandingTemparray;
                    }

                    this.slides.map((item: any) => {
                        if (item.isDefault || item.IsDefault) {
                            this.branding = `[${JSON.stringify(item)}]`;
                            this.defaultBranding = `[${JSON.stringify(item)}]`;
                        }
                    })
                }
            });
        }
    }

    updateTempTotalCountPerPage() {
        this.tempTotalCountPerPage = [];
        for (let i = 0; i < this.imagesArr.length; i++) {
            this.tempTotalCountPerPage.push({ id: i, controlsCount: this.imagesArr[i].controlsCount, documentId: this.imagesArr[i].documentId });
        }
    }

    addInitialCtrlOnEachPage(ctrlObj: any, data: any) {
        //console.log('ctrlObj', ctrlObj);
        this.copyPastedcontrolsArr = [];
        let left: any = ctrlObj.left;
        let top: any = ctrlObj.top;
        let currentPageNo: any = ctrlObj.pageNo;
        let ctrlEle: any = document.getElementById(ctrlObj.pageNo);
        if (ctrlEle && ctrlEle.getAttribute('img-document-id')) {
            let currentCtrlEle: any = document.getElementById('resizable_' + ctrlObj.id);
            if (currentCtrlEle) {
                let positions: any = this.controlPropertyService.getElePositions(currentCtrlEle);
                left = parseFloat(positions.Left);
                top = parseFloat(positions.Top);
            }
            let documentImages: any = document.querySelectorAll('.img-control');
            let length: any = data.secondInputval;
            let currentCtrlPageNum: any = parseInt(currentCtrlEle.getAttribute('pageno'));
            let currentCtrlPageEle: any = document.getElementById(currentCtrlPageNum);
            let currentCtrlPageWidth: any = currentCtrlPageEle.width;

            //let currentCtrlPageHeight:any = currentCtrlPageEle.height;
            Array.from(documentImages).forEach((ele: any, index: any) => {
                if (data.selectedOption === 'Place From Here') {
                    // index = parseInt(currentPageNo);
                    index = data.firstInputval;
                }
                if (ele) {
                    let imgEle: any = document.getElementById(ele.id.split('_')[1]);
                    ctrlObj.pageNo = parseInt(ele.id.split('_')[1]);
                    let currentCtrlHeight: any = (top + currentCtrlEle.offsetHeight);
                    let currentCtrlWidth: any = (left + currentCtrlEle.offsetWidth);

                    // imgEle.getAttribute('img-document-id') === ctrlEle.getAttribute('img-document-id') &&
                    if (ctrlObj.pageNo >= index && imgEle && parseInt(currentPageNo) != ctrlObj.pageNo && ctrlObj.pageNo <= length &&
                        currentCtrlHeight < imgEle.height && currentCtrlWidth < imgEle.width && currentCtrlPageWidth === imgEle.width) {
                        this.createControl(ctrlObj, ControlConfig.Duplicate, 'true');
                    }
                }
            })
        }
        ctrlObj.pageNo = currentPageNo;

        let chInitRequiredContainer: any = document.getElementById('chInitRequiredContainer');
        if (chInitRequiredContainer) {
            chInitRequiredContainer.classList.add('place-on-initials-btn');
        }
        this.onClickOutsideSelectionArea();
    }

    onInitRequired(e: any) {
        let currentSelectedControl: any = this.sessionStorageService.getCurrentClickedDroppedControl();
        let resizableEle: any = document.getElementById('resizable_' + currentSelectedControl);
        let placeOnEachPageOfTheDocument: any = resizableEle.getAttribute('placeOnEachPageOfTheDocument');
        let initialStateObj: any = {
            initialsCtrl: currentSelectedControl,
            totalImages: this.imagesArr.length
        }
        let modalRef: any = this.modalService.show(InitialsCreationComponent, { initialState: { initialStateObj: initialStateObj }, keyboard: false, backdrop: 'static', class: 'modal-mt modal-mw' });
        modalRef.content?.initialsChangesMade.subscribe((data: any) => {
            if (currentSelectedControl && placeOnEachPageOfTheDocument != 'true') {
                this.showLoader = true;
            }
            setTimeout(() => {
                if (currentSelectedControl && placeOnEachPageOfTheDocument != 'true') {
                    let currentCtrlELe: any = document.getElementById(currentSelectedControl);
                    let currentCtrlEleResizable: any = document.getElementById('resizable_' + currentSelectedControl);
                    let spnCtrlEleResizable: any = document.getElementById('spn' + currentSelectedControl);
                    let ctrlPositions = this.controlPropertyService.getElePositions(currentCtrlEleResizable);
                    if (currentCtrlELe && currentCtrlEleResizable && spnCtrlEleResizable) {
                        currentCtrlEleResizable.setAttribute('placeOnEachPageOfTheDocument', true);
                        currentCtrlEleResizable.setAttribute('isCheckedInitEachPage', true);
                        currentCtrlEleResizable.setAttribute('fromParentInitialCtrl', true);
                        let textFormatting: any = {
                            color: spnCtrlEleResizable.style.color,
                            fontID: spnCtrlEleResizable.getAttribute('font-family-id'),
                            fontFamily: spnCtrlEleResizable.style.fontFamily,
                            fontSize: spnCtrlEleResizable.style.fontSize,
                            fontWeight: spnCtrlEleResizable.fontWeight,
                            textDecoration: spnCtrlEleResizable.fontWeight,
                            fontStyle: spnCtrlEleResizable.fontWeight,
                            lineHeight: spnCtrlEleResizable.style.fontSize,
                            textAlign: 'left'
                        };

                        let controlObj: any = {
                            id: currentSelectedControl,
                            controlName: currentCtrlELe.getAttribute('data-controlname'),
                            pageNo: currentCtrlELe.getAttribute('data-pageno'),
                            dataDocumentContentId: currentCtrlELe.getAttribute('data-document-content-id'),
                            dataGuid: currentCtrlEleResizable.getAttribute('data-guid'),
                            recipientId: currentCtrlEleResizable.getAttribute('data-rcptid'),
                            recpColor: currentCtrlEleResizable.getAttribute('data-recp-color'),
                            top: ctrlPositions.Top,
                            left: ctrlPositions.Left,
                            height: currentCtrlEleResizable.style.height,
                            width: currentCtrlEleResizable.style.width,
                            isRequired: currentCtrlEleResizable.getAttribute('data-guid'),
                            textFormatting: textFormatting
                        };
                        this.addInitialCtrlOnEachPage(controlObj, data);
                    }
                    this.showLoader = false;
                    if (data.selectedOption === 'Place On All Pages') {
                        if (this.isPortrait == true && this.isLandscape == true) {
                            this.toastrService.info('Controls placed on all pages that have the same orientation.', 'Info');
                        }
                        else this.toastrService.info('Controls placed on all pages.', 'Info');
                    } else {
                        if (this.isPortrait == true && this.isLandscape == true) {
                            this.toastrService.info('Controls placed on selected page range ' + data.firstInputval + ' to ' + data.secondInputval + ' that have the same orientation.', 'Info');
                        }
                        else this.toastrService.info('Controls placed on selected page range ' + data.firstInputval + ' to ' + data.secondInputval + '.', 'Info');
                    }
                }
                else this.showLoader = false;
            }, 10);
        });
    }

    // Start redirecting user to RSign LogOn Page operation
    redirectRSignLogOnPage() {
        this.hostListenerStatus = true;
        this.showLoader = false;
        this.toastrService.info(CommonConstants.SessionTimedOutMsg, 'Info');
        this.sessionStorageService.setIntializePrepareDetails('EnvelopeID', '');
        this.sessionStorageService.setIntializePrepareDetails('EmailId', '');
        this.sessionStorageService.setIntializePrepareDetails('PageName', '');

        let getIntializePrepareDetails: any = this.sessionStorageService.getIntializePrepareDetails();
        if (getIntializePrepareDetails && getIntializePrepareDetails != null && getIntializePrepareDetails.SourceUrlType && getIntializePrepareDetails.SourceUrlType != "") {
            let sourceUrl: any = this.commonService.generateDomainRediRectURL(getIntializePrepareDetails.SourceUrlType);
            window.location.href = sourceUrl + NavigateUrlConfig.LogOn;
        }
        else {
            this.router.navigate(['/global-error']);
        }
    }
    // End redirecting user to RSign LogOn Page operation

    // Start Handle control drop and undo operation
    performUndoRedoAction(actionType: string, payload: any) {
        this.undoRedoItems.push(payload);
        // Push the action onto the undo stack
        this.undoRedoStackService.push({ type: actionType, payload });
    }
    // End Handle control drop and undo operation

    // Start Handle control delete operation
    deleteItems(ids: any) {
        this.deleteCtrlItemsFlag = true;
        this.undoFlag = 'true';
        let deletedItemsArr: any = [];
        for (let i = 0; i < ids.length; i++) {
            let delEle: any = document.getElementById(ids[i]);
            let tobeDeletedCtrl: any = (<HTMLInputElement>document.getElementById(ids[i].replace('resizable_', '')));
            if (delEle) {
                let ctrlContentId: any = tobeDeletedCtrl.getAttribute('data-document-content-id');
                delEle.style.display = 'none';

                //Delete the control from local storage arrays
                this.controlPropertyService.updateDependecyForDependentControls(ids[i].replace('resizable_', ''), ctrlContentId);
            }
            if (this.allControlsArr && this.allControlsArr.length > 0) {
                this.allControlsArr.forEach((ele: any) => {
                    if (ele.id === ids[i].replace('resizable_', '')) {
                        deletedItemsArr.push(ele);
                    }
                })
            }
        }

        // Push the delete action to the undo stack
        this.undoRedoStackService.push({
            type: 'DELETE_ITEM',
            payload: [{
                index: this.undoRedoStackService['undoStack'].length,
                item: deletedItemsArr,
            }],
        });

        setTimeout(() => {
            this.showHideDependenciesButton();
            this.updateExtendDocumentWidth();
        }, 10); //50

        this.getControlsCount(event, '', '');
    }
    // End Handle control delete operation

    // Start Handle control undo operation
    undoCtrl(event: any) {
        let action: any = this.undoRedoStackService.undo();
        this.arrCopyPasteCtrl = [];
        this.sessionStorageService.setArrCopyPasteCtrl(JSON.stringify(this.arrCopyPasteCtrl));
        if (action) {
            this.redoFlag = 'true';
            // Revert state based on the action
            if (action && action.type === 'DELETE_ITEM' && action.payload && action.payload[0].item) {
                for (let i = 0; i < action.payload[0].item.length; i++) {
                    this.undoRedoStackService.resetBgColorAndActiveClass(action.payload[0].item[i].id, this.isEnvelopeEditable, 'block');
                    this.undoRedoItems.splice(action.payload[0].index, 0, action.payload[0].item[i]);
                }
            }
            else if (action && action.type === 'ADD_ITEM') {
                if (action && action.payload && action.payload && action.payload.length) {
                    for (let i = 0; i < action.payload.length; i++) {
                        this.undoRedoStackService.resetBgColorAndActiveClass(action.payload[i].id, this.isEnvelopeEditable, 'none');
                    }
                }
            }

            this.getControlsCount(event, '', '');
        }
        this.undo({});
    }
    // End Handle control undo operation

    // Start Handle control redo operation
    redoCtrl(event: any) {
        let action: any = this.undoRedoStackService.redo();
        if (action) {
            // Reapply state based on the action
            if (action && action.type === 'DELETE_ITEM' && action.payload && action.payload[0].item) {
                for (let i = 0; i < action.payload[0].item.length; i++) {
                    this.undoRedoStackService.resetBgColorAndActiveClass(action.payload[0].item[i].id, this.isEnvelopeEditable, 'none');
                    this.undoRedoItems.splice(action.payload[0].index, 0, action.payload[0].item[i]);
                }
                const index = action.payload[0].index;
                this.undoRedoItems.splice(index, 1);
            }

            if (action && action.payload) {
                if (action && action.payload && action.payload && action.payload.length) {
                    this.undoRedoStackService.redoCtrlResetCtrlBgs(action, this.isEnvelopeEditable);
                }
            }
        } else {
            this.redoFlag = 'false';
        }
        this.getControlsCount(event, '', '');
    }
    // End Handle control redo operation

    // Start Handle control paste operation
    onPaste(): void {
        if (this.isCopiedSelecteditems === true) {
            let arrCopyPasteCtrlsTemp: any = this.sessionStorageService.getArrCopyPasteCtrlsTemp();
            this.arrCopyPasteCtrlsTemp = JSON.parse(arrCopyPasteCtrlsTemp);
            this.copyPastedcontrolsArr = [];
            if (this.arrCopyPasteCtrlsTemp && this.arrCopyPasteCtrlsTemp.length > 0) {
                this.updateCopyAndPasteControls();
            } else {
                let data: any = {};
                this.createDupicateControl(data);
            }
            this.isCtrlAndControlMouseSelection = true;
            if (this.selectedItems && this.selectedItems.length) {
                this.controlPropertyService.removeCtrlSelectionBgColor(this.selectedItems);
            }
        }

    }
    // End Handle control paste operation

    //Start If broken image is there then reload the image again
    updateImageSrc(imgPath: any, id: any) {
        if (parseInt(id) <= parseInt(this.envelope.envelopeDetails.documentImageConversionMaxLimit)) {
            console.log("updateImageSrc ----> imgPath:" + imgPath);
            setTimeout(() => {
                var newImage = new Image();
                newImage.src = imgPath;
                setTimeout(() => {
                    if (!newImage.complete) {
                        console.log("updateImageSrc ----> Image not completed and path is:" + newImage.src);
                        if (newImage.src) {
                            let imageId: any = newImage.src.split('=')[1].split('&')[0];
                            if (imageId) {
                                this.updateImageSrc(newImage.src, imageId);
                            }
                        }
                    }
                    else if (newImage && newImage.complete && newImage.width > 0 && newImage.height > 30) {
                        let imageId: any = newImage.src.split('=')[1].split('&')[0];
                        if (imageId) {
                            let imageObj: any = this.imagesArr[parseInt(imageId) - 1];
                            if (imageObj && parseInt(imageObj.id) == parseInt(imageId)) {
                                this.imagesArr[parseInt(imageId) - 1] = {
                                    path: imageObj.path, isActive: false, id: imageObj.id, imageId: imageObj.id, documentId: imageObj.documentId, width: imageObj.width, height: imageObj.height, isVisible: true, isTemplateDatedBeforePortraitLandscapeFeature: imageObj.isTemplateDatedBeforePortraitLandscapeFeature, docPageNo: imageObj.docPageNo,
                                    document: { id: imageObj.document.id, name: imageObj.document.name }, controlsCount: 0
                                };
                                let imgControl: any = document.getElementById("imgControl_" + imageObj.id);
                                if (imgControl && imgControl != null) {
                                    imgControl.style.display = '';
                                    imgControl.style.display = 'block';
                                }
                                console.log("updateImageSrc ----> Image completed and path is:" + imgPath);
                            }
                        }
                    }
                    else {
                        console.log("updateImageSrc ----> Image not loaded and path is:" + imgPath);
                        if (newImage.src) {
                            let imageId: any = newImage.src.split('=')[1].split('&')[0];
                            if (imageId) {
                                this.updateImageSrc(newImage.src, imageId);
                            }
                        }
                    }
                }, 15);
            }, 30);
        }
    }
    //End If broken image is there then reload the image again
    toggleHideTextWithAsterisks(isChecked: any): void {
        const textInput: any = document.getElementById('addTextTextarea');
        this.asteriskOriginalText = textInput.value.trim();
        if (isChecked) {
            const trimmedText = textInput.value.trim(); // Trim extra spaces
            const asterisks = '*'.repeat(trimmedText.length); // Replace text with asterisks
            this.hiddenText = asterisks; // Store asterisks as the original value
        } else {
            textInput.value = this.asteriskOriginalText; // Restore original text (not asterisks)
        }
    }
    showHiddenTextATFocusIn(event: any): void {
        const textInput: HTMLTextAreaElement = <HTMLTextAreaElement>document.getElementById('addTextTextarea');
        const textAreaVal: string = event.target.value.trim();
        const checkbox: HTMLInputElement = <HTMLInputElement>document.getElementById('checkboxHideText');
        let currentSelectedControl: any = this.currentControlId;
        let ctrlEle: any = document.getElementById('resizable_' + currentSelectedControl);
        if (ctrlEle) {
            this.asteriskOriginalText = ctrlEle.getAttribute('data-asteriskOriginalText');
            let addtextareaval: any = ctrlEle.getAttribute('data-addtextareaval');
            if (addtextareaval === null || addtextareaval === undefined || addtextareaval === '') {
                this.asteriskOriginalText = addtextareaval;
            }
        }
        // Show original text only there's a value
        if (this.asteriskOriginalText != 'null') {
            textInput.value = this.asteriskOriginalText;
        }
    }
}