import { Injectable } from '@angular/core';
import { ControlPropertyService } from '../services/control.properties.service';
import { Control } from '../constants/common-constants';

@Injectable({
    providedIn: 'root'
})
export class AlignControlsService {
    request: any = {  // Initialize request object with appropriate structure
        Type: '',
        Controls: []
    };

    maxLeft:any = 0;
    minLeftWithControl: any = 0;
    distWidth: any = 0;
    selectedCntrlAndWidth: any[] = [];
    pageWidth:any = 0;

    constructor(
        private controlPropertyService: ControlPropertyService
    ) { }


    fnRightAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        let RightPoint: any = 0;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left) + eleRef.offsetWidth;
            arrLeft.push(left);
        });

        RightPoint = Math.max(...arrLeft);

        let request: any = {
            //   EnvelopeID: document.getElementById('PrepareGlobalEnvelopeID').getAttribute('value'),
            //   EnvelopeStage: document.getElementById('EnvelopeStage').getAttribute('value'),
            Type: 'RightAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let rightValue: any = (RightPoint - element.offsetWidth)
            const newTransformValue = `translate3d(${rightValue}px, ${posObj.Top}px, 0px)`;
            element.style.left = rightValue+'px';
            // Set the new transform value to the element's style
            // element.style.transform = newTransformValue;
            // element.style.left = (RightPoint - element.offsetWidth) + 'px';
            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef);
            request.Controls.push({
                ID: control.DocumentContentID,
                XCoordinate: control.XCoordinate,
                YCoordinate: control.YCoordinate,
                ZCoordinate: control.ZCoordinate,
                Left: control.Left,
                Top: control.Top,
                Width: control.Width,
                Height: control.Height
            });
        });
    }

    fnLeftAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        let minLeft = Infinity;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);

            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left);
            arrLeft.push(left);
        });

        minLeft = Math.min(...arrLeft);

        let request: any = {
            //   EnvelopeID: this.elementRef.nativeElement.querySelector('#PrepareGlobalEnvelopeID').value,
            //   EnvelopeStage: this.elementRef.nativeElement.querySelector('#EnvelopeStage').value,
            Type: 'LeftAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            //const newTransformValue = `translate3d(${minLeft}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            // element.style.transform = newTransformValue;
            element.style.left = minLeft+'px';
            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef);
            request.Controls.push({
                ID: control.DocumentContentID,
                XCoordinate: control.XCoordinate,
                YCoordinate: control.YCoordinate,
                ZCoordinate: control.ZCoordinate,
                Left: control.Left,
                Top: control.Top,
                Width: control.Width,
                Height: control.Height
            });
        });
    }

    fnCenterAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        const arrWidth: number[] = [];
        let minLeft = Infinity;
        let centerPoint = 0;
        let maxWidth = 0;
        const selectedControlElements = selectedItems;
        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left);
            arrLeft.push(left);
            arrWidth.push(left + eleRef.offsetWidth);
        });

        minLeft = Math.min(...arrLeft);
        maxWidth = Math.max(...arrWidth);
        centerPoint = minLeft + ((maxWidth - minLeft) / 2);

        let request: any = {
            //   EnvelopeID: this.elementRef.nativeElement.querySelector('#PrepareGlobalEnvelopeID').value,
            //   EnvelopeStage: this.elementRef.nativeElement.querySelector('#EnvelopeStage').value,
            Type: 'CenterAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef);
            //let posObj: any = this.controlPropertyService.getElePositions(element);
            let left: any = (centerPoint - (element.offsetWidth / 2));
            //const newTransformValue = `translate3d(${left}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            // element.style.transform = newTransformValue;
            element.style.left = left+'px';
            // element.style.top = element+'px';
            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef);
            request.Controls.push({
                ID: control.DocumentContentID,
                XCoordinate: control.XCoordinate,
                YCoordinate: control.YCoordinate,
                ZCoordinate: control.ZCoordinate,
                Left: control.Left,
                Top: control.Top,
                Width: control.Width,
                Height: control.Height
            });
        });
    }

    fnTopAlign(selectedItems: any) {
        const arrTop: number[] = [];
        let minTop = Infinity;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const top = parseFloat(posObj.Top);
            arrTop.push(top);
        });

        minTop = Math.min(...arrTop);

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef);
            // let posObj: any = this.controlPropertyService.getElePositions(element);
            //const newTransformValue = `translate3d(${posObj.Left}px, ${minTop}px, 0px)`;

            // Set the new transform value to the element's style
            // element.style.transform = newTransformValue;
            element.style.top = minTop+'px';
            element.style.right = 'inherit';
        });
    }

    fnBottomAlign(selectedItems: any) {
        const arrBottom: number[] = [];
        let BottomPoint = 0;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const top = (parseFloat(posObj.Top) + eleRef.offsetHeight);
            arrBottom.push(top);
        });

        BottomPoint = Math.max(...arrBottom);

        selectedControlElements.forEach((ele: any) => {
            let getdataControlname: any = document.getElementById(ele.replace('resizable_', ''));
            const controlName = getdataControlname.getAttribute('data-controlname');

            let element: any = document.getElementById(ele);
            //let posObj: any = this.controlPropertyService.getElePositions(element);
            //let newTransformValue: any = `translate3d(${posObj.Left}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
           // element.style.transform = newTransformValue;

            // if (controlName === 'Signature') {
            //     //ele.style.top = (BottomPoint - ele.querySelector('.labelWrap').offsetHeight) + 'px';
            //     let topVal: any = (BottomPoint - element.offsetHeight);
            //     newTransformValue = `translate3d(${posObj.Left}px, ${topVal}px, 0px)`;
            // } else {
            // }
            let topVal: any = (BottomPoint - element.offsetHeight);
            //newTransformValue = `translate3d(${posObj.Left}px, ${topVal}px, 0px)`;
            //element.style.transform = newTransformValue;
            element.style.top = topVal+'px';
            element.style.bottom = 'inherit';
        });
    }

    fnMiddleAlign(selectedItems: any) {
        const arrTop: number[] = [];
        const arrHeight: number[] = [];
        let minTop = Infinity;
        let centerPoint = 0;
        let maxHeight = 0;
        const selectedControlElements = selectedItems;
        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            const top = parseFloat(posObj.Top);
            arrTop.push(top);
            arrHeight.push(top + eleRef.offsetHeight);
        });

        minTop = Math.min(...arrTop);
        maxHeight = Math.max(...arrHeight);
        centerPoint = minTop + ((maxHeight - minTop) / 2);

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let top: any = (centerPoint - (element.offsetHeight / 2));
            // const newTransformValue = `translate3d(${posObj.Left}px, ${top}px, 0px)`;
            // Set the new transform value to the element's style
            // element.style.transform = newTransformValue;
            element.style.left = posObj.Left +'px';
            element.style.top = top +'px';
            element.style.bottom = 'inherit';
        });
    }

    fnDistributeHorizontal(selectedItems: any) {
        let arrLeft: any[] = [];
        let arrWidth: any[] = [];
        let minLeft: any = 0;
        //let minLeftWithControl: any = 0;
        // let maxLeft: any = 0;
        let maxLeftWithControl: any = 0;
        this.distWidth = 0;
        this.selectedCntrlAndWidth = [];

        let selectedControl: any = selectedItems;

        if (selectedControl.length > 2) {
            let cntrlLeft: any = 0;
            let allCntrlWidth: any = 0;
            selectedControl.forEach((ele: any, i: any) => {
                let eleRef: any = document.getElementById(ele);
                let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                cntrlLeft = parseFloat(posObj.Left);
                this.selectedCntrlAndWidth.push({ "Control": ele, "Left": cntrlLeft });
                arrLeft.push(cntrlLeft);

                allCntrlWidth += eleRef.offsetWidth;
                arrWidth.push(parseFloat(cntrlLeft) + eleRef.offsetWidth);
            });

            this.minLeftWithControl = Math.min.apply(null, arrWidth);
            maxLeftWithControl = Math.max.apply(null, arrWidth);
            minLeft = Math.min.apply(null, arrLeft);
            this.maxLeft = Math.max.apply(null, arrLeft);

            if (this.maxLeft > this.minLeftWithControl) {
                this.distWidth = ((maxLeftWithControl - minLeft) - allCntrlWidth) / (selectedControl.length - 1);
            }

            if (this.distWidth != 0) {
                this.selectedCntrlAndWidth = this.selectedCntrlAndWidth.sort(function (x, y) { return ((x.Left > y.Left) ? 1 : (x.Left < y.Left) ? -1 : 0) });

                let controlLeft: any = 0;
                let controlLeftPos: any = 0;
                let prevCntrlLeft: any = this.minLeftWithControl;
                this.pageWidth = 0;

                this.selectedCntrlAndWidth.forEach((ctrl: any, i: any) => {
                    let eleRef: any = document.getElementById(ctrl.Control);
                    // if (!eleRef) return;
                    if (eleRef) {
                        let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                        controlLeft = parseFloat(posObj.Left);
                        let imgEle: any = document.getElementsByClassName('dispImg');
                        let getImgIndex: any = parseInt(eleRef.getAttribute('pageno'));
                        if(imgEle && imgEle.length && getImgIndex){
                            this.pageWidth = imgEle[getImgIndex - 1].offsetWidth;
                            let ctrlWidth: any = document.getElementById(ctrl.Control);
                            if (this.minLeftWithControl != (controlLeft + ctrlWidth.offsetWidth)) {
                                controlLeftPos = controlLeft + (this.distWidth - (controlLeft - prevCntrlLeft));
                                prevCntrlLeft = controlLeftPos + ctrlWidth.offsetWidth;

                                if (this.pageWidth > prevCntrlLeft) {
                                    let element: any = document.getElementById(ctrl.Control);
                                    let posObj: any = this.controlPropertyService.getElePositions(element);
                                    //const newTransformValue = `translate3d(${controlLeftPos}px, ${posObj.Top}px, 0px)`;

                                    // Set the new transform value to the element's style
                                    // element.style.transform = newTransformValue;
                                    element.style.left = controlLeftPos+'px';
                                    element.style.top = posObj.Top+'px';
                                    element.style.right = 'inherit';
                                } else {
                                    prevCntrlLeft = (controlLeft + ctrlWidth.offsetWidth);
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    fnDistributeVertical(selectedItems: any) {
        let arrTop: any[] = [];
        let arrHeight: any[] = [];
        let minTop: any = 0;
        let minTopWithControl: any = 0;
        let maxTop: any = 0;
        let maxTopWithControl: any = 0;
        let DistHeight: any = 0;
        let selectedCntrlAndHeight: any[] = [];

        let selectedControl: any = selectedItems;

        if (selectedControl.length > 2) {
            let cntrlTop: any = 0;
            let allCntrlHeight: any = 0;

            selectedControl.forEach((ele: any, i: any) => {
                let eleRef: any = document.getElementById(ele);
                let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                cntrlTop = parseFloat(posObj.Top);
                selectedCntrlAndHeight.push({ "Control": ele, "Top": cntrlTop });
                arrTop.push(cntrlTop);

                allCntrlHeight += eleRef.offsetHeight;
                arrHeight.push(parseFloat(cntrlTop) + eleRef.offsetHeight);
            });

            minTopWithControl = Math.min.apply(null, arrHeight);
            maxTopWithControl = Math.max.apply(null, arrHeight);
            minTop = Math.min.apply(null, arrTop);
            maxTop = Math.max.apply(null, arrTop);

            if (maxTop > minTopWithControl) {
                DistHeight = ((maxTopWithControl - minTop) - allCntrlHeight) / (selectedControl.length - 1);
            }

            if (DistHeight != 0) {
                selectedCntrlAndHeight = selectedCntrlAndHeight.sort(function (x, y) { return ((x.Top > y.Top) ? 1 : (x.Top < y.Top) ? -1 : 0) });

                let controlTop: any = 0;
                let controlTopPos: any = 0;
                let prevCntrlTop: any = minTopWithControl;
                let pageHeight: any = 0;
                selectedCntrlAndHeight.forEach((ctrl: any, i: any) => {
                    let eleRef: any = document.getElementById(ctrl.Control);
                    if (eleRef) {
                        let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                        controlTop = parseFloat(posObj.Top);
                        let imgEle: any = document.getElementsByClassName('dispImg');
                        let getImgIndex: any = parseInt(eleRef.getAttribute('pageno'));
                        if(imgEle && imgEle.length && getImgIndex){
                            pageHeight = imgEle[getImgIndex - 1].offsetHeight;
                            let ctrlHeight: any = document.getElementById(ctrl.Control);
                            if (minTopWithControl != (controlTop + ctrlHeight.offsetHeight)) {
                                controlTopPos = controlTop + (DistHeight - (controlTop - prevCntrlTop));
                                prevCntrlTop = controlTopPos + ctrlHeight.offsetHeight;

                                if (pageHeight > prevCntrlTop) {
                                    let element: any = document.getElementById(ctrl.Control);
                                    let posObj: any = this.controlPropertyService.getElePositions(element);
                                    //const newTransformValue = `translate3d(${posObj.Left}px, ${controlTopPos}px, 0px)`;

                                    // Set the new transform value to the element's style
                                    // element.style.transform = newTransformValue;
                                    element.style.left = posObj.Left+'px';
                                    element.style.top = controlTopPos+'px';
                                    element.style.bottom = 'inherit';
                                } else {
                                    prevCntrlTop = (controlTop + ctrlHeight.offsetHeight);
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    getControlProperty(id: string) {
        // Implement your logic to fetch control properties based on its ID
        return {
            DocumentContentID: 'sampleID',
            XCoordinate: 0,
            YCoordinate: 0,
            ZCoordinate: 0,
            Left: 0,
            Top: 0,
            Width: 0,
            Height: 0
        };
    }

    applyAlignChanges(selectedOptionVal: any, selectedItems: any, selectedDocumentImage: any, container: any) {
        switch (selectedOptionVal) {
            case 'left':
                this.fnLeftAlign(selectedItems);
                break;
            case 'center':
                this.fnCenterAlign(selectedItems);
                break;
            case 'right':
                this.fnRightAlign(selectedItems);
                break;
            case 'top':
                this.fnTopAlign(selectedItems);
                break;
            case 'middle':
                this.fnMiddleAlign(selectedItems);
                break;
            case 'bottom':
                this.fnBottomAlign(selectedItems);
                break;
            case 'distributehorizontally':
                this.fnDistributeHorizontal(selectedItems);
                break;
            case 'distributevertically':
                this.fnDistributeVertical(selectedItems);
                break;
        }

    }

}
